import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { useOnBlockEditSubmit } from '@ContractBuilder/modules/inline-block-edit/hooks/use-on-block-edit-submit';

import { useVariationsTabs } from '../../variations-tabs/context/context';
import { InlineBlockEdit } from '../views/InlineBlockEdit';

interface InlineBlockEditControllerProps {
  className?: string;
}

export const InlineBlockEditController: FC<InlineBlockEditControllerProps> = ({ className }) => {
  const { handleSubmit } = useForm<BlockEditFormState>();
  const { onSubmit, onSubmitAsNew } = useOnBlockEditSubmit();
  const { currentVariationIdx, variationsCount } = useVariationsTabs();

  useCustomEventListener(ArtificialCustomEvent.SubmitInlineBlockEdit, () => {
    const latestFormValues = useBlockEditFormStore.getState().formValues;

    if (latestFormValues) {
      return onSubmit(latestFormValues);
    }
  });

  useCustomEventListener(ArtificialCustomEvent.SubmitAsNewInlineBlockEdit, () => {
    const latestFormValues = useBlockEditFormStore.getState().formValues;

    if (latestFormValues) {
      return onSubmitAsNew(latestFormValues);
    }
  });

  return (
    <InlineBlockEdit
      className={className}
      onSubmit={handleSubmit(onSubmit)}
      currentVariationIdx={currentVariationIdx}
      hasVariations={variationsCount > 0}
    />
  );
};
