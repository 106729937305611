import { cva } from 'class-variance-authority';

export const classes = {
  list: 'flex basis-1/2 flex-col gap-0.5 overflow-y-scroll py-1',
  listItem: cva(['cursor-pointer rounded-md px-1 text-center leading-7 transition-colors hover:bg-info-100'], {
    variants: {
      isSelected: {
        true: 'bg-primary-50',
      },
    },
  }),
};
