import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import type { PdfDocumentType } from '@root/@types/types';
import qs from 'qs';

import type { EntityData } from '../containers/ContractBuilder/types';
import { getPdfDocument } from '../queries/getPdfDocument';

export const usePullPdfDocumentUrl = (
  resourceName: 'templates' | 'submissions' | 'endorsements',
  pdfDocumentType: PdfDocumentType,
  submission?: EntityData,
) => {
  const { id = '' } = useParams<{ id: string }>();
  const { endorsement_id = '' } = useParams<{ endorsement_id: string }>();
  const queryParams: { authToken?: string } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const expectedVersion = submission?.version ?? 1;
  const documentsQueryKey = ['documents', { id, endorsement_id, expectedVersion, pdfDocumentType }];

  const documentsQuery = useQuery(
    documentsQueryKey,
    () =>
      getPdfDocument(id, endorsement_id, queryParams?.authToken ?? '', resourceName, pdfDocumentType, expectedVersion),
    {
      retry: 999,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
      cacheTime: 60_000,
      staleTime: 60_000,
      keepPreviousData: true,
    },
  );

  return {
    url: documentsQuery?.data?.data?.url,
    documentVersion: documentsQuery?.data?.data?.documentVersion,
    expectedVersion,
    isLoading: documentsQuery.isLoading || documentsQuery.isFetching || documentsQuery.isRefetching,
    refetch: documentsQuery.refetch,
    documentPullUpdatedAt: documentsQuery.dataUpdatedAt,
  };
};
