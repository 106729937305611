interface Data {
  file: File;
}
export const uploadAttachmentDocument = async <T extends Data>(url: string, data: T) => {
  await fetch(url, {
    method: 'PUT',
    body: data.file,
    headers: {
      'Content-Type': 'application/pdf',
      'Content-Length': `${data?.file?.length || 0}`,
    },
  });
};
