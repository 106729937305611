import { CognitoAuthProvider } from './cognitoAuthProvider';
import { EntraAuthProvider } from './entraAuthProvider';
import type { IAuthProvider } from './types';

const getAuthProvider = (): IAuthProvider => {
  const authMethod = import.meta.env?.VITE_AUTH_METHOD;

  switch (authMethod) {
    case 'entra':
      return new EntraAuthProvider();
    default:
      return new CognitoAuthProvider();
  }
};

export default getAuthProvider();
