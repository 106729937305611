import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { UpdateBlockInLibraryPayload } from '@root/@types/types';
import axios from 'axios';

export const updateBlock = async (id: string, data: UpdateBlockInLibraryPayload): Promise<string> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `blocks-spa-al/${id}`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
