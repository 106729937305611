import React from 'react';
import { useLocation } from 'react-router-dom';
import { getPdfDocumentName } from '@pdf/custom/helpers/helpers';
import type { PdfDocumentType } from '@root/@types/types';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import { downloadDocument } from '../../../../helpers/downloadDocument';
import { usePullPdfDocumentUrl } from '../../../../hooks/pullDocumentUrl';
import { useEntityStore } from '../../store';
import type { EntityData } from '../../types';

const PdfModalForm = ({
  handleClose,
  submission,
  resourceName,
  pdfDocumentType,
}: {
  handleClose: () => void;
  submission?: EntityData;
  resourceName: 'templates' | 'submissions' | 'endorsements';
  pdfDocumentType: PdfDocumentType;
}) => {
  let pdfUrl: string | undefined = undefined;
  let pdfPreviewUrl = undefined;

  const toolbarEnabled: boolean = false;

  const pdfData = usePullPdfDocumentUrl(resourceName, pdfDocumentType, submission);

  if (pdfData?.url) {
    pdfPreviewUrl = `${pdfData.url}#page=1&toolbar=${toolbarEnabled ? 1 : 0}&zoom=auto&view=FitH&navpanes=0`;
    pdfUrl = pdfData.url;
  }

  const handleDownload = () => {
    if (pdfUrl) {
      downloadDocument(pdfUrl);
    }
  };

  return (
    <div
      className="pt-6"
      data-cypress="pdf-modal"
      data-cypress-pdf-document-version={pdfData?.documentVersion ?? 0}
      data-cypress-pdf-expected-version={pdfData?.expectedVersion ?? 0}
    >
      <div className="flex flex-col space-y-6">
        <div className="w-full border-b">
          <div className="flex justify-center">
            {pdfPreviewUrl && (
              <embed
                type="application/pdf"
                src={pdfPreviewUrl}
                className="m-0 h-[70vh] min-h-[70vh] bg-white p-0 md:w-[60rem]"
              />
            )}
            {!pdfPreviewUrl && (
              <div className="m-0 flex h-[70vh] min-h-[70vh] items-center justify-center p-0 md:w-[60rem]">
                <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-info-900" />
              </div>
            )}
          </div>
        </div>
        <div className="ml-3 mr-3 flex space-x-4">
          <Button
            onClick={handleClose}
            className="flex-1 rounded-lg"
            style={{ borderColor: 'rgba(209, 213, 219, 1)' }}
            data-cypress="close-pdf-button"
          >
            Cancel
          </Button>
          <Button
            className="flex-1 rounded-lg"
            kind="primary"
            onClick={handleDownload}
            isDisabled={!pdfUrl}
            data-cypress="download-pdf-button"
          >
            Download PDF
            <Icon name="download-pdf" className="ml-2 hidden md:inline" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const PdfModal = ({ handleClose }: { handleClose: () => void }) => {
  const location = useLocation();

  let resourceName: 'templates' | 'submissions' | 'endorsements' = 'submissions';
  if (location?.pathname?.startsWith('/templates')) {
    resourceName = 'templates';
  }
  if (location?.pathname?.includes('endorsements')) {
    resourceName = 'endorsements';
  }

  let pdfDocumentType: PdfDocumentType = 'submission-document';
  switch (resourceName) {
    case 'endorsements':
      pdfDocumentType = 'endorsement-summary'; // by default display endorsement summary document
      break;
    case 'templates':
      pdfDocumentType = 'template-document';
      break;
    default:
      pdfDocumentType = 'submission-document';
      break;
  }

  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  return (
    <Modal
      open
      onClose={handleClose}
      title={submission ? getPdfDocumentName(submission)?.longName : ''}
      className="min-h-[80vh] !px-0 md:min-w-[60rem]"
      titleClassName="px-6"
    >
      <PdfModalForm
        handleClose={handleClose}
        submission={submission}
        resourceName={resourceName}
        pdfDocumentType={pdfDocumentType}
      />
    </Modal>
  );
};

export default PdfModal;
