import clsx from 'clsx';

export const createMention = (id: string, className?: string): string => {
  const lastSegment = id.split('.').pop();
  if (lastSegment!.endsWith('_other')) {
    return '';
  }

  return `<span data-type="mention" title="${id}" class="${clsx(
    'mention',
    className,
  )}" data-id="${id}" contenteditable="false">{{${lastSegment}</span>`;
};
