import type { FC } from 'react';
import { useState } from 'react';
import { AddNewCommentController } from '@ContractBuilder/modules/add-new-comment/controller/AddNewCommentController';
import { PopoverInner } from '@ContractBuilder/modules/block/view/PopoverInner';
import { CommentSection } from '@ContractBuilder/modules/comments/views/CommentSection';
import type { EntityData } from '@ContractBuilder/types';
import type { DynamoDBResourceComment } from '@root/@types/database';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface CommentThreadPopoverInnerProps {
  blockId: string;

  data: DynamoDBResourceComment[];
  onAfterResolve?: (data?: EntityData) => void;
  threadId: string;
}

const getSortedCommentsByDate = (array: DynamoDBResourceComment[]) =>
  Array.from(array).sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();

    return dateB - dateA;
  });

export const CommentThreadPopoverInner: FC<CommentThreadPopoverInnerProps> = ({
  blockId,
  data,
  onAfterResolve,
  threadId,
}) => {
  const [isCommentInputVisible, setCommentInputVisibility] = useState(false);

  const handlePopoverClick = () => setCommentInputVisibility((current) => !current);

  const sortedComments = getSortedCommentsByDate(data);

  return (
    <motion.div className="w-full cursor-pointer transition" key={threadId}>
      <PopoverInner>
        <div className={clsx('transition hover:bg-info-50')} onClick={handlePopoverClick}>
          {sortedComments.map((comment, index) => (
            <CommentSection
              data={comment}
              key={`${comment.id}-${data.length}`}
              onAfterResolve={onAfterResolve}
              showResolveButton={index === 0}
              threadId={threadId}
            />
          ))}
        </div>
        {isCommentInputVisible && (
          <AddNewCommentController
            blockId={blockId}
            className="border-t border-info-200"
            onAfterSubmit={() => setCommentInputVisibility(false)}
            threadId={threadId}
          />
        )}
      </PopoverInner>
    </motion.div>
  );
};
