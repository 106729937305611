import type { FC } from 'react';
import { Spinner } from '@components/Spinner';
import clsx from 'clsx';

import { livePreviewUpdatingPillClasses } from './classes';

interface LivePreviewUpdatingProps {
  isUpdating: boolean;
}

export const LivePreviewUpdating: FC<LivePreviewUpdatingProps> = ({ isUpdating }) => {
  if (!isUpdating) {
    return null;
  }

  return (
    <div className={clsx(livePreviewUpdatingPillClasses)}>
      <span>Updating</span>
      <Spinner
        className="[&>*:first-child]:!fill-gray-300 [&>*:first-child]:!opacity-100 [&>path]:!fill-blue-600"
        size="xs"
      />
    </div>
  );
};
