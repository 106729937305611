import { isNonNullish } from '@root/helpers';

export const extractRepeatersFromContent = (content = '') => {
  const div = document.createElement('div');
  div.innerHTML = content;
  const sectionRepeaters = div.querySelectorAll('[data-type="section-repeater-child"]');
  const contentRepeaterGroups = div.querySelectorAll('[data-type="content-repeater-group-child"]');
  const result = [...sectionRepeaters, ...contentRepeaterGroups]
    .map((template) => template.getAttribute('data-parentid'))
    .filter(isNonNullish);
  div.remove();

  return result;
};
