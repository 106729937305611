import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import type { InputPercentageProps } from '../InputPercentage';
import InputPercentage from '../InputPercentage';

interface InputPercentageSchemaProps extends InputPercentageProps {
  item: any;
  ownKey: string;
}

const InputPercentageSchema: React.FC<InputPercentageSchemaProps> = ({ item, ownKey, ...props }) => {
  const { errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);
  return (
    <InputPercentage
      {...props}
      isIncomplete={isIncomplete}
      decimalScale={7}
      isAllowed={({ floatValue = 0, value = '' }) => {
        const [root] = value.split('.');

        if (root.length > 3) {
          return false;
        }

        if (value === '' || (!item?.maximum && !item?.minimum)) {
          return true;
        }

        if (item?.maximum) {
          return floatValue <= item?.maximum;
        }

        if (item?.minimum) {
          return floatValue >= item?.minimum;
        }

        return false;
      }}
      value={Array.isArray(value) ? 0 : value}
    />
  );
};

export default InputPercentageSchema;
