import type { FC } from 'react';
import React from 'react';
import Button from '@components/Button';
import InputText from '@components/InputText';
import type { UIInputValue } from '@root/@types/types';
import { useLanguage } from '@root/src/language';

import { AttachmentLoaded } from '../components/../components/AttachmentLoaded';

interface AttachmentDocumentRenameProps {
  attachmentName?: string;
  attachmentFileName?: string;
  isRenamingDisabled: boolean;
  isLoading: boolean;
  setAttachmentName: (value: UIInputValue) => void;
  handleClickRename: () => void;
  handleClose: () => void;
}

export const AttachmentRename: FC<AttachmentDocumentRenameProps> = ({
  handleClickRename,
  handleClose,
  attachmentName,
  attachmentFileName,
  setAttachmentName,
  isRenamingDisabled,
  isLoading,
}) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.attachment' });

  return (
    <>
      <InputText
        className="mb-6 mt-2"
        labelText={getContent('fields.name.label')}
        onChange={(event) => setAttachmentName(event.target.value)}
        value={attachmentName ?? ''}
      />
      <AttachmentLoaded fileName={attachmentFileName} />
      <div className="mt-8 flex w-full justify-end gap-4">
        <Button onClick={handleClose} className="h-10">
          Cancel
        </Button>
        <Button
          onClick={handleClickRename}
          className="h-10"
          kind="primary"
          type="submit"
          isDisabled={isRenamingDisabled}
          loading={isLoading}
        >
          Rename
        </Button>
      </div>
    </>
  );
};
