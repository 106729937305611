import { useCallback, useEffect, useState } from 'react';
import Debugger from '@components/Debugger';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import formatErrors from '@root/src/formatErrors';
import { canChange } from '@root/src/helpers/canChange';
import { useFeatureFlags } from '@src/hooks';
import { cloneDeep, debounce, set } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Form from '../../components/Form';
import mapChildren from '../../mapChildren';

import FormNavigation from './form-navigation/FormNavigation';
import { useEntityStore, useSchemaStore } from './store';

const ContractForm = () => {
  const { isLoading, submission } = useEntityStore(({ isLoading, submission }) => ({
    isLoading,
    submission,
  }));
  const { formValues, isDirty, isSubmitting, onChange, onSubmit, setFormValues } = useDatapointsStore(
    ({ isDirty, formValues, isSubmitting, onChange, onSubmit, setFormValues }) => ({
      formValues,
      isDirty,
      isSubmitting,
      onChange,
      onSubmit,
      setFormValues,
    }),
  );
  const { resolvedSchema, errors: validationErrors } = useSchemaStore(({ resolvedSchema, errors }) => ({
    resolvedSchema,
    errors,
  }));

  const disabledByStatus = !canChange(submission?.status, '', 'edit');
  const isViewingRevisionHistory = useIsRevisionHistory();
  const getFeatureFlagState = useFeatureFlags();

  const shouldShowDebugger = getFeatureFlagState('Debugger');
  const [isDebuggerVisible, setDebuggerVisible] = useState(shouldShowDebugger);

  const { validate } = useSchemaStore(({ validate }) => ({ validate }));

  // eslint-disable-next-line -- It's fine to use an inline function here
  const executeValidation = useCallback(debounce(validate, 1000), []);

  useDeepCompareEffect(() => {
    executeValidation();
  }, [executeValidation, formValues]);

  useEffect(() => {
    if (formValues?.risk_details?.sections?.length === 0) {
      const clonedFormValues = cloneDeep(formValues);

      set(clonedFormValues, 'risk_details.split_by_sections', []);
      setFormValues(clonedFormValues);
    }
  }, [formValues, formValues?.risk_details?.sections?.length, setFormValues]);

  return (
    <div className="h-full overflow-x-auto" data-cypress="contract-form">
      <div className="mx-10 mt-12 flex min-h-screen items-start pb-24">
        {isDebuggerVisible && <Debugger handleClose={setDebuggerVisible.bind(null, false)} />}
        <Form className="h-full w-full max-w-3xl" id="submissionForm" key="submissionForm">
          {resolvedSchema
            ? mapChildren({
                isReadOnly: disabledByStatus || isViewingRevisionHistory,
                onChange,
                parentKey: '',
                parentSchema: resolvedSchema,
                validationErrors: formatErrors(validationErrors),
                isDisabled: isLoading,
                hideErrors: true,
              })
            : null}
        </Form>
        <FormNavigation
          loading={isSubmitting}
          canSubmit={disabledByStatus || isViewingRevisionHistory ? false : !isSubmitting && isDirty}
          handleSubmit={onSubmit}
          schema={resolvedSchema}
        />
      </div>
    </div>
  );
};

export default ContractForm;
