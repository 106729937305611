import type { CSSProperties } from 'react';
// @ts-expect-error
import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';

import { findFirstNodeAbove } from './helpers';

type FontSizeOptions = {
  types: string[];
  getStyle: (fontSize: string) => string;
};

export const FontSize = Extension.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions(): FontSizeOptions {
    return {
      types: ['textStyle'],
      // don't forget to pass size with unit (15px, 1rem, 2em, etc.)
      getStyle: (fontSize: string) => {
        return `font-size: ${fontSize}`;
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element: HTMLElement) => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: (attributes: CSSProperties) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: this.options.getStyle(attributes.fontSize),
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: string) =>
        ({ chain, state }: any) => {
          const firstNodeAbove = findFirstNodeAbove('listItem', state.selection);

          if (firstNodeAbove) {
            return chain().setMark('textStyle', { fontSize }).setMarkerSize(fontSize, firstNodeAbove.depth).run();
          }

          return chain().setMark('textStyle', { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain, state }: any) => {
          if (findFirstNodeAbove('listItem', state.selection)) {
            chain().setMarkerSize(null).removeEmptyTextStyle().run();
          }
          return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
        },
    };
  },
});
