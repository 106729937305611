import React, { useMemo, useState } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import { cloneDeep, set } from 'lodash-es';

import type { GenericObject, UIChildrenMappingProps, UIMapChildrenFn } from '../../../@types/types';
import pickCommaSeparate from '../../helpers/pickCommaSeparate';
import Button from '../Button';
import InputAddress from '../InputAddress';

interface AddressAutocompleteProps extends UIChildrenMappingProps {
  id: string;
  item: any;
  mapChildren: UIMapChildrenFn;
  ownKey: string;
  parentKey: string;
  className: string;
  value: GenericObject<string>;
  errors: string | string[];
  isReadOnly: boolean;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  id: _id,
  item,
  parentKey: _parentKey,
  ownKey,
  className,
  value,
  errors,
  isReadOnly,
  validationErrors,
  onChange,
  mapChildren,
  hideErrors,
  ...props
}) => {
  const { isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { formValues, setFormValues, isIncomplete } = useDatapointsStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
    ...selector({ formValues }),
  }));

  const hasInitialValue = value && Object.keys(value).length > 0;
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(hasInitialValue);

  const initialValue = pickCommaSeparate(value, [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'zipcode',
    'country',
  ]);
  const handlePlaceChange = (newPlace: GenericObject) => {
    const clonedFormValues = cloneDeep(formValues);

    set(clonedFormValues, ownKey, newPlace);
    setFormValues(clonedFormValues);
    setIsAddressFormVisible(true);
  };

  return (
    <div className={className}>
      <InputAddress
        isIncomplete={isIncomplete}
        className="mb-8"
        labelText="Search for address"
        name="addressSearch"
        onPlaceChange={handlePlaceChange}
        errors={errors}
        {...props}
        defaultValue={initialValue}
        allowedCountries={item['ui:component:allowedCountries']}
        isReadOnly={isReadOnly}
      />

      {!isAddressFormVisible && !isReadOnly && (
        <Button data-testid="input-address-button" kind="primary" onClick={() => setIsAddressFormVisible(true)}>
          Input manually
        </Button>
      )}

      {isAddressFormVisible && (
        <div className="border-l-4 border-info-400 pl-8">
          {mapChildren({
            parentSchema: item,
            parentKey: ownKey,
            onChange,
            validationErrors,
            isReadOnly,
            hideErrors,
          })}
        </div>
      )}
    </div>
  );
};

export default AddressAutocomplete;
