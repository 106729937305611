import type { ForwardedRef } from 'react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DropdownChevron from '@components/DropdownChevron';
import IconMdi from '@components/IconMdi';
import { Spinner } from '@components/Spinner';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiCloseCircleOutline, mdiMagnify } from '@mdi/js';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import type { DownshiftState, StateChangeOptions } from 'downshift';
import Downshift from 'downshift';

import type { UIComponentBase, UIOnChangeFn, UISelectionType } from '../../@types/types';

import { selectTriggerSize } from './classes/select';
import type { InputFieldProps } from './InputField';
import { InputField } from './InputField';
import InputReadOnly from './InputReadOnly';
import { MAX_DROPDOWN_HEIGHT_PX } from './shared';

type InputSelectStyle = 'button' | 'input' | 'menuInner' | 'menuItem' | 'menuOuter' | 'menuItemDisabled';
type InputSelectStyles = Record<InputSelectStyle, string>;

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const inputSelectStyles: InputSelectStyles = {
  button:
    'bg-white border border-info-300 px-3 py-2 rounded text-info-900 h-10 flex justify-between items-center w-full focus:outline-none focus:shadow-outline',
  input: 'border-b border-info-100 px-3 py-2 text-info-900 w-full focus:outline-none',
  menuInner: 'overflow-y-auto list-none',
  menuItem: 'py-2 px-3 cursor-pointer text-sm font-sans truncate',
  menuOuter:
    'border-info-300 border rounded absolute inset-x-0 z-[21] top-0 bg-white text-info-900 mt-11 shadow overflow-hidden w-full min-w-fit',
  menuItemDisabled: 'cursor-not-allowed bg-info-50 text-info-300',
};

const itemClasses = cva(inputSelectStyles.menuItem, {
  variants: {
    isEmpty: { true: 'text-info-400' },
    isDisabled: { true: inputSelectStyles.menuItemDisabled },
    isHighlighted: { true: 'bg-info-100' },
  },
});

const selectTriggerClasses = cva(['m-auto flex flex-1 justify-between rounded-md text-sm text-info-900 '], {
  variants: {
    unstyled: {
      true: '',
      false: 'border border-info-300 shadow-sm',
    },
    isDisabled: {
      true: 'cursor-not-allowed opacity-50',
      false: '',
    },
  },
  defaultVariants: {
    unstyled: false,
    isDisabled: false,
  },
});

export interface InputSelectProps<T = string> extends InputFieldProps {
  'data-testid'?: string;
  id?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  isSearchable?: boolean;
  labelText?: string;
  name?: string;
  options?: readonly UISelectionType<T>[] | readonly string[];
  value?: T;
  initialSelectedItem?: T;
  placeholder?: string;
  placeholderClassName?: string;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  formatter?: (value: any) => string | number | false;
  itemFormatter?: (value: any) => string;
  onChange?: UIOnChangeFn<T>;
  menuOuterClassName?: string;
  unstyled?: boolean;
  limitValueLengthPx?: number;
  inputClassName?: string;
}

export interface MenuContainerProps {
  anchorRef?: React.RefObject<HTMLInputElement>;
  children?: UIComponentBase['children'];
  className?: string;
}

export type UseDownshiftStyleState = React.CSSProperties | undefined;

const useDownshiftStyle = (
  anchorRef?: React.RefObject<HTMLInputElement>,
  ref?: React.RefObject<HTMLInputElement>,
): UseDownshiftStyleState => {
  const [style, setStyle] = useState<UseDownshiftStyleState>(undefined);

  useEffect(() => {
    const element = ref?.current;
    const anchorElement = anchorRef?.current;

    if (element && anchorElement) {
      const anchor = anchorElement.getBoundingClientRect();
      const bound = element.getBoundingClientRect();

      // if overflowing
      if (anchor.y + anchor.height > window.innerHeight - MAX_DROPDOWN_HEIGHT_PX) {
        const height = bound.bottom - bound.y + 8; // get element height
        const style = { marginTop: -height };
        setStyle({ ...style });
      }
    }
  }, [anchorRef, ref]);

  return style;
};

const downshiftStateReducer = <T extends number | string | null | undefined = string>(
  _state: DownshiftState<UISelectionType<T>>,
  changes: StateChangeOptions<UISelectionType<T>>,
): Partial<StateChangeOptions<UISelectionType<T>>> => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return { ...changes, inputValue: '' };

    case Downshift.stateChangeTypes.clickButton:
    case Downshift.stateChangeTypes.changeInput:
      return { ...changes, highlightedIndex: 0 };

    default:
      return changes;
  }
};

export const InputSelectMenuContainer: React.FC<MenuContainerProps> = ({
  anchorRef,
  children,
  className = inputSelectStyles.menuOuter,
}) => {
  const ref = useRef(null);
  const style = useDownshiftStyle(anchorRef, ref);

  return (
    <div className={className} ref={ref} style={style}>
      {children}
    </div>
  );
};

const formatSelectValue = <T = string,>(value: T, options: UISelectionType<T>[] = []) => {
  const selected = options.find((option) => option.value === value);
  return selected?.name ?? value;
};

const getItemToString = <T = string,>(item: UISelectionType<T> | null): string => {
  return (item?.value ?? '').toString();
};

const parseInputOptions = <T = string,>(
  options: readonly UISelectionType<T>[] | readonly string[] = [],
): UISelectionType<T>[] => {
  return options.map((item) => (typeof item === 'string' ? ({ name: item, value: item } as UISelectionType<T>) : item));
};

const InputSelectInner = <T extends string | number = string>(
  {
    'data-testid': dataTestId = 'dropdown-select',
    className,
    descriptionText,
    errors,
    formatter,
    id,
    inputClassName,
    isClearable = false,
    isLoading = false,
    isReadOnly,
    isSearchable = true,
    itemFormatter,
    labelText,
    limitValueLengthPx,
    menuOuterClassName,
    name = 'input',
    onChange,
    options: defaultOptions,
    placeholder = 'Select',
    placeholderClassName,
    size = 'md',
    unstyled,
    value,
    ...props
  }: InputSelectProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const options = parseInputOptions(defaultOptions);
  const getReadOnlyItem = (value?: T) => {
    const selected = options?.find((option) => option.value === value);
    return selected?.name ?? value;
  };

  const handleRemoveSelection: React.HTMLAttributes<HTMLDivElement>['onClick'] = (event) => {
    event.stopPropagation();
    return onChange?.(undefined, name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id}
      labelText={labelText}
      name={name}
      {...props}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={getReadOnlyItem(value) || ''}
        />
      )}

      {!isReadOnly && (
        <Downshift<UISelectionType<T>>
          stateReducer={downshiftStateReducer}
          // @ts-expect-error
          selectedItem={value ?? ''}
          itemToString={getItemToString}
          onSelect={(item) => {
            if (onChange) {
              onChange(item?.value, name);
            }
          }}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            highlightedIndex,
            isOpen,
            inputValue,
            getInputProps,
          }) => {
            const normalized = String(inputValue ?? '').toLowerCase();
            const byInputValue = (item: UISelectionType<T>) =>
              String(item.name ?? '')
                .toLowerCase()
                .includes(normalized);
            const filteredItems = options?.filter(byInputValue);
            const selectableItems = isSearchable ? filteredItems : options;
            const hasSelection = Boolean(value);

            return (
              <div className="relative flex text-sm">
                <button
                  id={`${id}-select-trigger`}
                  data-testid={dataTestId}
                  disabled={props.isDisabled}
                  {...getToggleButtonProps()}
                  aria-label={labelText ?? props['aria-label']}
                  aria-labelledby={props['aria-labelledby']}
                  type="button"
                  ref={ref}
                  className={clsx(
                    selectTriggerClasses({
                      unstyled,
                      isDisabled: props.isDisabled || isLoading,
                      className: inputClassName,
                    }),
                    selectTriggerSize({ size }),
                  )}
                >
                  {hasSelection ? (
                    <span
                      style={{
                        maxWidth: limitValueLengthPx,
                      }}
                      className={clsx(limitValueLengthPx && 'truncate whitespace-nowrap')}
                    >
                      {formatter ? formatter(value) : formatSelectValue(value, options)}
                    </span>
                  ) : (
                    <span className={clsx('text-info-400', placeholderClassName)}>{placeholder}</span>
                  )}
                  <div className="my-auto inline-flex">
                    {isLoading && <Spinner className="[&>path]:fill-info-500" size="xs" />}
                    {isClearable && hasSelection && (
                      <TooltipedIcon
                        data-testid="Clear-dropdown"
                        placement="top"
                        path={mdiCloseCircleOutline}
                        className="cursor-pointer text-info-400 transition-colors hover:text-error-500"
                        size={0.625}
                        content="Remove selection"
                        onClick={handleRemoveSelection}
                      />
                    )}
                    <DropdownChevron open={isOpen} />
                  </div>
                </button>

                {isOpen && (
                  <InputSelectMenuContainer className={clsx(inputSelectStyles.menuOuter, menuOuterClassName)}>
                    <div {...getMenuProps()} data-testid="downshift-list">
                      {isSearchable && (
                        <div className="relative pl-4">
                          <IconMdi
                            path={mdiMagnify}
                            size={0.75}
                            className="absolute left-2 top-1/2 -translate-y-1/2 text-info-400"
                          />
                          <input
                            autoFocus
                            className={clsx(inputSelectStyles.input)}
                            placeholder="Search..."
                            {...getInputProps()}
                          />
                        </div>
                      )}

                      <ul className={inputSelectStyles.menuInner} style={{ maxHeight: MAX_DROPDOWN_HEIGHT_PX }}>
                        {(selectableItems || []).map((item, index) => {
                          const { isDisabled = false } = item;
                          const isEmpty = item.value === undefined;
                          const result =
                            (itemFormatter && itemFormatter(item.value)) ||
                            (formatter && formatter(item.value)) ||
                            item.name;

                          const { onClick, onMouseDown, onMouseMove, ...itemProps } = getItemProps({ item });

                          const actionProps = isDisabled
                            ? {}
                            : {
                                onClick,
                                onMouseDown,
                                onMouseMove,
                              };

                          return (
                            <li
                              data-testid={item.name}
                              className={itemClasses({
                                isEmpty,
                                isDisabled: isDisabled && !isEmpty,
                                isHighlighted: index === highlightedIndex,
                              })}
                              key={item.value ? JSON.stringify(item.value) : `select-${id}-${index}`}
                              {...actionProps}
                              {...itemProps}
                            >
                              {result}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </InputSelectMenuContainer>
                )}
              </div>
            );
          }}
        </Downshift>
      )}
    </InputField>
  );
};

const InputSelect = forwardRef(InputSelectInner);

export default InputSelect;
