import axios from 'axios';

import type { EndorsementData } from '../containers/ContractBuilder/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

const getUrl = (submissionId: string) => [`submissions-spa-al`, submissionId, `endorsements-spa-al`].join('/');

export const fetchEndorsements = async (submissionId: string): Promise<EndorsementData[]> => {
  const params = new URLSearchParams();
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: getUrl(submissionId),
    params,
  });

  const response = await axios(networkPayload);
  return response.data;
};
