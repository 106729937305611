import type { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import Tooltip from '@components/Tooltip';
import { PencilIcon } from '@heroicons/react/outline';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

const classes = cva(['ml-[4px]', 'cursor-pointer', 'bg-info-100', 'text-info-600', 'p-0.5', 'rounded-full'], {
  variants: {
    enableAppearingEffect: {
      true: 'opacity-0 group-hover:opacity-100',
      false: '',
    },
    ignoreMargin: {
      true: 'ml-0',
      false: '',
    },
  },
});

interface WithEditIconProps extends ButtonHTMLAttributes<HTMLElement> {
  tooltipContent?: string;
  disabled?: boolean;
  enableAppearingEffect?: boolean;
  ignoreMargin?: boolean;
}

export const WithEditIcon: FC<PropsWithChildren<WithEditIconProps>> = ({
  children,
  onClick,
  disabled,
  tooltipContent,
  ignoreMargin = false,
  enableAppearingEffect = true,
  ...buttonProps
}) => {
  return (
    <div
      aria-hidden="true"
      onClick={disabled ? undefined : onClick}
      className={clsx(disabled ? 'pointer-events-none' : 'cursor-pointer', `group flex place-items-center`)}
    >
      {children}
      <Tooltip content={tooltipContent} disabled={tooltipContent === undefined}>
        <button
          className={classes({
            enableAppearingEffect,
            ignoreMargin,
          })}
          disabled={disabled}
          type="button"
          {...buttonProps}
        >
          <span className="sr-only">Inline edit</span>
          <PencilIcon className="h-4 w-4" />
        </button>
      </Tooltip>
    </div>
  );
};
