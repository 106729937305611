import React from 'react';
import Card from '@components/Card';
import ErrorText from '@components/ErrorText';
import Icon from '@components/Icon';
import IconMdi from '@components/IconMdi';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { mdiHelpCircleOutline, mdiMinus, mdiPlus } from '@mdi/js';
import type { JSONSchema, JSONSchemaProperties, UIChildrenMappingProps } from '@root/@types/types';
import { resolveSchema } from '@root/helpers/schema';
import mapChildren from '@src/mapChildren';
import { cloneDeep, cloneDeepWith, get, set } from 'lodash-es';

import Button from '../../Button';

export interface RepeaterProps extends UIChildrenMappingProps {
  className?: string;
  id: string;
  ownKey: string;
  item: JSONSchemaProperties;
}

const CUSTOM_CONTENT: Record<string, string> = {
  sections: 'Adding sections will enable the option to split content into each section.',
};

const customCloneDeep = (value: any) => {
  return cloneDeepWith(value, (item) => {
    if (Array.isArray(item)) {
      return JSON.parse(JSON.stringify(item));
    }
    return undefined;
  });
};

const Repeater: React.FC<RepeaterProps> = ({
  className,
  id,
  item,
  ownKey,
  validationErrors,
  showQuestionKey,
  onChange,
  isReadOnly = false,
  isDisabled,
  hideErrors,
}) => {
  const { formValues, setFormValues, value } = useDatapointsStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
    value: get(formValues, ownKey),
  }));

  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  let defaultValue = [];
  for (let i = 0; i < (item?.minItems ?? 0); i++) {
    defaultValue.push({});
  }
  const initialItems = hasInitialValue ? value : defaultValue;
  const canAdd = item?.maximum === undefined || initialItems?.length < item?.maximum;
  const total100Error = validationErrors?.find(
    (error) => error.instancePath === ownKey && error.keyword === 'total100',
  );
  const isEmpty = initialItems.length === 0;
  const emptyContent = CUSTOM_CONTENT[id];

  const handleAdd = () => {
    const cloned = customCloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, {}]);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex: number) => {
    const cloned = customCloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  const schemaItem = item.items?.type === 'object' ? item.items : { properties: { [id]: item.items } };

  return (
    <div className={className}>
      <div className="text-md flex items-center justify-between font-medium">
        <div className="flex justify-start gap-2">
          {item.shortName ?? item.title}
          {!!item?.helperText && (
            <TooltipedIcon path={mdiHelpCircleOutline} placement="top" content={item?.helperText} />
          )}
        </div>
      </div>
      {isEmpty && emptyContent && (
        <div className="mb-4 flex items-center gap-x-3.5 rounded-md bg-info-50 px-3 py-4 text-xs font-medium text-info-600">
          <Icon name="book" />
          {emptyContent}
        </div>
      )}
      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(schemaItem);
        set(
          clonedSchema,
          ['properties', item.key, 'title'],
          `${get(clonedSchema, ['properties', item.key, 'title'])} #${index + 1}`,
        );

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card className="mb-2" key={key} header={null}>
            <div className="flex justify-start justify-items-end gap-2">
              {mapChildren({
                onChange,
                parentKey: key,
                parentSchema: clonedSchema as JSONSchema,
                validationErrors,
                showQuestionKey,
                isReadOnly,
                isDisabled,
                hideErrors,
              })}
            </div>
          </Card>
        );
      })}
      {total100Error && <ErrorText className="mb-4">{total100Error.message}</ErrorText>}
      <div className="flex">
        {!isReadOnly && (item?.minItems === undefined || item?.minItems < initialItems.length) && (
          <Button
            kind="ghost"
            className="mr-4 border-red-300 px-0 text-red-500 shadow-none focus:shadow-none enabled:hover:bg-white enabled:hover:text-red-600"
            onClick={() => handleRemove(initialItems.length - 1)}
          >
            <IconMdi path={mdiMinus} />
            {`Remove ${item.title ?? 'a section'}`}
          </Button>
        )}
        {!isReadOnly && canAdd && (
          <Button
            id="add-button"
            className="border-primary-300 px-0 text-primary-500 shadow-none focus:shadow-none enabled:hover:bg-white enabled:hover:text-primary-600"
            onClick={handleAdd}
            kind="ghost"
          >
            <IconMdi path={mdiPlus} />
            {`Add ${item.title ?? 'a section'}`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Repeater;
