import type { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { Show } from './Show';

interface ChipProps {
  children: ReactNode;
  'data-testid'?: string;
  dot?: boolean;
  className?: string;
}

export const Chip: FC<ChipProps> = ({ children, className, dot, 'data-testid': dataTestId }) => (
  <div
    data-testid={dataTestId}
    className={clsx('flex items-center gap-1 rounded-full bg-primary-200/50 px-2 py-0.5 text-primary-700', className)}
  >
    <Show when={dot}>
      <span className="h-1.5 w-1.5 rounded-full bg-primary-300" />
    </Show>
    {children}
  </div>
);
