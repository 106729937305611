import { createContext, useContext } from 'react';
import type { BlockVariation } from '@root/@types/database';
import type { BlockVisibilityOutput } from '@root/@types/types';

export interface VariationsTabsContextValue {
  selectedVariationId?: string;
  variationInView?: string;
  currentVariationIdx: number;
  variationsCount: number;
  visibilityDetails?: BlockVisibilityOutput[];
  hasLogic?: boolean;
  create: (payload?: BlockVariation) => void;
  remove: (id: string) => void;
  removeAll: (isRemovingFirstVariation?: boolean) => void;
  select: (id: string) => void;
  setCurrent: (id: string) => void;
  duplicate: (id: string) => void;
}

export const VariationsTabsContext = createContext<VariationsTabsContextValue | null>(null);

export const useVariationsTabs = () => {
  const context = useContext(VariationsTabsContext);

  if (!context) {
    throw new Error(`"VariationsProvider" must be present in the DOM tree`);
  }

  return context;
};
