import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewPath } from '@ContractBuilder/contract-builder-header/constants';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useDocumentLocation } from '@src/hooks';

export const useContractFormPreventableLink = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { isFormView } = useDocumentLocation();

  const { isDirty, onSubmit, onFormReset } = useDatapointsStore(({ isDirty, onSubmit, onFormReset }) => ({
    isDirty,
    onSubmit,
    onFormReset,
  }));

  const [isBlocked, setIsBlocked] = useState(false);
  const [nextRoute, setNextRoute] = useState<string>(ViewPath.Form);

  const onNavigate = (to: string) => {
    setIsBlocked(false);
    navigate(to + search);
  };

  const onReset = () => {
    setIsBlocked(false);
    setNextRoute(ViewPath.Form);
  };

  const onLinkClick = (to: string) => {
    if (isDirty && isFormView) {
      setNextRoute(to);
      setIsBlocked(true);
    } else {
      onNavigate(to);
    }
  };

  const onDisregardChanges = () => {
    onFormReset();
    onNavigate(nextRoute);
  };

  const onSaveChanges = async () => {
    await onSubmit();
    return onNavigate(nextRoute);
  };

  return {
    isBlocked,
    onDisregardChanges,
    onLinkClick,
    onReset,
    onSaveChanges,
  };
};
