import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import { type BrandingDetailsType } from '@root/@types/types';
import axios from 'axios';

import {
  type BrandingPatchOperations,
  type BrandingPatchPayload,
  prepareBrandingPayload,
} from '../utils/prepare-branding-payload';

export const updateBranding = async (
  data: BrandingPatchPayload,
  operation: BrandingPatchOperations,
): Promise<Pick<BrandingDetailsType, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: '/brandings/' + data.id,
    data: { ...prepareBrandingPayload(data, operation), operation },
  });

  const response = await axios(networkPayload);
  return response.data;
};
