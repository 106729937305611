import type React from 'react';
import { useEntityStore } from '@ContractBuilder/store/entity.store';
import { useSchemaStore } from '@ContractBuilder/store/schema.store';
import type { FormError } from '@root/@types/types';
import { cloneDeep, isEqual, set } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

export interface DatapointsStoreState {
  formErrors: FormError[];
  formValues: Record<string, any>;
  isDirty: boolean;
  isSubmitting: boolean;
  onChange: (...args: any) => void;
  onFormReset: () => void;
  onSubmit: () => Promise<void>;
  reset: () => void;
  setFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  setFormValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const useDatapointsStore = createWithEqualityFn<DatapointsStoreState>(
  (setter, get) => ({
    formErrors: [],
    formValues: {},
    isDirty: false,
    isSubmitting: false,
    onChange: (...args) => {
      const nextFormValues = cloneDeep(get().formValues);

      if (args.length === 1) {
        const [event] = args;
        set(nextFormValues, event.target.name, event.target.value);
      }

      if (args.length === 2) {
        const [value, name] = args;
        set(nextFormValues, name, value);
      }

      get().setFormValues(nextFormValues);
    },
    onFormReset: () => {
      const initialState = useEntityStore.getState().submission?.data_items ?? {};
      useSchemaStore.getState().refreshResolvedSchema(initialState);
      setter({ formValues: initialState, isDirty: false, isSubmitting: false });
    },
    onSubmit: async () => {
      const updateFormData = useEntityStore.getState().updateFormData;
      setter({ isSubmitting: true });

      try {
        await updateFormData(get().formValues);
      } catch (error) {
        get().setFormErrors(error as any);
      } finally {
        setter({ isSubmitting: false, isDirty: false });
      }
    },
    reset: () => setter({ formErrors: [], formValues: {}, isDirty: false, isSubmitting: false }),
    setFormErrors: (param) => {
      if (typeof param === 'function') {
        return setter({ formErrors: param(get().formErrors) });
      }

      return setter({ formErrors: param });
    },
    setFormValues: (param) => {
      const oldFormValues = useEntityStore.getState().submission?.data_items ?? {};
      const nextFormValues = typeof param === 'function' ? param(get().formValues) : param;
      useSchemaStore.getState().refreshResolvedSchema(nextFormValues);

      return setter({
        formValues: nextFormValues,
        isDirty: !isEqual(nextFormValues, oldFormValues),
      });
    },
  }),
  isEqual,
);
