import type { FC } from 'react';
import type { MrcBoundingBox } from '@root/@types/types';

interface BoundingBoxProps {
  boundingBox: MrcBoundingBox;
  focussed: boolean;
  id: number;
}

export const BoundingBox: FC<BoundingBoxProps> = ({ boundingBox, focussed, id }) => {
  const top = `${boundingBox.top * 100}%`;
  const right = `${(1 - boundingBox.left - boundingBox.width) * 100}%`;
  const bottom = `${(1 - boundingBox.top - boundingBox.height) * 100}%`;
  const left = `${boundingBox.left * 100}%`;

  const bgColour = 'bg-blue-500';
  const opacity = focussed ? 'opacity-25' : 'opacity-0';

  // The z-index increase is for compatibility with react-image-crop.
  // otherwise the bounding box becomes hidden.
  return (
    <div
      id={`mrc-candidate-bounding-box-${id}`}
      className={`absolute z-10 rounded-sm ${bgColour} ${opacity}`}
      style={{ top, right, bottom, left }}
    ></div>
  );
};
