export const Clause = () => (
  <svg width="80" height="37" viewBox="0 0 80 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.867188" width="79" height="35" rx="3.5" fill="white" />
    <path d="M8 8.36719H72" stroke="#1B6DFC" strokeWidth="2" />
    <path d="M8 14.3672H72" stroke="#1B6DFC" />
    <path d="M8 18.3672H72" stroke="#1B6DFC" />
    <path d="M8 22.3672H72" stroke="#1B6DFC" />
    <path d="M8 26.3672H72" stroke="#1B6DFC" />
    <rect x="0.5" y="0.867188" width="79" height="35" rx="3.5" stroke="#1B6DFC" />
  </svg>
);
