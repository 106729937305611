import type { FC, ReactNode } from 'react';
import { mdiClose } from '@mdi/js';
import IconMdi from '@root/src/components/IconMdi';

interface FallbackInfoContainerProps {
  onClose: () => void;
  children: ReactNode;
}

export const FallbackInfoContainer: FC<FallbackInfoContainerProps> = ({ onClose, children }) => (
  <div className="min-w-96 cursor-default px-6  py-7">
    {children}
    <IconMdi path={mdiClose} onClick={onClose} className="absolute right-2 top-2" />
  </div>
);
