import type { PropsWithChildren } from 'react';
import React, { memo } from 'react';
import type { ListChildComponentProps } from 'react-window';
import { areEqual } from 'react-window';

import type { RenderItemFn } from '../types';

interface ItemData<ItemProps = unknown> {
  data: ItemProps[];
  renderItem: RenderItemFn<ItemProps>;
}

interface RowProps<ItemProps> extends Omit<PropsWithChildren<ListChildComponentProps<ItemProps>>, 'data'> {
  data: ItemData<ItemProps>;
}

/** Default component to wrap a single element within `VirtualizedList` providing it with appropriate styling  */
export const Row = memo(function Row<ItemProps>({ data, index, style }: RowProps<ItemProps>) {
  const { data: itemData, renderItem } = data;
  const itemProps = itemData[index];

  return (
    <div className="overflow-y-auto" style={style}>
      {renderItem({ index, ...itemProps })}
    </div>
  );
}, areEqual);
