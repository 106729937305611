import type { DocumentOperation, SubmissionStatus } from '@root/@types/types';
import { DocumentMode } from '@root/@types/types';
import { keys } from 'lodash-es';

import OPERATIONS_ALLOWED_IN_DOC_STATUS from '../document-permissions';

import { documentModeRegex } from './getDocumentMode';

export const canChange = (status = '', pathname?: string, operation: DocumentOperation = 'edit'): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  let url = pathname ?? window.location.pathname;

  let mode: DocumentMode = DocumentMode.SUBMISSIONS;

  keys(documentModeRegex).forEach((key) => {
    if (documentModeRegex[key as keyof typeof documentModeRegex].test(url)) {
      mode = key as DocumentMode;
    }
  });

  return OPERATIONS_ALLOWED_IN_DOC_STATUS[operation][mode].includes(status as SubmissionStatus);
};
