import { PanelPlacement } from '@ContractBuilder/hooks';
import type { MotionProps } from 'framer-motion';

export const getAnimationProps = (placement: PanelPlacement): MotionProps => {
  const isLeftSide = placement === PanelPlacement.Left;
  const XOffset = isLeftSide ? '-100%' : '100%';

  return {
    animate: {
      x: 0,
    },
    exit: {
      x: XOffset,
    },
    initial: {
      x: XOffset,
    },
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  };
};
