import type { DependencyList } from 'react';
import { useCallback } from 'react';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';

function checkDeps(deps: DependencyList, name: string) {
  const reactHookName = `React.${name.replace(/DeepCompare/, '')}`;

  if (!deps || deps.length === 0) {
    throw new Error(`${name} should not be used with no dependencies. Use ${reactHookName} instead.`);
  }
}

/**
 * `useDeepCompareCallback` will only recompute the memoized value when one of the
 * `deps` has changed.
 *
 * Usage note: only use this if `deps` are objects or arrays that contain
 * objects. Otherwise you should just use React.useCallback.
 *
 */
export const useDeepCompareCallback = <T extends (...args: any[]) => any, TDependencyList extends DependencyList>(
  cb: T,
  dependencies: TDependencyList,
) => {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareCallback');
  }

  // eslint-disable-next-line -- Assuming callback fn is static,  we only care about deps changes
  return useCallback(cb, useDeepCompareMemoize(dependencies));
};
