import { Route, Routes } from 'react-router-dom';
import { MrcDocumentUploadController } from '@MrcExtraction/modules/mrc-document-upload';
import withMenuLayout from '@root/src/layout/withMenuLayout';

const MrcExtraction = () => {
  return (
    <Routes>
      <Route path="/upload" element={<MrcDocumentUploadController />} />
    </Routes>
  );
};

export const UserMrcExtractionPage = withMenuLayout(MrcExtraction);
