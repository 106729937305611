import Modal from '@components/Modal';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

type RestoreRevisionModalProps = {
  handleClose: () => void;
  handleConfirm: () => void;
};

const Title = () => (
  <div className="flex">
    <div className="flex-shrink-0">
      <QuestionMarkCircleIcon className="h-10 w-10 rounded-full bg-green-100 p-2 text-green-600" />
    </div>
    <h1 className="ml-4 text-2xl font-normal">Restore this revision?</h1>
  </div>
);

export const RestoreRevisionModal = (props: RestoreRevisionModalProps) => {
  return (
    <Modal open className="w-[660px]" onClose={props.handleClose} title={<Title />}>
      <div className="ml-14 flex flex-col gap-5 font-light text-gray-500">
        By restoring this revision of the contract all changes made since will be lost, are you sure you would like to
        restore back to this revision?
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          onClick={props.handleConfirm}
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
        >
          Yes, restore this revision
        </button>
        <button
          onClick={props.handleClose}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};
