import React from 'react';

import type { JSONSchema, UIChildrenMappingProps, UIMapChildrenFn } from '../../../@types/types';

interface GroupProps extends UIChildrenMappingProps {
  item: JSONSchema;
  mapChildren: UIMapChildrenFn;
  ownKey: string;
}

const Group: React.FC<GroupProps> = ({
  item,
  mapChildren,
  ownKey,
  onChange,
  validationErrors,
  ctx,
  showQuestionId,
  showQuestionKey,
  hideErrors,
}) => {
  return (
    <div>
      {mapChildren({
        parentSchema: item,
        parentKey: ownKey,
        onChange,
        validationErrors,
        ctx,
        showQuestionId,
        showQuestionKey,
        hideErrors,
      })}
    </div>
  );
};

export default Group;
