import type { FC } from 'react';
import type { ElementSize, ElementSizes } from '@root/@types/types';
import clsx from 'clsx';

const colourScheme = {
  red: 'text-error-1000 bg-error-100',
  grape: 'text-grape-1000 bg-grape-100',
  violet: 'text-violet-1000 bg-violet-100',
  indigo: 'text-primary-1000 bg-primary-100',
  blue: 'text-primary-1000 bg-primary-100',
  cyan: 'text-cyan-1000 bg-cyan-100',
  teal: 'text-teal-1000 bg-teal-100',
  green: 'text-success-1000 bg-success-100',
  orange: 'text-warning-1000 bg-warning-100',
  pink: 'text-pink-1000 bg-pink-100',
};

const newColourScheme = {
  complete: 'text-success-800 bg-success-100',
  incomplete: 'text-yellow-800 bg-yellow-100',
  not_started: 'text-info-800 bg-info-100',
  error: 'text-error-800 bg-error-100',
  warning: 'text-warning-800 bg-warning-100',
  primary: 'text-primary-800 bg-primary-100',
};

const sizes: ElementSizes = {
  sm: 'text-xs leading-4 font-medium h-5 px-2.5 rounded-full',
  md: 'text-sm h-12 px-3 font-medium rounded',
  lg: 'text-base h-fit w-fit px-3 py-1 font-medium rounded-full',
};

interface StatusProps {
  color?: keyof typeof colourScheme;
  className?: string;
  size?: ElementSize;
  status: keyof typeof newColourScheme;
  statusText?: string;
}

const Status: FC<StatusProps> = ({ status, color = 'blue', statusText, className, size = 'md' }) => {
  const text = statusText || status;
  const finalColor = newColourScheme[status] || colourScheme[color];

  return (
    <div className={clsx('inline-flex items-center justify-center ', sizes[size], finalColor, className)}>{text}</div>
  );
};

export default Status;
