import type { FC } from 'react';
import { useState } from 'react';
import type { pdfjs } from 'react-pdf';
import { Document } from 'react-pdf';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { PanelPlacement } from '@ContractBuilder/hooks';
import { pdfDocumentClasses } from '@ContractBuilder/modules/pdf-preview/components/classes';
import { LoadingIndicator } from '@ContractBuilder/modules/pdf-preview/components/LoadingIndicator';
import { mdiDownload } from '@mdi/js';
import type { MrcExtractionResultItem } from '@root/@types/types';
import clsx from 'clsx';

import { StrictWrapper } from '../components/StrictWrapper';
import type { PageItem } from '../types';

import { downloadButtonContainerClasses } from './classes';

interface MrcDocumentViewLoadedProps {
  data?: MrcExtractionResultItem[];
  focussedCandidate?: string;
  inputFileUrl?: string;
  onDocumentLoadSuccess?: () => void;
  onDownload: () => Promise<void>;
  pageWidth: number;
  placement: PanelPlacement;
}

export const MrcDocumentViewLoaded: FC<MrcDocumentViewLoadedProps> = ({
  data = [],
  focussedCandidate,
  inputFileUrl,
  onDocumentLoadSuccess,
  onDownload,
  pageWidth,
  placement,
}) => {
  const [pages, setPages] = useState<number[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const isLeftSidePlacement = placement === PanelPlacement.Left;
  const isRightSidePlacement = placement === PanelPlacement.Right;

  const handleSetPages = (newPdf: pdfjs.PDFDocumentProxy) => {
    const { numPages } = newPdf;
    const newPages = new Array(numPages).fill(0).map((_, idx) => idx + 1);

    setPages(newPages);
  };

  const onDownloadPDFDocument = async () => {
    setIsDownloading(true);

    try {
      await onDownload();
    } finally {
      setIsDownloading(false);
    }
  };

  const items: PageItem[] = Array.from({ length: pages.length }, (_, i) => {
    const candidates = data.filter((item) => item.pageNum === i + 1);
    return { candidates };
  });

  return (
    <>
      <div className="h-full overflow-y-hidden">
        <Document
          className={clsx(pdfDocumentClasses)}
          error={<div className="flex h-40 w-full items-center justify-center">Failed to load PDF</div>}
          file={inputFileUrl}
          loading={
            <div
              className="absolute inset-0 flex h-full w-full items-center justify-center bg-white"
              data-testid="loading-overlay"
            >
              <LoadingIndicator />
            </div>
          }
          noData={
            <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white">
              <LoadingIndicator />
            </div>
          }
          onLoadSuccess={(pdf) => {
            handleSetPages(pdf);
            onDocumentLoadSuccess?.();
          }}
        >
          {items.map((item, idx) => (
            <StrictWrapper
              focussedCandidate={focussedCandidate}
              key={`mrc-document-page-${idx}`}
              index={idx}
              item={item}
              pageWidth={pageWidth}
            />
          ))}
        </Document>
      </div>
      <div className={downloadButtonContainerClasses({ isLeftSidePlacement, isRightSidePlacement })}>
        <Button
          className="h-10 w-[126px] whitespace-nowrap"
          data-cypress="download-pdf-button"
          kind="primary"
          onClick={onDownloadPDFDocument}
          loading={isDownloading}
        >
          <IconMdi path={mdiDownload} />
          Download
        </Button>
      </div>
    </>
  );
};
