import React, { useMemo } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { get } from 'lodash-es';

import type { UIOnChangeFn } from '../../../@types/types';
import { getValueFromObjOrSimple } from '../../helpers/getValue';
import InputCheckbox from '../InputCheckbox';

const cxInputCheckboxGroup = cva(['grid gap-4 items-center'], {
  variants: {
    columns: {
      1: 'grid-cols-1',
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
      6: 'grid-cols-6',
      7: 'grid-cols-7',
      8: 'grid-cols-8',
      9: 'grid-cols-9',
      10: 'grid-cols-10',
      11: 'grid-cols-11',
      12: 'grid-cols-12',
    },
  },
  defaultVariants: {
    columns: 1,
  },
});

type Option =
  | {
      labelText: string;
      value: string;
    }
  | string;

type Value = string | string[];

interface InputCheckboxGroupProps extends InputFieldProps {
  isReadOnly: boolean;
  item: { items: { enum: Option[] } };
  onChange: UIOnChangeFn<Value>;
  ownKey: string;
  value: Value;
}

const InputCheckboxGroup: React.FC<InputCheckboxGroupProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  item,
  labelText,
  mrcId,
  name = 'checkbox-group',
  onChange,
  ownKey,
}) => {
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value = [], isIncomplete } = useDatapointsStore(selector);
  const columns = get(item, 'ui:columns', 1);

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id ?? name}
      isRequired={isRequired}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
    >
      <div className={cxInputCheckboxGroup({ columns })}>
        {item?.items?.enum?.map((option) => (
          <InputCheckbox
            className={clsx('my-6', isIncomplete && 'field-incomplete')}
            errors={errors}
            id={[name, option].join('.')}
            isChecked={value.includes(getValueFromObjOrSimple(option, 'value'))}
            isRequired={isRequired}
            key={getValueFromObjOrSimple(option, 'value')}
            labelText={getValueFromObjOrSimple(option, 'labelText')}
            name={name}
            onChange={(e) => {
              const defaultValue = Array.isArray(value) ? value : [];
              const nextValue = defaultValue.includes(e.target.value)
                ? defaultValue.filter((i) => i !== e.target.value)
                : [...defaultValue, e.target.value];

              return onChange(nextValue, name);
            }}
            value={getValueFromObjOrSimple(option, 'value')}
            isDisabled={isReadOnly}
            readOnly={isReadOnly}
          />
        ))}
      </div>
    </InputField>
  );
};

export default InputCheckboxGroup;
