import type { ToastClassName, TypeOptions } from 'react-toastify';
import { mdiAlertOutline, mdiCheckCircleOutline, mdiInformationOutline } from '@mdi/js';
import { twMerge } from 'tailwind-merge';

/**
 * If you do not want an icon then pass false:
 * toast('Notification', { icon: false })
 */
export const typeMappingIcon: Record<TypeOptions, string | null> = {
  default: null,
  error: mdiInformationOutline,
  info: mdiInformationOutline,
  success: mdiCheckCircleOutline,
  warning: mdiAlertOutline,
};

export const typeMappingIconColor: Record<TypeOptions, string> = {
  default: '',
  error: 'text-error-500',
  info: 'text-primary-500',
  success: 'text-success-500',
  warning: 'text-warning-500',
};

export const typeMappingClassnames: Record<TypeOptions, string> = {
  default: '',
  error: 'border-l-error-500',
  info: 'border-l-primary-500',
  success: 'border-l-green-500',
  warning: 'border-l-warning-500',
};

export const toastClassName: ToastClassName = (args) =>
  twMerge(
    typeMappingClassnames[args?.type || 'default'],
    'bg-white',
    'text-info-900',
    'relative',
    'flex',
    'p-4',
    'rounded-md',
    'shadow-xl',
    'justify-between',
    'overflow-hidden',
    'cursor-pointer',
    'mb-5',
  );

export const toastBodyClassName: ToastClassName = (_args): string => twMerge('block', 'flex', 'items-start');
