import React, { Fragment, useEffect, useMemo, useState } from 'react';
import type { QueryStatus } from 'react-query';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import IconMdi from '@components/IconMdi';
import InputMultiSelect from '@components/InputMultiSelect/InputMultiSelect';
import LoadingOverlay from '@components/LoadingOverlay';
import { ServerSidePagination } from '@components/ServerSidePagination';
import { Show } from '@components/Show';
import { Pagination } from '@components/Table';
import Tooltip from '@components/Tooltip';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { mdiChevronDown, mdiClose, mdiFilter } from '@mdi/js';
import { GlobalSearch } from '@pages/User/components/GlobalSearch';
import type {
  Action,
  CheckboxFilter,
  Column,
  DropdownFilter,
  Filter,
  SetIsLoading,
  SortOrder,
  Tag,
  ToggleSortOrderDirectionFn,
  UrlFilter,
} from '@root/@types/types';
import { getLimit, getPage, getSortBy, getSortDirection } from '@root/helpers/pagination';
import clsx from 'clsx';
import { get, isEqual } from 'lodash-es';

import Toggle from '../../../components/Toggle';

const getItemValue = (column: Column, item: any) => {
  const defaultItemValue = get(item, column.key);
  if (defaultItemValue || !column?.fallbackKeys?.length) {
    return typeof column.formatter === 'function' ? column.formatter(defaultItemValue) : defaultItemValue;
  }
  for (let i = 0; i < column.fallbackKeys.length; i++) {
    const fallbackItemValue = get(item, column.fallbackKeys[i]);
    if (fallbackItemValue) {
      return typeof column.formatter === 'function' ? column.formatter(fallbackItemValue) : fallbackItemValue;
    }
  }
};

const defaultHeadingCellClassName = 'w-42 truncate px-3 py-3.5 text-left text-sm font-normal text-info-500';
const defaultLinkClassName = 'text-primary-600 hover:text-primary-900 font-light';

const TableDataCell = ({
  column,
  item,
  tags,
  setIsLoading,
}: {
  column: Column;
  item: any;
  tags: Tag[];
  setIsLoading?: SetIsLoading;
}) => {
  const cellFormattedValue = getItemValue(column, item);
  return column.type === 'link' ? (
    <td
      key={column.key}
      className={clsx(
        'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
        column.dataCellClassName,
      )}
    >
      {typeof column?.onClick === 'function' ? (
        <a href="#" onClick={column.onClick(item)} className={clsx(defaultLinkClassName, column.linkClassName)}>
          {column.label}
        </a>
      ) : (
        <a
          target={column.target ?? ''}
          href={column?.href?.indexOf('{id}') === -1 ? column?.href ?? '#' : column?.href?.replace('{id}', item.id)}
          rel={column.rel ?? ''}
          className={clsx(defaultLinkClassName, column.linkClassName)}
        >
          {column.label}
        </a>
      )}
    </td>
  ) : (
    <Fragment key={column.key}>
      {column.render ? (
        column.render(item, tags, setIsLoading)
      ) : (
        <Tooltip content={cellFormattedValue} disabled={!cellFormattedValue}>
          <td
            key={column.key}
            className={clsx(
              'w-42 truncate whitespace-nowrap px-3 py-4 align-top text-sm text-info-500',
              column.dataCellClassName,
            )}
          >
            {cellFormattedValue}
          </td>
        </Tooltip>
      )}
    </Fragment>
  );
};

interface TableProps {
  entitiesLabel: string;
  filteredData: any[];
  status?: QueryStatus;
  actions?: Action[];
  filters: Filter[];
  isFetching?: boolean;
  columns: Column[];
  sortOrder: SortOrder;
  toggleSortOrderDirection: ToggleSortOrderDirectionFn;
  pageSize?: number;
  tags?: Tag[];
  tableClasses?: string;
  onFilterChange?: (index: number, filterValue?: Filter[]) => void;
  getClearedFilters?: () => Filter[];
  isServerSidePagination?: boolean;
  totalAmount?: number;
}

const prepareFiltersForUrl = (filters: Filter[]) =>
  filters.reduce((acc, curr) => {
    if ('selected' in curr && curr.selected?.length > 0) {
      acc.push({
        key: curr.key,
        value:
          (curr?.optionsMapping?.length ?? 0) > 0
            ? curr.selected?.map(
                (selectedLabel) => curr.optionsMapping?.find((i) => i.label === selectedLabel)?.value ?? selectedLabel,
              )
            : curr.selected,
      });
    } else if ('isChecked' in curr && curr.isChecked) {
      acc.push({ key: curr.key, value: curr.isChecked });
    }
    return acc;
  }, [] as UrlFilter[]);

const isUrlFilterSet = (i: UrlFilter) => (Array.isArray(i.value) ? i.value.length > 0 : i.value);

export const Table = ({
  actions = [],
  columns,
  entitiesLabel,
  filteredData,
  filters: initialFilters,
  isFetching = false,
  getClearedFilters,
  onFilterChange,
  pageSize = 12,
  sortOrder,
  status,
  tableClasses,
  tags = [],
  toggleSortOrderDirection,
  isServerSidePagination = false,
  totalAmount,
}: TableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedUrlFilters = useMemo(() => {
    try {
      return JSON.parse(decodeURIComponent(searchParams.get('filters') ?? '[]'));
    } catch {
      return [];
    }
  }, [searchParams]);

  const [filters, setFilters] = useState(
    initialFilters.map((filter) => {
      const foundUrlFilter = parsedUrlFilters.find((i: any) => i.key === filter.key);
      if (foundUrlFilter) {
        if (Array.isArray(foundUrlFilter.value)) {
          const currentFilter = filter as DropdownFilter;
          return {
            ...filter,
            selected:
              (currentFilter?.optionsMapping?.length ?? 0) > 0
                ? foundUrlFilter.value?.map(
                    (urlFilterValue: string) =>
                      currentFilter?.optionsMapping?.find((i) => i.value === urlFilterValue)?.label ??
                      foundUrlFilter.value,
                  )
                : foundUrlFilter.value,
          };
        }
        return { ...filter, isChecked: foundUrlFilter.value ? 1 : 0 };
      }
      return filter;
    }),
  );

  const [isFiltersBarVisible, setIsFiltersBarVisible] = useState(false);
  const toggleFiltersBarVisibility = () => setIsFiltersBarVisible(!isFiltersBarVisible);

  useEffect(() => {
    setFilters(
      (filters as DropdownFilter[]).map((backendFilter) => {
        const foundUrlFilter = initialFilters.find((i: any) => i.key === backendFilter.key) as DropdownFilter;
        return { ...backendFilter, options: foundUrlFilter?.options ?? backendFilter?.options };
      }),
    );
    // eslint-disable-next-line -- We only want to react to `initialFilters` and `parsedUrlFilters` changes
  }, [initialFilters, parsedUrlFilters]);

  const [hasNewFiltersToApply, setHasNewFiltersToApply] = useState(false);
  const [isAnyFilterPresentInUrl, setIsAnyFilterPresentInUrl] = useState(false);
  const [isAnyFilterInputPopulated, setIsAnyFilterInputPopulated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const sortDirection = getSortDirection(searchParams.get('sortDirection'));
  const sortByFieldName = getSortBy(searchParams.get('sortBy'));
  const backendPageSize = getLimit(searchParams.get('limit'), totalAmount!);
  const backendPage = getPage(searchParams.get('page'), totalAmount!, backendPageSize);
  const startDataSliceIndex = isServerSidePagination ? 0 : page * pageSize;
  const endDataSliceIndex = isServerSidePagination ? backendPageSize : (page + 1) * pageSize;

  useEffect(() => {
    setIsAnyFilterInputPopulated(
      filters.some((i) => (i as DropdownFilter)?.selected?.length > 0 || (i as CheckboxFilter)?.isChecked),
    );
    const hasSameInputFiltersAsInUrl = isEqual(parsedUrlFilters, prepareFiltersForUrl(filters as Filter[]));
    setHasNewFiltersToApply(!hasSameInputFiltersAsInUrl);
    setIsAnyFilterPresentInUrl(parsedUrlFilters.some(isUrlFilterSet));
    // eslint-disable-next-line -- react to `filters` changes only
  }, [filters]);

  useEffect(() => {
    if (isAnyFilterPresentInUrl && !isFiltersBarVisible) {
      setIsFiltersBarVisible(true);
    }
    // eslint-disable-next-line -- react to `isAnyFilterPresentInUrl` changes only
  }, [isAnyFilterPresentInUrl]);

  const handleApplyFiltersClick = () => {
    const newSearchParams = createSearchParams({
      ...Object.fromEntries([...searchParams]),
      filters: JSON.stringify(prepareFiltersForUrl(filters as Filter[])),
    });
    setSearchParams(newSearchParams);
  };

  const handleClearAllFiltersClick = () => {
    if (isServerSidePagination) {
      setFilters(initialFilters);
      if (isAnyFilterPresentInUrl) {
        const newSearchParams = createSearchParams({
          ...Object.fromEntries([...searchParams]),
          filters: JSON.stringify([]),
        });
        setSearchParams(newSearchParams);
        setIsFiltersBarVisible(false);
      }
    } else {
      setFilters(getClearedFilters!());
    }
  };

  const handleToggleSortRecordsClick = (columnKey: string) => () => {
    const newSortDirection = sortByFieldName === columnKey && sortDirection === 'desc' ? 'asc' : 'desc';
    const newSearchParams = createSearchParams({
      ...Object.fromEntries([...searchParams]),
      sortDirection: newSortDirection,
      sortBy: columnKey,
    });
    setSearchParams(newSearchParams);
  };

  const handleGlobalSearchClick = (searchPhrase: string) => {
    const newSearchParams = createSearchParams({
      ...Object.fromEntries([...searchParams]),
      search: searchPhrase,
    });
    setSearchParams(newSearchParams);
  };

  if (!['error', 'success'].includes(status ?? '') || isFetching) {
    return (
      <div className="overlay bg-transparent md:left-64">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('px-4 sm:px-6 lg:px-8', tableClasses)}>
      <LoadingOverlay active={isLoading} />
      <div className="mt-6 flex items-center justify-between gap-x-2">
        <div className="flex flex-auto shrink-0 self-start sm:mb-4 lg:mb-0">
          {actions?.map((action) => (
            <div key={action.key} className="md:pl-0">
              <button
                disabled={action.disabled}
                aria-disabled={action.disabled}
                type="button"
                className="!xl-mr-auto inline-flex items-end justify-center rounded-md border border-transparent bg-primary-600 px-5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto"
                onClick={action.onClick}
              >
                {action.label}
              </button>
            </div>
          ))}
        </div>
        <Show when={isAnyFilterPresentInUrl || isAnyFilterInputPopulated}>
          <button
            onClick={handleClearAllFiltersClick}
            className="mr-4 flex h-full items-center justify-between px-2 py-3"
            data-testid="clear-all-filters"
          >
            <div className="ml-auto flex items-center gap-1">
              <IconMdi path={mdiClose} className="text-gray-500" />
              <span className="text-sm font-medium leading-5 text-gray-700">Clear all</span>
            </div>
          </button>
        </Show>
        <Show when={filters.length > 0}>
          <button
            className="flex w-32 items-center justify-between border border-info-200 bg-info-50 p-2 py-2.5 hover:bg-opacity-75 md:rounded-lg"
            onClick={toggleFiltersBarVisibility}
            data-testid="toggle-filters-bar-visibility"
          >
            <div className="ml-auto flex items-center gap-1">
              <IconMdi path={mdiFilter} className="text-gray-400" />
              <span className="text-sm font-medium leading-5 text-gray-700">Filters</span>
            </div>
            <IconMdi className="ml-4" path={mdiChevronDown} />
          </button>
        </Show>
        <div className="ml-6 w-96">
          <GlobalSearch
            entitiesLabel={entitiesLabel}
            onSearchClick={handleGlobalSearchClick}
            initialSearchPhrase={searchParams.get('search') ?? ''}
            isDebouncedSearch={false}
          />
        </div>
      </div>
      <Show when={isFiltersBarVisible}>
        <div
          data-testid="filters-bar"
          className="filters-bar mt-4 border-[1px] border-gray-200 bg-gray-50 p-4 pt-1 md:rounded-lg"
        >
          <div className="mt-4 flex flex-row items-start md:mt-0 md:flex-row xl:flex">
            <div key={JSON.stringify(filters ?? [])} className="flex flex-wrap items-start gap-5">
              {filters?.map((filter, index) =>
                filter.type === 'checkbox' ? (
                  <div key={filter.key} className={filter?.wrapperClassName ?? ''}>
                    <Toggle
                      value={Boolean(filter.isChecked ? 1 : 0)}
                      className={filter?.className ?? 'mb-0 ml-2 mr-6 mt-9 xl:ml-4'}
                      labelText={filter.label}
                      onClick={() => {
                        setFilters(
                          filters.map((currentFilter, currentIndex) =>
                            currentIndex === index
                              ? { ...currentFilter, isChecked: filter.isChecked ? 0 : 1 }
                              : currentFilter,
                          ),
                        );
                        if (!isServerSidePagination) {
                          filter.onChange();
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-3 mt-3 last:mr-0" key={filter.key} data-testid={filter.label}>
                    <InputMultiSelect
                      labelText={filter.label}
                      name={filter.key}
                      placeholder={filter.placeholder}
                      className={
                        filter.className ??
                        'block rounded-md border-info-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm'
                      }
                      dropdownWrapperClassName="bg-white"
                      dropdownInnerWrapperClassName="flex-col"
                      options={filter.options}
                      onChange={(value) => {
                        setFilters(
                          filters.map((currentFilter, currentIndex) =>
                            currentIndex === index ? { ...currentFilter, selected: value ?? [] } : currentFilter,
                          ),
                        );
                        if (!isServerSidePagination) {
                          setPage(0);
                          // @ts-expect-error
                          onFilterChange(index, value);
                        }
                      }}
                      onSelect={() => {}}
                      value={(filter as DropdownFilter).selected}
                    />
                  </div>
                ),
              )}
            </div>
            <Show when={isServerSidePagination}>
              <button
                className="ml-auto mt-6 flex h-full min-w-fit items-center justify-between rounded-lg bg-primary-600 p-2 px-5 py-2.5 py-3 text-sm text-white enabled:hover:bg-primary-700 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={handleApplyFiltersClick}
                disabled={!hasNewFiltersToApply}
              >
                Apply filters
              </button>
            </Show>
          </div>
        </div>
      </Show>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table data-testid="data-table" className="w-full min-w-full table-fixed divide-y divide-info-300">
                <thead className="bg-info-50">
                  <tr>
                    {columns?.map((column: Column) => {
                      if (column.sortable) {
                        return (
                          <th
                            key={column.key}
                            scope="col"
                            className={clsx(defaultHeadingCellClassName, '!px-0 !py-0', column?.headingCellClassName)}
                          >
                            <Show when={isServerSidePagination}>
                              <button
                                onClick={handleToggleSortRecordsClick(column.key)}
                                className={clsx('group inline-flex w-full px-3 py-3.5', column?.headingLinkClassName)}
                              >
                                <span className="truncate">{column.label}</span>
                                <span
                                  className={clsx(
                                    sortByFieldName === column.key
                                      ? 'ml-2 flex-none rounded bg-info-200 text-info-900 group-hover:bg-info-300'
                                      : 'invisible ml-2 flex-none rounded bg-info-200 text-info-400 group-hover:visible group-focus:visible',
                                  )}
                                >
                                  {sortByFieldName === column.key && sortDirection === 'desc' && (
                                    <ChevronDownIcon className="h-5 w-5" />
                                  )}
                                  {sortByFieldName === column.key && sortDirection === 'asc' && (
                                    <ChevronUpIcon className="h-5 w-5" />
                                  )}
                                </span>
                              </button>
                            </Show>
                            <Show when={!isServerSidePagination}>
                              <a
                                href="#"
                                className={clsx('group inline-flex w-full px-3 py-3.5', column?.headingLinkClassName)}
                                onClick={toggleSortOrderDirection(column.key, column.normalizer)}
                              >
                                <span className="truncate">{column.label}</span>
                                <span
                                  className={clsx(
                                    sortOrder.fieldName === column.key
                                      ? 'ml-2 flex-none rounded bg-info-200 text-info-900 group-hover:bg-info-300'
                                      : 'invisible ml-2 flex-none rounded bg-info-200 text-info-400 group-hover:visible group-focus:visible',
                                  )}
                                >
                                  {sortOrder.fieldName === column.key && sortOrder.sortDirection === 'desc' && (
                                    <ChevronDownIcon className="h-5 w-5" />
                                  )}
                                  {sortOrder.fieldName === column.key && sortOrder.sortDirection === 'asc' && (
                                    <ChevronUpIcon className="h-5 w-5" />
                                  )}
                                </span>
                              </a>
                            </Show>
                          </th>
                        );
                      }
                      if (column.type === 'link') {
                        return (
                          <th
                            key={column.key}
                            scope="col"
                            className={clsx('relative w-20 py-3.5 pl-3 pr-4 sm:pr-6', column?.headingCellClassName)}
                          >
                            <span className="sr-only">{column.label}</span>
                          </th>
                        );
                      }
                      return (
                        <th
                          key={column.key}
                          scope="col"
                          className={clsx(defaultHeadingCellClassName, column?.headingCellClassName)}
                        >
                          {column.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-info-200 bg-white">
                  {filteredData?.slice(startDataSliceIndex, endDataSliceIndex)?.map((item) => (
                    <tr data-testid="table-row" key={item.id} className="hover:bg-primary-50">
                      {columns?.map((column) => (
                        <TableDataCell
                          key={`${item.id}-${column.key}`}
                          column={column}
                          item={item}
                          tags={tags}
                          setIsLoading={setIsLoading}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Show when={!isServerSidePagination}>
                <Pagination totalResults={filteredData?.length ?? 0} pageSize={pageSize} onPageChange={setPage} />
              </Show>
              <Show when={isServerSidePagination}>
                <ServerSidePagination page={backendPage} pageSize={backendPageSize} totalAmount={totalAmount} />
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
