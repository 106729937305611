import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

const SUBMISSIONS_URI = 'submissions-spa-al';
const MRC_DOCUMENT_UPLOAD_URI = 'data-extraction';

interface MrcExtractionDocumentPresignedUrlResponse {
  data: {
    id: string;
    url: string;
    fields: Record<string, unknown>;
  };
}

export const getMrcExtractionDocumentPresignedUrl = async (submissionId: string, fileName: string) => {
  const url = [SUBMISSIONS_URI, submissionId, MRC_DOCUMENT_UPLOAD_URI].filter(Boolean).join('/');
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url,
    data: {
      file: fileName,
    },
  });

  const response = await axios<MrcExtractionDocumentPresignedUrlResponse>(networkPayload);
  return response;
};
