import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useEffectOnce } from '@src/hooks';
import { debounce } from 'lodash-es';

interface ScrollWizardProps {
  container: HTMLElement | null;
  onUpdate: (newPosition: number) => void;
  scrollPosition?: number;
}

export const ScrollRemember: FC<ScrollWizardProps> = ({ container, onUpdate, scrollPosition = 0 }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    if (container && scrollPosition !== 0) {
      setTimeout(() => container.scroll({ behavior: 'smooth', top: scrollPosition }), 100);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => {
      if (!container || !ref.current) {
        return;
      }

      const position = container.scrollTop;
      onUpdate(position);
    }, 100),
    [container],
  );

  useEffect(() => {
    if (!container) {
      return;
    }

    container.addEventListener('scroll', handleScroll, { passive: true });
  }, [container, handleScroll]);

  return <div className="absolute right-0 top-0 h-0 w-0" ref={ref} />;
};
