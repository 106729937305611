import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiAlert } from '@mdi/js';
import type { FallbackRender } from '@sentry/react';

import Nav from '../routes';

// eslint-disable-next-line react-refresh/only-export-components
export const sentryErrorFallback: FallbackRender = ({ error: _error, componentStack: _componentStack, resetError }) => {
  return <ErrorPage type={'unknown'} resetError={resetError} />;
};

interface ErrorPageProps {
  type: 'document' | 'unknown' | '500' | '404';
  docType?: 'submission' | 'template';
  resetError?: () => void;
  errorDetails?: string;
}

export const ErrorPage: FC<ErrorPageProps> = ({ type, docType, resetError, errorDetails }) => {
  const home = {
    submission: Nav.Submissions,
    template: Nav.Templates,
  };

  const content = {
    unknown: {
      title: 'unknown error',
      description: 'The platform has encountered an error.',
      helperText: 'Please try to refresh the page, or return to home.',
    },
    document: {
      title: `${docType} missing`,
      description: 'Unable to load template.',
      helperText: 'Please, check the URL and try again, or return to home.',
    },
    500: {
      title: '500 error',
      description: 'Internal Server Error.',
      helperText: 'The server has encountered an unexpected condition and is unable to fulfil the request.',
    },
    404: {
      title: '404 error',
      description: 'Page not found.',
      helperText: 'The page you were looking for does not exist.',
    },
  }[type];

  const refresh = () => {
    resetError?.();
    window.location.reload();
  };
  const navigateHome = () => {
    const homeURL = home[docType ?? 'submission'];
    window.location.href = homeURL;
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="inline-flex h-1/2 items-center justify-center bg-white">
        <div className="inline-flex shrink grow basis-0 flex-col items-center justify-start gap-6 self-stretch">
          <div className="inline-flex items-center justify-center gap-2.5 rounded-[90px] bg-error-100 p-2">
            <IconMdi path={mdiAlert} className="relative h-8 w-8 text-error-400" />
          </div>
          <div className="flex h-[108px] flex-col items-center justify-start gap-2 self-stretch">
            <div className="self-stretch text-center text-sm font-semibold uppercase leading-tight tracking-tight text-error-600">
              {content.title}
            </div>
            <div className="self-stretch text-center text-[32px] font-extrabold leading-[48px] text-info-900">
              {content.description}
            </div>
            <div className="self-stretch text-center text-base font-normal leading-normal text-info-500">
              {content.helperText}
              {errorDetails ? <div className="text-xs">{errorDetails}</div> : null}
            </div>
          </div>
          <div className="inline-flex items-start justify-center gap-3">
            {type !== '404' && (
              <button
                className="flex items-center justify-center rounded-md bg-error-600 px-[17px] py-[9px] shadow"
                onClick={refresh}
              >
                <div className="text-sm font-medium leading-tight text-white">Refresh page</div>
              </button>
            )}
            <button
              className="flex items-center justify-center rounded-md bg-error-100 px-[17px] py-[9px] shadow"
              onClick={navigateHome}
            >
              <div className="text-sm font-medium leading-tight text-error-700">Go back home</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
