import type { ReactNode } from 'react';
import React from 'react';
import IconMdi from '@components/IconMdi';
import InputText from '@components/InputText';
import { mdiClose, mdiMagnify } from '@mdi/js';
import { Icon } from '@mdi/react';
import { KeyCode } from '@root/@types/types';
import { isWithinViewport } from '@src/utils';
import { scrollIntoView } from '@utils/scroll-into-view';
import type { RenderExpandedProps } from '@WysiwygEditor/components/InsertMenu/components/types';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

import { containerClasses, itemClasses, listHeaderClasses } from './style/classes';
import { RenderItemInner } from './RenderItemInner';

interface InsertMenuInnerExpandedProps {
  className?: string;
  command: (args: { id: string }) => void;
  isSearchable?: boolean;
  items: { label: string; value: string }[];
  label: string;
  onCancel?: () => void;
  onSearch: (value: string) => void;
  renderExpanded?: (props: RenderExpandedProps) => ReactNode;
  search?: string;
  schemaId?: string;
  insertOnEnter?: boolean;
  disableConditionals?: boolean;
}

interface InsertMenuInnerExpandedState {
  highlightedId: string;
}

const ID_PREFIX = 'insert-menu-expanded-list-item-';

export class InsertMenuExpandedInner extends React.Component<
  InsertMenuInnerExpandedProps,
  InsertMenuInnerExpandedState
> {
  private wrapperRef = React.createRef<HTMLDivElement>();
  private containerRef = React.createRef<HTMLDivElement>();
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: InsertMenuInnerExpandedProps) {
    super(props);

    this.state = {
      highlightedId: props.items[0]?.value,
    };
  }

  handleSelectItem = (id: string) => {
    this.props.command({ id });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyCode.ArrowUp) {
      this.onArrowUpKeyHandler();
      return true;
    }
    if (event.key === KeyCode.ArrowDown) {
      this.onArrowDownKeyHandler();
      return true;
    }
    if (event.key === KeyCode.Enter && this.state.highlightedId && this.props.insertOnEnter) {
      this.handleSelectItem(this.state.highlightedId);
      return true;
    }
    if (event.key === KeyCode.Esc) {
      this.props.onCancel?.();
      return true;
    }
    return false;
  };

  componentDidMount() {
    if (this.props.isSearchable) {
      setTimeout(() => this.inputRef.current?.focus(), 50);
    }
  }

  componentDidUpdate(_prevProps: InsertMenuInnerExpandedProps, prevState: InsertMenuInnerExpandedState) {
    if (prevState.highlightedId !== this.state.highlightedId) {
      const id = `${ID_PREFIX}${this.state.highlightedId}`;
      const item = document.getElementById(id);

      if (item && this.containerRef?.current && !isWithinViewport(item, this.containerRef.current)) {
        scrollIntoView(id, { block: 'nearest' });
      }
    }
  }

  handleFocusInput = () => {
    this.inputRef.current?.focus();
  };

  onArrowUpKeyHandler = () => {
    this.setState((prevState) => {
      const currentIndex = this.props.items.findIndex((item) => item.value === prevState.highlightedId);
      const newIndex = currentIndex === 0 ? this.props.items.length - 1 : currentIndex - 1;
      return { highlightedId: this.props.items[newIndex]?.value };
    });
  };

  onArrowDownKeyHandler = () => {
    this.setState((prevState) => {
      const currentIndex = this.props.items.findIndex((item) => item.value === prevState.highlightedId);
      const newIndex = currentIndex === this.props.items.length - 1 ? 0 : currentIndex + 1;
      return { highlightedId: this.props.items[newIndex]?.value };
    });
  };

  render() {
    const {
      className,
      isSearchable,
      label,
      onSearch,
      renderExpanded,
      search,
      disableConditionals = false,
    } = this.props;

    return (
      <div
        className={containerClasses({ className })}
        onKeyDown={this.handleKeyDown}
        ref={this.wrapperRef}
        tabIndex={0}
        role="group"
      >
        <div className={clsx(listHeaderClasses)}>{label}</div>
        {isSearchable && (
          <InputText
            onChange={(event) => onSearch(event.target.value)}
            placeholder="Search datapoints"
            ref={this.inputRef}
            value={search}
            inputClassName="px-8"
            className="p-2"
            startAdornment={
              <IconMdi
                path={mdiMagnify}
                onClick={this.handleFocusInput}
                className="absolute left-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
              />
            }
            endAdornment={
              <IconMdi
                path={mdiClose}
                onClick={onSearch.bind(null, '')}
                className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
              />
            }
          />
        )}
        <div className="grow overflow-auto" ref={this.containerRef}>
          {typeof renderExpanded === 'function' &&
            renderExpanded({
              renderItem: (props) => (
                <RenderItemInner
                  id={`${ID_PREFIX}${props.value}`}
                  onClick={() => this.handleSelectItem(props.value)}
                  highlightedId={this.state.highlightedId}
                  disableConditionals={disableConditionals}
                  {...props}
                />
              ),
              search,
            })}
          {!renderExpanded &&
            (!isEmpty(this.props.items) ? (
              this.props.items.map(({ label, value }) => {
                return (
                  <button
                    id={`${ID_PREFIX}${value}`}
                    key={value}
                    title={value}
                    onClick={() => this.handleSelectItem(value)}
                    className={itemClasses({ isHighlighted: this.state.highlightedId === value })}
                  >
                    {label}
                  </button>
                );
              })
            ) : (
              <div className="flex flex-col items-center gap-1 py-4 text-center">
                <Icon path={mdiMagnify} size={1} />
                <span>Nothing was found based on your search criteria.</span>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
