import type { FC } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Show, ShowFirstMatching } from '@components/Show';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

const containerClasses = cva(
  ['flex flex-col items-center justify-start gap-1 self-stretch rounded-md border-2 border-dashed  p-7'],
  {
    variants: {
      errors: { true: 'border-red-500', false: 'border-info-300' },
    },
  },
);

interface ImageUploadViewProps {
  onChange: (files: File[]) => void;
  onFileRejection?: (fileRejections: FileRejection[], event: DropEvent) => void;
  files: File[];
  errors?: string[];
}

export const ImageUploadView: FC<ImageUploadViewProps> = ({ onChange, onFileRejection, files, errors }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
    },
    maxSize: 1024 * 1024, //1MB
    onDrop: onChange,
    multiple: true,
    onDropRejected: onFileRejection,
  });

  const hasErrors = !isEmpty(errors);

  return (
    <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-[22px]">
      <div className="inline-flex items-start justify-center gap-4 self-stretch">
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2 self-stretch">
          <div className="self-stretch text-sm font-normal leading-tight text-info-500">
            Upload an image to the library for use within any branding
          </div>
        </div>
      </div>
      <div className={containerClasses({ errors: hasErrors })} {...getRootProps()}>
        <input
          {...getInputProps()}
          type="file"
          className="sr-only"
          id="file-upload"
          multiple
          data-testid="image-upload-input"
        />
        <ShowFirstMatching>
          <Show when={!isEmpty(files)}>
            <div className="flex flex-col gap-3 text-base text-info-600">
              {files.map((file) => (
                <div className="flex items-center gap-3" key={file.name}>
                  <DocumentTextIcon className="h-6 w-6" />
                  {/* <img src={URL.createObjectURL(file)} alt={file.name} className="h-6" /> */}
                  <span className="w-fit">{file.name}</span>
                </div>
              ))}
            </div>
          </Show>
          <Show when={true}>
            <label htmlFor="file-upload" className="inline-flex cursor-pointer items-center justify-start gap-1">
              <div className="flex items-center justify-start rounded-md bg-white">
                <div className="text-sm font-medium leading-tight text-primary-600">Upload a file</div>
              </div>
              <div className="text-sm font-medium leading-tight text-info-600">or drag and drop</div>
            </label>
            <div className="text-center text-xs font-normal leading-none text-info-500">
              JPG or PNG up to 1MB File size
            </div>
          </Show>
        </ShowFirstMatching>
      </div>
      <Show when={hasErrors}>
        <ul className="-my-3 text-sm font-normal text-error-500">
          {errors?.map((error) => (
            <li className={clsx(errors.length > 1 ? 'ml-4 list-item list-disc' : '')} key={error}>
              {error}
            </li>
          ))}
        </ul>
      </Show>
      <div className="self-stretch text-xs font-normal leading-tight text-info-400">
        By uploading an image, you confirm that it does not violate any applicable laws or rights of third party
        companies or individuals.
      </div>
    </div>
  );
};
