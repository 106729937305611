import type { FC } from 'react';
import Status from '@components/Status';
import { ValidationStateIcon, validationStateIconClasses } from '@components/ValidationStateIcon';
import { useSchemaStore } from '@ContractBuilder/store';
import { PlayIcon } from '@heroicons/react/solid';
import type { JSONSchema, JSONSchemaProperties } from '@root/@types/schema';
import clsx from 'clsx';

interface FormNavigationProps {
  onClick: () => void;
  required?: string[];
  schema?: JSONSchema;
  section?: JSONSchemaProperties;
  sectionKey: string;
  title: string;
}

export const NavItem: FC<FormNavigationProps> = ({ onClick, sectionKey, title }) => {
  const { getSectionValidationState } = useSchemaStore(({ getSectionValidationState }) => ({
    getSectionValidationState,
  }));

  const { hasErrors, hasValues } = getSectionValidationState(sectionKey);

  const isValid = !hasErrors && hasValues;
  const inProgress = hasErrors;
  const notStarted = !isValid && !inProgress;

  return (
    <button
      className="my-4 flex w-full cursor-pointer items-center justify-between gap-2.5 focus:outline-none"
      onClick={onClick}
      type="button"
    >
      <div className="flex flex-shrink-0 gap-x-2">
        <ValidationStateIcon
          idleStateNode={
            <PlayIcon className={validationStateIconClasses({ className: 'rounded-full bg-info-400 fill-white' })} />
          }
          isDirty={inProgress}
          isValid={isValid}
          withMarginLeft
          disableTooltip
        />
        <p
          title={title}
          className={clsx(
            'mx-3 max-w-[10vw] flex-shrink truncate whitespace-nowrap transition-all xl:max-w-fit',
            inProgress ? 'font-medium text-black' : 'text-info-700 hover:text-info-900',
          )}
        >
          {title}
        </p>
      </div>

      <div className="flex-shrink-0" data-testid={`${sectionKey}_form_navigation_item_status`}>
        {isValid && <Status size="sm" statusText="Complete" status="complete" />}
        {inProgress && <Status size="sm" statusText="Incomplete" status="incomplete" />}
        {notStarted && <Status size="sm" statusText="Not started" status="not_started" />}
      </div>
    </button>
  );
};
