import { mdiArrowCollapseDown, mdiArrowCollapseUp, mdiFormatListBulleted, mdiFormatListNumbered } from '@mdi/js';

import { OrderedListDecimal } from '../components/icons/OrderedListDecimal';
import { OrderedListLetters } from '../components/icons/OrderedListLetters';
import { OrderedListRoman } from '../components/icons/OrderedListRoman';
import type { Option } from '../types';
import { OrderedListType } from '../types';

import { getSeparator } from './separator';

export const getListControls = (editor: any): Option[] => {
  if (!editor) {
    return [];
  }

  const setOrderedList = (listType: OrderedListType) => {
    const isOrderedList = editor.isActive('orderedList');

    if (!isOrderedList) {
      editor.chain().focus().toggleOrderedList().run();
    }

    editor.chain().focus().setIconType(listType).run();
  };

  return [
    {
      format: () => editor?.chain().focus().toggleBulletList().run(),
      isActive: editor?.isActive('bulletList'),
      icon: mdiFormatListBulleted,
      key: 'bulletList',
      title: 'Bulleted list',
    },
    {
      format: () => setOrderedList(OrderedListType.NUMBERS),
      isActive: editor.getAttributes('orderedList').type === OrderedListType.NUMBERS,
      icon: mdiFormatListNumbered,
      title: 'Ordered list',
      key: 'ordered-list-numbers',
    },
    {
      format: () => setOrderedList(OrderedListType.DECIMAL),
      isActive: editor.getAttributes('orderedList').type === OrderedListType.DECIMAL,
      icon: OrderedListDecimal,
      title: 'Decimal',
      key: 'ordered-list-decimal',
    },
    {
      format: () => setOrderedList(OrderedListType.LETTERS),
      isActive: editor.getAttributes('orderedList').type === OrderedListType.LETTERS,
      icon: OrderedListLetters,
      title: 'Letters',
      key: 'ordered-list-letters',
    },
    {
      format: () => setOrderedList(OrderedListType.ROMAN),
      isActive: editor.getAttributes('orderedList').type === OrderedListType.ROMAN,
      icon: OrderedListRoman,
      title: 'Roman literals',
      key: 'ordered-list-roman',
    },
    getSeparator('lists-2'),
    {
      format: () =>
        editor
          ?.chain()
          .focus()
          .updateAttributes('orderedList', { start: (editor?.getAttributes('orderedList').start || 1) - 1 })
          .run(),

      icon: mdiArrowCollapseDown,
      key: 'decreaseStart',
      title: 'Decrease Start',
    },
    {
      format: () =>
        editor
          ?.chain()
          .focus()
          .updateAttributes('orderedList', { start: (editor?.getAttributes('orderedList').start || 1) + 1 })
          .run(),

      icon: mdiArrowCollapseUp,
      key: 'increaseStart',
      title: 'Increase Start',
    },
  ];
};
