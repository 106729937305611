export enum VariationsSystemHelperText {
  VARIATIONS = 'VARIATIONS',
  VARIATIONS_LOGIC = 'VARIATIONS_CONDITIONAL',
  NO_VARIATIONS = 'NO_VARIATIONS',
  SELECT_VARIATIONS = 'SELECT_VARIATION',
}

export const VARIATIONS_COPY_MAP: Record<VariationsSystemHelperText, string | null> = {
  [VariationsSystemHelperText.NO_VARIATIONS]: null,
  [VariationsSystemHelperText.VARIATIONS]: 'This block has multiple variations',
  [VariationsSystemHelperText.VARIATIONS_LOGIC]: 'This block has multiple conditional variations',
  [VariationsSystemHelperText.SELECT_VARIATIONS]: 'Please choose one of the available variations',
};
