import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import IconMdi from '@components/IconMdi';
import { Show, ShowFirstMatching } from '@components/Show';
import { useForm } from '@hooks/form';
import { mdiChevronLeft } from '@mdi/js';
import type { UIComponentBase } from '@root/@types/types';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import Button from '../../components/Button';
import Form from '../../components/Form';
import H3 from '../../components/H3';
import InputText from '../../components/InputText';

import AuthLayout from './AuthLayout';

const InfoText: React.FC<UIComponentBase> = ({ children, className }) => (
  <p className={twMerge('mb-6 text-sm text-info-700', className)}>{children}</p>
);

const ForgotPassword: React.FC = () => {
  const [areInstructionsSent, setInstructionsSent] = useState<boolean | never>();
  const { getFieldProps, canSubmit, onSubmit, formError } = useForm<{ email: string }>({
    initialState: { email: '' },
    onSubmit: async (formValues) => {
      await Auth.forgotPassword(formValues.email.trim());

      setInstructionsSent(true);
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
    }),
  });

  const handleSubmit = async () => {
    await onSubmit();
  };

  return (
    <AuthLayout>
      <ShowFirstMatching>
        <Show when={areInstructionsSent}>
          <div className="flex flex-col gap-3">
            <div>
              <H3 className="mb-2 font-['Inter'] !font-[600]">Instructions sent</H3>
              <InfoText>Instructions to reset your password have been sent to you.</InfoText>
              <InfoText>Please check your inbox.</InfoText>
            </div>
            <Link to={'/login'}>
              <Button kind="primary" className="h-10 w-full">
                Return to Login
              </Button>
            </Link>
          </div>
        </Show>
        <Show when={!areInstructionsSent}>
          <Link to={'/login'} className="mb-4 block text-sm ">
            <Button className="h-9 px-2">
              <IconMdi path={mdiChevronLeft} /> Return to login
            </Button>
          </Link>
          <H3 className="mb-2 font-['Inter'] !font-[600]">Forgotten your password?</H3>
          <InfoText>
            It happens to all of us. Just enter the email you used to create the account and we’ll send you some
            instructions to reset your password.
          </InfoText>
          <Form onSubmit={handleSubmit}>
            <InputText
              errors={formError}
              isPublic={true}
              className="mb-6"
              labelText="Email address"
              type="email"
              {...getFieldProps('email')}
            />
            <Button kind="primary" type="submit" isDisabled={!canSubmit} className="h-10 w-full">
              Send reset instructions
            </Button>
          </Form>
        </Show>
      </ShowFirstMatching>
    </AuthLayout>
  );
};

export default ForgotPassword;
