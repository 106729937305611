import { DataType } from '@features/create-entity-modal/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { z } from 'zod';

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const step1Schema = z.object({
  name: z.string().min(1, { message: 'Required' }),
  team: z.array(z.number()),
  class_of_business: z.enum(ALL_CLASSES_OF_BUSINESS).optional(),
});

export const step2Schema = step1Schema.extend({
  copy_from_template: z.string().optional(),
  copy_from_submission: z.string().optional(),
});

export const step2SchemaRefined = step1Schema
  .extend({
    copy_from_template: z.string().optional(),
    copy_from_submission: z.string().optional(),
  })
  .partial()
  .refine((data) => data.copy_from_template ?? data.copy_from_submission);

const dataType = z.nativeEnum(DataType);

export const step3Schema = step2Schema.extend({
  branding: z.string(),
  dataType: dataType.optional(),
});

export const step4Schema = step3Schema.extend({
  file: z.any().optional(),
});
