import { STEPS_MAP } from '../constants';
import type { EntityModalType } from '../types';

export const getStepComponents = (type: EntityModalType) => {
  const map = STEPS_MAP[type];
  return {
    Step1: map['1'],
    Step2: map['2'],
    Step3: map['3'],
  };
};
