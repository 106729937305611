import { replaceMatchedFragments } from '@ContractBuilder/functions/replace-matched-fragments';
import type { LibraryBlockType } from '@ContractBuilder/types';
import { useDeepCompareMemo } from '@src/hooks';

const parseNameAndClassOfBusiness = (item: LibraryBlockType, search: string) => {
  let newName = item.name ? item.name?.replace(search, replaceMatchedFragments) : '';
  let newClassOfBusiness = item.class_of_business
    ? item.class_of_business?.replace(search, replaceMatchedFragments)
    : undefined;

  return {
    ...item,
    name: newName,
    class_of_business: newClassOfBusiness,
  };
};

export const useFilteredBlocks = (blocks: LibraryBlockType[] = [], search: string = '') => {
  return useDeepCompareMemo(() => blocks.map((item) => parseNameAndClassOfBusiness(item, search)), [search, blocks]);
};
