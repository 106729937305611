import React, { forwardRef } from 'react';
import type { Editor as TipTapEditor } from '@tiptap/react';
import { EditorContent } from '@tiptap/react';
import clsx from 'clsx';

import './index.css';
import './editor.css';
import './table-borders.css';
import 'tiptap-extension-resizable-image/styles.css';

interface EditorProps {
  editor: TipTapEditor | null;
  isDisabled?: boolean;
}

export const Editor = forwardRef<HTMLDivElement, EditorProps>(function Editor({ editor, isDisabled = false }, ref) {
  return (
    <div
      className={clsx(
        isDisabled && 'pointer-events-none cursor-not-allowed opacity-50',
        'flex flex-1 cursor-text [&>div]:flex [&>div]:flex-1',
      )}
    >
      <EditorContent disabled={isDisabled} editor={editor} ref={ref} />
    </div>
  );
});
