import type { FC } from 'react';
import React from 'react';
import { useUserStore } from '@Auth/store';
import { InlineEditableText } from '@components/InlineEditableText';
import InputMultiSelect from '@components/InputMultiSelect';
import InputSelect from '@components/InputSelect';
import Tooltip from '@components/Tooltip';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import type { OnSaveFn } from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import type { EntityData } from '@ContractBuilder/types';
import { UserGroupIcon } from '@heroicons/react/solid';
import { tenantConfig } from '@root/config/tenants';
import { getUserTeamNames } from '@root/helpers/permissions/utils';
import { isTemplatePath } from '@utils/app-paths';

import { useEditableField } from '../hooks';

const TEAMS = tenantConfig?.teams ?? [];

interface EditableTeamsFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn<number[]>;
}

const getEntityTeamsDisplay = (teams: number[]) => {
  const teamsLength = teams.length;
  if (teamsLength === 0) {
    return 'All teams';
  }
  if (teamsLength === 1) {
    return TEAMS.find((team) => team.id === teams[0])?.name;
  }
  return `${teamsLength} teams`;
};

export const EditableTeamsField: FC<EditableTeamsFieldProps> = ({ entity, hasOperationsDisabled, onSave }) => {
  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField<number[]>({
    defaultValue: entity.teams ?? [],
    name: 'teams',
    onSave,
  });

  const isTemplate = isTemplatePath();
  const { user } = useUserStore(({ user }) => ({ user }));
  const userTeams = getUserTeamNames(user, true);

  const handleChange = (value?: string[]) => {
    const newValue = value?.map((teamName) => TEAMS?.find((team) => team.name === teamName)!.id);

    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <div className="flex items-center gap-1">
      <Tooltip content={`Team${isTemplate ? 's' : ''}`}>
        <span>
          <UserGroupIcon className="h-4 w-4" />
        </span>
      </Tooltip>
      <InlineEditableText
        editor={
          isTemplate ? (
            <InputMultiSelect
              value={(state?.value as number[])?.map(
                (teamId: number) => TEAMS.find((team) => team.id === teamId)?.name ?? '',
              )}
              options={userTeams}
              onChange={handleChange}
              onSelect={() => {}}
              name="teams"
              isDisabled={false}
              className="max-w-[15rem]"
              menuOuterClassName="min-w-fit"
              selectedItemClassName="-my-4"
              placeholder="Select teams"
              size="sm"
            />
          ) : (
            <InputSelect
              value={TEAMS.find((team) => team.id === state?.value?.[0])?.name}
              options={userTeams.map((label) => ({ name: label, value: label }))}
              onChange={(value) =>
                onChange([value]?.map((teamName) => TEAMS?.find((team) => team.name === teamName)!.id))
              }
              name="teams"
              isDisabled={false}
              placeholder="Select team"
              size="sm"
              menuOuterClassName="min-w-fit"
            />
          )
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={getEntityTeamsDisplay(entity?.teams ?? [])}
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? `Edit team${isTemplate ? 's' : ''}` : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
