import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

type CreateBrandingPayload = {
  name: string;
  clone_from_id: string;
};

export const createBranding = async (data: Partial<CreateBrandingPayload>): Promise<{ id: string }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: '/brandings',
    data,
  });

  return (await axios(networkPayload)).data;
};
