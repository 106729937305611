import type { ChangeEvent, FC } from 'react';
import { useRef, useState } from 'react';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import Input from '@components/Input';
import { Show } from '@components/Show';
import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import { mdiChevronDown, mdiFilter, mdiMagnify } from '@mdi/js';
import type { BlockType, ClauseType } from '@root/@types/types';
import { useEffectOnce } from '@src/hooks';
import { useLanguage } from '@src/language';
import clsx from 'clsx';

import type { FiltersState } from '../types';

import { SearchFiltersDropdown } from './SearchFiltersDropdown';

export interface BlocksLibrarySearchInputProps {
  filters: FiltersState;
  onApplyFilters: (filters: FiltersState) => void;
  onResetFilters: () => void;
  onSearchReset: () => void;
  onSearch: (searchInput: string) => void;
  searchText?: string;
  isFetching: boolean;
}

const filterPillClasses = ['flex items-center gap-1 rounded-xl border border-info-400 bg-white px-1.5 py-0.5'];
const filterPillCloseIconClasses = ['mt-0.5 cursor-pointer text-info-500 transition duration-300 hover:text-info-400'];

export const BlocksLibrarySearchInput: FC<BlocksLibrarySearchInputProps> = ({
  filters,
  onApplyFilters,
  onResetFilters,
  onSearch,
  onSearchReset,
  searchText = '',
  isFetching,
}) => {
  const [searchInput, setSearchInput] = useState(searchText);
  const ref = useRef<HTMLInputElement>(null);
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const focusSearch = () => {
    if (ref.current) {
      ref.current?.focus();
    }
  };

  useEffectOnce(() => {
    focusSearch();
  });

  const filtersCombined = [...filters.type, ...filters.clause_type];
  const hasFilters = filtersCombined.length > 0;

  const handleIndividualBlockTypeFilterClear = (value: BlockType) => {
    const nextValue = { ...filters, type: filters.type.filter((item) => item !== value) };
    if (!nextValue.type.includes('clause')) {
      return onApplyFilters({ ...nextValue, clause_type: [] });
    }
    return onApplyFilters(nextValue);
  };

  const handleIndividualClauseTypeFilterClear = (value: ClauseType) => {
    return onApplyFilters({ ...filters, clause_type: filters.clause_type.filter((item) => item !== value) });
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const handleSearchClick = () => onSearch(searchInput);

  return (
    <div className={clsx(DEFAULT_CREATOR_PADDING, 'flex flex-col gap-4')}>
      <div className="flex w-full place-items-center gap-2">
        <div className={'relative flex h-10 w-full items-center overflow-clip rounded-md'}>
          <IconMdi
            path={mdiMagnify}
            className="absolute left-3 top-1/2 -translate-y-1/2 text-info-600"
            onClick={focusSearch}
            size={1}
          />
          <Input
            ref={ref}
            value={searchInput}
            placeholder="Search by name or keyword"
            className="rounded-r-none bg-white px-10 pl-10"
            onChange={handleSearchInputChange}
            isDisabled={isFetching}
          />
          <CloseIconButton
            size={0.65}
            onClick={onSearchReset}
            className={clsx(
              'absolute top-1/2 -translate-x-1/2 -translate-y-1/2 text-info-600 transition duration-300 hover:text-info-400',
              'right-0',
              searchText?.length ? 'cursor-pointer opacity-100' : 'pointer-events-none select-none opacity-0',
            )}
          />
          <div className="relative">
            <SearchFiltersDropdown
              filters={filters}
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
              renderTrigger={({ onClick }) => (
                <button
                  className="flex h-10 w-32 items-center justify-between border border-l-0 border-info-200 bg-info-50 p-2 py-3 hover:bg-opacity-75"
                  onClick={onClick}
                >
                  <div data-testid="filter-btn" className="ml-auto flex items-center gap-1">
                    <IconMdi path={mdiFilter} className="text-info-400" />
                    <span className="text-sm font-medium leading-5">Filters</span>
                  </div>
                  <IconMdi className="ml-4" path={mdiChevronDown} />
                </button>
              )}
              isFetching={isFetching}
            />
          </div>
        </div>
        <Button
          className="ml-4 h-10 px-8"
          kind="primary"
          onClick={handleSearchClick}
          isDisabled={!searchInput || isFetching}
        >
          Search
        </Button>
      </div>
      <Show when={hasFilters}>
        <div className="ml-1 flex items-start gap-3 border-b border-gray-200 pb-5 text-sm font-medium text-info-900 ">
          FILTERS:
          <div className="flex flex-wrap items-center gap-4">
            {filters.type.map((filter) => (
              <button
                className={clsx(filterPillClasses)}
                key={`filter-${filter}`}
                onClick={() => handleIndividualBlockTypeFilterClear(filter)}
              >
                {getContent(filter)}
                <CloseIconButton size={0.5} onClick={onSearchReset} className={clsx(filterPillCloseIconClasses)} />
              </button>
            ))}
            {filters.clause_type.map((filter) => (
              <button
                className={clsx(filterPillClasses)}
                key={`filter-${filter}`}
                onClick={() => handleIndividualClauseTypeFilterClear(filter)}
              >
                {filter}
                <CloseIconButton size={0.5} onClick={onSearchReset} className={clsx(filterPillCloseIconClasses)} />
              </button>
            ))}
          </div>
        </div>
      </Show>
    </div>
  );
};
