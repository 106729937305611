import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import type { Editor } from '@tiptap/react';

import { EditorsMapContext } from './context';

interface EditorsMapProviderState {
  [k: string]: Editor | null;
}

export const EditorsMapProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editorsMap, setEditorsMap] = useState<EditorsMapProviderState>({});

  const registerEditor = (name: string, editor: Editor) => {
    setEditorsMap((current) => ({ ...current, [name]: editor }));
  };

  const deregisterEditor = (name: string) => {
    setEditorsMap((current) => ({ ...current, [name]: null }));
  };

  const getEditor = (name: string) => {
    return editorsMap[name];
  };

  return (
    <EditorsMapContext.Provider value={{ deregisterEditor, getEditor, registerEditor }}>
      {children}
    </EditorsMapContext.Provider>
  );
};
