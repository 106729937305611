import { createContext, useContext } from 'react';
import type { StateFromBlock } from '@ContractBuilder/modules/block-edit';

import type { SetFormValues } from '../types';

export interface BlockVisibilityFormContextValue {
  basePath: string;
  formValues: StateFromBlock;
  handleAdd: () => void;
  handleRemove: (index: number) => void;
  handleRemoveAll: () => void;
  hasEmptyConditions: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
  setFormValues: SetFormValues;
  setTab: (tab: string) => void;
  tab: string | null;
}

export const BlockVisibilityFormContext = createContext<BlockVisibilityFormContextValue | null>(null);

export const useBlockVisibilityForm = () => {
  const context = useContext(BlockVisibilityFormContext);

  if (!context) {
    throw new Error(`"BlockVisibilityFormContext" must be present in React DOM tree.`);
  }

  return context;
};
