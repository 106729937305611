import type { ToggleGroupOption, ToggleGroupValue } from './types';

export const isOptionChecked = (value: unknown, option?: ToggleGroupValue | ToggleGroupOption) =>
  typeof option === 'object' ? option.value === value : option === value;

export const normaliseOptions = (options: ToggleGroupOption[] | ToggleGroupValue[]): ToggleGroupOption[] =>
  (typeof options?.[0] === 'object'
    ? options
    : options?.map((option) => ({ value: option, label: option }))) as ToggleGroupOption[];

export const getOptionKey = (id: string, label: ToggleGroupValue) => `${id}-${label}`;
