import type { ReactNode } from 'react';
import React, { useState } from 'react';
import clsx from 'clsx';

import type { CustomSvgComponent } from '../../@types/types';

const Svg: CustomSvgComponent = ({ stroke, strokeWidth, fill }) => (
  <svg width="16" height="16" className="self-center">
    <circle cx="8" cy="8" r="7" stroke={stroke} strokeWidth={strokeWidth} fill="none" />
    <circle cx="8" cy="8" r="4" stroke="none" strokeWidth="0" fill={fill} />
  </svg>
);

export interface RenderLabelArgs extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  isChecked?: boolean;
  isDisabled?: boolean;
}

interface InputRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  descriptionText?: string;
  errors?: boolean;
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelText?: string;
  renderLabel?: (props: RenderLabelArgs) => ReactNode;
  isIncomplete?: boolean;
}

const InputRadio: React.FC<InputRadioProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isChecked,
  isDisabled,
  isRequired,
  labelText,
  name,
  renderLabel,
  value,
  isIncomplete,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (errors && '#f03e3e') || (isDisabled && '#CED4DA') || (isFocused && '#339AF0') || '#868E96';
  const strokeWidth = isFocused || errors ? '2' : '1';
  const fill = (isChecked && !isDisabled && '#339AF0') || (isChecked && isDisabled && '#CED4DA') || 'none';

  const label = renderLabel ? (
    renderLabel({ isChecked, value, isDisabled })
  ) : (
    <>
      <div className="ml-3">
        <div className="text-sm">{labelText}</div>
        {descriptionText && <p className={clsx('mt-2 text-sm', !isDisabled && 'text-info-800')}>{descriptionText}</p>}
      </div>
    </>
  );

  return (
    <>
      <label
        className={clsx(
          'relative flex cursor-pointer flex-wrap content-center items-start',
          isDisabled && 'cursor-auto text-info-700',
          className,
        )}
        htmlFor={id}
      >
        <Svg stroke={stroke} strokeWidth={strokeWidth} fill={fill} />
        {label}
      </label>
      <input
        className={clsx('absolute -z-10 opacity-0', isIncomplete && 'field-incomplete')}
        disabled={isDisabled}
        checked={isChecked}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="radio"
        value={value}
        required={isRequired}
        {...props}
      />
    </>
  );
};

export default InputRadio;
