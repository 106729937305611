import { PanelPlacement } from '@ContractBuilder/hooks';

import { SelectedMode } from '../types';

interface UsePdfPreviewStateArgs {
  placement: PanelPlacement;
  selectedMode: SelectedMode;
}

export const usePDFPreviewState = ({ placement, selectedMode }: UsePdfPreviewStateArgs) => {
  const isLeftSidePlacement = placement === PanelPlacement.Left;
  const isRightSidePlacement = placement === PanelPlacement.Right;

  const isInDataExtractionDocumentMode = selectedMode === SelectedMode.DataExtractionDocument;
  const isInDocumentMode = selectedMode === SelectedMode.Document;
  const isInSplitMode = selectedMode === SelectedMode.Split;

  return {
    isInDataExtractionDocumentMode: isInDataExtractionDocumentMode && !isInSplitMode,
    isInDocumentMode: isInDocumentMode && !isInSplitMode,
    isInSplitMode,
    isLeftSidePlacement: isLeftSidePlacement && !isInSplitMode,
    isRightSidePlacement: isRightSidePlacement && !isInSplitMode,
  };
};
