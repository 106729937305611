import { VariationsSystemHelperText } from '../view/VariationsSystemHelperTextCopy';

interface GetSystemHelperTextForVariations {
  hasVariations?: boolean;
  hasVariationsConditionalLogic?: boolean;
  selectedVariationId?: string;
}

export const getSystemHelperTextForVariations = ({
  hasVariations,
  hasVariationsConditionalLogic,
  selectedVariationId,
}: GetSystemHelperTextForVariations): VariationsSystemHelperText => {
  switch (true) {
    case !hasVariations:
      return VariationsSystemHelperText.NO_VARIATIONS;

    case hasVariationsConditionalLogic:
      return VariationsSystemHelperText.VARIATIONS_LOGIC;

    case !selectedVariationId:
      return VariationsSystemHelperText.SELECT_VARIATIONS;

    default:
      return VariationsSystemHelperText.VARIATIONS;
  }
};
