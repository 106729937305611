import React, { useCallback } from 'react';
import IconMdi from '@components/IconMdi';
import Tooltip from '@components/Tooltip';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import { useEntityStore } from '@ContractBuilder/store';
import { mdiMagnify } from '@mdi/js';
import { useSetFocussedCandidate } from '@MrcExtraction/modules/mrc-document-view';
import { isNonNullish } from '@root/helpers';
import { ROOT_SECTIONS_KEY } from '@root/helpers/schema';
import { find, first } from 'lodash-es';

const FIRST_SECTION_DESCRIPTION_ID = `${ROOT_SECTIONS_KEY}[0].section_description`;

function createPatternFromString(inputString: string): RegExp {
  const segments = inputString.split(/\[\d+\]/); // Split by numeric segments

  // Escape special characters in each segment and join them with a wildcard pattern
  const pattern = segments.map((segment) => segment.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('\\[\\d+\\]');

  // Construct and return the regular expression
  return new RegExp(`^${pattern}$`);
}

export const useDocumentPreviewNavigationIcon = () => {
  const onSetFocussedCandidate = useSetFocussedCandidate();

  return useCallback(
    (fieldId: string) => {
      const submission = useEntityStore.getState().submission;
      const dataExtractionItem = first(submission?.dataExtractions);

      if (!dataExtractionItem) {
        return null;
      }

      const pattern = createPatternFromString(fieldId);

      const field = dataExtractionItem?.data.find(({ id, value }) => pattern.test(id) && Array.isArray(value));
      const currencyField = find(
        dataExtractionItem?.data,
        ({ id }) => id.replace('.amount', '') === fieldId || id.replace('.currency', '') === fieldId,
      );
      const sectionsRootField =
        fieldId === FIRST_SECTION_DESCRIPTION_ID
          ? find(dataExtractionItem?.data, ({ id }) => id === '_sections')
          : undefined;

      const parsedField = field ?? currencyField ?? sectionsRootField;
      const parsedFieldId = parsedField?.id;

      if (!parsedField) {
        return null;
      }

      const alertItems = parsedField.alerts?.map((item) => item.text).filter(isNonNullish);
      const hasAlerts = alertItems && alertItems.length > 0;

      const matchConfidence = parsedField.matchConfidence ?? 0;
      const textConfidence = parsedField.textConfidence ?? 0;

      if (matchConfidence === 0) {
        return null;
      }

      return (
        <PillBadge
          className="cursor-pointer bg-purple-100 p-0.5 font-medium uppercase text-purple-900 transition-colors hover:text-purple-800 [&>span]:flex [&>span]:items-center [&>span]:gap-x-1"
          content={
            <>
              <IconMdi className="cursor-pointer" path={mdiMagnify} size={0.5} />
              <Tooltip
                content={
                  <div className="flex flex-col">
                    <p>
                      Match confidence: <strong>{matchConfidence}%</strong>
                    </p>
                    <p>
                      Text Accuracy: <strong>{textConfidence}%</strong>
                    </p>
                    {hasAlerts && (
                      <>
                        <p className="mt-4">Warnings:</p>
                        <ul className="flex shrink-0 list-disc flex-col pl-4">
                          {alertItems?.map((item) => <li key={`alert-item-${item}`}>{item}</li>)}
                        </ul>
                      </>
                    )}
                  </div>
                }
                maxWidth="auto"
              >
                <span>Content found</span>
              </Tooltip>
            </>
          }
          maxWidth="auto"
          onClick={() => onSetFocussedCandidate(parsedFieldId)}
        />
      );
    },
    [onSetFocussedCandidate],
  );
};
