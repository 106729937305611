// Type inferred from `mrc` should drive the shape of any other naming object
export const mrc = {
  attachment: 'Attachment',
  section: 'Part',
  block: {
    name: 'Block',
    title: 'Block Heading',
    inline: 'Subheading',
    layout: {
      clause: 'Clause',
      generic: 'Fullwidth',
      'mrc-heading': 'Heading',
    },
  },
  attachments: {
    title: 'Attachments',
  },
  leftSidebar: {
    title: 'Navigation',
    controls: {
      expand: 'Expand all',
      collapse: 'Collapse all',
    },
  },
  rightSidebar: {
    fields: {
      section: { label: 'Change block placement' },
      datapoints: { single: { label: 'Datapoints on block' }, variations: { label: 'Data points on variant' } },
      instructions: { label: 'Add block instructions', placeholder: 'Instructions or helper text for this block' },
      settings: {
        label: 'Block settings',
        variations: { label: 'Add variants of this block' },
        pageBreak: { label: 'Add page break after block' },
        superAdminEdit: { label: 'Only super admin can edit' },
      },
      submissionPermissions: {
        label: 'Block permissions for submissions',
        canEdit: { label: 'Can be edited' },
        canDelete: { label: 'Can be removed' },
      },
      templatePermissions: {
        label: 'Block permissions for templates',
        canDelete: { label: 'Can be removed' },
      },
      actions: { label: 'Additional actions' },
      layout: { label: 'Block layout' },
      clause: {
        type: {
          label: 'Clause type',
        },
        reference: {
          label: 'Clause reference',
        },
      },
    },
    removeButton: 'Remove this block',
    configureVisibilityButton: 'Configure visibility',
  },
  modals: {
    attachment: {
      title: { create: 'Append an attachment', rename: 'Rename attachment' },
      subtitle: 'Append an accompanying document to the end of the contract file',
      fields: {
        name: {
          label: 'Attachment name',
        },
      },
      limit: 'PDF only, up to 10MB',
      uploading: 'Uploading your document...',
    },
    editSection: {
      title: { edit: 'Edit part', create: 'Create part' },
      fields: {
        name: { label: 'Name' },
        customName: { label: 'Custom name' },
        hideTitle: { label: "Hide the part's title in PDF" },
        resetNumbering: {
          label: 'Reset page counter',
          helperText: 'Would you like to reset the document page counter to 1 from this part onwards?',
        },
      },
      button: {
        edit: 'Save',
        create: 'Create part',
      },
    },
  },
  contextMenus: {
    attachment: {
      rename: 'Rename',
      remove: 'Remove',
    },
    section: {
      edit: 'Edit part',
      remove: 'Remove part',
    },
    variation: {
      rename: 'Rename',
      remove: 'Remove',
      duplicate: 'Duplicate',
    },
  },
  contractForm: {
    sectionComplete: 'Part complete',
    viewInContractButtonLabel: 'View this part in contract',
  },
};

export type LanguageNaming = typeof mrc;
