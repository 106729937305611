// @ts-expect-error
import { Mark, mergeAttributes } from '@tiptap/core';

export interface UppercaseMarkOptions {
  HTMLAttributes: Record<string, any>;
}

export const UppercaseMark = Mark.create<UppercaseMarkOptions>({
  name: 'uppercase',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        style: 'text-transform',
        getAttrs: (value: string) => /^(uppercase)$/.test(value as string) && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, {
        ...HTMLAttributes,
        style: 'text-transform: uppercase;',
      }),
      0,
    ];
  },

  addCommands() {
    return {
      toggleUppercase:
        () =>
        ({ commands, state }: any) => {
          const { from, to } = state.selection;
          const currentText = state.doc.textBetween(from, to, '');

          if (currentText) {
            commands.insertContentAt({ from, to }, currentText.toUpperCase());
            commands.setTextSelection({ from, to });
          }

          return commands.setMark('uppercase');
        },
      toggleLowercase:
        () =>
        ({ commands, state }: any) => {
          const { from, to } = state.selection;
          const currentText = state.doc.textBetween(from, to, '');

          if (currentText) {
            commands.insertContentAt({ from, to }, currentText.toLowerCase());
            commands.setTextSelection({ from, to });
          }

          return commands.unsetMark('uppercase');
        },
    };
  },
});
