import type { FC } from 'react';
import Avatar from '@components/Avatar';
import Form from '@components/Form';
import Icon from '@components/Icon';
import InputTextarea from '@components/InputTextarea';
import { getUserDisplayName } from '@ContractBuilder/modules/comments/utils';
import { getInitials } from '@helpers/getInitials';
import type { GetFieldPropsFn } from '@hooks/form';
import type { BaseUser } from '@root/@types/types';
import clsx from 'clsx';

import type { CommentsFormState } from '../types';

interface AddNewCommentFormProps {
  canSubmit: boolean;
  getFieldProps: GetFieldPropsFn<CommentsFormState>;
  isSubmitting: boolean;
  onSubmit: () => Promise<void>;
  user: BaseUser;
}

export const AddNewCommentForm: FC<AddNewCommentFormProps> = ({
  canSubmit,
  getFieldProps,
  isSubmitting,
  onSubmit,
  user,
}) => {
  return (
    <div className="flex w-full px-2.5 py-4">
      <div className="flex w-full flex-col">
        <div className="flex items-center gap-2.5 pb-2">
          <Avatar initials={getInitials(user)} />
          {getUserDisplayName(user)}
        </div>
        <Form className="relative" onSubmit={onSubmit}>
          <InputTextarea
            {...getFieldProps('comment')}
            autoFocus
            isDisabled={isSubmitting}
            name="comment"
            placeholder="Add a comment..."
          />
          <button className="absolute bottom-3 right-3 transition-colors" disabled={isSubmitting} type="submit">
            <Icon
              className={clsx(
                'flex h-6 w-6 items-center justify-center rounded-full p-1 text-white',
                canSubmit ? 'cursor-pointer bg-primary-600' : 'pointer-events-none cursor-not-allowed bg-primary-400',
              )}
              name="paper-airplane"
            />
          </button>
        </Form>
      </div>
    </div>
  );
};
