import type { FC } from 'react';
import { useState } from 'react';
import type { pdfjs } from 'react-pdf';
import type { PanelPlacement } from '@ContractBuilder/hooks';
import { useScrollToFocussedBlock } from '@ContractBuilder/modules/live-pdf-preview/utils/useScrollToFocussedBlock';

import { LivePDFPreviewDocument } from '../views/LivePDFPreviewDocument';

interface LivePDFPreviewControllerProps {
  documentUrl?: string;
  documentVersion?: number;
  expectedVersion?: number;
  focussedBlockId?: string;
  onDownload: () => Promise<void>;
  pageWidth: number;
  placement: PanelPlacement;
}

export const LivePDFPreviewController: FC<LivePDFPreviewControllerProps> = ({
  documentUrl,
  documentVersion,
  expectedVersion,
  focussedBlockId,
  onDownload,
  pageWidth,
  placement,
}) => {
  const [pages, setPages] = useState<number[]>([]);
  const isUpdating = Boolean(documentVersion && expectedVersion && documentVersion < expectedVersion);

  useScrollToFocussedBlock(focussedBlockId, pages);

  const onDocumentLoadSuccess = (newPdf: pdfjs.PDFDocumentProxy) => {
    const { numPages } = newPdf;
    const newPages = new Array(numPages).fill(0).map((_, idx) => idx + 1);

    setPages(newPages);
  };

  return (
    <LivePDFPreviewDocument
      documentVersion={documentVersion}
      expectedVersion={expectedVersion}
      fileUrl={documentUrl}
      isUpdating={isUpdating}
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      onDownload={onDownload}
      pages={pages}
      pageWidth={pageWidth}
      placement={placement}
    />
  );
};
