import type { FC } from 'react';
import { Show } from '@components/Show';
import type { FormError } from '@root/@types/types';

interface ErrorsListProps {
  sectionId?: string;
  errors?: FormError[];
}

export const ErrorsList: FC<ErrorsListProps> = ({ errors, sectionId }) => (
  <ul className="ml-3 !list-disc">
    <Show when={!!errors?.length}>
      {errors?.map((e, idx) => <li key={`${sectionId}: ${e.message} - ${idx}`}>{e.message}</li>)}
    </Show>
  </ul>
);
