import type { DataType } from '@root/helpers/schema';
import {
  isContentRepeaterGroup,
  isSectionRepeater,
  ROOT_SECTIONS_KEY,
  SPLIT_BY_SECTIONS_KEY,
} from '@root/helpers/schema';
import { get } from 'lodash-es';

export const getShouldHideTitle = (dataType: DataType, formValues: Record<string, any>, id: string) => {
  if (isSectionRepeater(dataType)) {
    const splitBySections = get(formValues, SPLIT_BY_SECTIONS_KEY, []);
    const sectionsLength = get(formValues, `${ROOT_SECTIONS_KEY}.length`, 0);

    return sectionsLength <= 1 || (isSectionRepeater(dataType) && sectionsLength > 1 && !splitBySections.includes(id));
  }

  if (isContentRepeaterGroup(dataType)) {
    const contentGroupLength = get(formValues, `${id}.length`, 0);
    return contentGroupLength <= 1;
  }

  return false;
};
