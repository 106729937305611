import React from 'react';
import ItemTeams from '@pages/User/components/ItemTeams';
import { tenantConfig } from '@root/config/tenants';
import { isNonNullish } from '@root/helpers';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';

export const teamsAccessor = <T extends { teams?: number[] }>(columnHelper: ColumnHelper<T>, header = 'Teams') =>
  columnHelper.accessor(
    (entity) =>
      (entity.teams ?? [])
        .map((teamId) => {
          return tenantConfig.teams?.find((team) => team.id === teamId)?.name;
        })
        .join('|'),
    {
      id: 'teams',
      header,
      cell: (cellContext: CellContext<T, string>) => (
        <ItemTeams
          teams={
            cellContext
              .getValue()
              ?.split('|')
              ?.map((teamName) => tenantConfig.teams?.find((team) => team.name === teamName)?.id)
              ?.filter(isNonNullish) ?? []
          }
        />
      ),
      enableSorting: false,
      filterFn: (row, _, filteredTeamLabels) => {
        if (!row.original.teams) {
          return false;
        }
        return row.original.teams
          ?.map((teamId) => tenantConfig.teams?.find((team) => team.id === teamId)?.name)
          ?.filter(isNonNullish)
          ?.some((teamName) => filteredTeamLabels?.includes(teamName));
      },
      meta: {
        headingLinkClassName: 'pl-0',
        isStandaloneDataCell: true,
        filter: {
          placeholder: 'All teams',
          options: (tenantConfig.teams ?? []).map((i) => i.name),
        },
      },
    },
  );
