import type { KeyboardEvent } from 'react';
import { get } from 'lodash-es';

/** Gets new input value from `onKeyDown` event based on currently typed value and cursor position */
export const getNewInputValueFromEvent = (event: KeyboardEvent<HTMLInputElement>): string => {
  const newValue = get(event, 'target.value', '');
  const valueArray = newValue.split('');

  const selectionStart = get(event, 'target.selectionStart', 0);
  const selectionEnd = get(event, 'target.selectionEnd', 0);

  const selectionLength = selectionEnd - selectionStart;

  if (event.key === 'Backspace') {
    if (selectionLength === 0 && selectionStart > 0) {
      valueArray.splice(selectionStart - 1, selectionLength + 1);
    } else {
      valueArray.splice(selectionStart, selectionLength);
    }
  } else if (event.key === 'Delete') {
    if (selectionLength === 0) {
      valueArray.splice(selectionStart, selectionLength + 1);
    } else {
      valueArray.splice(selectionStart, selectionLength);
    }
  } else {
    valueArray.splice(selectionStart, selectionLength, event.key);
  }

  return valueArray.join('');
};
