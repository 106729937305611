import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import clsx from 'clsx';

export const BlocksLibraryLoading = () => {
  return (
    <div className={clsx('flex w-full animate-pulse flex-col gap-y-2', DEFAULT_CREATOR_PADDING)}>
      <div className="h-[38px] w-full animate-pulse bg-info-200" />

      <div className="relative flex h-fit max-h-[75px] w-full cursor-default justify-between border-b py-4">
        <div className="flex flex-col justify-evenly gap-4">
          <div className="flex gap-4">
            <div className="h-5 w-56 animate-pulse rounded-full bg-info-200" />
            <div className="h-5 w-28 animate-pulse rounded-full bg-info-200" />
          </div>
          <div className="flex gap-4">
            <div className="h-3 w-36 animate-pulse rounded-full bg-info-200" />
            <div className="h-3 w-24 animate-pulse rounded-full bg-info-200" />
            <div className="h-3 w-24 animate-pulse rounded-full bg-info-200" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="h-8 w-[68px] animate-pulse rounded-md bg-info-200" />
          <div className="h-8 w-[54px] animate-pulse rounded-md bg-info-200" />
        </div>
      </div>

      <div className="relative flex h-fit max-h-[75px] w-full cursor-default justify-between border-b py-4">
        <div className="flex flex-col justify-evenly gap-4">
          <div className="flex gap-4">
            <div className="h-5 w-48 animate-pulse rounded-full bg-info-200" />
            <div className="h-5 w-16 animate-pulse rounded-full bg-info-200" />
          </div>
          <div className="flex gap-4">
            <div className="h-3 w-36 animate-pulse rounded-full bg-info-200" />
            <div className="h-3 w-36 animate-pulse rounded-full bg-info-200" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="h-8 w-[68px] animate-pulse rounded-md bg-info-200" />
          <div className="h-8 w-[54px] animate-pulse rounded-md bg-info-200" />
        </div>
      </div>

      <div className="relative flex h-fit max-h-[75px] w-full cursor-default justify-between border-b py-4">
        <div className="flex flex-col justify-evenly gap-4">
          <div className="flex gap-4">
            <div className="h-5 w-64 animate-pulse rounded-full bg-info-200" />
            <div className="h-5 w-32 animate-pulse rounded-full bg-info-200" />
          </div>
          <div className="flex gap-4">
            <div className="h-3 w-16 animate-pulse rounded-full bg-info-200" />
            <div className="h-3 w-32 animate-pulse rounded-full bg-info-200" />
            <div className="h-3 w-24 animate-pulse rounded-full bg-info-200" />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="h-8 w-[68px] animate-pulse rounded-md bg-info-200" />
          <div className="h-8 w-[54px] animate-pulse rounded-md bg-info-200" />
        </div>
      </div>
    </div>
  );
};
