import type React from 'react';
import { useEffect, useState } from 'react';

export const useStickyState = <TData = unknown>(
  defaultValue: TData,
  key: string,
): readonly [TData, React.Dispatch<TData>] => {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
