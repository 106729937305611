import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Team } from '@root/@types/types';
import { isTemplatePath } from '@utils/app-paths';

import type { CreateEntityFormState, Step } from '../types';
import { DataType } from '../types';
import { getValidationSchema } from '../utils/getValidationSchema';

export const useCreateEntityForm = (userTeams: Team[], currentStep: Step) => {
  const validationSchema = useMemo(() => getValidationSchema(currentStep), [currentStep]);
  const isTemplate = isTemplatePath();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
    trigger,
    watch,
  } = useForm<CreateEntityFormState>({
    defaultValues: {
      dataType: DataType.Blank,
      team: isTemplate ? [] : [userTeams[0]?.id],
    },
    context: { currentStep },
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isDirty) {
      trigger();
    }
    // eslint-disable-next-line -- We want to re-trigger the validation  resolver when navigating between steps to avoid stale cached data
  }, [currentStep]);

  const formValues = watch();

  return {
    control,
    formValues,
    handleSubmit,
    isValid,
    setValue,
  };
};
