import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import { currencyFormatter } from '../../helpers/formatters';
import type { InputSelectProps } from '../InputSelect';
import InputSelect from '../InputSelect';

interface InputSelectCurrencyProps extends InputSelectProps {
  item: { enum: string[] };
  ownKey: string;
}

const InputSelectCurrency: React.FC<InputSelectCurrencyProps> = ({ item, ...props }) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);

  const { value, isIncomplete } = useDatapointsStore(selector);

  return (
    <InputSelect
      {...props}
      isIncomplete={isIncomplete}
      isClearable={false}
      options={item.enum?.map((value) => ({ name: value, value }))}
      formatter={currencyFormatter}
      value={value}
    />
  );
};

export default InputSelectCurrency;
