import type { UIComponentBase } from '@root/@types/types';
import clsx from 'clsx';

export const SideColumnInnerContainer = ({
  children,
  className,
  noPadding,
}: UIComponentBase & { noPadding?: boolean }) => <div className={clsx(!noPadding && 'p-2', className)}>{children}</div>;

export const ModalSideColumn = ({ children, className }: UIComponentBase) => (
  <div className={clsx('z-[1]', className)}>{children}</div>
);

export const ModalCenterColumn = ({ children, className }: UIComponentBase) => (
  <div className={clsx('relative', className)}>{children}</div>
);
