import type { FC, PropsWithChildren, ReactNode } from 'react';
import Avatar from '@components/Avatar';
import type { User } from '@root/@types/types';
import dayjs from 'dayjs';

import { getUserInitialsFromFullName } from '../utils/get-user-initials';

interface BlockItemProps {
  createdAt: string;
  icon?: ReactNode;
  user?: User;
}

export const BlockItem: FC<PropsWithChildren<BlockItemProps>> = ({ children, createdAt, icon, user }) => {
  const userName = user?.name ?? 'System';

  return (
    <div className="grid gap-x-2.5" style={{ gridTemplateColumns: 'auto 1fr' }}>
      <Avatar initials={getUserInitialsFromFullName(userName)} />
      <div className="flex min-w-0 items-center">
        <p className="overflow-hidden text-ellipsis whitespace-nowrap pr-2.5 text-sm font-medium leading-5">
          {userName}
        </p>
        <p className="shrink overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-5 text-info-400">
          {dayjs(createdAt).fromNow()}
        </p>
        {icon && <div className="pl-1">{icon}</div>}
      </div>
      <div className="pl-10.5 col-start-2 truncate whitespace-pre-wrap text-xs font-normal leading-5 text-info-800">
        {children}
      </div>
    </div>
  );
};
