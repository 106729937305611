import { cva } from 'class-variance-authority';

export const formContainerClasses = ['flex', 'flex-col', 'items-center justify-center', 'gap-6', 'px-8 pb-6 pt-0'];
export const buttonsContainerClasses = ['grid w-full grid-cols-2 justify-between gap-3'];

export const stepperItemClasses = cva(
  ['flex items-center justify-center stepper-item relative h-8 w-8 rounded-full border-2 border-gray-300'],
  {
    variants: {
      isActive: {
        true: 'text-primary-600 border-primary-600',
        false: '',
      },
      isCompleted: {
        true: 'bg-primary-600 text-primary-600 border-primary-600',
        false: '',
      },
    },
    defaultVariants: {
      isActive: false,
      isCompleted: false,
    },
  },
);
