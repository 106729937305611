import type { FC } from 'react';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiHelpCircleOutline } from '@mdi/js';
import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

interface H2Props extends UIComponentBase {
  helperText?: string;
}

const H2: FC<H2Props> = ({ className, children, helperText }) => {
  return (
    <h2 className={clsx('flex justify-start gap-2 text-2xl font-semibold leading-9', className)}>
      {children}
      {helperText && <TooltipedIcon path={mdiHelpCircleOutline} placement="top" content={helperText} />}
    </h2>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default H2;
