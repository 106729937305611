// @ts-expect-error
import { mergeAttributes } from '@tiptap/core';
import MentionExtension from '@tiptap/extension-mention';

import { useEntityStore } from '../../../../ContractBuilder/store';

import { formatFormValue, renderTransformedMention } from './format';

export const Mention = () => {
  return MentionExtension.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        'data-hidden': {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('data-hidden') === 'true'),
        },
        'data-source': {
          default: null,
          parseHTML: (element: any) => element.getAttribute('data-source'),
        },
      };
    },
    renderHTML({ HTMLAttributes, node }: any) {
      const { id } = node.attrs;
      if (!id) return ['span'];

      const datapoints = useEntityStore.getState().submission?.data_items;
      const sectionId = useEntityStore.getState().modal?.block?.section_id;
      const lastSegment = id.split('.').pop();

      const value = renderTransformedMention(datapoints, sectionId, id);
      const formattedValue = value
        ? formatFormValue(id.split('.')[1], value)
        : this.options.renderLabel({
            options: this.options,
            node: { ...node, attrs: { ...node.attrs, id: lastSegment } },
          });

      if (!formattedValue) {
        return ['span'];
      }

      const result = [
        'span',
        mergeAttributes(
          {
            'data-type': 'mention',
            title: id,
          },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
        formattedValue,
      ];

      if (value) {
        result[1].class = 'mention-cleanup mention';
      }

      return result;
    },
  });
};
