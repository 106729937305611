import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import './styles/index.css';
import './styles/components.css';
import './styles/fonts.css';
import './styles/contract/index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
