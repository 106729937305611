import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { mdiCheck } from '@mdi/js';
import Tooltip from '@root/src/components/Tooltip';

import { controlClasses } from '../classes';
import type { InlineOptionProps } from '../types';

import { TooltipIcon } from './TooltipIcon';

export const InlineOption: FC<InlineOptionProps> = ({
  format,
  isActive,
  icon,
  title,
  displayLabel,
  iconSize,
  isDisabled,
  enableCheckmark = false,
}) => (
  <Tooltip content={title}>
    <button
      onClick={format}
      className={controlClasses({
        isActive,
        isDisabled,
        className: ['text-info-700', 'flex', 'content-center', 'gap-1', 'text-left'],
      })}
      type="button"
    >
      <Show when={enableCheckmark}>
        <div className="w-5">{isActive && <IconMdi path={mdiCheck} />}</div>
      </Show>
      <Show when={!!icon}>
        {typeof icon === 'string' ? <TooltipIcon icon={icon} className={'m-0'} size={iconSize} /> : icon}
      </Show>
      <Show when={displayLabel}>
        <span className="text-xs">{title}</span>
      </Show>
    </button>
  </Tooltip>
);
