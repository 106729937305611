import type { FC } from 'react';
import { memo } from 'react';
import Tooltip from '@components/Tooltip';
import { useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import type { LinkedDatapoint } from '@root/@types/types';
import { isNonNullish } from '@root/helpers';
import clsx from 'clsx';
import { get, isEqual } from 'lodash-es';

interface LinkedDatapointsStatusProps {
  data?: LinkedDatapoint[];
  missingDatapoints: string[];
}

export const LinkedDatapointsStatusInner: FC<LinkedDatapointsStatusProps> = ({ data, missingDatapoints }) => {
  const formValues = useDatapointsStore(({ formValues }) => formValues);
  const isEmpty = !data || data.length === 0;
  const { findField } = useSchemaStore(({ findField }) => ({ findField }));

  if (isEmpty) {
    return null;
  }

  return (
    <div className="flex max-h-[24vh] flex-col">
      <div className="flex flex-col divide-y overflow-y-auto rounded-lg border border-t border-info-200 shadow">
        {data.map((datapoint) => {
          const { id } = datapoint;
          const title = findField(id)?.title || id;

          const value = get(formValues, id, undefined);
          const isValuePresent = isNonNullish(value);
          const isMissing = missingDatapoints.includes(id);
          const IconComponent = isValuePresent ? CheckCircleIcon : InformationCircleIcon;

          return (
            <div className="flex cursor-pointer items-center gap-x-3 bg-none px-3 py-2 text-sm" key={id}>
              {isMissing ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#F87171" className="mx-0.5 h-6 w-6">
                  <Tooltip content="Missing from block">
                    <path
                      fillRule="evenodd"
                      d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clipRule="evenodd"
                    />
                  </Tooltip>
                </svg>
              ) : (
                <Tooltip content={isMissing ? 'Missing from block' : !isValuePresent && 'Incomplete datapoint'}>
                  <IconComponent
                    className={clsx('h-8 w-8 text-white', isValuePresent ? 'fill-success-400' : 'fill-warning-400')}
                  />
                </Tooltip>
              )}
              <Tooltip content={title}>
                <span
                  className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-5 text-info-900"
                  title={title}
                >
                  {title}
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LinkedDatapointsStatus = memo(LinkedDatapointsStatusInner, isEqual);
