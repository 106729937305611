import type { FC } from 'react';

import type { CustomOptionProps, InlineOptionProps, Option } from '../types';

import { InlineOption } from './InlineOption';

interface OptionMapperProps {
  option: Option;
  isDisabled?: boolean;
}

export const OptionMapper: FC<OptionMapperProps> = ({ option, isDisabled }) => {
  if ((option as any).custom) {
    return (option as CustomOptionProps).custom();
  }

  return <InlineOption {...({ ...option, isDisabled } as InlineOptionProps)} />;
};
