import type { Images } from '@domain/schemas/images.schema';
import { ApiPatchOperation } from '@root/@types/types';
import { pick } from 'lodash-es';

export type ImagesPatchPayload = Partial<Images>;

export type ImagesPatchOperations = ApiPatchOperation.UpdateStatus | ApiPatchOperation.SoftDelete;

const IMAGES_PATCH_SCENARIOS_KEYS: Record<ImagesPatchOperations, (keyof Images)[]> = {
  [ApiPatchOperation.UpdateStatus]: ['status'],
  [ApiPatchOperation.SoftDelete]: [],
};

export const prepareImagesPayload = (data: ImagesPatchPayload, operation: ImagesPatchOperations): ImagesPatchPayload =>
  pick(data, [...IMAGES_PATCH_SCENARIOS_KEYS[operation]]);
