import type { FC } from 'react';
import Form from '@components/Form';
import { WysiwygEditor } from '@ContractBuilder/components/Wysiwyg';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFieldProps } from '@ContractBuilder/modules/block-edit/hooks/use-block-edit-field-props';
import { INLINE_BLOCK_EDITOR_NAME } from '@ContractBuilder/modules/editors-map';
import type { ResourceBlock } from '@root/@types/base';
import { getCurrentContentId } from '@WysiwygEditor/components/Editor/extensions/helpers';
import { get } from 'lodash-es';

type BlockState = Pick<
  ResourceBlock,
  'content' | 'name' | 'section_id' | 'title' | 'type' | 'shouldInsertPageBreak' | 'variations' | 'visibility'
> & {
  id?: string;
  order?: number;
};

interface InlineBlockEditProps {
  block?: BlockState;
  className?: string;
  onSubmit: () => Promise<void>;
  currentVariationIdx: number;
  hasVariations: boolean;
}

export const InlineBlockEdit: FC<InlineBlockEditProps> = ({
  className,
  currentVariationIdx,
  hasVariations,
  onSubmit,
}) => {
  const { formValues } = useBlockEditFormStore(({ formValues }) => ({
    formValues,
  }));

  const { name: contentFieldAccessor, onChange: contentFieldChangeHandler } = useBlockEditFieldProps<BlockState>(
    hasVariations,
    currentVariationIdx,
    'content',
  );
  const { name: titleFieldAccessor, onChange: titleFieldChangeHandler } = useBlockEditFieldProps<BlockState>(
    hasVariations,
    currentVariationIdx,
    'title',
  );

  return (
    <Form className="flex w-full flex-col bg-white pb-3" onSubmit={onSubmit}>
      <WysiwygEditor
        blockType={formValues?.type}
        className={className}
        content={get(formValues, contentFieldAccessor, '') as string}
        currentContentId={getCurrentContentId(formValues, currentVariationIdx)}
        handleTitleChange={titleFieldChangeHandler}
        name={INLINE_BLOCK_EDITOR_NAME}
        onUpdate={contentFieldChangeHandler}
        shouldShowControls={false}
        shouldShowHelperText={false}
        title={get(formValues, titleFieldAccessor, '') as string}
        titleFieldName={titleFieldAccessor}
      />
    </Form>
  );
};
