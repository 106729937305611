import { useState } from 'react';

export type OnSaveFn<T = string> = (field: string, value: T) => Promise<void>;

interface UseEditableFieldArgs<T = string> {
  defaultValue: T;
  name: string;
  onSave: OnSaveFn<T>;
}

interface EditableFieldState<T = string> {
  value: T;
  isEditing: boolean;
  isUpdating: boolean;
}

export const useEditableField = <T = string>({ defaultValue, onSave, name }: UseEditableFieldArgs<T>) => {
  const [state, setState] = useState<EditableFieldState<T>>({
    value: defaultValue,
    isEditing: false,
    isUpdating: false,
  });

  const handleUpdate = async () => {
    setState((prev) => ({ ...prev, isUpdating: true }));
    try {
      await onSave(name, state.value);
    } finally {
      setState((prev) => ({ ...prev, isUpdating: false, isEditing: false }));
    }
  };

  const handleClick = () => {
    setState((prev) => ({ ...prev, isEditing: true }));
  };

  const handleCancel = () => {
    setState((prev) => ({ ...prev, isEditing: false, value: defaultValue }));
  };

  const handleChange = (value: T) => {
    setState((prev) => ({ ...prev, value }));
  };

  return {
    onCancel: handleCancel,
    onChange: handleChange,
    onClick: handleClick,
    onUpdate: handleUpdate,
    state,
  };
};
