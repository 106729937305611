import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useDataQuery } from '@pages/User/hooks';
import type { TemplateShort, UIInputValue, WithRequired } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import { fetchTemplates } from '@src/queries';

import { SearchableInputSelect } from '../../components/SearchableInputSelect';
import type { StepProps } from '../../types';

interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

const filterPublishedTemplates = (item: TemplateShort): item is PublishedTemplate => {
  return !isEmptyValue(item.name) && item.status === 'PUBLISHED';
};

export const Step2: FC<StepProps> = ({ control, formValues, setValue }) => {
  const { data, isFetching } = useDataQuery<TemplateShort>('templates', [], fetchTemplates);
  const parsedOptions = data.filter(filterPublishedTemplates).map((item) => ({ name: item.name, value: item.id }));

  const handleCopyFromTemplateIdChange = (templateId: UIInputValue<string>) => {
    const template = data.find((item) => item.id === templateId);

    if (template?.branding) {
      setValue('branding', template.branding);
    }
  };

  return (
    <Controller
      control={control}
      render={({ field: { ref: _ref, ...props }, fieldState: { error } }) => (
        <SearchableInputSelect
          {...props}
          errors={error?.message}
          isLoading={isFetching}
          labelText="Select base template"
          placeholder="Search templates"
          onChange={(value) => {
            handleCopyFromTemplateIdChange(value);
            return props.onChange(value);
          }}
          options={parsedOptions}
          // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
          value={formValues?.copy_from_template}
        />
      )}
      name="copy_from_template"
    />
  );
};
