import produce from 'immer';

import type { MainStore, ModalsStoreSlice, SliceType, ToolState } from './types';

const toolClosedState: ToolState = { open: false, mode: undefined, block: undefined };

export const createModalsSlice: SliceType<ModalsStoreSlice> = (set) => ({
  settings: { open: false },
  modal: { open: false },
  sidebar: {
    activeTabKey: 'Comments',
    setActiveTabKey: (tabKey) => {
      set(
        produce((state: MainStore) => {
          state.sidebar.activeTabKey = tabKey;
        }),
      );
    },
  },
  openModal: (block, section_id, mode) => {
    switch (mode) {
      case 'create':
      case 'edit':
        if (block) {
          set(
            produce((state: MainStore) => {
              state.modal = {
                open: true,
                mode,
                block: { ...block, section_id },
              };
            }),
          );
        }
        break;
      default:
        break;
    }
  },
  changeModalMode: (mode) => {
    set(
      produce((state: MainStore) => {
        state.modal.mode = mode;
      }),
    );
  },
  closeModal: () => {
    set(
      produce((state: MainStore) => {
        state.modal.open = false;
      }),
    );
    // Account for modal exit animation
    setTimeout(() => {
      set(
        produce((state: MainStore) => {
          state.modal = toolClosedState;
        }),
      );
    }, 300);
  },
  setModalLoading: () =>
    set(
      produce((state: MainStore) => {
        state.modal.loading = true;
      }),
    ),
});
