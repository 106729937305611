import { useState } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useEntityStore } from '@ContractBuilder/store';
import { getAttachmentDocumentPresignedUrl } from '@queries/getAttachmentDocumentPresignedUrl';
import { isEndorsementPath, isSubmissionPath, isTemplatePath } from '@root/src/utils/app-paths';
import { uploadAttachmentDocument } from '@src/mutations';

import { AttachmentUpload } from '../views/AttachmentUpload';

interface UploadState {
  error?: string;
  isFileDropped: boolean;
  isUploading: boolean;
}

export const AttachmentUploadController = ({ handleClose }: { handleClose: () => void }) => {
  const [attachmentName, setAttachmentName] = useState<string | undefined>(undefined);
  const [attachment, setAttachment] = useState<File | undefined>(undefined);

  const { submission, recreatePdfWithAttachment, updateAttachmentStatus } = useEntityStore(
    ({ submission, recreatePdfWithAttachment, updateAttachmentStatus }) => ({
      submission,
      recreatePdfWithAttachment,
      updateAttachmentStatus,
    }),
  );

  const templateId = isTemplatePath() ? submission?.id : undefined;
  const submissionId = isEndorsementPath() ? submission?.parentId : isSubmissionPath() ? submission?.id : undefined;
  const endorsementId = isEndorsementPath() ? submission?.id : undefined;

  const [uploadState, setUploadState] = useState<UploadState>({
    error: undefined,
    isFileDropped: false,
    isUploading: false,
  });

  const handleFileRejected = (fileRejections: FileRejection[], _event: DropEvent) => {
    const latestFileError = fileRejections?.pop()?.errors?.pop();
    const code = latestFileError?.code;
    let message = latestFileError?.message;

    if (code === 'file-too-large') {
      message = 'File is larger than 10MB';
    }
    if (code === 'file-invalid-type') {
      message = 'File type must be pdf';
    }
    if (code === 'too-many-files') {
      message = 'Too many files';
    }

    setUploadState((current) => ({ ...current, error: message }));
  };

  const handleFileUpload = async (files: File[]) => {
    if (files.length < 1) {
      return;
    }

    setAttachment(files[0]);

    setUploadState((current) => ({ ...current, isFileDropped: true }));
  };

  const handleClickUpload = async () => {
    if (attachment) {
      setUploadState((current) => ({ ...current, isUploading: true }));

      const response = await getAttachmentDocumentPresignedUrl(
        attachment.name,
        attachmentName ?? '',
        attachment.size,
        templateId,
        submissionId,
        endorsementId,
      );

      if (response?.status !== 201) {
        return setUploadState((current) => ({ ...current, error: 'Failed to upload', isUploading: false }));
      }

      await uploadAttachmentDocument(response.data.data.url, { file: attachment });

      await updateAttachmentStatus(response.data.data.id, 'ready');
      await recreatePdfWithAttachment();

      handleClose();
    }
  };

  return (
    <div className="relative h-full">
      <div className="relative flex flex-col justify-center rounded-lg text-center">
        <AttachmentUpload
          error={uploadState.error}
          isUploadDisabled={!attachment || !attachmentName || uploadState.isUploading}
          attachmentName={attachmentName}
          attachmentFileName={attachment?.name || 'document.pdf'}
          isFileDropped={uploadState.isFileDropped}
          isUploading={uploadState.isUploading}
          onFileUpload={handleFileUpload}
          onFileRejected={handleFileRejected}
          setAttachmentName={setAttachmentName}
          handleClickUpload={handleClickUpload}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};
