import { cva } from 'class-variance-authority';

export const selectTriggerSizes = {
  sm: ['min-h-8', 'px-1', 'py-0.5'],
  md: ['min-h-11', 'px-3', 'py-2.5'],
  lg: ['min-h-12', 'px-3', 'py-2.5'],
};

export const selectTriggerSize = cva([], {
  variants: {
    size: selectTriggerSizes,
  },
  defaultVariants: {
    size: 'md',
  },
});
