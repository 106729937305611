import React from 'react';
import clsx from 'clsx';

interface ToggleProps {
  value?: boolean;
  className?: string;
  isDisabled?: boolean;
  labelText: string;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  'data-testid'?: string;
}

const Toggle: React.FC<ToggleProps> = ({ isDisabled, value, onClick, className, labelText, ...props }) => (
  <div
    className={clsx('flex cursor-pointer items-center', className)}
    onClick={isDisabled ? undefined : onClick}
    {...props}
  >
    <div
      className={clsx(
        'relative h-4 w-8 shrink-0 rounded-full transition duration-200',
        value ? 'bg-primary-600' : 'bg-info-200',
      )}
    >
      <div
        className={clsx(
          '-mt-0.5 h-5 w-5 rounded-full border border-info-400 bg-white shadow-sm transition duration-200',
          !value && '-translate-x-1.5',
          value && 'translate-x-4',
        )}
      />
    </div>

    {labelText && (
      <p className={clsx('truncate pl-4')} title={labelText}>
        {labelText}
      </p>
    )}
  </div>
);

export default Toggle;
