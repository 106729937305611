import { useCallback } from 'react';
import type { LeafPaths, NodesPaths } from '@root/@types/types';
import { get } from 'lodash-es';

import language from './language';

type LanguageSource = LeafPaths<typeof language>;
type LanguagePrefix = NodesPaths<typeof language>;

interface UseLanguageOptions {
  prefix: LanguagePrefix;
}

function useLanguage(): {
  getContent: (key: LanguageSource) => string;
};

// eslint-disable-next-line
function useLanguage({ prefix }: UseLanguageOptions): {
  getContent: (key: LeafPaths<typeof prefix>) => string;
};

// eslint-disable-next-line
function useLanguage(options?: UseLanguageOptions): {
  getContent: (key: string) => string;
} {
  const { prefix } = options ?? {};

  const getContent = useCallback(
    (key: string) => {
      const path = prefix ? `${prefix}.${key}` : key;
      return get(language, path) || key;
    },
    [prefix],
  );

  return {
    getContent,
  };
}

export { useLanguage };
