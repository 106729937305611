import type { FC } from 'react';
import { useRef, useState } from 'react';
import type { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { PanelPlacement } from '@ContractBuilder/hooks';
import { LoadingIndicator, pdfDocumentClasses } from '@ContractBuilder/modules/pdf-preview';
import { mdiDownload } from '@mdi/js';
import clsx from 'clsx';

import { LivePreviewUpdating } from '../components/LivePreviewUpdating';
import { ScrollRemember } from '../components/ScrollRemember';

import { previewFooterClasses } from './classes';

interface LivePDFPreviewDocumentProps {
  documentVersion?: number;
  expectedVersion?: number;
  fileUrl?: string;
  isUpdating: boolean;
  onDocumentLoadSuccess: (pdf: pdfjs.PDFDocumentProxy) => void;
  onDownload: () => Promise<void>;
  pageWidth: number;
  pages: number[];
  placement: PanelPlacement;
}

export const LivePDFPreviewDocument: FC<LivePDFPreviewDocumentProps> = ({
  documentVersion,
  expectedVersion,
  fileUrl,
  isUpdating,
  onDocumentLoadSuccess,
  onDownload,
  pageWidth,
  pages,
  placement,
}) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const isLeftSidePlacement = placement === PanelPlacement.Left;
  const isRightSidePlacement = placement === PanelPlacement.Right;

  const isButtonLoading = isUpdating || isDownloading;

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      await onDownload();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <LivePreviewUpdating isUpdating={isUpdating} />
      <div
        className="h-full overflow-y-hidden"
        data-cypress="pdf-modal"
        data-cypress-pdf-document-version={documentVersion}
        data-cypress-pdf-expected-version={expectedVersion}
      >
        <Document
          className={clsx(pdfDocumentClasses)}
          error={<div className="flex h-40 w-full items-center justify-center">Failed to load PDF</div>}
          file={fileUrl}
          loading={
            <div
              className="absolute inset-0 flex h-full w-full items-center justify-center bg-white"
              data-testid="loading-overlay"
            >
              <LoadingIndicator />
            </div>
          }
          noData={
            <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white">
              <LoadingIndicator />
            </div>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          inputRef={containerRef}
        >
          {pages.map((page) => (
            <Page key={`live-pdf-preview-pdf-page-${page}`} pageNumber={page} width={pageWidth} loading="" />
          ))}
          <ScrollRemember
            container={containerRef.current}
            onUpdate={setScrollPosition}
            scrollPosition={scrollPosition}
          />
        </Document>
      </div>
      <div className={previewFooterClasses({ isLeftSidePlacement, isRightSidePlacement })}>
        <Button
          className="h-10 w-[126px] whitespace-nowrap"
          data-cypress="download-pdf-button"
          kind="primary"
          loading={isButtonLoading}
          onClick={handleDownload}
        >
          <Show when={!isButtonLoading}>
            <IconMdi path={mdiDownload} />
            Download
          </Show>
        </Button>
      </div>
    </>
  );
};
