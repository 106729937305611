import sharedClasses from '@utils/shared-classes';
import { cva } from 'class-variance-authority';

export const blockActionsClasses = cva([
  ...sharedClasses.microInteractions,
  'top-0',
  'left-0',
  'fixed',
  'bg-transparent',
  'group-hover:bg-white',
  'group-hover:shadow-lg',
  'group-hover:sticky',
  'hover:bg-white',
  'hover:shadow-lg',
  'hover:sticky',
  'p-2',
  'group',
  'w-fit',
  'h-fit',
  'pointer-events-none',
  'rounded-full',
  'shadow-none',
  'transform',
  'translate-x-1',
]);

export const iconClasses = cva(
  [
    'block',
    'group-hover:visible',
    'group-hover:scale-100',
    'group-hover:pointer-events-auto',
    ...sharedClasses.microInteractions,
  ],
  {
    variants: {
      comments: {
        true: ['pointer-events-auto'],
        false: ['scale-0'],
      },
      isDisabled: {
        true: ['!cursor-default hover:text-gray-600 opacity-50'],
        false: ['hover:text-primary-500', 'cursor-pointer'],
      },
    },
    defaultVariants: {
      comments: false,
      isDisabled: false,
    },
  },
);
