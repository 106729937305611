import type { TenantName } from '@root/@types/types';

export type BrandingArtificial = 'Artificial' | 'Artificial Brand 2';
export type BrandingBMS = 'BMS' | 'BMS Re' | 'BMS Re Asia' | 'BMS Re Australia' | 'BankServe' | 'H&D Marine';
export type BrandingLockton = 'Lockton' | 'Lockton Edge' | 'Lockton Omni' | 'Lockton SBS';
export type BrandingAJG =
  | 'Gallagher'
  | 'Gallagher European Risk Services'
  | 'Gallagher Retail'
  | 'Alesco'
  | 'Alesco Europe';

const client: TenantName = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;
if (!client) {
  throw new Error('Missing env var: CLIENT');
}

const getPdfBrandingAJG = () => {
  return [
    'Gallagher',
    'Gallagher European Risk Services',
    'Gallagher Retail',
    'Alesco',
    'Alesco Europe',
  ] satisfies BrandingAJG[];
};

const getPdfBrandingBMS = () => {
  return ['BMS', 'BMS Re', 'BMS Re Asia', 'BMS Re Australia', 'BankServe', 'H&D Marine'] satisfies BrandingBMS[];
};

const getPdfBrandingLockton = () => {
  return ['Lockton', 'Lockton Edge', 'Lockton Omni', 'Lockton SBS'] satisfies BrandingLockton[];
};

const getPdfBrandingArtificial = () => {
  return ['Artificial', 'Artificial Brand 2'] satisfies BrandingArtificial[];
};

export const getPdfBrandingList = (): string[] =>
  client === 'ct-ajg'
    ? getPdfBrandingAJG()
    : client === 'ct-bms'
    ? getPdfBrandingBMS()
    : client === 'ct-lockton'
    ? getPdfBrandingLockton()
    : getPdfBrandingArtificial();
/* eslint-enable */

const getDefaultPdfBrandingAJG = () => {
  return 'Gallagher' satisfies BrandingAJG;
};

const getDefaultPdfBrandingBMS = () => {
  return 'BMS' satisfies BrandingBMS;
};

const getDefaultPdfBrandingLockton = () => {
  return 'Lockton' satisfies BrandingLockton;
};

const getDefaultPdfBrandingArtificial = () => {
  return 'Artificial' satisfies BrandingArtificial;
};

export const getDefaultPdfBranding = (): string =>
  client === 'ct-ajg'
    ? getDefaultPdfBrandingAJG()
    : client === 'ct-bms'
    ? getDefaultPdfBrandingBMS()
    : client === 'ct-lockton'
    ? getDefaultPdfBrandingLockton()
    : getDefaultPdfBrandingArtificial();
/* eslint-enable */
