import type { Theme, TypeOptions } from 'react-toastify';
import IconMdi from '@components/IconMdi';
import clsx from 'clsx';

import { typeMappingIcon, typeMappingIconColor } from './settings';

interface IconReplacementProps {
  theme: Theme;
  type?: TypeOptions;
}

export const IconReplacement = ({ type }: IconReplacementProps) => {
  const icon = typeMappingIcon[type ?? 'default'];
  const color = typeMappingIconColor[type ?? 'default'];

  return icon ? <IconMdi path={icon} className={clsx('mt-1', color)} /> : null;
};
