import type { BlockVisibilityValueType } from '@root/@types/types';
import { isArray, isUndefined } from 'lodash-es';

export const quoted = (value: string | number) => `"${value}"`;

export const toDisplayValue = (value?: BlockVisibilityValueType) => {
  switch (true) {
    case isUndefined(value):
      return null;
    case isArray(value):
      return (value as string[]).map(quoted).join(', ');
    default:
      return JSON.stringify(value);
  }
};
