import { cva } from 'class-variance-authority';

export const previewFooterClasses = cva('flex pb-1 pt-2.5', {
  variants: {
    isRightSidePlacement: {
      true: ['justify-start'],
    },
    isLeftSidePlacement: {
      true: ['justify-end'],
    },
  },
});
