import { toast } from 'react-toastify';
import Nav from '@src/routes';
import type { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash-es';

const OMIT_ERROR_TOASTS = [
  {
    testUrl: (url: string) => url.split('/').includes('templates-spa-al'),
    method: 'get',
    status: 404,
  },
  {
    testUrl: (url: string) => url.split('/').includes('submissions-spa-al'),
    method: 'get',
    status: 404,
  },
];

const shouldSkipToast = (error: any): boolean => {
  return OMIT_ERROR_TOASTS.some((i) => {
    const urlMatches = i.testUrl(error.config.url);
    return urlMatches && i.method === error.config.method && i.status === error.response.status;
  });
};

const formatErrorMessage = (response?: AxiosResponse) =>
  get(response, 'data.message') || get(response, 'data.error.message') || 'Server Error';

export const AxiosErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  const responseStatusCode = error.response?.status ?? 200;

  if ([401, 403].includes(responseStatusCode)) {
    const artificialErrorCode = get(error, 'response.data.artificialErrorCode');

    if (artificialErrorCode) {
      window.history.pushState({ errorCode: artificialErrorCode }, '', Nav.Login);
    }

    window.location.href = Nav.Login;
    return Promise.reject(error);
  }

  if (responseStatusCode >= 400 && responseStatusCode <= 599) {
    if (!shouldSkipToast(error)) {
      const formattedMessage = formatErrorMessage(error.response);
      toast.error(formattedMessage, {
        // 3 min
        autoClose: 3 * 60 * 1000,
        pauseOnHover: true,
      });
    }
  }

  return Promise.reject(error);
};

export const AxiosResponseInterceptor = (response: AxiosResponse): AxiosResponse => response;
