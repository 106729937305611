import type { FC } from 'react';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Show } from '@components/Show';
import {
  CONTRACT_BUILDER_FOOTER_Z_INDEX,
  CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX,
  CONTRACT_VIEW_Z_INDEX,
} from '@constants/z-indices';
import { ContractBuilderFooter } from '@ContractBuilder/contract-builder-footer';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { NavigationProvider } from '@ContractBuilder/modules/navigation';
import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { EndorsementSummaryController } from '@features/endorsement-summary';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { canChange } from '@root/src/helpers/canChange';
import { useDeepCompareMemo, useEntitySections, useFeatureFlags } from '@src/hooks';
import { isEndorsementPath, isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';
import type { MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

import LeftSidebar from './components/LeftSidebar';
import Section from './components/SectionView/Section/Section';
import { AttachmentSection } from './modules/attachment/views/AttachmentSection';
import { EditorToolbarController } from './modules/editor-toolbar';
import { INLINE_BLOCK_EDITOR_NAME } from './modules/editors-map';
import { MentionsHandlerController } from './modules/mentions-handler';
import { getDocumentContext } from './utils/get-document-context';
import { pagesContainerClasses, pagesContainerWrapper, pagesSectionsContainerClasses } from './ContractViewClasses';
import type { EntityData } from './types';

import '../WysiwygEditor/components/Editor/table-borders.css';
import './contract.css';

type StateValue = Record<string, boolean>;

const getInitialState = (submission?: EntityData): StateValue => {
  if (!submission?.sections) {
    return {};
  }

  return submission.sections.map(({ id }) => id).reduce((acc, id) => ({ ...acc, [id]: false }), {});
};

const animationProps: MotionProps = {
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  transition: { duration: 0.2, delay: 0.1 },
};

export const ContractView: FC = () => {
  const { onCancel } = useBlockEdit();
  const { showHiddenFields } = useUIStore();
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);
  const { attachments, endorsementParent, submission } = useEntityStore(
    ({ attachments, endorsementParent, submission }) => ({
      attachments,
      endorsementParent,
      submission,
    }),
  );
  const isEditing = editingBlockId !== undefined;
  const isTemplate = isTemplatePath();
  const isEndorsementAppPath = isEndorsementPath();
  const isViewingRevisionHistory = useIsRevisionHistory();
  const hasAttachmentsFeature = useFeatureFlags()('Attachments');
  const submissionSections = useEntitySections();

  const isEndorsementView = useUIStore(({ isEndorsementView }) => isEndorsementView);
  const [sections, setSections] = useState<StateValue>(() => getInitialState(submission));

  const disableControls = !canChange(submission?.status) || isEndorsementView || isViewingRevisionHistory;
  const shouldShowCreator = !disableControls && !isViewingRevisionHistory;

  const handleInViewChange = (inView: boolean, _entry: IntersectionObserverEntry, sectionId: string) => {
    setSections((prev) => ({ ...prev, [sectionId]: inView }));
  };

  const parsedSections = isEndorsementView
    ? submissionSections
    : submissionSections.filter((section) => !section.deleted_at);

  const activeSectionId = useDeepCompareMemo(() => {
    const defaultSectionId = submissionSections[0]?.id;

    if (editingBlockId) {
      return (
        submissionSections.find(({ blocks }) => blocks.some(({ id }) => id === editingBlockId))?.id ?? defaultSectionId
      );
    }

    return submissionSections.find(({ id }) => sections[id])?.id ?? defaultSectionId;
  }, [editingBlockId, sections, submissionSections]);

  const handleDocumentMarginClick = () => {
    if (editingBlockId) {
      return onCancel();
    }
  };
  const context = getDocumentContext();

  return (
    <div className="flex h-full flex-col">
      <div className={clsx(pagesContainerWrapper, isTemplate && 'page-template')} data-cypress="contract-view">
        {!isTemplate && <MentionsHandlerController />}
        <NavigationProvider>
          <LeftSidebar activeSectionId={activeSectionId} isEditing={isEditing} />
        </NavigationProvider>
        <div className={clsx(pagesContainerClasses, 'relative overflow-hidden bg-info-50')}>
          <AnimatePresence>
            {isEditing && (
              <motion.div
                {...animationProps}
                className={clsx('absolute left-0 top-0 flex w-drawer-leftover bg-white', CONTRACT_VIEW_Z_INDEX)}
              >
                <EditorToolbarController
                  currentEditorKey={INLINE_BLOCK_EDITOR_NAME}
                  type={isEndorsementView ? 'endorsementSummary' : 'contract'}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div
            className={clsx(
              pagesContainerClasses,
              isEditing && 'contract-view-content-shrink',
              showHiddenFields ? 'show-fields' : '',
              'contract-view-content overflow-y-auto bg-info-50 pt-6',
            )}
            id="contract-view-content"
            onClick={handleDocumentMarginClick}
          >
            {isEditing && (
              <div
                id={'contract-view-edit-mode-overlay'}
                className={clsx(
                  'remove-interactive-mention pointer-events-none absolute inset-0 bg-info-50 opacity-50',
                  CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX,
                )}
              />
            )}
            <div className={clsx(pagesSectionsContainerClasses, isEditing && 'contract-view-editing')}>
              <Show when={isEndorsementView}>
                <EndorsementSummaryController />
              </Show>
              <Show when={!isEndorsementView}>
                {parsedSections.map((section) => (
                  <InView
                    delay={50}
                    key={`section-in-view-${section.id}`}
                    onChange={(inView, entry) => handleInViewChange(inView, entry, section.id)}
                  >
                    {({ ref }) => (
                      <div className={clsx('mb-8 mt-20 bg-white shadow-lg ')} ref={ref}>
                        <Section
                          context={context}
                          disableControls={!canChange(submission?.status) || isViewingRevisionHistory}
                          endorsementParent={endorsementParent}
                          key={`${section.id}-${section.blocks.length}`}
                          section={section}
                          shouldShowCreator={shouldShowCreator}
                          submission={submission}
                        />
                      </div>
                    )}
                  </InView>
                ))}
              </Show>
              <Show when={attachments?.length > 0 && !isEndorsementAppPath && hasAttachmentsFeature}>
                <AttachmentSection attachments={attachments} />
              </Show>
            </div>
          </div>
          <AnimatePresence initial={false}>
            {isEditing && (
              <motion.div
                {...animationProps}
                className={clsx('sticky bottom-0 w-drawer-leftover bg-white', CONTRACT_BUILDER_FOOTER_Z_INDEX)}
              >
                <ContractBuilderFooter />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
