import type { InlineOptionProps } from '../types';

type GetActiveConditionsFn = (editor: any) => boolean;

export const getControl =
  (editor?: any) =>
  (
    operation: string,
    activeConditions: string | object | boolean | GetActiveConditionsFn,
    icon: string,
    title: string,
    args?: any,
  ): InlineOptionProps => {
    const isActive =
      typeof activeConditions === 'function'
        ? activeConditions(editor)
        : typeof activeConditions === 'boolean'
        ? activeConditions
        : editor?.isActive(activeConditions);

    return {
      format: () => editor?.chain().focus()[operation](args).run(),
      isActive,
      icon,
      key: title,
      title,
    };
  };
