import type { FC } from 'react';
import type { PanelPlacement } from '@ContractBuilder/hooks';
import { MrcDocumentViewLoaded } from '@MrcExtraction/modules/mrc-document-view';
import type { DataExtractionData } from '@root/@types/types';

interface DataExtractionPDFDocumentProps {
  dataExtractionItem?: DataExtractionData;
  fileUrl?: string;
  focussedCandidate?: string;
  onDocumentLoadSuccess: () => void;
  onDownload: () => Promise<void>;
  pageWidth: number;
  placement: PanelPlacement;
}

export const DataExtractionPDFDocument: FC<DataExtractionPDFDocumentProps> = ({
  dataExtractionItem,
  fileUrl,
  focussedCandidate,
  onDocumentLoadSuccess,
  onDownload,
  pageWidth,
  placement,
}) => {
  return (
    <MrcDocumentViewLoaded
      data={dataExtractionItem?.data}
      focussedCandidate={focussedCandidate}
      inputFileUrl={fileUrl}
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      onDownload={onDownload}
      pageWidth={pageWidth}
      placement={placement}
    />
  );
};
