import type { FC } from 'react';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useSetFocussedBlock } from '@ContractBuilder/modules/live-pdf-preview';
import { useEntityStore } from '@ContractBuilder/store';
import type { EntityData } from '@ContractBuilder/types';
import { mdiTextBoxSearchOutline } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { iconClasses } from './classes';
import { getId } from './utils';

interface PreviewInPDFTooltipedIconProps {
  block: ResourceBlock;
}

const shouldDisablePreviewInPDFFeature = (submission?: EntityData) => {
  if (!submission) {
    return false;
  }

  // data-fix: Disable `Jump to PDF preview` for contracts last updated before 9 July 2024
  const FEATURE_ENABLE_THRESHOLD = '2024-07-08T23:59:59.999Z';

  const deployedAt = dayjs(FEATURE_ENABLE_THRESHOLD);
  const lastUpdatedAt = dayjs(submission.updated_at ?? submission.created_at);

  return lastUpdatedAt.isBefore(deployedAt);
};

export const PreviewInPDFTooltipedIcon: FC<PreviewInPDFTooltipedIconProps> = ({ block }) => {
  const submission = useEntityStore(({ submission }) => submission);

  const onSetFocussedBlock = useSetFocussedBlock();
  const handlePreviewInPDF = () => onSetFocussedBlock(block);

  const isDisabled = shouldDisablePreviewInPDFFeature(submission);

  return (
    <TooltipedIcon
      size={0.9}
      className={clsx(iconClasses({ isDisabled }))}
      data-testid={getId('preview-in-pdf', block.name)}
      path={mdiTextBoxSearchOutline}
      onClick={isDisabled ? undefined : handlePreviewInPDF}
      content={isDisabled ? 'Disabled' : 'Preview in PDF'}
    />
  );
};
