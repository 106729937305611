import Tooltip from '@components/Tooltip';

import type { IconType } from '../../Editor/extensions/OrderedList';
import { controlClasses } from '../classes';

import { TooltipIcon } from './TooltipIcon';

interface controlRendererArgs {
  format: () => void;
  isActive: boolean;
  isDisabled?: boolean;
  icon: IconType;
  title: string;
  key: string;
}

export const controlRenderer = ({ format, isActive, isDisabled = false, icon, title, key }: controlRendererArgs) => (
  <Tooltip content={title} key={key}>
    <button
      onClick={format}
      disabled={isDisabled}
      className={controlClasses({ isActive, isDisabled, className: ['h-6'] })}
      type="button"
    >
      <TooltipIcon icon={icon} />
    </button>
  </Tooltip>
);
