import type { Section } from '@ContractBuilder/types';

import { SECTIONS_KEYS_SELECTOR } from '../settings';

export const getSortedSections = (sections: Section[], submissionLayout: string[] = []) => {
  const clonedSections = Array.from(sections);

  return clonedSections.sort((a, b) => {
    const aOrder = submissionLayout.indexOf(a.id);
    const bOrder = submissionLayout.indexOf(b.id);

    if (aOrder || bOrder) {
      return aOrder - bOrder;
    }

    if (!a.standardised_name) {
      return 1;
    }

    if (!b.standardised_name) {
      return -1;
    }

    const sectionAIndex = SECTIONS_KEYS_SELECTOR.findIndex((item) => item.value === a.standardised_name);
    const sectionBIndex = SECTIONS_KEYS_SELECTOR.findIndex((item) => item.value === b.standardised_name);

    return sectionAIndex - sectionBIndex;
  });
};
