import { createContext, type FC, type ReactNode, useContext } from 'react';
import type { DocumentContext } from '@ContractBuilder/utils/get-document-context';
import type { GeneratedBlockFlags } from '@hooks/use-block-flags-generator';
import type { ResourceBlock } from '@root/@types/base';

export type BlockFlagsContextType = GeneratedBlockFlags & {
  isHighlighted?: boolean;
  isLoading?: boolean;
  mentionValues?: Record<string, string | number>;
  block: ResourceBlock;
  context: DocumentContext;
};

export const BlockFlagsContext = createContext<BlockFlagsContextType | null>(null);

export const useBlockFlags = () => {
  const context = useContext(BlockFlagsContext);

  if (!context) {
    throw new Error(`"BlockFlagsProvider" must be present in React DOM tree.`);
  }

  return context;
};

interface BlockFlagsProviderProps {
  value: BlockFlagsContextType;
  children?: ReactNode;
}

export const BlockFlagsProvider: FC<BlockFlagsProviderProps> = ({ children, value }) => (
  <BlockFlagsContext.Provider value={value}>{children}</BlockFlagsContext.Provider>
);
