import type { FC } from 'react';
import AvatarMenu from '@components/AvatarMenu';
import Icon from '@components/Icon';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import PublishingOptions from '@ContractBuilder/components/PublishingOptions';
import { ShowCommentsAndActivitySection } from '@ContractBuilder/components/ShowCommentsAndActivitySection';
import { CircleIconWrapper } from '@ContractBuilder/contract-builder-header/components/CircleIconWrapper';
import { EndorsementsButtonController } from '@ContractBuilder/contract-builder-header/modules/endorsements-button/controller/EndorsementsButtonController';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { EntityData, PublishingOptionType, StatusType } from '@ContractBuilder/types';
import { canChange } from '@helpers/canChange';
import { DocumentSearchIcon, EyeOffIcon } from '@heroicons/react/outline';
import { MODALS } from '@src/constants';
import { useDocumentLocation, useFeatureFlags, useModal } from '@src/hooks';
import sharedClasses from '@src/utils/shared-classes';
import clsx from 'clsx';

interface ContractToolsProps {
  isLoading: boolean;
  isViewingRevisionHistory: boolean;
  onStatusUpdate: (status: StatusType) => Promise<void>;
  publishingOptions: PublishingOptionType[];
  submission: EntityData;
}

export const ContractTools: FC<ContractToolsProps> = ({
  isLoading,
  isViewingRevisionHistory,
  onStatusUpdate,
  publishingOptions,
  submission,
}) => {
  const { isContractView, isTemplate, isEndorsement } = useDocumentLocation();
  const { toggleShowHiddenFields, showHiddenFields, togglePDFPreview, isPDFPreviewOpen } = useUIStore();
  const isEditing = useBlockEditFormStore((state) => state.formValues?.id !== undefined);
  const { showModal } = useModal();
  const getFeatureFlagState = useFeatureFlags();

  const shouldDisablePublishingOptions =
    isLoading || !canChange(submission.status, undefined, 'status-change') || isViewingRevisionHistory || isEditing;

  const hasEndorsementsFeature = getFeatureFlagState('Endorsements');

  const handleOpenSummaryModal = () => {
    return showModal(MODALS.CONFIRM_STATUS_FINAL_ENDORSEMENT, {});
  };

  const handleOpenEndorsementDetailsModal = () => {
    return showModal(MODALS.CREATE_ENDORSEMENT, { isEdit: true });
  };

  const handlePreviewPdf = () => {
    if (!isViewingRevisionHistory) {
      return togglePDFPreview();
    }
  };

  return (
    <>
      <div className="flex items-center gap-x-4">
        <Show when={!isTemplate && isContractView}>
          <Tooltip content={`${showHiddenFields ? 'Hide' : 'Show'} hidden fields`} disabled={isViewingRevisionHistory}>
            <CircleIconWrapper
              data-cypress="show-hidden-fields-button"
              icon={EyeOffIcon}
              isActive={showHiddenFields}
              onClick={toggleShowHiddenFields}
              wrapperClassName={clsx(
                isViewingRevisionHistory && 'cursor-not-allowed pointer-events-none bg-transparent opacity-50',
                showHiddenFields && 'hover:bg-primary-100',
              )}
            />
          </Tooltip>
        </Show>
        <Tooltip content="Preview PDF" disabled={isViewingRevisionHistory}>
          <CircleIconWrapper
            data-cypress="preview-pdf-button"
            icon={DocumentSearchIcon}
            isActive={isPDFPreviewOpen}
            onClick={handlePreviewPdf}
            wrapperClassName={clsx(
              isViewingRevisionHistory && 'cursor-not-allowed pointer-events-none bg-transparent opacity-50',
            )}
          />
        </Tooltip>
        <ShowCommentsAndActivitySection />
        <Show when={isEndorsement && hasEndorsementsFeature}>
          <div className="relative z-0 inline-flex divide-x divide-info-300 rounded-md border border-info-300 bg-info-100">
            <Tooltip content="Endorsement Summary" disabled={isEditing}>
              <div
                className={clsx(
                  isEditing && sharedClasses.disabled,
                  'relative inline-flex h-9 cursor-pointer items-center rounded-l-md border-info-300 border-transparent px-5 py-1 text-white',
                )}
                onClick={handleOpenSummaryModal}
              >
                <Icon className="text-info-600" name="speech-bubble" />
              </div>
            </Tooltip>
            <Tooltip content="Endorsement Details" disabled={isEditing}>
              <div
                className={clsx(
                  isEditing && sharedClasses.disabled,
                  'relative inline-flex h-9 cursor-pointer items-center rounded-l-none rounded-r-md border-info-300 border-transparent px-5 py-1 text-white',
                )}
                onClick={handleOpenEndorsementDetailsModal}
              >
                <Icon className="text-info-600" name="pencil-edit" />
              </div>
            </Tooltip>
          </div>
        </Show>
        <EndorsementsButtonController submission={submission} />
      </div>
      <PublishingOptions
        isDisabled={shouldDisablePublishingOptions}
        onStatusUpdate={onStatusUpdate}
        publishingOptions={publishingOptions}
        status={submission.status}
      />
      <AvatarMenu />
    </>
  );
};
