import {
  mdiBorderAll,
  mdiBorderBottomVariant,
  mdiBorderInside,
  mdiBorderLeftVariant,
  mdiBorderNone,
  mdiBorderOutside,
  mdiBorderRightVariant,
  mdiBorderTopVariant,
  mdiFormatLineStyle,
  mdiTableColumnPlusAfter,
  mdiTableColumnPlusBefore,
  mdiTableColumnRemove,
  mdiTableLock,
  mdiTableMergeCells,
  mdiTableRemove,
  mdiTableRowPlusAfter,
  mdiTableRowPlusBefore,
  mdiTableRowRemove,
  mdiTableSplitCell,
} from '@mdi/js';
import { cva } from 'class-variance-authority';

import { ControlGroup } from '../components/ControlGroup';
import type { TableControlsOptions } from '../types';

import { getControl } from './control';
import { getSeparator } from './separator';

const borderPreviewClasses = cva(
  ['border-info-700', 'w-36', 'h-5', 'mb-5', 'hover:bg-primary-50', 'border-solid', 'border-b'],
  {
    variants: {
      type: {
        normal: [],
        thick: ['border-b-2'],
        dotted: ['border-dotted'],
        dashed: ['border-dashed'],
      },
    },
    defaultVariants: {
      type: 'normal',
    },
  },
);

export const getTableControls = (editor: any): TableControlsOptions => {
  const getEditorControl = getControl(editor);
  return [
    // remove
    getEditorControl('deleteTable', false, mdiTableRemove, 'Remove Table'),
    getSeparator('separator-table-1'),
    // columns
    getEditorControl('addColumnAfter', false, mdiTableColumnPlusAfter, 'Add column after'),
    getEditorControl('addColumnBefore', false, mdiTableColumnPlusBefore, 'Add column before'),
    getEditorControl('deleteColumn', false, mdiTableColumnRemove, 'Remove selected column'),
    getSeparator('separator-table-2'),
    // rows
    getEditorControl('addRowAfter', false, mdiTableRowPlusAfter, 'Add row after'),
    getEditorControl('addRowBefore', false, mdiTableRowPlusBefore, 'Add row before'),
    getEditorControl('deleteRow', false, mdiTableRowRemove, 'Remove selected row'),
    getSeparator('separator-table-3'),
    getEditorControl('mergeCells', false, mdiTableMergeCells, 'Merge selected cells'),
    getEditorControl('splitCell', false, mdiTableSplitCell, 'Split selected cell'),
    getEditorControl('toggleLock', false, mdiTableLock, 'Toggle first column lock'),
    getSeparator('separator-table-4'),
    // borders
    getEditorControl('setAllBorders', false, mdiBorderAll, 'All borders'),
    getEditorControl('unsetAllBorders', { hideall: true }, mdiBorderNone, 'No borders'),

    getEditorControl('setOuterBorders', false, mdiBorderOutside, 'Outer borders'),
    getEditorControl('setInnerBorders', false, mdiBorderInside, 'Inner borders'),

    getEditorControl('toggleBorder', { hidetop: true }, mdiBorderTopVariant, 'Toggle Top Border', 'top'),
    getEditorControl('toggleBorder', { hideright: true }, mdiBorderRightVariant, 'Toggle Right Border', 'right'),
    getEditorControl('toggleBorder', { hidebottom: true }, mdiBorderBottomVariant, 'Toggle Bottom Border', 'bottom'),
    getEditorControl('toggleBorder', { hideleft: true }, mdiBorderLeftVariant, 'Toggle Left Border', 'left'),
    getSeparator('separator-table-5'),
    // styling
    {
      key: 'table_border_style',
      custom: () => (
        <ControlGroup
          title="Border Style"
          align="right"
          disableDynamicIcon
          options={[
            {
              format: () => editor?.chain().focus().setBorderStyle('normal').run(),
              isActive: editor.isActive({ borderlinestyle: 'normal' }),
              icon: <div className={borderPreviewClasses({ type: 'normal' })}>&nbsp;</div>,
              key: 'border-normal',
              title: 'Normal',
              enableCheckmark: true,
            },
            {
              enableCheckmark: true,
              format: () => editor?.chain().focus().setBorderStyle('thick').run(),
              isActive: editor.isActive({ borderlinestyle: 'thick' }),
              icon: <div className={borderPreviewClasses({ type: 'thick' })}>&nbsp;</div>,
              key: 'border-thick',
              title: 'Thick',
            },
            {
              format: () => editor?.chain().focus().setBorderStyle('dashed').run(),
              isActive: editor.isActive({ borderlinestyle: 'dashed' }),
              icon: <div className={borderPreviewClasses({ type: 'dashed' })}>&nbsp;</div>,
              key: 'border-dashed',
              title: 'Dashed',
              enableCheckmark: true,
            },
            {
              format: () => editor?.chain().focus().setBorderStyle('dotted').run(),
              isActive: editor.isActive({ borderlinestyle: 'dotted' }),
              icon: <div className={borderPreviewClasses({ type: 'dotted' })}>&nbsp;</div>,
              key: 'border-dotted',
              title: 'Dotted',
              enableCheckmark: true,
            },
          ]}
          direction="column"
          icon={mdiFormatLineStyle}
          key={'table_border_style'}
        />
      ),
    },
  ];
};
