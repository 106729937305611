import type { EntityData } from '@ContractBuilder/types';
import { MrcExtractionResult } from '@root/@types/api';
import { first } from 'lodash-es';

export const isFinalDataExtractionStatus = (status?: MrcExtractionResult) =>
  status && [MrcExtractionResult.Failed, MrcExtractionResult.Succeeded].includes(status);

export const getPendingDataExtraction = (submission?: EntityData) => {
  const latestExtraction = first(submission?.dataExtractions);

  if (!isFinalDataExtractionStatus(latestExtraction?.status)) {
    return latestExtraction;
  }

  return undefined;
};
