import { useUserStore } from '@Auth/store';
import type { ContextOption } from '@components/menu';
import { ContextMenu } from '@components/menu';
import { useBlockEdit } from '@ContractBuilder/modules/block-edit';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { crossCheckBlockUserCtx } from '@ContractBuilder/rules/block/cross-check-block-user-ctx';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { getDocumentContext } from '@ContractBuilder/utils/get-document-context';
import { getLockedBlockPermissionsMessage } from '@ContractBuilder/utils/get-locked-block-permissions-message';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import type { ResourceBlock } from '@root/@types/base';
import { MODALS } from '@root/src/constants';
import { canChange } from '@root/src/helpers/canChange';
import { useModal } from '@src/hooks';
import { isTemplatePath } from '@utils/app-paths';

import { useEntityStore } from '../store';

interface BlockMenuProps {
  block: ResourceBlock;
  className?: string;
  section_id: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const BlockMenu = ({ block, className, isOpen, onClose, onOpen }: BlockMenuProps) => {
  const isTemplate = isTemplatePath();
  const { canDelete, canEdit } = block;
  const { onCancel, setEditingBlock, onSubmit } = useBlockEdit();
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));
  const isViewingRevisionHistory = useIsRevisionHistory();

  const { showModal } = useModal();
  const openDrawer = useDrawerStore(({ openDrawer }) => openDrawer);
  const user = useUserStore(({ user }) => user);

  const context = getDocumentContext();

  const onEdit = () => {
    setEditingBlock(block.id);
    openDrawer({
      type: DrawerLayoutComponent.BlockConfigurationPanel,
      props: {
        onCancel: () => onCancel(true),
        onSubmit,
      },
    });
  };

  const canDeleteBlock = isTemplate || canDelete;
  const canEditBlock = isTemplate || canEdit;
  const { canBeDeletedWithCurrentPermissions, canBeEditedWithCurrentPermissions } = crossCheckBlockUserCtx(
    block,
    context,
    user,
  );

  const onDelete = () =>
    showModal(MODALS.CONFIRM_DELETE, {
      id: block.id,
      title: block.name,
      type: 'block',
      callback: () => onCancel(true),
    });

  const editOption: ContextOption = {
    title: 'Edit block',
    onClick: onEdit,
    icon: 'edit',
    order: 3,
    isDisabled: !canEditBlock || !canBeEditedWithCurrentPermissions,
    disabledMessage: getLockedBlockPermissionsMessage(
      false,
      true,
      canBeEditedWithCurrentPermissions,
      canBeDeletedWithCurrentPermissions,
      isTemplate,
    ),
  };

  const removeOption: ContextOption = {
    title: 'Remove block',
    onClick: onDelete,
    icon: 'trashcan',
    order: 5,
    isDisabled: !canDeleteBlock || !canBeDeletedWithCurrentPermissions,
    disabledMessage: getLockedBlockPermissionsMessage(
      true,
      false,
      canBeEditedWithCurrentPermissions,
      canBeDeletedWithCurrentPermissions,
      isTemplate,
    ),
  };

  const options: ContextOption[] = [];

  options.push(removeOption);
  options.push(editOption);

  return (
    <div className={className} data-testid={`block-menu:${block.name}`}>
      <ContextMenu
        disabled={!canChange(submission?.status) || isViewingRevisionHistory || options.length === 0}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        options={options}
      />
    </div>
  );
};

export default BlockMenu;
