import React from 'react';
import IconMdi from '@components/IconMdi';
import InputText from '@components/InputText';
import { mdiClose, mdiMagnify } from '@mdi/js';

type SearchBoxProps = {
  onChange: (value: string) => void;
  searchText: string;
};

export const SearchBox = (props: SearchBoxProps) => {
  const { onChange, searchText } = props;

  const inputRef = React.createRef<HTMLInputElement>();

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <>
      <InputText
        onChange={(event) => onChange(event.target.value)}
        placeholder="Search datapoints"
        ref={inputRef}
        value={searchText}
        inputClassName="px-8"
        className="p-2"
        startAdornment={
          <IconMdi
            path={mdiMagnify}
            onClick={handleFocusInput}
            className="absolute left-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
          />
        }
        endAdornment={
          <IconMdi
            path={mdiClose}
            onClick={() => onChange('')}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
          />
        }
      />
    </>
  );
};
