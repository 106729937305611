import ParagraphExtension from '@tiptap/extension-paragraph';

export const Paragraph = ParagraphExtension.extend({
  addAttributes() {
    return {
      parentId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-parentid'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-parentid': attributes.parentId,
          };
        },
      },
      dataType: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-type'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-type': attributes.dataType,
          };
        },
      },
    };
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const { editor } = this;
        const { state } = editor;
        const { $from } = state.selection;

        if ($from.node().attrs.dataType === 'content-repeater-group-child') {
          // @ts-expect-error
          editor.commands.first(({ commands }) => [
            () => commands.newlineInCode(),
            () => commands.createParagraphNear(),
            () => commands.liftEmptyBlock(),
            () => commands.splitBlock(),
          ]);
          // Remove repeater-related attributes from the new paragraph
          this.editor.commands.setNode('paragraph', {});
          return true;
        }

        return false;
      },
    };
  },
});
