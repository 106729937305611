import { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import InputSelect from '../InputSelect';

const InputSelectSearchable = (props: any) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  return <InputSelect {...props} isSearchable value={value} isIncomplete={isIncomplete} />;
};

export default InputSelectSearchable;
