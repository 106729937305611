import axios from 'axios';

interface Data {
  file: File;
}

export const uploadMrcExtractionDocument = async <T extends Data>(url: string, data: T) => {
  const response = await axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data;
};
