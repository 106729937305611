import type { FC } from 'react';
import { Fragment } from 'react';
import Button from '@components/Button';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useSchemaStore } from '@ContractBuilder/store';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import type { ValidateRequiredDatapointsResult } from '../utils/validate-required-datapoints';

interface MissingMandatoryDatapointsModalProps {
  isOpen: boolean;
  missingDatapoints: ValidateRequiredDatapointsResult;
  onCancel: () => void;
  onConfirm: () => void;
}

export const MissingMandatoryDatapointsModal: FC<MissingMandatoryDatapointsModalProps> = ({
  isOpen,
  missingDatapoints,
  onCancel,
  onConfirm,
}) => {
  const getFieldTitle = (fieldId: string): string => {
    return useSchemaStore.getState().findField(fieldId)?.title ?? fieldId;
  };

  const { list, hasVariations } = missingDatapoints;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
        data-testid="missing-mandatory-datapoints-modal"
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={onCancel}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-error-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-error-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    You have missing mandatory data points
                  </Dialog.Title>
                  <div className="mt-2 text-sm font-normal leading-5 text-info-500">
                    <p className="mb-3">These mandatory data points are missing from this block:</p>
                    <ul className="mb-3 list-[disc] pl-4">
                      {!hasVariations
                        ? list?.length &&
                          list[0]?.datapoints?.map((dp: string) => <li key={dp}>{getFieldTitle(dp)}</li>)
                        : list!.map((variations) => (
                            <>
                              <li className="font-bold">
                                {variations?.name}
                                <ul key={variations?.name} className="mb-3 pl-4 font-normal">
                                  {variations?.datapoints?.map((dp: string) => <li key={dp}>{getFieldTitle(dp)}</li>)}
                                </ul>
                              </li>
                            </>
                          ))}
                    </ul>
                    <p>Please ensure all of these are present within the block content.</p>
                  </div>
                </div>
              </div>
              <div className="mt-6 items-center justify-end gap-x-2.5 sm:flex">
                <Button className="h-auto px-4 py-2" onClick={onConfirm} kind="primary" size="sm">
                  Ok
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
