import { get, isEmpty, keys } from 'lodash-es';

export const isSkeletonEmpty = (arrayOfObjects?: Record<string, unknown>[]) => {
  if (!arrayOfObjects) {
    return true;
  }

  const paths = keys(get(arrayOfObjects, 0, {}));

  if (paths.length === 0) {
    return true;
  }

  return paths.every((key) => isEmpty(get(arrayOfObjects, [0, key])));
};
