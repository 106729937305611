import { formatDate } from '@root/helpers';
import { flatten } from 'flat';

const dateFormatMoment = 'Do MMMM YYYY';

const formatAddressIngredient = (value?: string | number): string => {
  if (value === undefined) {
    return '';
  }

  return `${value}, `;
};

interface FormatAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  region: string;
  postCode: string;
}

const formatAddress = ({ addressLine1, addressLine2, city, country, region, postCode }: FormatAddressProps): string =>
  `${formatAddressIngredient(addressLine1)} ${formatAddressIngredient(addressLine2)} ${formatAddressIngredient(
    region,
  )} ${formatAddressIngredient(postCode)} ${formatAddressIngredient(city)} ${country || ''}`;

export const formatFormValue = (key: string, value: any): string => {
  if (!value) {
    return '';
  }

  if (key === 'address' && typeof value === 'object') {
    return formatAddress(value);
  }

  if (
    typeof value === 'string' &&
    [
      'inception_date',
      'expiry_date',
      'insurance_period_start',
      'insurance_period_end',
      'date_contract_entered_into',
      'premium_settlement_due_date',
    ].includes(key)
  ) {
    return formatDate(value, dateFormatMoment) ?? '';
  }

  if (key && typeof key === 'string' && key.includes('percentage') && typeof value === 'number') {
    return String(value);
  }

  if (typeof value === 'number') {
    return new Intl.NumberFormat().format(value);
  }

  return value;
};

export const renderTransformedMention = (
  datapoints: Record<string, any> | undefined,
  sectionId: string | undefined,
  id: string,
): string | null => {
  if (!sectionId) {
    return null;
  }

  const sectionDatapoints = datapoints?.[sectionId] ?? {};
  const flat: Record<string, string> = flatten(sectionDatapoints);
  return flat[id];
};
