import { mdiPlus } from '@mdi/js';
import IconMdi from '@root/src/components/IconMdi';
import clsx from 'clsx';

import { useVariationsTabs } from '../context/context';

export const NewTabButton = () => {
  const { create: createVariation } = useVariationsTabs();

  return (
    <button
      type="button"
      data-testid="add-variation"
      className={clsx('cursor-pointer', 'hover:bg-info-100', 'select-none')}
      onClick={createVariation.bind(null, undefined)}
    >
      <IconMdi
        path={mdiPlus}
        className={clsx(['block', 'select-none', 'm-2', 'rounded-full', 'text-info-800', 'shrink-0'])}
      />
    </button>
  );
};
