type OverflowAxis = 'x' | 'y';

export const getScrollParent = (node: Element, overflowAxis: OverflowAxis = 'y'): Element => {
  const axisKey = overflowAxis === 'y' ? 'overflowY' : 'overflowX';
  const computedStyle = window.getComputedStyle(node);

  const overflowValues = [computedStyle[axisKey], computedStyle['overflow']];

  if (overflowValues.some((value) => /auto|scroll/.test(value))) {
    return node;
  }

  if (node.parentNode instanceof Element) {
    return getScrollParent(node.parentNode, overflowAxis);
  }

  return document.body;
};
