import type { LinkedDatapoints } from '@root/@types/types';
import { uniq } from 'lodash-es';
import { parse } from 'node-html-parser';

export const extractDataPointsFromHTML = (
  content: string,
  currentLinkedDatapoints: LinkedDatapoints = [],
): LinkedDatapoints => {
  const div = parse(content);
  const datapoints = div.querySelectorAll('.mention');
  const result = [...datapoints].map((dp) => dp.getAttribute('data-id')).filter(Boolean) as string[];
  div.remove();

  const uniqueResults = uniq(result);

  return uniqueResults.map((datapointId) => {
    const currentlyLinkedDatapoint = currentLinkedDatapoints.find((item) => item.id === datapointId);
    return {
      id: datapointId,
      isRequired: currentlyLinkedDatapoint?.isRequired ?? false,
    };
  });
};
