import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputSelect from '@components/InputSelect';
import { getPdfBrandingList } from '@pdf/custom/config/branding/config-default';

import type { StepProps } from '../../types';

const pdfBrandingOptions = getPdfBrandingList();

export const Step3: FC<StepProps> = ({ control, formValues }) => {
  return (
    <Controller
      control={control}
      name="branding"
      render={({ field }) => (
        <InputSelect
          {...field}
          isDisabled={field.disabled}
          labelText="Template branding"
          options={pdfBrandingOptions}
          placeholder="Select branding"
          value={formValues?.branding}
        />
      )}
    />
  );
};
