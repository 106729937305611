import { type FC, type ReactNode, useRef } from 'react';
import Stick from 'react-stick/lib/Stick';
import { useClickAway } from 'react-use';
import IconMdi from '@components/IconMdi';
import { CONTEXT_MENU_Z_INDEX } from '@constants/z-indices';
import { Menu, Transition } from '@headlessui/react';
import { mdiCloseCircle, mdiContentCopy, mdiPencil } from '@mdi/js';
import { useLanguage } from '@root/src/language';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import sharedClasses from '@root/src/utils/shared-classes';
import clsx from 'clsx';

import { useVariationsTabs } from '../context/context';

interface SectionMenuProps {
  className?: string;
  isOpen: boolean;
  variationId: string;
  canRemove?: boolean;
  onClose: () => void;
  onRename: () => void;
  children?: ReactNode;
  width: number;
}

// eslint-disable-next-line react-refresh/only-export-components
export const TAB_MIN_W = 110; //px

export const VariationMenu: FC<SectionMenuProps> = ({
  className,
  isOpen,
  variationId,
  canRemove,
  onClose,
  onRename,
  children,
  width,
}) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc' });
  const { remove, duplicate } = useVariationsTabs();

  const handleRemoveVariation = () => {
    remove(variationId);
    onClose();
  };

  const handleDuplicateVariation = () => {
    duplicate(variationId);
    onClose();
  };

  const options = [
    {
      testId: getDataTestId('variations.editTabNameButton').toString(),
      path: mdiPencil,
      title: getContent('contextMenus.variation.rename'),
      onClick: () => onRename(),
    },
    {
      testId: getDataTestId('variations.tabDuplicate').toString(),
      path: mdiContentCopy,
      title: getContent('contextMenus.variation.duplicate'),
      onClick: () => handleDuplicateVariation(),
    },
    {
      testId: getDataTestId('variations.tabRemoveButton').toString(),
      path: mdiCloseCircle,
      title: getContent('contextMenus.variation.remove'),
      onClick: () => handleRemoveVariation(),
      isDisabled: !canRemove,
    },
  ];

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    onClose?.();
  });

  return (
    <Stick
      position={'bottom center'}
      autoFlipHorizontally
      autoFlipVertically
      style={{ minWidth: width }}
      node={
        <Transition appear show={isOpen} as="div">
          <Menu
            as="div"
            style={{ minWidth: width }}
            className={clsx('relative inline-block text-left ', className, CONTEXT_MENU_Z_INDEX)}
            ref={ref}
          >
            <Transition.Child
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <Menu.Items
                className={clsx(
                  'flex w-full flex-col divide-y divide-info-100 rounded-b-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                )}
              >
                {options.map(({ path, onClick, title, isDisabled, testId }) => (
                  <Menu.Item key={title} data-testid={testId}>
                    {({ active }) => (
                      <button
                        onClick={
                          isDisabled
                            ? undefined
                            : (event) => {
                                event.stopPropagation();
                                onClick();
                                onClose?.();
                              }
                        }
                        className={clsx(
                          active && 'bg-primary-200',
                          'text-grey-400 group px-3 py-2 text-sm last:rounded-b-md',
                          isDisabled && sharedClasses.disabledContainer,
                        )}
                      >
                        <span
                          title={title}
                          className={clsx(isDisabled && sharedClasses.disabled, 'flex w-full items-center gap-3')}
                        >
                          <IconMdi path={path} size={0.7} />
                          <span>{title}</span>
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition.Child>
          </Menu>
        </Transition>
      }
    >
      {children}
    </Stick>
  );
};
