import type { ValidationState } from './types';

interface GetValidationStateArgs {
  isValid?: boolean;
  isParentDirty?: boolean;
  isDirty?: boolean;
}

export const getValidationState = ({ isValid, isParentDirty, isDirty }: GetValidationStateArgs): ValidationState => {
  switch (true) {
    case isValid:
      return 'valid';
    case isParentDirty:
      return 'parentDirty';
    case isDirty:
      return 'dirty';
    default:
      return 'idle';
  }
};
