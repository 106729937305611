import type { FC } from 'react';
import type { TooltipedIconProps } from '@components/TooltipedIcon';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiClose } from '@mdi/js';

export const CloseIconButton: FC<Omit<TooltipedIconProps, 'path'>> = (props) => {
  return (
    <TooltipedIcon
      aria-label="Clear"
      data-testid={`Clear-dropdown`}
      placement="top"
      path={mdiClose}
      className="h-fit cursor-pointer text-info-800 hover:text-error-500"
      size={0.65}
      content="Clear"
      {...props}
    />
  );
};
