import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { isEndorsementData } from '@ContractBuilder/types';
import type { BlockMentionValues } from '@root/@types/types';
import { formatDate, isEmptyValue } from '@root/helpers';
import { getAllPolicyholderNames, POLICYHOLDERS_DATAPOINT_ID } from '@root/helpers/mentions';
import { get } from 'lodash-es';

const ALLOWED_ENDORSEMENT_PICKS = [
  'effective_date',
  'endorsement_summary',
  'expiry_date',
  POLICYHOLDERS_DATAPOINT_ID,
  'reason',
  'reference',
  'type',
];
const isAllowedEndorsementPick = (path: string) => ALLOWED_ENDORSEMENT_PICKS.includes(path);
const isDateString = (value: string) => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/.test(value);

export const getEndorsementMentionValues = <T extends EntityData | EndorsementData>(
  data: T,
  ids: string[] = [],
  parentData?: EntityData,
): BlockMentionValues => {
  if (!isEndorsementData(data)) {
    return {};
  }

  return ids.filter(isAllowedEndorsementPick).reduce((acc, id) => {
    const value = get(data, id);

    if (id === POLICYHOLDERS_DATAPOINT_ID) {
      return {
        ...acc,
        [id]: getAllPolicyholderNames(get(parentData, 'data_items.risk_details.policyholder', [])),
      };
    }

    if (isEmptyValue(value)) {
      return {
        ...acc,
        [id]: undefined,
      };
    }

    return {
      ...acc,
      [id]: isDateString(value) ? formatDate(value, 'D MMMM YYYY') : value,
    };
  }, {});
};
