import React from 'react';
import { twMerge } from 'tailwind-merge';

const H3: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
  <h3 className={twMerge('text-xl font-semibold leading-relaxed', className)} {...props}>
    {children}
  </h3>
);

// eslint-disable-next-line react-refresh/only-export-components
export default H3;
