import type { FC } from 'react';
import { useDocumentLocation } from '@src/hooks';

import { ContractBuilderContractViewLoading } from '../views/ContractBuilderContractViewLoading';
import { ContractBuilderFormViewLoading } from '../views/ContractBuilderFormViewLoading';

export const ContractBuilderLoaderController: FC = () => {
  const { isFormView } = useDocumentLocation();
  return isFormView ? <ContractBuilderFormViewLoading /> : <ContractBuilderContractViewLoading />;
};
