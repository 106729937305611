import type { FC } from 'react';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { Show, ShowFirstMatching } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { type StateFromBlock } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { isTemplatePath } from '@utils/app-paths';

import { INVALID_CONF_TOOLTIP } from '../constants';
import { BlockVisibilityConfigurationForm } from '../controllers/BlockVisibilityFormController';
import { useVisibilityFormState } from '../hooks/use-visibility-form-state';
import type { SetFormValues } from '../types';

interface VisibilityConfigModalProps {
  blockName?: string;
  formValues: StateFromBlock;
  isDirty?: boolean;
  onCancel: () => void;
  onSaveChanges: () => void | Promise<false | void>;
  setFormValues: SetFormValues;
}

export const BlockVisibilityModalView: FC<VisibilityConfigModalProps> = ({
  blockName,
  formValues,
  isDirty,
  onCancel,
  onSaveChanges,
  setFormValues,
}) => {
  const isTemplate = isTemplatePath();
  const { isLoading } = useEntityStore(({ isLoading }) => ({ isLoading }));
  const isValid = useVisibilityFormState({ formValues });

  const mode = formValues.visibility?.variations?.length ? 'variation' : 'block';
  const tooltipContent = INVALID_CONF_TOOLTIP[mode];

  const buttonDisabled = isDirty ? !isValid : true;

  return (
    <Modal open onClose={onCancel} className={'w-[1000px]'}>
      <div className="flex w-full flex-col">
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            onClick={onCancel}
          >
            <span className="sr-only">Cancel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="my-5 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-800">
              <ShowFirstMatching>
                <Show when={!!blockName}>
                  <>Conditional logic for &quot;{blockName}&quot;</>
                </Show>
                <Show when={true}>Conditional logic</Show>
              </ShowFirstMatching>
            </Dialog.Title>
          </div>
        </div>
        <BlockVisibilityConfigurationForm formValues={formValues} setFormValues={setFormValues} />
        <div className="flex-grow items-center justify-end gap-x-2.5 border-t pt-5 sm:flex">
          <Button className="h-auto px-4 py-2" onClick={onCancel} kind="secondary" size="sm" isDisabled={isLoading}>
            <ShowFirstMatching>
              <Show when={isTemplate}>Cancel</Show>
              <Show when={true}>OK</Show>
            </ShowFirstMatching>
          </Button>
          <Show when={isTemplate}>
            <Tooltip disabled={isValid} content={tooltipContent}>
              <div>
                <Button
                  className="h-auto px-4 py-2"
                  isDisabled={buttonDisabled}
                  kind="primary"
                  loading={isLoading}
                  onClick={onSaveChanges}
                  size="sm"
                >
                  Apply
                </Button>
              </div>
            </Tooltip>
          </Show>
        </div>
      </div>
    </Modal>
  );
};
