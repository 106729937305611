import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Icon from '@components/Icon';
import { useMenu } from '@components/menu';
import { Show } from '@components/Show';
import type { Attachment } from '@ContractBuilder/types';
import { SwitchVerticalIcon as DragAndDropIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { AttachmentMenu } from '../components/AttachmentMenu';

export const AttachmentItem = ({
  attachment,
  withContextMenu,
  dragHandleProps,
}: {
  attachment: Attachment;
  withContextMenu: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}) => {
  const visibleOnHoverClasses = ['group-hover:opacity-100', 'opacity-0'];
  const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useMenu();

  return (
    <div className="flex flex-col">
      <div className="pointer-events-auto relative flex cursor-default border-b px-mrc-block-padding py-3 opacity-100 transition-colors duration-500">
        <div className="content-center p-2">
          <Icon name="paperclip" className="flex h-[20px] w-[20px] items-center hover:opacity-75" />
        </div>
        <p className="w-full overflow-hidden text-ellipsis">
          <span className="font-semibold">{attachment?.name ?? ''}</span>
          <br />
          <span className="text-slate-500">{attachment?.fileName ?? ''}</span>
        </p>
        <Show when={withContextMenu}>
          <div className="relative inline-flex grow flex-row-reverse items-center">
            <AttachmentMenu
              isOpen={isMenuOpen}
              onClose={onMenuClose}
              onOpen={onMenuOpen}
              attachmentId={attachment.id}
              className={clsx(
                visibleOnHoverClasses,
                'cursor-pointer group-hover:text-info-800',
                isMenuOpen ? 'text-info-800 opacity-100' : 'text-info-400',
              )}
            />
            {dragHandleProps && (
              <div className={clsx('h-6 w-6 group-hover:text-info-800', visibleOnHoverClasses)} {...dragHandleProps}>
                <DragAndDropIcon className="h-6 w-6" />
              </div>
            )}
          </div>
        </Show>
      </div>
    </div>
  );
};
