import axios from 'axios';

import type { TemplateShort } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const fetchTemplates = async (): Promise<TemplateShort[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: 'templates-spa-al',
  });

  const response = await axios(networkPayload);
  return response.data;
};
