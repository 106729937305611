import type { Option } from '../types';

const EDITOR_FONT_SIZES = [
  { size: '7pt', name: 'S' },
  { size: '9pt', name: 'M', isDefault: true },
  { size: '11pt', name: 'L' },
  { size: '13pt', name: 'XL' },
  { size: '15pt', name: 'XXL' },
];

const isFontSize = (editor: any, fontSize: string | number) => editor?.isActive('textStyle', { fontSize });

const setSize = (editor: any, fontSize: string | number) => {
  const isActive = isFontSize(editor, fontSize);

  if (isActive) return editor?.chain().focus().unsetFontSize().run();

  return editor?.chain().focus().setFontSize(fontSize).run();
};

export const getFontSizeCtrlOptions = (editor: any, onSelected: () => void): Option[] =>
  EDITOR_FONT_SIZES.map(({ size, name, isDefault }) => ({
    format: () => {
      setSize(editor, size);
      return onSelected();
    },
    isActive: isFontSize(editor, size),
    key: name,
    icon: <span>{name}</span>,
    title: `Set font size to ${size}.${isDefault ? ' Default.' : ''}`,
  }));
