import { useQuery } from 'react-query';
import type { LibraryBlockType } from '@ContractBuilder/types';
import type { UseQueryRefetchFn, UseQueryStatus } from '@root/@types/types';
import { get } from 'lodash-es';
import qs from 'qs';

import { fetchBlocks } from '../queries';

interface BlocksLibraryHook {
  data?: LibraryBlockType[];
  totalAmount: number;
  names: string[];
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useBlocksLibrary = (params = {}, enabled = true): BlocksLibraryHook => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    data: blocksResult,
    status,
    isFetching,
    refetch,
  } = useQuery(['blocks', JSON.stringify(params)], () => fetchBlocks(queryParams.authToken as string, params), {
    refetchOnWindowFocus: false,
    enabled,
  });

  return {
    data: get(blocksResult, 'data', []),
    totalAmount: get(blocksResult, 'totalAmount', 0),
    names: get(blocksResult, 'names', []),
    status,
    isFetching,
    refetch,
  };
};
