import type { FC } from 'react';
import type { UIComponentBase } from '@root/@types/types';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface DividerWithTextProps extends UIComponentBase {
  onClick?: () => void;
  variant?: 'primary' | 'info';
  id?: string;
}

const borderClasses = cva(['w-full', 'border-b'], {
  variants: {
    variant: {
      info: 'border-info-300',
      primary: 'border-primary-500',
    },
    interactive: {
      true: 'cursor-pointer',
      false: [],
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});

const textClasses = cva(['bg-white', 'text-sm'], {
  variants: {
    variant: {
      info: 'text-info-500',
      primary: 'text-primary-500',
    },
    interactive: {
      true: 'cursor-pointer',
      false: [],
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});

export const DividerWithText: FC<DividerWithTextProps> = ({
  children,
  className,
  variant = 'info',
  onClick,
  ...props
}) => (
  <div className={clsx('relative py-4', className)} onClick={onClick} {...props}>
    <div className="absolute inset-0 flex items-center">
      <div className={borderClasses({ variant, interactive: !!onClick })}></div>
    </div>
    <div className="relative flex justify-center">
      <span className={textClasses({ variant, interactive: !!onClick })}>{children}</span>
    </div>
  </div>
);
