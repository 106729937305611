import { useLocalStorage, useWindowSize } from 'react-use';

const INITIAL_DRAWER_MAX_OFFSET_PX = 48;
const INITIAL_WIDTH_LOCAL_STORAGE_KEY = 'initial-drawer-width';

export const useInitialWidth = () => {
  const { width: windowWidth } = useWindowSize();
  const initialWidth = Math.floor(windowWidth / 2);

  const [lastSavedWindowWidth, setInitialWidth] = useLocalStorage(INITIAL_WIDTH_LOCAL_STORAGE_KEY, initialWidth);
  const parsedInitialWidth = Math.min(lastSavedWindowWidth ?? initialWidth, windowWidth - INITIAL_DRAWER_MAX_OFFSET_PX);

  return {
    initialWidth: parsedInitialWidth,
    onResize: setInitialWidth,
    windowWidth,
  };
};
