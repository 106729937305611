// @ts-expect-error
import { Mark } from '@tiptap/core';

export const Superscript = Mark.create({
  name: 'superscript',

  parseHTML() {
    return [
      {
        tag: 'sup',
      },
      {
        style: 'vertical-align',
        getAttrs: (value: string) => value === 'super' && null,
      },
    ];
  },

  renderHTML() {
    return ['sup', 0];
  },

  addCommands() {
    return {
      toggleSuperscript:
        () =>
        ({ commands }: any) => {
          commands.unsetMark('subscript');
          return commands.toggleMark(this.name);
        },
    };
  },
});
