import { useCallback, useMemo, useState } from 'react';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import Fuse from 'fuse.js';

interface Option {
  label: string;
  value: string;
}

interface UseFuseSearchArgs {
  options: Option[];
  searchText?: string;
}

export const useFuseSearch = ({ options, searchText }: UseFuseSearchArgs) => {
  const [search, setSearch] = useState<string | undefined>(searchText);

  const getIdentifier = useCallback((option: Option, path: string | string[]) => {
    if (path === 'value' || path.includes('value')) {
      return option.value;
    }

    return option.label;
  }, []);

  const fuse = useDeepCompareMemo(
    () => new Fuse(options, { keys: ['value', 'label'], getFn: getIdentifier }),
    [getIdentifier, options],
  );

  const searchResult = useMemo(
    () =>
      search
        ? fuse.search(search).map((current) => (current.refIndex !== undefined ? options[current.refIndex] : current))
        : options,
    [fuse, options, search],
  );

  const handleReset = () => {
    return setSearch(undefined);
  };

  return { onReset: handleReset, onSearch: setSearch, search, searchResult: searchResult as Option[] };
};
