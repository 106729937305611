import type { FC } from 'react';
import Button from '@components/Button';
import Form from '@components/Form';
import InputCheckbox from '@components/InputCheckbox';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import { SECTIONS_KEYS_SELECTOR } from '@ContractBuilder/settings';
import type { StandardisedSectionName, UIInputValue } from '@root/@types/types';
import type { GetFieldPropsFn } from '@root/src/hooks/form';
import { useLanguage } from '@root/src/language';

import type { SectionModalFormState } from '../types';

interface SectionFormProps {
  getFieldProps: GetFieldPropsFn<SectionModalFormState>;
  modalMode: 'create' | 'edit';
  onClose: () => void;
  onSubmit: () => Promise<void>;
  isLoading: boolean;
}

const STANDARDISED_SECTION_NAME_OPTIONS = SECTIONS_KEYS_SELECTOR.map((item) => ({
  name: item.label,
  value: item.value,
}));

export const SectionForm: FC<SectionFormProps> = ({ getFieldProps, isLoading, modalMode, onClose, onSubmit }) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.editSection' });
  const submitButtonText = getContent(`button.${modalMode}`);
  const { value: checkboxValue, ...checkboxProps } = getFieldProps('shouldResetPageCounter');
  const { onChange: selectChangeHandler, ...selectProps } = getFieldProps('standardisedSectionName');
  const { value: hideTitleCheckboxValue, ...hideTitleCheckboxProps } = getFieldProps('shouldHideTitleInPdf');

  const handleSelectChange = (value: UIInputValue<StandardisedSectionName>) => {
    return selectChangeHandler(value, 'standardisedSectionName');
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputSelect
        className="mb-6"
        isSearchable={false}
        labelText={getContent('fields.name.label')}
        placeholder="Custom"
        options={[...STANDARDISED_SECTION_NAME_OPTIONS, { name: '+ Custom', value: undefined }]}
        onChange={handleSelectChange}
        {...selectProps}
      />
      {!selectProps.value && (
        <InputText
          className="mb-6"
          labelText={getContent('fields.name.label')}
          placeholder="Risk Details"
          {...getFieldProps('name')}
        />
      )}
      <InputCheckbox
        className="mb-6"
        labelText={getContent('fields.hideTitle.label')}
        isChecked={Boolean(hideTitleCheckboxValue)}
        {...hideTitleCheckboxProps}
      />
      <InputCheckbox
        className="mb-6"
        descriptionText={getContent('fields.resetNumbering.helperText')}
        labelText={getContent('fields.resetNumbering.label')}
        isChecked={Boolean(checkboxValue)}
        {...checkboxProps}
      />
      <div className="mt-8 flex w-full justify-between gap-4">
        <Button onClick={onClose} className="w-full">
          Cancel
        </Button>
        <Button className="w-full" kind="primary" type="submit" aria-label={submitButtonText} loading={isLoading}>
          {submitButtonText}
        </Button>
      </div>
    </Form>
  );
};
