import type { FC } from 'react';
import { useUserStore } from '@Auth/store';
import { Spinner } from '@components/Spinner';
import { useEntityStore } from '@ContractBuilder/store';
import { useForm } from '@hooks/form';
import { eventPropagationTrap } from '@src/utils';
import * as yup from 'yup';

import type { CommentsFormState } from '../types';
import { getThreadIdForBlock } from '../utils/get-thread-id-for-block';
import { AddNewCommentForm } from '../views/AddNewCommentForm';

interface AddNewCommentControllerProps {
  blockId?: string;
  className?: string;
  onAfterSubmit?: () => void;
  threadId?: string;
}

export const AddNewCommentController: FC<AddNewCommentControllerProps> = ({
  blockId,
  className,
  onAfterSubmit,
  threadId,
}) => {
  const { user } = useUserStore(({ user }) => ({ user }));
  const { addComment, submission } = useEntityStore(({ addComment, submission }) => ({
    addComment,
    submission,
  }));

  const handleAddComment = async ({ comment }: CommentsFormState) => {
    const parsedThreadId = getThreadIdForBlock(submission?.comments ?? [], blockId, threadId);
    await addComment(comment, blockId, parsedThreadId);
    return onAfterSubmit?.();
  };

  const { canSubmit, getFieldProps, isLoading, onSubmit } = useForm<CommentsFormState>({
    onSubmit: handleAddComment,
    validationSchema: yup.object().shape({ comment: yup.string().required() }),
  });

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    await onSubmit();
  };

  if (!user || !submission) {
    return <Spinner className="grow" />;
  }

  return (
    <div className={className} onClick={eventPropagationTrap}>
      <AddNewCommentForm
        canSubmit={canSubmit}
        getFieldProps={getFieldProps}
        isSubmitting={isLoading}
        onSubmit={handleSubmit}
        user={user}
      />
    </div>
  );
};
