import { PDF_DRAWER_Z_INDEX } from '@constants/z-indices';
import sharedClasses from '@utils/shared-classes';
import { cva } from 'class-variance-authority';

export const dataExtractionSplitPaneClasses = [
  'absolute left-0 top-0',
  'bg-gray-100',
  'flex shrink-0 grow-0 flex-col',
  'px-3 py-1.5',
  PDF_DRAWER_Z_INDEX,
];

export const dataExtractionSplitPaneHeight = 'calc(100vh - 90px)';

export const fileNameWrapperClasses = [
  'bg-white',
  'border border-b-0 border-gray-200',
  'flex items-center gap-3',
  'h-[41px] max-w-[50%]',
  'mr-auto',
  'px-3 py-2',
];

export const buttonDisabledClasses = [...sharedClasses.disabled, 'bg-gray-200'];

export const splitDocumentsButtonClasses = cva(
  ['flex items-center justify-center', 'border-r border-gray-200', 'px-3.5 py-1', 'cursor-pointer', 'text-gray-500'],
  {
    variants: {
      isInSplitMode: {
        true: 'bg-gray-200',
      },
    },
  },
);

export const pdfPreviewPanelClasses = cva(['absolute top-0', 'flex', 'h-full', 'bg-gray-100', PDF_DRAWER_Z_INDEX], {
  variants: {
    isLeftSidePlacement: {
      true: 'left-0 border-r border-gray-300',
    },
    isRightSidePlacement: {
      true: 'right-0 border-l border-gray-300',
    },
  },
});

export const unfoldMoreButtonClasses = cva(
  ['-translate-y-1/2', 'absolute top-1/2', 'bg-primary-600 text-white', 'cursor-pointer', 'p-1', 'rounded-full'],
  {
    variants: {
      isLeftSidePlacement: {
        true: 'right-0 translate-x-1/2',
      },
      isRightSidePlacement: {
        true: 'left-0 -translate-x-1/2',
      },
    },
  },
);

export const documentFileNameTabClasses = cva([], {
  variants: {
    marginFull: {
      true: 'mr-auto',
      false: 'mr-1.5',
    },
  },
});

export const animationProps = {
  animate: {
    x: 0,
  },
  exit: {
    x: '-100%',
  },
  initial: {
    x: '-100%',
  },
  transition: {
    duration: 0.3,
    ease: 'easeInOut',
  },
};
