import type { DocumentMode } from '@root/@types/types';
import { getDocumentMode } from '@root/src/helpers/getDocumentMode';
import { useLanguage } from '@root/src/language';

interface DocumentStatusUpdateProps {
  status: string;
}

export const DocumentStatusUpdate = ({ status }: DocumentStatusUpdateProps) => {
  const { mode } = getDocumentMode();
  const { getContent } = useLanguage({ prefix: mode as Exclude<DocumentMode, 'other'> });
  const lowerCase = status?.toLocaleLowerCase();
  return (
    <div className="text-sm">
      <h3 className="self-center font-medium leading-relaxed text-info-700">
        Status updated to <span className="capitalize">{lowerCase}</span>
      </h3>
      <p className="whitespace-nowrap text-info-400">{getContent(`notifications.statusUpdate.${lowerCase}`)}</p>
    </div>
  );
};
