import { POPOVER_Z_INDEX } from '@constants/z-indices';

export const livePreviewUpdatingPillClasses = [
  'flex items-center justify-center gap-1',
  'whitespace-nowrap text-xs font-medium leading-5',
  'bg-gray-100 text-gray-800',
  'rounded-full',
  'absolute',
  'left-1/2 top-[72px]',
  '-translate-x-1/2',
  'px-3 py-0.5',
  POPOVER_Z_INDEX,
];
