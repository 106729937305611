import { lazy } from 'react';

export const DrawerLayoutComponent = {
  Activity: 'activity',
  BlockConfigurationPanel: 'block_configuration_panel',
  Comments: 'comments',
  RevisionHistory: 'revision_history',
} as const;

export const componentsMap = {
  [DrawerLayoutComponent.Activity]: lazy(() =>
    import('@ContractBuilder/modules/activity').then(({ ActivityController }) => ({ default: ActivityController })),
  ),
  [DrawerLayoutComponent.BlockConfigurationPanel]: lazy(() =>
    import('../../block-configuration-panel').then(({ BlockConfigurationPanelController }) => ({
      default: BlockConfigurationPanelController,
    })),
  ),
  [DrawerLayoutComponent.Comments]: lazy(() =>
    import('@ContractBuilder/modules/comments').then(({ CommentsController }) => ({
      default: CommentsController,
    })),
  ),
  [DrawerLayoutComponent.RevisionHistory]: lazy(() =>
    import('@ContractBuilder/modules/revision-history').then(({ RevisionHistoryController }) => ({
      default: RevisionHistoryController,
    })),
  ),
};
