import { type FC, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import Divider from '@components/Divider';
import Icon from '@components/Icon';
import LoadingOverlay from '@components/LoadingOverlay';
import StrictModeDroppable from '@components/StrictModeDroppable';
import Tooltip from '@components/Tooltip';
import MODALS from '@constants/modals';
import { useEntityStore } from '@ContractBuilder/store';
import { useModal } from '@hooks/useModal';
import { useLanguage } from '@root/src/language';
import clsx from 'clsx';

import { AttachmentItem } from '../components/AttachmentItem';
import { AttachmentLoading } from '../components/AttachmentLoading';

interface AttachmentsProps {}

export const Attachments: FC<AttachmentsProps> = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { attachments, reorderAttachment } = useEntityStore(({ attachments, reorderAttachment }) => ({
    attachments,
    reorderAttachment,
  }));

  const { showModal } = useModal();

  const { getContent } = useLanguage({ prefix: 'naming.mrc.attachments' });

  const handleDragEnd = async (result: DropResult) => {
    const { destination, type } = result;

    if (!destination) {
      return;
    }

    try {
      if (type === 'COLUMN') {
        setIsLoading(true);
        await reorderAttachment(result);
      }
    } catch (_error) {
      // do nothing
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    showModal(MODALS.ATTACHMENT_UPLOAD, {});
  };

  return (
    <>
      <div className="flex h-[50px] items-center justify-between gap-4 px-4 py-3">
        <div className="flex flex-col">
          <p className="text-base font-normal leading-6 text-black">{getContent('title')}</p>
        </div>
        <Tooltip content="Append attachment">
          <div className="flex h-5 w-5 cursor-pointer items-center justify-center rounded border border-info-600 transition-all hover:opacity-75">
            <Icon
              aria-label="Append attachment"
              name="plus"
              onClick={handleClick}
              className="flex h-3 w-3 items-center hover:opacity-75"
              data-testid="add-attachment"
            />
          </div>
        </Tooltip>
      </div>
      <Divider />
      <div className="grow overflow-y-auto py-3">
        <LoadingOverlay active={isLoading} className="!fixed" />
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable
            droppableId="droppable"
            direction="vertical"
            fallback={<AttachmentLoading />}
            type="COLUMN"
          >
            {(provided, snapshot) => (
              <ol
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={clsx(snapshot.isDraggingOver && 'bg-info-100', 'text-[9pt]')}
              >
                {attachments?.map((attachment, index) => (
                  <Draggable key={`attachment-${attachment?.id}`} draggableId={attachment?.id} index={index}>
                    {(providedInner) => (
                      <li
                        id={`attachment-${attachment?.id}-draggable`}
                        key={`attachment-item-${attachment?.id}`}
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                      >
                        <div className="group cursor-pointer rounded px-1 hover:bg-info-50">
                          <AttachmentItem
                            attachment={attachment}
                            withContextMenu={true}
                            dragHandleProps={providedInner.dragHandleProps}
                          />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ol>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </>
  );
};
