// @ts-expect-error
import type { CommandProps } from '@tiptap/core';
import TableExtension from '@tiptap/extension-table';
import { CellSelection, TableMap } from 'prosemirror-tables';
import { findTable } from 'prosemirror-utils';

import { setAdjacentBorders, setCellBorders } from './helpers';

export const Table = () => {
  return TableExtension.extend({
    addOptions() {
      return {
        ...this.parent?.(),
        allowTableNodeSelection: true,
        resizable: true,
      };
    },
    addAttributes() {
      const parent = this.parent ? this.parent() : {};
      return {
        ...parent,
      };
    },

    addCommands() {
      const parent = this.parent ? this.parent() : {};
      return {
        ...parent,
        setOuterBorders:
          () =>
          ({ state, tr }: CommandProps) => {
            const { selection } = state;

            if (!(selection instanceof CellSelection)) {
              return false;
            }

            const table = findTable(selection);

            if (!table) {
              return false;
            }

            const tableMap = TableMap.get(table.node);

            const anchorCell = tableMap.findCell(selection.$anchorCell.pos - table.start);
            const headCell = tableMap.findCell(selection.$headCell.pos - table.start);

            const rect = {
              left: Math.min(anchorCell.left, headCell.left),
              right: Math.max(anchorCell.right, headCell.right),
              top: Math.min(anchorCell.top, headCell.top),
              bottom: Math.max(anchorCell.bottom, headCell.bottom),
            };

            const setBorders = setCellBorders(tr);
            const setHelperBorders = setAdjacentBorders(setBorders, table, rect);

            if (rect.left > 0) {
              setHelperBorders('left');
            }

            if (rect.top > 0) {
              setHelperBorders('top');
            }

            const cellPositions = tableMap.cellsInRect(rect);

            cellPositions.forEach((cellPos) => {
              const cellNode = table.node.nodeAt(cellPos);
              if (!cellNode) return;
              const posInTable = table.start + cellPos;
              const cellRect = tableMap.findCell(posInTable - table.start);

              const isTop = cellRect.top === rect.top;
              const isLeft = cellRect.left === rect.left;
              const isRight = cellRect.right === rect.right;
              const isBottom = cellRect.bottom === rect.bottom;

              setBorders(cellNode, posInTable, { isTop, isLeft, isRight, isBottom });
            });
          },
        setInnerBorders:
          () =>
          ({ state, tr }: CommandProps) => {
            const { selection } = state;

            if (!(selection instanceof CellSelection)) {
              return false;
            }

            const table = findTable(selection);

            if (!table) {
              return false;
            }

            const tableMap = TableMap.get(table.node);

            const anchorCell = tableMap.findCell(selection.$anchorCell.pos - table.start);
            const headCell = tableMap.findCell(selection.$headCell.pos - table.start);

            const rect = {
              left: Math.min(anchorCell.left, headCell.left),
              right: Math.max(anchorCell.right, headCell.right),
              top: Math.min(anchorCell.top, headCell.top),
              bottom: Math.max(anchorCell.bottom, headCell.bottom),
            };

            const setBorders = setCellBorders(tr);

            const cellPositions = tableMap.cellsInRect(rect);

            cellPositions.forEach((cellPos) => {
              const cellNode = table.node.nodeAt(cellPos);
              if (!cellNode) return;
              const posInTable = table.start + cellPos;
              const cellRect = tableMap.findCell(posInTable - table.start);

              const isTop = cellRect.top !== rect.top;
              const isLeft = cellRect.left !== rect.left;
              const isRight = cellRect.right !== rect.right;
              const isBottom = cellRect.bottom !== rect.bottom;

              setBorders(cellNode, posInTable, { isTop, isLeft, isRight, isBottom });
            });
          },
      };
    },

    addKeyboardShortcuts() {
      return {
        Tab: () => false,
        'Shift-Tab': () => false, // both shortcuts are dealt with in the Ident module
        'Shift-Enter': () => {
          return this.editor.chain().addRowAfter().goToNextCell().run();
        },
      };
    },
  });
};
