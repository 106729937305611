import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { PanelPlacement } from '@ContractBuilder/hooks';
import type { DataExtractionData } from '@root/@types/types';
import { scrollIntoView } from '@utils/scroll-into-view';

import { DataExtractionPDFDocument } from '../views/DataExtractionPDFDocument';

interface DataExtractionPDFPreviewControllerProps {
  dataExtractionItem?: DataExtractionData;
  fileUrl?: string;
  focussedCandidate?: string;
  onDownload: () => Promise<void>;
  pageWidth: number;
  placement: PanelPlacement;
}

export const DataExtractionPDFPreviewController: FC<DataExtractionPDFPreviewControllerProps> = ({
  dataExtractionItem,
  fileUrl,
  focussedCandidate,
  onDownload,
  pageWidth,
  placement,
}) => {
  const [isDocumentMounted, setIsDocumentMounted] = useState(false);

  useEffect(() => {
    if (isDocumentMounted && focussedCandidate) {
      scrollIntoView(`mrc-candidate-bounding-box-${focussedCandidate}`, { block: 'start' }, 50);
    }
  }, [isDocumentMounted, focussedCandidate]);

  return (
    <DataExtractionPDFDocument
      dataExtractionItem={dataExtractionItem}
      fileUrl={fileUrl}
      focussedCandidate={focussedCandidate}
      onDocumentLoadSuccess={() => setIsDocumentMounted(true)}
      onDownload={onDownload}
      pageWidth={pageWidth}
      placement={placement}
    />
  );
};
