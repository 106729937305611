import type { DynamoDBResourceComment } from '@root/@types/database';

export const sortComments = (comments?: DynamoDBResourceComment[]) => {
  if (!comments) {
    return [];
  }

  return comments.sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });
};
