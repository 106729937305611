import type { FC } from 'react';

import { SubmissionStep1, SubmissionStep2, SubmissionStep3 } from './views/submission';
import { TemplateStep1, TemplateStep2, TemplateStep3 } from './views/template';
import type { EntityModalType, Step } from './types';

type StepsMap = Record<EntityModalType, Record<Exclude<Step, Step.MrcFileUpload>, FC<any>>>;

export const STEPS_MAP: StepsMap = {
  submission: {
    1: SubmissionStep1,
    2: SubmissionStep2,
    3: SubmissionStep3,
  },
  template: {
    1: TemplateStep1,
    2: TemplateStep2,
    3: TemplateStep3,
  },
};
