import type { EntityData } from '@ContractBuilder/types';
import axios from 'axios';

import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const fetchSubmissions = async (): Promise<EntityData[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/submissions-spa-al',
  });

  const response = await axios(networkPayload);
  return response.data;
};
