import { scrollIntoView } from '@root/src/utils/scroll-into-view';

import type { HighlightStoreSlice, SliceType } from './types';

export const createHighlightSlice: SliceType<HighlightStoreSlice> = (set, get) => ({
  highlightedBlockId: undefined,
  setHighlightedBlock: (blockId, shouldScrollIntoView = false) => {
    if (blockId && shouldScrollIntoView) {
      return scrollIntoView(blockId, undefined, 0, () => set({ highlightedBlockId: blockId }));
    }

    set({ highlightedBlockId: blockId });
  },
  highlightChangedBlock: (blockId, timeout = 2000) => {
    get().setHighlightedBlock(blockId);
    setTimeout(() => {
      get().setHighlightedBlock(undefined);
    }, timeout);
  },
});
