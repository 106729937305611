import clsx from 'clsx';

import { stringToHslColor } from '../helpers/stringToHslColor';

interface AvatarProps {
  className?: string;
  initials: string;
}

const Avatar = ({ className, initials }: AvatarProps) => {
  const color = stringToHslColor(initials);

  return (
    <div
      className={clsx(
        'flex h-8 w-8 select-none items-center justify-center rounded-circle text-xs font-semibold uppercase text-info-700',
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {initials}
    </div>
  );
};

export default Avatar;
