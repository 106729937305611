import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { BlockCreatorStepId } from './types';

export interface BlockCreatorContextValue {
  step: BlockCreatorStepId;
  blockLibraryId?: string;
  setStep: Dispatch<SetStateAction<BlockCreatorStepId>>;
  resetState: () => void;
  onPrevStep: () => void;
  setBlockLibraryId: Dispatch<SetStateAction<string | undefined>>;
  handleCancel: () => void;
  handleAbort: () => void;
}

export const BlockCreatorContext = createContext<BlockCreatorContextValue | null>(null);

export const useBlockCreator = () => {
  const context = useContext(BlockCreatorContext);

  if (!context) {
    throw new Error(`"BlockCreatorProvider" must be present in React DOM tree`);
  }

  return context;
};
