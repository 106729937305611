import { useParams } from 'react-router-dom';
import { useEntityStore } from '@ContractBuilder/store';
import { isEndorsementData } from '@ContractBuilder/types';

import { ContractEndorsementBanner } from '../views/ContractEndorsementBanner';
import { ContractEndorsementBannerLoading } from '../views/ContractEndorsementBannerLoading';

export const ContractEndorsementBannerController = () => {
  const { endorsement_id } = useParams();
  const { submission } = useEntityStore(({ submission }) => ({ submission }));

  if (!endorsement_id) {
    return null;
  }

  if (!submission?.parentId) {
    return <ContractEndorsementBannerLoading />;
  }

  const name = isEndorsementData(submission) ? submission.reference ?? submission.name : submission.name;
  return <ContractEndorsementBanner name={name} parentId={submission.parentId} />;
};
