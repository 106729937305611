import type { ChangeEvent } from 'react';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import InputText from '@components/InputText';
import { Show } from '@components/Show';

import { useBrandingStore } from '../../store/branding.store';
import { useBrandingTemplateStore } from '../branding-template-selector/branding-templates.store';
import { InputSelectBranding } from '../branding-template-selector/InputSelectBranding';

export const CreateBrandingForm = () => {
  const { search, setSearch } = useBrandingTemplateStore((s) => s);
  const { handleChange, branding } = useBrandingStore((s) => s);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange('name')(e.target.value);
  };

  const clearSearch = () => setSearch('');

  return (
    <>
      <InputText
        isRequired
        labelText="Branding name"
        placeholder="Branding name"
        value={branding?.name}
        onChange={onNameChange}
      />
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium leading-tight text-gray-700">Select branding template</div>
        <div className="flex-col items-start justify-start gap-2 rounded-md border border-gray-300 bg-white shadow-sm">
          <div className="flex w-full items-center justify-between pr-3">
            <InputText
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search branding"
              inputClassName="border-0 !shadow-none !bg-transparent ring-0 outline-0"
              className="!bg-transparent !shadow-none"
            />
            <Show when={!!search}>
              <CloseIconButton onClick={clearSearch} />
            </Show>
          </div>

          <div className="h-px self-stretch bg-gray-200" />
          <InputSelectBranding />
        </div>
      </div>
    </>
  );
};
