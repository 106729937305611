import type { BlockVisibilityCondition } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';

export const isConditionValid = (condition: BlockVisibilityCondition): boolean => {
  const isValueRequired = ['IS', 'IS NOT'].includes(condition.operator);

  return (
    // prettier-ignore
    !isEmptyValue(condition.field) &&
    !isEmptyValue(condition.operator) &&
    (isValueRequired ? !isEmptyValue(condition.value) : true)
  );
};
