import type { FormHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

const Form = forwardRef<HTMLFormElement, FormHTMLAttributes<HTMLFormElement>>(function Form(
  { onSubmit, ...props },
  ref,
) {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return <form onSubmit={handleSubmit} ref={ref} {...props} />;
});

export default Form;
