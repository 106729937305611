import { type FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '@components/Button';
import Modal from '@components/Modal';

import { useBrandingStore } from '../../store/branding.store';
import { useBrandingTemplateStore } from '../branding-template-selector/branding-templates.store';

import { CreateBrandingForm } from './CreateBrandingForm';

export interface CreateBrandingModalProps {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

export const CreateBrandingModal: FC<CreateBrandingModalProps> = ({ handleClose }) => {
  const navigate = useNavigate();
  const { form, branding, duplicateBranding } = useBrandingStore((s) => s);
  const { selectedBrandingId } = useBrandingTemplateStore((s) => s);
  const isValid = useMemo(() => form.isDirty && branding, [form, branding]);

  const handleCreate = async () => {
    handleClose();
    await duplicateBranding(selectedBrandingId)
      .then(navigate)
      .catch(() => toast.error('There was an error creating your branding. Please try again.'));
  };

  return (
    <Modal
      open
      className="w-full max-w-[512px]"
      onClose={handleClose}
      title="Create a new branding"
      description="Setup a new header & footer branding"
      shouldShowCloseIcon={false}
      titleClassName="block"
      descriptionClassName="text-center"
    >
      <div className="mt-5 flex w-full flex-col gap-6">
        <CreateBrandingForm />
        <div className="inline-flex items-start justify-start gap-3 self-stretch">
          <div className="flex w-full items-start justify-end gap-3">
            <Button kind="secondary" className="h-9 min-w-[80px]" onClick={handleClose}>
              Cancel
            </Button>
            <Button kind="primary" className="h-9 min-w-[80px]" isDisabled={!isValid} onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
