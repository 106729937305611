import type { FC } from 'react';
import React from 'react';
import type { ContextOption } from '@components/menu';
import { ContextMenu } from '@components/menu';
import { useEntityStore } from '@ContractBuilder/store/entity.store';
import { useLanguage } from '@root/src/language';
import { MODALS } from '@src/constants';
import { useModal } from '@src/hooks';

interface AttachmentMenuProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  attachmentId: string;
}

export const AttachmentMenu: FC<AttachmentMenuProps> = ({ className, isOpen, onClose, onOpen, attachmentId }) => {
  const { attachments } = useEntityStore(({ attachments }) => ({
    attachments,
  }));
  const { showModal } = useModal();

  const { getContent } = useLanguage({ prefix: 'naming.mrc' });

  const eventPropagationTrap = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const attachment = attachments?.find((attachment) => attachment.id === attachmentId);

  const options: ContextOption[] = [
    {
      title: getContent('contextMenus.attachment.rename'),
      onClick: () =>
        showModal(MODALS.ATTACHMENT_RENAME, {
          attachmentId: attachmentId,
          attachmentName: attachment?.name,
          attachmentFileName: attachment?.fileName,
        }),
      icon: 'edit',
    },
    {
      title: getContent('contextMenus.attachment.remove'),
      onClick: () =>
        showModal(MODALS.CONFIRM_DELETE, {
          id: attachmentId,
          title: attachment?.name,
          type: getContent('attachment').toLowerCase(),
        }),
      icon: 'trashcan',
    },
  ];

  return (
    <div className={className} onClick={eventPropagationTrap}>
      <ContextMenu isOpen={isOpen} onClose={onClose} onOpen={onOpen} options={options} dataTestId={`attachment-menu`} />
    </div>
  );
};
