import type { ResolvedSchemaTree } from '@root/helpers/schema';
import { isSectionRepeater, isSectionRepeatersRoot } from '@root/helpers/schema';
import { isInlineContentRepeater } from '@root/helpers/schema/resolveSchemaTreeFromForm';

import { getDataType } from './get-data-type';
import { getShouldHideTitle } from './get-should-hide-title';

export const getRepeaterTitle = (item: ResolvedSchemaTree, formValues: Record<string, any>) => {
  const dataType = getDataType(item);

  const isInline = isInlineContentRepeater(item.type);
  const shouldHideTitle = getShouldHideTitle(dataType, formValues, item.id);

  if (shouldHideTitle) {
    return undefined;
  }

  if (isSectionRepeatersRoot(item)) {
    return (currentIdx: number) => `Section ${currentIdx + 1}`;
  }

  if (isSectionRepeater(dataType)) {
    return (currentIdx: number) => `Section ${currentIdx + 1}`;
  }

  if (!isInline) {
    return (currentIdx: number) => `${item.title} - ${currentIdx + 1}`;
  }

  return undefined;
};
