import { EndorsementSummaryInsertMenuController } from '@features/endorsement-summary-insert-menu';
import { BrandingInsertMenu } from '@root/src/features/branding-edit/components/BrandingInsertMenu';
import { InsertMenu } from '@WysiwygEditor/components/InsertMenu';

import type { CustomOptionProps } from '../types';

export const getDatapointControls = (editor: any): CustomOptionProps => {
  return {
    custom: () => <InsertMenu editor={editor} key="insert-menu" />,
    key: 'insert-menu',
  };
};

export const getBrandingDatapointControls = (editor: any): CustomOptionProps => ({
  custom: () => <BrandingInsertMenu editor={editor} key="branding-insert-menu" />,
  key: 'branding-insert-menu',
});

export const getEndorsementSummaryBlockDatapointControls = (editor: any): CustomOptionProps => ({
  custom: () => <EndorsementSummaryInsertMenuController editor={editor} key="endorsement-summary-block-insert-menu" />,
  key: 'endorsement-summary-block-insert-menu',
});
