import type { Dispatch, SetStateAction } from 'react';
import { isEqual } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

type UiState = {
  isBlockUsedInTemplatesModalOpen: boolean;
  isEndorsementView: boolean;
  isPDFPreviewOpen: boolean;
  reset: () => void;
  setBlockUsedInTemplatesModalVisibility: (visibility: boolean) => void;
  setEndorsementView: Dispatch<SetStateAction<boolean>>;
  showHiddenFields: boolean;
  togglePDFPreview: () => void;
  toggleShowHiddenFields: () => void;
};

export const useUIStore = createWithEqualityFn<UiState>(
  (set, get) => ({
    isBlockUsedInTemplatesModalOpen: false,
    isEndorsementView: false,
    isPDFPreviewOpen: false,
    reset: () => set({ isBlockUsedInTemplatesModalOpen: false, showHiddenFields: false }),
    setBlockUsedInTemplatesModalVisibility: (value) => set({ isBlockUsedInTemplatesModalOpen: value }),
    setEndorsementView: (value) => {
      const newValue = typeof value === 'function' ? value(get().isEndorsementView) : value;
      return set({ isEndorsementView: newValue });
    },
    showHiddenFields: false,
    togglePDFPreview: () =>
      set((state) => {
        const newValue = !state.isPDFPreviewOpen;
        return { isPDFPreviewOpen: newValue };
      }),
    toggleShowHiddenFields: () => set((state) => ({ showHiddenFields: !state.showHiddenFields })),
  }),
  isEqual,
);
