import type { FC } from 'react';
import type { ButtonProps } from '@components/Button';
import Button from '@components/Button';
import { Show, ShowFirstMatching } from '@components/Show';
import clsx from 'clsx';

import { buttonsContainerClasses } from '../classes';
import type { CreateEntityFormState } from '../types';
import { Step } from '../types';

interface FormFooterProps extends ButtonProps {
  currentStep: Step;
  formValues?: CreateEntityFormState;
  isLastStep: boolean;
  isLoading: boolean;
  isMRCUpload: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

export const FormFooter: FC<FormFooterProps> = ({
  currentStep,
  formValues,
  isLastStep,
  isLoading,
  isMRCUpload,
  onNext,
  onPrevious,
  ...buttonProps
}) => {
  const isLastStepDisabled = isLastStep && isMRCUpload && !formValues?.file;
  const isButtonDisabled = isLastStepDisabled || buttonProps.isDisabled || isLoading;

  return (
    <div className={clsx(buttonsContainerClasses)}>
      <Button className="h-[2.375rem]" kind="secondary" onClick={onPrevious}>
        {currentStep === Step.GeneralEntityData ? 'Cancel' : 'Previous'}
      </Button>
      <ShowFirstMatching>
        <Show when={isLastStep}>
          <Button {...buttonProps} isDisabled={isButtonDisabled} type="submit">
            {currentStep === Step.MrcFileUpload ? 'Extract' : 'Create'}
          </Button>
        </Show>
        <Show when={!isLastStep}>
          <Button {...buttonProps} onClick={onNext} type="button">
            Next
          </Button>
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
