import { useEntityStore } from '@ContractBuilder/store/entity.store';
import { patchEntity } from '@mutations/patchEntity';
import { ApiPatchOperation } from '@root/@types/types';
import { createBlock, updateBlock } from '@src/mutations';

import type { LibraryStoreSlice, SliceType } from './types';

export const createLibrarySlice: SliceType<LibraryStoreSlice> = (set, get) => ({
  createBlockInLibrary: async (payload) => {
    try {
      useEntityStore.setState({ isLoading: true });
      const { id } = await createBlock(payload);
      get().highlightChangedBlock(id);
      return id;
    } finally {
      set({ loadingBlockId: undefined });
      get().closeModal();
      useEntityStore.setState({ isLoading: false });
    }
  },
  archiveBlockInLibrary: async (blockId) => {
    if (!blockId) {
      return;
    }
    try {
      set({ loadingBlockId: blockId });
      useEntityStore.setState({ isLoading: true });
      await updateBlock(blockId, { deleted_at: new Date().toISOString() });
      get().highlightChangedBlock(blockId);
    } finally {
      set({ loadingBlockId: undefined });
      get().closeModal();
      useEntityStore.setState({ isLoading: false });
    }
  },
  restoreBlockInLibrary: async (blockId) => {
    if (!blockId) {
      return;
    }
    try {
      set({ loadingBlockId: blockId });
      useEntityStore.setState({ isLoading: true });
      await updateBlock(blockId, { deleted_at: null });
      get().highlightChangedBlock(blockId);
    } finally {
      set({ loadingBlockId: undefined });
      get().closeModal();
      useEntityStore.setState({ isLoading: false });
    }
  },
  addBlockFromLibrary: async (
    { blockLibraryId, sectionId, selectedVariationId, order, canDelete, canDeleteOnTemplate, canEdit, visibility },
    cb,
  ) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    useEntityStore.setState({ isLoading: true });
    const result = await patchEntity({
      operation: ApiPatchOperation.AddNewBlockFromLibrary,
      blockLibraryId,
      canDelete,
      canDeleteOnTemplate,
      canEdit,
      order,
      sectionId,
      selectedVariationId,
      visibility,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      cb?.();
      get().highlightChangedBlock(result.blockId);
      useEntityStore.setState({ isLoading: false });
    }
  },
  updateBlockInLibrary: async (blockLibraryId, payload, originalBlockId) => {
    if (!blockLibraryId) {
      return;
    }

    try {
      const parsedId = originalBlockId ?? blockLibraryId;
      set({ loadingBlockId: parsedId });
      useEntityStore.setState({ isLoading: true });
      await updateBlock(blockLibraryId, payload);
      get().highlightChangedBlock(parsedId);
      const submission = get().submission;
      if (submission) {
        await get().reloadSubmission();
      }
    } finally {
      set({ loadingBlockId: undefined });
      get().closeModal();
      useEntityStore.setState({ isLoading: false });
    }
  },
  replaceBlockForBlockInLibrary: async (blockId, payload) => {
    const { blockLibraryId, sectionId, canDelete, canDeleteOnTemplate, canEdit, visibility, order } = payload;

    if (!blockId || !sectionId) {
      return;
    }

    useEntityStore.setState({ isLoading: true });

    try {
      await get().deleteBlock(blockId, false);
      await get().addBlockFromLibrary({
        blockLibraryId,
        canDelete,
        canDeleteOnTemplate,
        canEdit,
        order,
        sectionId,
        visibility,
      });

      const submission = get().submission;
      if (submission) {
        await get().reloadSubmission();
      }
    } finally {
      useEntityStore.setState({ isLoading: false });
    }
  },
});
