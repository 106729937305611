import type { ReactNode } from 'react';
import clsx from 'clsx';

interface PillBadgeProps {
  content: string | ReactNode;
  className?: string;
  onClick?: () => void;
  overflow?: 'truncate' | 'no-breaks';
  maxWidth?: string | number;
}

const classes = {
  base: [
    'text-xxs',
    'inline-flex',
    'items-center',
    'px-3',
    'py-1',
    'h-5',
    'bg-primary-100',
    'text-primary-800',
    'rounded-full',
    'text-xs',
    'leading-4',
    'font-medium',
  ],
  overflow: {
    // display text with ellipsis up until width limit
    truncate: ['text-ellipsis overflow-hidden', 'whitespace-nowrap'],
    // display text without line breaks, no width limit
    'no-breaks': ['whitespace-nowrap'],
  },
};

export const PillBadge = ({ content, className, onClick, overflow = 'truncate', maxWidth = 100 }: PillBadgeProps) => (
  <div className={clsx(classes.base, className)} onClick={onClick}>
    <span className={clsx(classes.overflow[overflow])} style={{ maxWidth }}>
      {content}
    </span>
  </div>
);
