import type { StandardisedSectionName } from '@root/@types/types';
import { getStandardisedSectionLabel } from '@src/utils';
import { isEmpty } from 'lodash-es';

export const getSectionLabel = (name?: string, standardisedSectionName?: StandardisedSectionName) => {
  if (isEmpty(name)) {
    return getStandardisedSectionLabel(standardisedSectionName);
  }

  return name;
};
