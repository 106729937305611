import type { EndorsementData } from '@ContractBuilder/types';
import type { GroupedClauses, ResourceBlock } from '@root/@types/types';
import { filter, flow, groupBy, partialRight } from 'lodash-es';

export const getEndorsementRemovedGroupedClauses = (entity?: EndorsementData): GroupedClauses => {
  if (!entity?.sections) {
    return {} as GroupedClauses;
  }

  return flow(
    partialRight(filter, (block: ResourceBlock) => block.isDeleted && block.type === 'clause'),
    partialRight(groupBy, 'clause_type'),
  )(entity.changed_blocks ?? []);
};
