import type { ResolvedSchemaField, ResolvedSchemaTree } from '@root/helpers/schema/types';
import type { CommandProps } from '@tiptap/core/dist/packages/core/src/types';

import { getContentForRepeater } from './get-content-for-repeater';
import type { ConfigParams } from './types';

export const insertSectionRepeaterContent = (
  editor: any,
  item: ResolvedSchemaTree | ResolvedSchemaField,
  config: ConfigParams,
  searchTerm: string = '',
) => {
  return editor
    .chain()
    .focus()
    .command(({ tr, dispatch, state }: CommandProps) => {
      // Replace the first instance of the search term (e.g. `{{policyholder`) with an empty string
      state.doc.descendants((node, pos) => {
        if (node.isText && node.text) {
          const matches = Array.from(node.text.matchAll(new RegExp(`{{${searchTerm}`, 'g'))).filter(([matchText]) =>
            matchText.trim(),
          );

          for (let j = 0; j < matches.length; j += 1) {
            const match = matches[j];

            if (match[0] === '') {
              break;
            }

            if (match.index !== undefined) {
              return dispatch?.(tr.insertText('', pos + match.index, pos + match.index + match[0].length));
            }
          }
        }
      });
    })
    .insertContent(`${getContentForRepeater(item, config)}<p><br /></p>`, {
      updateSelection: true,
      parseOptions: {
        preserveWhitespace: false,
      },
    })
    .run();
};
