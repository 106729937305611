import React, { useState } from 'react';
import { getSubmissionsTableColumns } from '@pages/User/helpers/columns/getSubmissionsTableColumns';
import type { Action } from '@root/@types/types';
import { SUBMISSION_STATUSES } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchSubmissions } from '@src/queries';

import { MODALS } from '../../constants';

import { ReactTable } from './components';
import { useDataQuery } from './hooks';

export const Submissions: React.FC = () => {
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { data, status, tags } = useDataQuery('submissions', [], fetchSubmissions);

  const columns = getSubmissionsTableColumns({ tags, setIsLoading });

  const actions: Action[] = [
    {
      key: 'add_submission',
      label: 'New Submission',
      onClick: () => showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data }),
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
        class_of_business: false,
        created_by: false,
        sourceResourceDisplayName: false,
        branding: false,
      }}
      entitiesLabel="submissions"
      filteredData={data}
      initialFilters={[{ id: 'status', value: SUBMISSION_STATUSES.filter((status) => status !== 'ARCHIVED') }]}
      initialSorting={[{ id: 'ref', desc: true }]}
      status={status}
      isLoading={isLoading}
    />
  );
};

export const UserSubmissionsPage = withMenuLayout(Submissions);
