import { DATAPOINT, ENDORSEMENT_DATA } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import { POLICYHOLDERS_DATAPOINT_ID } from '@root/helpers/mentions';

export const endorsementSummaryDatapoints: SelectMenuItem[] = [
  {
    label: 'Unique Market Reference',
    id: 'risk_details.unique_market_reference.unique_market_reference',
    order: 0,
    type: DATAPOINT,
  },
  { label: 'Reference', id: 'reference', order: 1, type: ENDORSEMENT_DATA },
  { label: 'Insured', id: POLICYHOLDERS_DATAPOINT_ID, order: 2, type: ENDORSEMENT_DATA },
  { label: 'Type', id: 'type', order: 3, type: ENDORSEMENT_DATA },
  { label: 'Reason', id: 'reason', order: 4, type: ENDORSEMENT_DATA },
];
