import React, { useEffect } from 'react';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import type { BlockVariation } from '@root/@types/database';
import { DEFAULT_VARIATION_ID } from '@root/helpers/variations';
import { isEmpty, pick } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import { useBlockEditFormStore } from '../../block-edit';
import type { VariationsTabsContextValue } from '../context/context';
import { VariationsTabsContext } from '../context/context';

interface VariationsProviderProps {
  children?: React.ReactNode;
}

export const VariationsProvider = ({ children }: VariationsProviderProps) => {
  const { formValues: block, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));
  const { variations, selectedVariationId } = block || {};
  const blockId = block?.id ?? '';
  const { currentVariationIdx, setCurrentVariationIdx } = useVariationsTabsStore(
    ({ getBlockCurrentVariationIdx, setCurrentVariationIdx }) => ({
      currentVariationIdx: getBlockCurrentVariationIdx(blockId),
      setCurrentVariationIdx,
    }),
  );

  const handleSetCurrentVariationIdx = (newValue: number) => {
    return setCurrentVariationIdx(blockId, Math.max(newValue, 0));
  };

  const select = (newVariationId: string) => {
    return setFormValues((current) => {
      const isAlreadySelected = newVariationId === current?.selectedVariationId;
      return { ...current, selectedVariationId: isAlreadySelected ? undefined : newVariationId };
    });
  };

  const setCurrent = (id?: string) => {
    const { variations = [] } = block ?? {};
    if (!variations.length || !id) {
      setCurrentVariationIdx(blockId, 0);
      return;
    }

    const variation = variations.find((v) => v.id === id);
    const idx = variation ? variations.indexOf(variation) : 0;
    handleSetCurrentVariationIdx(idx);
  };

  const generateNext = (
    source: BlockVariation,
    isFirst: boolean,
    order: number,
    duplicate?: boolean,
  ): BlockVariation => {
    const name = isFirst ? 'Default' : `Variant ${order}`;
    const id = isFirst ? DEFAULT_VARIATION_ID : uuid();

    const NEW_VARIATION_KEYS = ['isComplete', 'linkedDatapoints', 'repeaterIds'];
    const DUPLICATE_VARIATION_KEYS = ['content', 'title', 'isComplete', 'linkedDatapoints', 'repeaterIds'];

    return {
      id,
      name,
      content: '',
      title: '',
      ...pick(source, duplicate ? DUPLICATE_VARIATION_KEYS : NEW_VARIATION_KEYS),
    } as BlockVariation;
  };

  const create = (payload?: BlockVariation) => {
    const { variations = [], ...rest } = block ?? {};
    const isFirst = variations?.length === 0;

    const source = payload ?? variations?.[currentVariationIdx] ?? rest;

    const newVariations: BlockVariation[] = [...variations];

    const order = variations?.length;
    const nextVariation = generateNext(source, isFirst, order, isFirst);

    newVariations.push(nextVariation);

    if (isFirst) {
      const secondVariation = generateNext(source, false, 1);
      newVariations.push(secondVariation);
    }

    setFormValues((current) => ({
      ...current,
      variations: newVariations,
    }));

    handleSetCurrentVariationIdx(newVariations.length - 1);
  };

  const duplicate = (variationId: string) => {
    const { variations = [] } = block ?? {};
    const source = variations?.find((v) => v.id === variationId);

    if (!source) return;

    const newVariations: BlockVariation[] = [...variations];
    const order = variations?.length;
    const nextVariation = generateNext(source, false, order, true);

    newVariations.push(nextVariation);

    setFormValues((current) => ({ ...current, variations: newVariations }));

    handleSetCurrentVariationIdx(newVariations.length - 1);
  };

  const remove = (id: string) => {
    const { variations = [] } = block ?? {};

    if (!variations.length) {
      return;
    }

    let newVariations = [...variations].filter((v) => v.id !== id);

    const length = newVariations.length;

    if (length < 2) {
      removeAll();
      return;
    }

    setFormValues((current) => ({
      ...current,
      variations: newVariations,
    }));

    handleSetCurrentVariationIdx(length - 1);
  };

  const removeAll = () => {
    setFormValues((block) => {
      if (!block) return;
      return { ...block, variations: [] };
    });
    setCurrentVariationIdx(blockId, 0);
  };

  const variationsCount = variations?.length ?? 0;

  useEffect(() => {
    if (variationsCount === currentVariationIdx) {
      handleSetCurrentVariationIdx(variationsCount - 1);
    }
    // eslint-disable-next-line -- not interested in handleSetCurrentVariationIdx changes
  }, [currentVariationIdx, variationsCount]);

  const contextValue: VariationsTabsContextValue = useDeepCompareMemo(
    () => ({
      variationsCount,
      currentVariationIdx,
      selectedVariationId,
      variationInView: variations?.[currentVariationIdx]?.id,
      visibilityDetails: block?.visibilityComputed?.variations,
      hasLogic: !isEmpty(block?.visibility?.variations),
      removeAll,
      remove,
      duplicate,
      create,
      select,
      setCurrent,
    }),
    // eslint-disable-next-line -- Update selected variation only when switching between editing blocks
    [currentVariationIdx, selectedVariationId, variations, block],
  );

  return <VariationsTabsContext.Provider value={contextValue}>{children}</VariationsTabsContext.Provider>;
};
