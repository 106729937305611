import { isEmpty as lodashIsEmpty } from 'lodash-es';

/** Slightly extended version of `isEmpty` which takes values other than string, objects and arrays into consideration */
export const isEmptyValue = (value: unknown) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (['bigint', 'boolean', 'number', 'symbol'].includes(typeof value)) {
    return false;
  }

  return lodashIsEmpty(value);
};
