import { createContext, useContext } from 'react';

interface NavigationContextValue {
  activeSectionId?: string;
  isSectionModalOpen: boolean;
  openSectionModal: (sectionId?: string) => void;
  closeSectionModal: () => void;
}

export const NavigationContext = createContext<NavigationContextValue | null>(null);

export const useNavigation = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error(`"NavigationProvider" must be present in the DOM tree`);
  }

  return context;
};
