import type { FormCollapsedStoreSlice, SliceType } from './types';

export const createFormCollapsedSlice: SliceType<FormCollapsedStoreSlice> = (set, get) => ({
  formCollapsedSections: {},
  collapseSection: (sectionId) => {
    set({ formCollapsedSections: { ...get().formCollapsedSections, [sectionId]: true } });
  },
  resetCollapsedSections: () => {
    set({ formCollapsedSections: {} });
  },
  expandSection: (sectionId) => {
    set({ formCollapsedSections: { ...get().formCollapsedSections, [sectionId]: false } });
  },
  isSectionCollapsed: (sectionId) => {
    return get().formCollapsedSections?.[sectionId] ?? false;
  },
  toggleSectionCollapsed: (sectionId) => {
    const isCollapsed = get().isSectionCollapsed(sectionId);
    if (isCollapsed) {
      get().expandSection(sectionId);
    } else {
      get().collapseSection(sectionId);
    }
  },
  sectionExists: (sectionId) => {
    return get().formCollapsedSections?.[sectionId] !== undefined;
  },
});
