import type { FC, PropsWithChildren } from 'react';
import { LogoCollaboration } from '@src/layout/LogoCollaboration';
import { twMerge } from 'tailwind-merge';

export interface AuthCardProps {
  className?: string;
}

export const AuthCard: FC<PropsWithChildren<AuthCardProps>> = ({ children, className }) => {
  return (
    <div className="min-w-screen flex h-full min-h-screen w-full items-center justify-center bg-info-100">
      <div className="flex w-[480px] flex-col items-center justify-start gap-5 rounded bg-white p-10 shadow">
        <div className="flex items-center justify-between px-7 py-[22px]">
          <LogoCollaboration />
        </div>
        <div className="flex flex-col items-start justify-center gap-2 self-stretch pb-8">
          <div className="self-stretch text-center text-xl font-semibold leading-[25px] text-neutral-800">
            Contract Builder
          </div>
        </div>
        <div
          className={twMerge('flex items-start justify-start gap-3 self-stretch rounded-md bg-red-50 p-4', className)}
        >
          {children}
        </div>
        <div className="flex h-[53px] flex-col items-center justify-center gap-[18px] self-stretch pt-5">
          <div className="h-px self-stretch bg-info-300" />
          <div className="self-stretch text-center text-xs font-normal leading-[14px] text-info-500">
            Powered by a partnership between Artificial and PPL
          </div>
        </div>
      </div>
    </div>
  );
};
