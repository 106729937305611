import { Fragment, memo } from 'react';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useNavigation } from '@ContractBuilder/modules/navigation';
import { getSectionLabel } from '@ContractBuilder/modules/navigation/modules/section-modal/utils/get-section-label';
import { SectionForm } from '@ContractBuilder/modules/navigation/modules/section-modal/views/SectionForm';
import { useEntityStore } from '@ContractBuilder/store';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useForm } from '@hooks/form';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { useLanguage } from '@root/src/language';
import clsx from 'clsx';

import { sectionSchema } from '../schema';
import type { SectionModalFormState } from '../types';
import { getInitialValues } from '../utils/get-initial-state';

export const SectionModalController = memo(function SectionModalController() {
  const { updateSection, createSection, submission } = useEntityStore(
    ({ updateSection, createSection, submission }) => ({
      updateSection,
      createSection,
      submission,
    }),
  );

  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.editSection' });

  const { activeSectionId, isSectionModalOpen, closeSectionModal } = useNavigation();
  const isEditing = activeSectionId !== undefined;
  const modalMode = isEditing ? 'edit' : 'create';

  const handleFormSubmit = async (formValues: SectionModalFormState) => {
    if (!submission) {
      return;
    }

    const section = submission.sections.find((item) => item.id === activeSectionId);
    const { name, standardisedSectionName, shouldHideTitleInPdf, shouldResetPageCounter } = formValues;

    const payload = {
      label: getSectionLabel(name, standardisedSectionName) as string,
      standardised_name: standardisedSectionName,
      should_hide_title_in_pdf: shouldHideTitleInPdf,
      should_reset_page_counter: shouldResetPageCounter,
    };

    if (section && isEditing) {
      await updateSection(activeSectionId, payload);
    } else {
      await createSection(payload);
    }

    return closeSectionModal();
  };

  const initialState = useDeepCompareMemo(
    () => getInitialValues(submission, activeSectionId),
    [submission, activeSectionId],
  );

  const { getFieldProps, onSubmit, isLoading } = useForm<SectionModalFormState>({
    initialState,
    onSubmit: handleFormSubmit,
    validationSchema: sectionSchema,
    shouldEnableReinitialization: true,
  });

  const handleSubmit = async () => {
    await onSubmit();
  };

  return (
    <Transition.Root show={isSectionModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
        data-cypress="section-modal"
        onClose={closeSectionModal}
      >
        <div className="flex min-h-screen min-w-96 items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-7 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={closeSectionModal}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-left sm:mt-0">
                  <Dialog.Title as="h3" className="mb-6 !text-2xl !font-bold leading-6 !text-info-900">
                    {getContent(`title.${modalMode}`)}
                  </Dialog.Title>
                  <SectionForm
                    getFieldProps={getFieldProps}
                    modalMode={modalMode}
                    isLoading={isLoading}
                    onClose={closeSectionModal}
                    onSubmit={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
