import type { FC } from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import clsx from 'clsx';

interface SectionTitleProps {
  id: string;
  areBlocksEmpty: boolean;
}

export const SectionTitle: FC<SectionTitleProps> = ({ id, areBlocksEmpty }) => {
  const { submission } = useEntityStore(({ submission, updateSection }) => ({
    submission,
    updateSection,
  }));

  const section = submission?.sections.find((item) => item.id === id);

  if (!section) {
    return null;
  }

  return (
    <span
      className={clsx('grow truncate font-bold text-info-800', areBlocksEmpty ? 'opacity-25' : '')}
      title={section.label}
    >
      {section.label}
    </span>
  );
};
