import type { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@components/Button';
import { CloudUploadIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { uploadPageContainerClasses, uploadWrapperClasses } from './classes';

interface MrcDocumentUploadEmptyProps {
  error?: string;
  onFileUpload: (files: File[]) => void;
}

export const MrcDocumentUploadEmpty: FC<MrcDocumentUploadEmptyProps> = ({ error, onFileUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: onFileUpload,
    multiple: false,
  });

  return (
    <div className={clsx(uploadPageContainerClasses)}>
      <div {...getRootProps()} className={clsx(uploadWrapperClasses)}>
        <h1 className="text-2xl font-bold leading-7">MRC Extraction</h1>
        <p className="mt-2 text-sm font-normal leading-5 text-gray-700">Click upload or drag and drop your file here</p>
        <input
          {...getInputProps()}
          id="file-upload"
          name="file-upload"
          type="file"
          className="sr-only"
          data-testid="mrc-upload-input"
        />
        <Button type="button" kind="primary" className="mt-6 self-center">
          <CloudUploadIcon className="-ml-1 mr-2 h-4 w-4" />
          Upload file
        </Button>
        {error && (
          <p data-testid="mrc-upload-error" className="mt-4 text-red-500">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
