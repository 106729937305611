export type IncludeFieldSet =
  | 'audit_log'
  | 'attachments'
  | 'blocks'
  | 'comments'
  | 'revision_history'
  | 'data_extractions';

export type MethodHTTP = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export enum MrcExtractionResult {
  Aborted = 'ABORTED',
  Failed = 'FAILED',
  NotFound = 'NOT_FOUND',
  NotStarted = 'NOT_STARTED',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
  TimedOut = 'TIMED_OUT',
  Unknown = 'UNKNOWN',
}
