import type { FC } from 'react';
import { toast } from 'react-toastify';
import { DocumentStatusUpdate } from '@ContractBuilder/components/Notifications';
import { useEntityStore } from '@ContractBuilder/store';
import type { PublishingOptionType, StatusType } from '@ContractBuilder/types';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';

import { ContractTools } from '../views/ContractTools';

interface ContractToolsControllerProps {
  publishingOptions: PublishingOptionType[];
}

export const ContractToolsController: FC<ContractToolsControllerProps> = ({ publishingOptions }) => {
  const { isLoading, submission, updateResourceData } = useEntityStore(
    ({ isLoading, submission, updateResourceData }) => ({
      isLoading,
      submission,
      updateResourceData,
    }),
  );
  const isViewingRevisionHistory = useIsRevisionHistory();

  const handleStatusUpdate = async (status?: StatusType) => {
    const response = await updateResourceData({ status });
    if (response?.message !== 'Server Error' && status) {
      toast(<DocumentStatusUpdate status={status} />, { type: 'success' });
    }
  };

  if (!submission) {
    return null;
  }

  return (
    <ContractTools
      isLoading={isLoading}
      isViewingRevisionHistory={isViewingRevisionHistory}
      onStatusUpdate={handleStatusUpdate}
      publishingOptions={publishingOptions}
      submission={submission}
    />
  );
};
