import type { FC } from 'react';
import { AuthCard } from '@Auth/AuthCard';
import { mdiCloseCircle } from '@mdi/js';
import { Icon as IconMdi } from '@mdi/react';

type AuthErrorProps = {
  errorCode?: number;
};

export const AuthError: FC<AuthErrorProps> = ({ errorCode }) => {
  let content = <p>Sorry, you are not authorised, please contact your administrator to confirm your permissions.</p>;

  if (errorCode) {
    switch (errorCode) {
      case 202:
      case 203:
      case 701:
        content = <p>Sorry, your session has timed out, please close this tab and return to PPL</p>;
        break;
      case 234:
        content = <p>Sorry, this Alias is not authorised, please switch to the correct Alias and re-authenticate.</p>;
        break;
      default:
        content = (
          <>
            <p>We have encountered an error while trying to access Contract Builder.</p>
            <p className="my-5 font-bold">Error Code: {errorCode}</p>
            <p>Please contact support</p>
          </>
        );
        break;
    }
  }

  return (
    <AuthCard>
      <IconMdi path={mdiCloseCircle} className="text-error-400" size={0.8} />
      <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-2">
        <div className="self-stretch text-sm font-medium leading-tight text-red-800">{content}</div>
      </div>
    </AuthCard>
  );
};
