import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import Tooltip from '@components/Tooltip';
import { PanelPlacement } from '@ContractBuilder/hooks';
import { mdiDockLeft, mdiDockRight } from '@mdi/js';
import clsx from 'clsx';

import { placementButtonClasses } from './classes';

interface PlacementButtonProps {
  className?: string;
  onClick: (placement: PanelPlacement) => void;
  placement: PanelPlacement;
}

export const PlacementButton: FC<PlacementButtonProps> = ({ className, onClick, placement }) => {
  const icon = placement === PanelPlacement.Left ? mdiDockLeft : mdiDockRight;

  return (
    <Tooltip content={`Toggle to ${placement} side`}>
      <button
        className={clsx(placementButtonClasses, className)}
        onClick={() => onClick(placement)}
        title="Change PDF sidebar preview placement"
      >
        <IconMdi className="transition-colors hover:text-gray-400" path={icon} />
      </button>
    </Tooltip>
  );
};
