import type { Images } from '@domain/schemas/images.schema';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import {
  type ImagesPatchOperations,
  type ImagesPatchPayload,
  prepareImagesPayload,
} from '../utils/prepare-images-payload';

const IMAGES_URI = 'images';

export const updateImage = async (
  data: ImagesPatchPayload,
  operation: ImagesPatchOperations,
): Promise<Pick<Images, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `/${IMAGES_URI}/` + data.id,
    data: { ...prepareImagesPayload(data, operation), operation },
  });

  const response = await axios(networkPayload);
  return response.data;
};
