import { lazy } from 'react';

export const SECTIONS_MAP = {
  'datapoint-inserter': lazy(() =>
    import('../DatapointInserter').then(({ DatapointInserter }) => ({ default: DatapointInserter })),
  ),
  'derived-content': lazy(() =>
    import('../ClauseListInserter').then(({ ClauseListInserter }) => ({ default: ClauseListInserter })),
  ),
};
