import type { FC } from 'react';

export interface BlocksCountProps {
  count: number;
  total: number;
}

export const BlocksCount: FC<BlocksCountProps> = ({ count, total }) => {
  return (
    <div className="absolute -right-8 bottom-0 h-11 w-fit -translate-x-1/2 -translate-y-1/2 text-sm">
      <b>{count}</b> of <b>{total}</b> blocks
    </div>
  );
};
