import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { Show } from '@components/Show';
import type { DocumentContext } from '@ContractBuilder/utils/get-document-context';
import type { ResourceBlock } from '@root/@types/base';
import clsx from 'clsx';
import deepEqual from 'deep-equal';

import { BlockController } from '../../../modules/block/controller/BlockController';
import { BlockCreatorController } from '../../../modules/block-creator';
import type { EntityData, Section as SectionType } from '../../../types';

import { useBlocks } from './hooks/use-blocks';
import { sectionClasses } from './classes';
import SectionTitle from './SectionTitle';

interface SectionProps {
  context: DocumentContext;
  disableControls?: boolean;
  endorsementParent?: EntityData;
  pdfBlocks?: ResourceBlock[];
  schemaTreeForPdf?: any;
  section: SectionType;
  shouldShowCreator?: boolean;
  shouldShowDiffBlocks?: boolean;
  submission?: EntityData;
}

const Section: FC<SectionProps> = memo(function Section({
  context,
  disableControls,
  endorsementParent,
  pdfBlocks,
  schemaTreeForPdf,
  section,
  shouldShowCreator,
  shouldShowDiffBlocks = false,
  submission,
}) {
  const { id, label, should_reset_page_counter, should_hide_title_in_pdf } = section;

  const { blocks, shouldShowDiff } = useBlocks({
    section,
    submission,
    pdfBlocks,
    shouldShowDiffBlocks,
  });

  const handleClickEvents: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
  };

  if (!submission || (shouldShowDiff && blocks?.length === 0)) {
    return null;
  }

  return (
    <div id={id} className={clsx(sectionClasses.body)} onClick={handleClickEvents}>
      <div className="group relative">
        <SectionTitle
          standardisedName={section.standardised_name}
          id={id}
          shouldHideTitleInPdf={should_hide_title_in_pdf}
          shouldResetPageCounter={should_reset_page_counter}
        >
          {label}
        </SectionTitle>
        <Show when={shouldShowCreator}>
          <BlockCreatorController id={id} order={-1} sectionId={id} />
        </Show>
      </div>
      {blocks.map((block, idx) => (
        <BlockController
          block={block}
          context={context}
          disableControls={disableControls}
          endorsementParent={endorsementParent}
          key={`${block.id}`}
          order={idx}
          schemaTreeForPdf={schemaTreeForPdf}
          sectionId={id}
          submission={submission}
        />
      ))}
    </div>
  );
}, deepEqual);

export default Section;
