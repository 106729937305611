import dayjs from 'dayjs';

import { TIME_FORMAT } from '../constants';

export const getParsedValue = (value: string | undefined) => {
  const newValue = value ? dayjs(value, TIME_FORMAT) : undefined;

  if (newValue?.isValid()) {
    return newValue;
  }

  return undefined;
};
