import type { FC } from 'react';
import { InlineBlockEditController } from '@ContractBuilder/modules/inline-block-edit';
import { useEffectOnce } from '@src/hooks';

import { useBlockEdit } from '../../../block-edit';
import { useBlockCreator } from '../../context';

export const StepBlockWysiwyg: FC = () => {
  const { setOnCancelCallback } = useBlockEdit();
  const { handleAbort } = useBlockCreator();

  useEffectOnce(() => setOnCancelCallback({ fn: handleAbort }));

  return <InlineBlockEditController />;
};
