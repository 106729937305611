export const DEFAULT_CREATOR_PADDING = '!px-5';

export const animationProps = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
      },
      opacity: {
        duration: 0.5,
        delay: 0.25,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.5,
        delay: 0.25,
      },
      opacity: {
        duration: 0.3,
      },
    },
  },
  transition: {
    ease: 'easeInOut',
  },
};
