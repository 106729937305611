import type { ReactNode } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDeepCompareCallback } from '@src/hooks';
import { getScrollParent } from '@src/utils';

interface ItemWithIdentifier {
  id: string;
}

interface InfiniteLoaderProps<T> {
  data: T[];
  total: number;
  renderItem: (item: T) => ReactNode;
  step?: number;
  threshold?: number;
  onLoadMoreItems: (previouslyLoadedItems: any[]) => void;
}

export const InfiniteLoader = <T extends ItemWithIdentifier>({
  data: itemsLoaded,
  total,
  renderItem,
  threshold = 364,
  onLoadMoreItems,
}: InfiniteLoaderProps<T>) => {
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);

  const scrollParent = useMemo(() => targetElement && getScrollParent(targetElement, 'y'), [targetElement]);
  const isEnabled = total > itemsLoaded.length;

  const handleIntersection = useDeepCompareCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries.some((entry) => entry.isIntersecting);

      if (isIntersecting && isEnabled) {
        onLoadMoreItems(itemsLoaded);
      }
    },
    // eslint-disable-next-line
    [isEnabled, itemsLoaded],
  );

  const observer = useMemo(
    () =>
      new IntersectionObserver(handleIntersection, {
        root: scrollParent,
        rootMargin: `${threshold}px`,
      }),
    [handleIntersection, scrollParent, threshold],
  );

  useEffect(() => {
    if (!targetElement) {
      return;
    }

    observer.observe(targetElement);
    return () => observer.unobserve(targetElement);
  }, [observer, targetElement]);

  return (
    <>
      {itemsLoaded.map((item) => (
        <Fragment key={item.id}>{renderItem(item)}</Fragment>
      ))}
      <div ref={setTargetElement} />
    </>
  );
};
