import { resolveSchema } from '@root/helpers/schema';
import pointer from 'json-pointer';
import traverse from 'json-schema-traverse';
import { cloneDeep, get, mergeWith, set, unset } from 'lodash-es';

export const resolveRefs = (schema: any) => {
  traverse(schema, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });
};

export const resolveFieldRefs = (schema: any, field: any) => {
  const clonedField = cloneDeep(field);

  traverse(clonedField, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });

  return clonedField;
};
const getFormValuesForSection = (formValues: any, sectionIndex: any) => {
  const clonedFormValues = cloneDeep(formValues);
  clonedFormValues?.risk_details?.split_by_sections?.forEach((path: any) => {
    set(clonedFormValues, path, [get(clonedFormValues, `${path}[${sectionIndex}]`)]);
  });
  return clonedFormValues;
};

export const resolveSchemas = (schema: any, formValues: any) => {
  const sections = formValues?.risk_details?.sections ?? [];
  const parsedSections = sections?.length === 0 ? [{}] : sections;

  return parsedSections.map((val: any, key: any) => {
    return resolveSchema(cloneDeep(schema), getFormValuesForSection(formValues, key));
  });
};
