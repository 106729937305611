import { replaceMention } from '../../src/containers/ContractBuilder/utils/replace-mention';

export const replaceMentionVariables = (
  key: string | undefined,
  value: string | number | undefined,
  content = '',
): string => {
  if (key === undefined || value === undefined) {
    return content;
  }

  if (typeof key !== 'string') {
    return content;
  }

  return replaceMention(key, value, content) ?? '';
};

export const replaceUnknownMentionVariables = (content = ''): string => {
  if (content.indexOf('{{') === -1) {
    return content;
  }

  return content.replace(/\{\{[a-zA-Z0-9_\-\\.]+(\}\})?/gi, function (s) {
    const key = s.replace('{{', '').replace('}}', '');
    return `{{ ${key} }}`;
  });
};
