import { ApiPatchOperation, type BrandingDetailsType } from '@root/@types/types';
import { pick } from 'lodash-es';

export type BrandingPatchPayload = Partial<BrandingDetailsType>;

export type BrandingPatchOperations =
  | ApiPatchOperation.UpdateContent
  | ApiPatchOperation.UpdateName
  | ApiPatchOperation.UpdateStatus;

const BRANDING_PATCH_SCENARIOS_KEYS: Record<BrandingPatchOperations, (keyof BrandingDetailsType)[]> = {
  [ApiPatchOperation.UpdateName]: ['name'],
  [ApiPatchOperation.UpdateContent]: ['footer_content', 'header_content'],
  [ApiPatchOperation.UpdateStatus]: ['status'],
};

export const prepareBrandingPayload = (
  data: BrandingPatchPayload,
  operation: BrandingPatchOperations,
): BrandingPatchPayload => pick(data, [...BRANDING_PATCH_SCENARIOS_KEYS[operation], 'id']);
