import type { FC } from 'react';
import { useBlockVisibilityNamedReasons } from '@hooks/use-block-visibility-named-reasons';
import type { BlockVisibilityOutput } from '@root/@types/types';
import clsx from 'clsx';

import { VisibilityReasonEntry } from './visibility-reason-entry';

interface VisibilityReasonsListProps {
  visibilityDetails: BlockVisibilityOutput;
  className?: string;
}

export const VisibilityReasonsList: FC<VisibilityReasonsListProps> = ({ visibilityDetails, className }) => {
  const list = useBlockVisibilityNamedReasons(visibilityDetails);

  return (
    <div className={clsx('flex flex-col gap-2 overflow-y-auto pb-2 font-normal', className)}>
      {list?.map((reason, idx) => (
        <VisibilityReasonEntry key={reason.field} reason={reason} isFirst={idx === 0} match={visibilityDetails.match} />
      ))}
    </div>
  );
};
