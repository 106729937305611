import type { EntityData } from '@ContractBuilder/types';
import type { PdfDocumentType, PdfGeneratedDocumentName, Template } from '@root/@types/types';
import { v4 as uuidv4 } from 'uuid';

/**
 * @deprecated
 * @param documentName
 * @param templateId
 * @param submissionId
 * @param endorsementId
 * @returns
 */
export const getS3PathFromParameters = (
  documentName: string,
  templateId?: string,
  submissionId?: string,
  endorsementId?: string,
): string => {
  let submissionOrTemplateId = '';

  if (templateId) {
    submissionOrTemplateId = templateId;
  }
  if (submissionId && !endorsementId) {
    submissionOrTemplateId = submissionId;
  }
  if (submissionId && endorsementId) {
    submissionOrTemplateId = submissionId;
  }

  let s3FileName = '';
  if (endorsementId) {
    s3FileName = `${submissionOrTemplateId}/${endorsementId}/${documentName}`;
  } else {
    s3FileName = `${submissionOrTemplateId}/${documentName}`;
  }

  return s3FileName;
};

/**
 *
 * @param category
 * @param documentName
 * @param pdfDocumentType
 * @param submissionOrTemplateId
 * @param endorsementId
 * @returns
 */
export const createS3Path = (
  category: 'templates' | 'submissions' | 'endorsements',
  documentName: string,
  pdfDocumentType: PdfDocumentType,
  submissionOrTemplateId: string,
  endorsementId?: string,
): string => {
  switch (category) {
    case 'endorsements':
      return `${submissionOrTemplateId}/${endorsementId}/${pdfDocumentType}/${documentName}`;
    default:
      return `${submissionOrTemplateId}/${pdfDocumentType}/${documentName}`;
  }
};

/**
 * @param item
 * @returns
 */
export const getPdfDocumentName = (item: EntityData | Template): PdfGeneratedDocumentName => {
  let version = Number(item?.version ?? 1) || 1;
  if (version > Number.MAX_SAFE_INTEGER) {
    version = Number.MAX_SAFE_INTEGER;
  }
  if (version < 1) {
    version = 1;
  }

  let documentName = '';
  switch (item?.document_type) {
    case 'MRC':
    default:
      documentName = `${item?.name ?? ''} ${item?.class_of_business ?? ''}`;
  }

  const longName = `${documentName} - ${version}`;
  const shortName = `${uuidv4()}-v${version}`;

  return { shortName, longName };
};
