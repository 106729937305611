import type { Middleware } from '@floating-ui/react';
import { detectOverflow } from '@floating-ui/react';

export const autoPositionCommentPopovers: Middleware = {
  name: 'auto-position-comment-popovers',
  async fn(state) {
    const element = state.elements.floating;
    const allPopovers = document.querySelectorAll('div[data-testid="popover"]');

    const arrayOfNodes: HTMLElement[] = Array.prototype.slice.call(allPopovers);
    const index = arrayOfNodes.indexOf(element);
    const previousNodes = arrayOfNodes.slice(0, index);
    const previousNodeNotEmpty = [...previousNodes].reverse().find((element) => element.clientHeight !== 0);

    if (!previousNodeNotEmpty) {
      return {};
    }

    const overflow = await detectOverflow(state, {
      boundary: previousNodeNotEmpty ?? undefined,
    });

    const offset = previousNodeNotEmpty.clientHeight;
    const computedYResult = state.y + overflow.top + offset;

    if (computedYResult < 0) {
      return {};
    }

    return {
      y: computedYResult,
    };
  },
};
