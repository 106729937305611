import { UserRole } from '@root/@types/types';

/**
 * Used in FE
 * @param amplifyUser
 * @returns
 */
export const cognitoUserAttributes = (amplifyUser: any) => {
  const attributes = amplifyUser?.signInUserSession?.idToken?.payload;

  return {
    given_name: attributes?.given_name ?? '',
    family_name: attributes?.family_name ?? '',
    email: attributes?.email,
    sub: attributes?.sub,
    identities: attributes?.identities,
    'custom:tenantId': attributes?.['custom:tenantId'],
    'custom:tenant_ids': attributes?.['custom:tenant_ids'],
    'custom:role': attributes?.['custom:role'] || UserRole.User,
  };
};
