export const isTemplatePath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/templates');
};

export const isBlocksPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/blocks');
};
export const isBrandingPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/branding');
};

export const isSubmissionPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.startsWith('/submissions');
};

export const isEndorsementPath = () => {
  const pathname = typeof window === 'undefined' ? '' : window?.location?.pathname;
  return pathname.includes('/endorsements/');
};
