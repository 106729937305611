import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import DropdownChevron from '@components/DropdownChevron';
import { FieldSchemaIdTooltip } from '@components/FieldCdrId';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { ValidationStateIcon } from '@components/ValidationStateIcon/ValidationStateIcon';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { getRootSectionId } from '@ContractBuilder/utils/is-incomplete';
import { useFieldSchemaIds } from '@hooks/use-schema-ids';
import { mdiHelpCircleOutline } from '@mdi/js';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import H4 from '../../H4';

import { expandSectionAnimationProps } from './constants';

interface CollapsibleItemProps {
  cdrId?: string | string[];
  cdrName?: string | string[];
  mrcId?: string;
  startCollapsed?: boolean;
  ownKey: string;
  className?: string;
  id: string;
  children?: ReactNode;
  title?: ReactNode;
  helperText?: string;
  isRequired?: boolean;
}

const CollapsibleItem: FC<CollapsibleItemProps> = ({
  cdrId,
  cdrName,
  mrcId,
  className,
  id,
  isRequired = false,
  ownKey,
  startCollapsed = false,
  children,
  title,
  helperText,
}) => {
  const { schemaId, parsedCdrIds, parsedCdrNames } = useFieldSchemaIds({ cdrId, cdrName, mrcId });
  const { toggleSectionCollapsed, collapseSection, expandSection, isSectionCollapsed, sectionExists } = useEntityStore(
    (state) => state,
  );

  const { getSectionValidationState } = useSchemaStore(({ getSectionValidationState }) => ({
    getSectionValidationState,
  }));

  const { hasErrors, hasValues, errors = [] } = getSectionValidationState(ownKey);
  const rootParentSectionId = getRootSectionId(ownKey);
  const { hasErrors: rootParentHasErrors } = getSectionValidationState(rootParentSectionId);

  const isValid = !hasErrors && hasValues;
  const inProgress = hasValues;
  const isParentDirty = rootParentHasErrors && isRequired;

  const handleCollapse = () => toggleSectionCollapsed(id);

  const collapsed = isSectionCollapsed(id);
  const collapseTouched = sectionExists(id);

  useEffect(() => {
    if (collapseTouched || collapsed) {
      return;
    }

    if (startCollapsed) {
      collapseSection(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only care about `id` here
  }, [id]);

  useEffect(() => {
    if (!collapseTouched || !collapsed) {
      return;
    }

    if (!startCollapsed) {
      expandSection(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only care about `startCollapsed` here
  }, [startCollapsed]);

  return (
    <div
      className={clsx(
        'relative mb-5 rounded-lg bg-white p-6 py-6 shadow transition-all',
        !isRequired && collapsed && !hasValues && 'opacity-50',
        className,
      )}
    >
      <H4
        className={clsx('flex cursor-pointer items-center justify-between', !collapsed && 'mb-3')}
        onClick={handleCollapse}
      >
        <div className="flex items-center gap-2">
          <ValidationStateIcon
            id={ownKey}
            isDirty={inProgress}
            isValid={isValid}
            isParentDirty={isParentDirty}
            className="!bg-transparent"
            idleStateNode={<div className="h-5 w-5 rounded-full border-4 border-info-300">&nbsp;</div>}
            errors={errors}
          />
          <FieldSchemaIdTooltip
            schemaId={schemaId}
            cdrIds={parsedCdrIds}
            cdrNames={parsedCdrNames}
            fallbackName={title}
          >
            <span>{title}</span>
          </FieldSchemaIdTooltip>
          <Show when={!!helperText}>
            <TooltipedIcon
              path={mdiHelpCircleOutline}
              data-testid={`${id}:helper-text`}
              placement="top"
              content={helperText as string}
              className="mb-0.5"
            />
          </Show>
        </div>
        <DropdownChevron open={collapsed} size={1} />
      </H4>
      <AnimatePresence>
        {!collapsed ? (
          <motion.div {...expandSectionAnimationProps} key={`collapsible-item-${id}`}>
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default CollapsibleItem;
