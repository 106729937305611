import type { FC } from 'react';
import React, { useEffect } from 'react';
import Tooltip from '@components/Tooltip';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition } from '@headlessui/react';
import { controlClasses } from '@WysiwygEditor/components/Controls/classes';
import clsx from 'clsx';

interface TableControlsInnerProps {
  columns: number;
  isActive: boolean;
  isDisabled: boolean;
  onClose: () => void;
  onSelected: () => void;
  open: boolean;
  rows: number;
  select: (x: number, y: number) => void;
  x: number;
  y: number;
}

export const TableControlsInner: FC<TableControlsInnerProps> = ({
  columns,
  isActive,
  isDisabled,
  onClose,
  onSelected,
  open,
  rows,
  select,
  x,
  y,
}) => {
  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [onClose, open]);

  return (
    <div>
      <Tooltip content="Table" key="table">
        <Popover.Button disabled={isDisabled} className={controlClasses({ isActive, isDisabled })}>
          <FontAwesomeIcon icon={faTable as any} />
        </Popover.Button>
      </Tooltip>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="table-control-popover-panel absolute z-50 mt-3 px-4">
          <div className="overflow-hidden rounded-lg bg-white p-3 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="row relative flex cursor-pointer justify-start">
              {Array.from({ length: rows }, (_, i) => (
                <div key={`row-${i}`}>
                  {Array.from({ length: columns }, (_, j) => (
                    <div
                      key={`column-${j}`}
                      className={clsx(
                        'm-1 my-2 h-5 w-5 rounded-sm border',
                        i < x && j < y ? 'border-primary-500 bg-primary-100' : 'border-info-300 bg-white',
                      )}
                      onMouseOver={() => select(i + 1, j + 1)}
                      onClick={onSelected}
                    ></div>
                  ))}
                </div>
              ))}
            </div>
            <div className="flex py-3">
              <p className="m-auto text-sm font-normal">
                {y} x {x}
              </p>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </div>
  );
};
