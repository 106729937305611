export const NavigationLoading = () => {
  return (
    <div className="animate-pulse flex-col pt-3">
      <div className="flex items-center gap-x-2 p-2">
        <div className="h-4 w-4 rounded-full bg-info-200" />
        <div className="h-3 w-1/2 rounded-full bg-info-200" />
      </div>
      <div className="flex items-center gap-x-2 p-2">
        <div className="h-4 w-4 rounded-full bg-info-200" />
        <div className="h-3 w-3/4 rounded-full bg-info-200" />
      </div>
      <div className="flex items-center gap-x-2 p-2">
        <div className="h-4 w-4 rounded-full bg-info-200" />
        <div className="h-3 w-3/4 rounded-full bg-info-200" />
      </div>
      <div className="flex items-center gap-x-2 p-2">
        <div className="h-4 w-4 rounded-full bg-info-200" />
        <div className="h-3 w-1/2 rounded-full bg-info-200" />
      </div>
      <div className="flex items-center gap-x-2 p-2">
        <div className="h-4 w-4 rounded-full bg-info-200" />
        <div className="h-3 w-3/4 rounded-full bg-info-200" />
      </div>
    </div>
  );
};
