import type { FC } from 'react';
import type { PopoverClickEventHandler } from '@components/Popover';
import { Popover } from '@components/Popover';
import { VisibilityReasonsList } from '@ContractBuilder/modules/visibility-reasons/visbility-reasons-list';
import type { BlockVisibilityOutput } from '@root/@types/types';

const VARIATION_NO_CONDITIONS_COPY = 'No conditions defined for this variant';
const BLOCK_NO_CONDITIONS_COPY = 'No conditions defined for this variant';

interface VisibilityReasonsProps {
  visibilityDetails?: BlockVisibilityOutput;
  trigger: FC<{ onClick: PopoverClickEventHandler } & any>;
  mode?: 'block' | 'variation';
}

export const VisibilityReasons: FC<VisibilityReasonsProps> = ({ visibilityDetails, trigger, mode = 'block' }) => {
  return (
    <Popover id="block-visibility-reasons" shouldRenderInsidePortal renderTrigger={trigger}>
      <div className={'w-fit max-w-lg overflow-x-hidden px-3'}>
        {visibilityDetails ? (
          <>
            <div data-testid={visibilityDetails.info} className="mb-2 py-2 text-xs uppercase text-info-600">
              {visibilityDetails.info} ({visibilityDetails.mode})
            </div>
            <VisibilityReasonsList visibilityDetails={visibilityDetails} className="max-h-96" />
          </>
        ) : (
          <div className="p-5 text-xs uppercase text-info-600">
            {mode === 'block' ? BLOCK_NO_CONDITIONS_COPY : VARIATION_NO_CONDITIONS_COPY}
          </div>
        )}
      </div>
    </Popover>
  );
};
