import type { FC } from 'react';
import { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import type { InputRadioGroupProps } from '../InputRadioGroup';
import InputRadioGroup from '../InputRadioGroup';

interface InputRadioGroupSchemaProps extends InputRadioGroupProps {
  ownKey: string;
}

const InputRadioGroupSchema: FC<InputRadioGroupSchemaProps> = (props) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);

  const { value, isIncomplete } = useDatapointsStore(selector);
  return <InputRadioGroup value={value} isIncomplete={isIncomplete} {...props} />;
};

export default InputRadioGroupSchema;
