import { ELEMENT_SIZES } from '@components/shared';
import { cva } from 'class-variance-authority';

export const inputFieldClasses = {
  inner: cva(['pointer-events-none', 'absolute', 'z-10', 'flex', 'w-10', 'items-center', 'justify-center', 'p-3'], {
    variants: {
      size: ELEMENT_SIZES,
      iconPosition: {
        left: 'left-0',
        right: 'right-0',
      },
    },
  }),
};
