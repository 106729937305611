import type MentionExtension from '@tiptap/extension-mention';
import { ResizableImage } from 'tiptap-extension-resizable-image';

import type { UseEditorHookProps } from '../types';

interface UseImageExtensionProps extends Pick<UseEditorHookProps, 'enableImageInsert'> {}

type MentionExtensionReturnType = undefined | typeof MentionExtension;

export const useImageExtension = ({ enableImageInsert }: UseImageExtensionProps): MentionExtensionReturnType => {
  if (!enableImageInsert) return undefined;

  return ResizableImage.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        'data-logo-path': {
          default: null,
        },
      };
    },
  });
};
