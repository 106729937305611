import type { ReactNode } from 'react';
import { useState } from 'react';
import type { DroppableProps } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { useEffectOnce } from '@src/hooks';

interface StrictModeDroppableProps extends DroppableProps {
  fallback?: ReactNode;
}

const StrictModeDroppable = ({ children, fallback = null, ...props }: StrictModeDroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffectOnce(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  });

  if (!enabled) {
    return <>{fallback}</>;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export default StrictModeDroppable;
