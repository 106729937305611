export const OrderedListRoman = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6V8H21.5V6H9ZM9 18H21.5V16H9V18ZM9 13H21.5V11H9V13Z" fill="#374151" />
    <path d="M2.5 9H3.5V7V5H2.5V9Z" fill="#374151" />
    <path d="M2.5 14H3.5V10H2.5V14Z" fill="#374151" />
    <path d="M4.5 14H5.5V10H4.5V14Z" fill="#374151" />
    <path d="M4.5 19H5.5V15H4.5V19Z" fill="#374151" />
    <path d="M6.5 19H7.5V15H6.5V19Z" fill="#374151" />
    <path d="M2.5 19H3.5V15H2.5V19Z" fill="#374151" />
  </svg>
);
