import type { FC } from 'react';
import { mdiInboxOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import type { LinkedDatapoints } from '@root/@types/types';

import { DatapointElement } from './DatapointElement';

export interface LinkedDatapointsConfigProps {
  data?: LinkedDatapoints;
  onChange: (id: string, isRequired: boolean, variationIndex?: number) => void;
  currentVariationIdx?: number;
}

export const LinkedDatapointsConfig: FC<LinkedDatapointsConfigProps> = ({ data, onChange, currentVariationIdx }) => {
  if (!data || data.length === 0) {
    return (
      <div className="pointer-events-none flex flex-col items-center gap-1 rounded-md bg-info-50 p-3 text-center text-xs text-info-400">
        <Icon path={mdiInboxOutline} size={0.8} />
        No data points added
      </div>
    );
  }

  return (
    <div className="mb-3 flex max-h-[24vh] flex-col rounded-lg border border-info-200 text-xs shadow">
      <div className="flex justify-between bg-info-50  px-3 py-2 font-medium uppercase text-info-700">
        <span>Data point</span>
        <span>Mandatory</span>
      </div>

      <div className="flex flex-col divide-y overflow-y-auto border-t">
        {data.map((datapoint) => (
          <DatapointElement
            key={datapoint.id}
            datapoint={datapoint}
            onChange={onChange}
            currentVariationIdx={currentVariationIdx}
          />
        ))}
      </div>
    </div>
  );
};
