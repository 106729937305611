import type { FC, ReactElement, ReactNode } from 'react';
import { Children, isValidElement, useMemo } from 'react';

/**
 * Inspired by SolidJS Show component
 * https://docs.solidjs.com/references/api-reference/control-flow/Show
 */

interface ShowProps {
  when?: boolean;
  children: ReactNode;
}

interface ShowFirstMatchingProps {
  children: ReactElement<ShowProps>[] | ReactElement<ShowProps>;
  fallback?: ReactNode;
}

export const Show: FC<ShowProps> = ({ when, children }) => <>{when ? children : null}</>;

export const ShowFirstMatching: FC<ShowFirstMatchingProps> = ({ children, fallback = null }) => {
  const renderChildren = useMemo(() => {
    let matchFound = false;
    const mappedChildren = Children.map(children, (child) => {
      if (isValidElement(child) && child.props.when && !matchFound) {
        matchFound = true;
        return child;
      }
      return null;
    });
    return { matched: matchFound, children: mappedChildren };
  }, [children]);

  return <>{renderChildren.matched ? renderChildren.children : fallback}</>;
};
