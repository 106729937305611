import type { FC } from 'react';
import { cva } from 'class-variance-authority';

const loaderClasses = cva(['animate-spin'], {
  variants: {
    size: {
      xs: ['w-4 h-4'],
      sm: ['w-8 h-8'],
      md: ['w-12 h-12'],
      lg: ['w-16 h-16'],
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface SpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ size, className }) => (
  <svg
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    xmlns="http://www.w3.org/2000/svg"
    className={loaderClasses({ size, className })}
    style={{ transformOrigin: 'center' }}
  >
    <path
      d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
      opacity=".34"
      className="fill-white"
    />
    <path
      className="fill-white"
      d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
    />
  </svg>
);
