import type { BaseSyntheticEvent } from 'react';
import { useCallback } from 'react';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import {
  AS_NEW_BLOCK_PAYLOAD_KEYS,
  CREATE_LIBRARY_BLOCK_PAYLOAD_KEYS,
  CREATE_RESOURCE_BLOCK_PAYLOAD_KEYS,
  UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS,
  UPDATE_LIBRARY_BLOCK_PERMISSIONS_KEYS,
  UPDATE_RESOURCE_BLOCK_PAYLOAD_KEYS,
  UPDATE_SUMMARY_BLOCK_PAYLOAD_KEYS,
  UPDATE_TEMPLATE_BLOCK_PERMISSIONS_KEYS,
  useBlockEdit,
  useBlockEditFormStore,
  validateRequiredDatapoints,
} from '@ContractBuilder/modules/block-edit';
import { useBlockActions } from '@ContractBuilder/modules/block-edit/hooks/use-block-actions';
import { useEntityStore } from '@ContractBuilder/store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { CreateResourceBlockPayload } from '@root/@types/types';
import type { WithRequired } from '@root/@types/utilities';
import { isNonNullish } from '@root/helpers';
import { performVisibilityConfigUpdates } from '@root/helpers/blocks/perform-visibility-config-updates';
import { upliftVariation } from '@root/helpers/variations';
import { isBlocksPath, isTemplatePath } from '@utils/app-paths';
import { isEmpty, pick } from 'lodash-es';

type BlockEditSubmitHandler = (state: BlockEditFormState, event?: BaseSyntheticEvent) => Promise<boolean> | boolean;

export const useOnBlockEditSubmit = () => {
  const { submission } = useEntityStore(({ submission }) => ({ submission }));
  const { currentBlock, reset } = useBlockEditFormStore(({ currentBlock, reset }) => ({
    currentBlock,
    reset,
  }));
  const { setBlockUsedInTemplatesModalVisibility } = useUIStore(({ setBlockUsedInTemplatesModalVisibility }) => ({
    setBlockUsedInTemplatesModalVisibility,
  }));

  const {
    addBlockFromLibrary,
    createBlock,
    createBlockInLibrary,
    createSummaryBlock,
    replaceBlockForBlockInLibrary,
    updateBlock,
    updateBlockInLibrary,
    updateSummaryBlock,
  } = useBlockActions();
  const { onMandatoryDatapointsModalOpen, resetState } = useBlockEdit();
  const { closeDrawer } = useDrawerStore(({ closeDrawer }) => ({ closeDrawer }));
  const isEndorsementView = useUIStore(({ isEndorsementView }) => isEndorsementView);

  const isBlocks = isBlocksPath();
  const isTemplate = isTemplatePath();
  const isAdmin = isTemplate || isBlocks;

  const handleResetState = useCallback(() => {
    closeDrawer();
    resetState();
    return reset();
  }, [closeDrawer, reset, resetState]);

  const performModalTriggerChecks = useCallback(
    (parsedFormValues: BlockEditFormState) => {
      const isBlockUsedInTemplatesModalOpen = useUIStore.getState().isBlockUsedInTemplatesModalOpen;
      const missingMandatoryDatapoints = validateRequiredDatapoints(currentBlock, parsedFormValues);

      if (!isAdmin && missingMandatoryDatapoints.list?.length) {
        onMandatoryDatapointsModalOpen();
        return false;
      }

      const { usedInTemplates = [] } = parsedFormValues;
      const notCurrentTemplate = ({ id }: { id: string }) => id !== submission?.id;

      const isUsedElsewhere = usedInTemplates.filter(notCurrentTemplate).length > 0;

      if (isAdmin && isUsedElsewhere && !isBlockUsedInTemplatesModalOpen) {
        setBlockUsedInTemplatesModalVisibility(true);
        return false;
      }

      return true;
    },
    [currentBlock, isAdmin, onMandatoryDatapointsModalOpen, setBlockUsedInTemplatesModalVisibility, submission?.id],
  );

  const getBlockPermissionsPayload = useCallback(
    (values: BlockEditFormState) => {
      if (isBlocks) {
        return pick(values, UPDATE_LIBRARY_BLOCK_PERMISSIONS_KEYS);
      }

      return pick(values, UPDATE_TEMPLATE_BLOCK_PERMISSIONS_KEYS);
    },
    [isBlocks],
  );

  const handleCreateBlock = useCallback(
    async (values: BlockEditFormState) => {
      const { canDelete, canEdit, canDeleteOnTemplate, order = 0 } = values;

      if (isEndorsementView) {
        await createSummaryBlock(values);
        return;
      }

      if (isAdmin) {
        const createPayload = pick(values, CREATE_LIBRARY_BLOCK_PAYLOAD_KEYS);
        const blockLibraryId = await createBlockInLibrary(createPayload);

        if (blockLibraryId) {
          await addBlockFromLibrary({
            canDelete,
            canDeleteOnTemplate,
            canEdit,
            selectedVariationId: values.selectedVariationId,
            blockLibraryId,
            sectionId: values.section_id,
            order: order + 1,
          });
        }
      } else {
        const createPayload = pick(values, CREATE_RESOURCE_BLOCK_PAYLOAD_KEYS);
        await createBlock({ ...createPayload, content: values.content ?? '' } as CreateResourceBlockPayload, order);
      }
    },
    [addBlockFromLibrary, createBlock, createBlockInLibrary, createSummaryBlock, isAdmin, isEndorsementView],
  );

  const handleUpdateBlock = useCallback(
    async (values: WithRequired<BlockEditFormState, 'id'>) => {
      const blockPermissionsPayload = getBlockPermissionsPayload(values);
      const isBlockFromLibrary = Boolean(isAdmin && values?.blockLibraryId);
      const visibilityParsedPayload = performVisibilityConfigUpdates(values);

      const { id } = values;

      if (isEndorsementView) {
        const payload = pick(values, UPDATE_SUMMARY_BLOCK_PAYLOAD_KEYS);
        await updateSummaryBlock({ ...payload, blockId: id });
        return;
      }

      if (isBlockFromLibrary || isBlocks) {
        const updatePayload = {
          ...pick(visibilityParsedPayload, UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS),
          variations: visibilityParsedPayload.variations ?? [],
        };
        await updateBlockInLibrary(visibilityParsedPayload.blockLibraryId ?? id, updatePayload, id);

        if (isTemplate) {
          await updateBlock({
            ...blockPermissionsPayload,
            id,
            selectedVariationId: visibilityParsedPayload.selectedVariationId,
            section_id: visibilityParsedPayload.section_id,
            visibility: visibilityParsedPayload.visibility,
          });
        }

        setBlockUsedInTemplatesModalVisibility(false);
      } else {
        const updatePayload = {
          ...pick(visibilityParsedPayload, UPDATE_RESOURCE_BLOCK_PAYLOAD_KEYS),
          id,
          variations: visibilityParsedPayload.variations ?? [],
        };
        await updateBlock(updatePayload);
      }

      handleResetState();
      return true;
    },
    [
      getBlockPermissionsPayload,
      handleResetState,
      isAdmin,
      isBlocks,
      isEndorsementView,
      isTemplate,
      setBlockUsedInTemplatesModalVisibility,
      updateBlock,
      updateBlockInLibrary,
      updateSummaryBlock,
    ],
  );

  const onSubmit: BlockEditSubmitHandler = useCallback(
    async (state) => {
      const isLoading = useEntityStore.getState().isLoading;

      if (isLoading) {
        return true;
      }

      const checksPassed = performModalTriggerChecks(state);

      if (!checksPassed) {
        return false;
      }

      const parsedFormValues = upliftVariation(state, state.selectedVariationId);
      const { id } = parsedFormValues;

      const blockExists = !isEmpty(id) && isNonNullish(id);

      if (!blockExists) {
        await handleCreateBlock(parsedFormValues);
      } else {
        await handleUpdateBlock({ ...parsedFormValues, id });
      }

      handleResetState();
      return true;
    },
    // eslint-disable-next-line -- We don't care about functions changing
    [isAdmin, submission?.id],
  );

  const onSubmitAsNew: BlockEditSubmitHandler = useCallback(async (state) => {
    if (!state) {
      return true;
    }

    if (!isTemplate || !state?.id || !state?.blockLibraryId) {
      return true;
    }

    const newBlockPayload = {
      ...pick(state, AS_NEW_BLOCK_PAYLOAD_KEYS),
      name: state.name ? `${state.name} (copy)` : undefined,
    };

    const { section_id: sectionId, canDeleteOnTemplate } = state;
    const order = submission?.sections
      ?.find((section) => section.id === state.section_id)
      ?.layout?.findIndex((blockId) => blockId === state.id);

    const blockLibraryId = await createBlockInLibrary(newBlockPayload);
    await replaceBlockForBlockInLibrary(state.id, { ...state, sectionId, blockLibraryId, order, canDeleteOnTemplate });

    handleResetState();
    return true;
    // eslint-disable-next-line -- We don't care about function-like dependencies of this hook
  }, []);

  return { onSubmit, onSubmitAsNew };
};
