import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { get, isEqual, set } from 'lodash-es';
import type { SchemaOf } from 'yup';

import type {
  FormError,
  FormHookArgs,
  FormHookPayload,
  FormValuesPlaceholderType,
  GetFieldPropsFn,
  HandleChangeFn,
  HandleSubmitFn,
} from './types';

const checkHasSchema = <T>(schema?: SchemaOf<T> | undefined): schema is SchemaOf<T> => {
  if (!schema) {
    return false;
  }

  return Object.keys(schema).length > 0;
};

const isFormEvent = (value: unknown): value is FormEvent => {
  return (value as FormEvent)?.target !== undefined;
};

export const useForm = <T = FormValuesPlaceholderType, AdditionalParams = undefined>({
  initialState,
  validationSchema,
  validationOptions,
  onSubmit,
  shouldEnableReinitialization = false,
}: FormHookArgs<T, AdditionalParams>): FormHookPayload<T, AdditionalParams> => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<T | undefined>(initialState);
  const [formError, setFormError] = useState<string | never>();
  const [formErrors, setFormErrors] = useState<FormError[] | null>();
  const [validationErrors, setValidationErrors] = useState([]);

  const hasChanges = !isEqual(initialState, formValues);
  const hasSchema = checkHasSchema(validationSchema);
  const isValid = !hasSchema || (hasSchema && validationSchema.isValidSync(formValues, validationOptions));
  const canSubmit = !isLoading && hasChanges && (!hasSchema || isValid);

  const handleChange: HandleChangeFn<T> = (eventOrValue, fieldName) => {
    const isEvent = isFormEvent(eventOrValue);
    const target = isEvent ? (eventOrValue.target as HTMLInputElement) : undefined;

    const isCheckbox = isEvent ? target?.type === 'checkbox' : false;
    const isChecked = isEvent ? target?.checked : undefined;
    const value = isEvent ? target?.value : eventOrValue;
    const name = (isEvent ? target?.name : fieldName) as keyof T;
    const finalValue = isCheckbox ? isChecked : value;

    setFormValues((current) => {
      return {
        ...set(structuredClone({ ...current }), name, finalValue),
      } as T;
    });
  };

  const handleSubmit: HandleSubmitFn<AdditionalParams> = async (params) => {
    setLoading(true);

    try {
      await onSubmit(formValues as T, params);
    } catch (error) {
      return setFormError(get(error, 'message'));
    } finally {
      setLoading(false);
    }
  };

  const getFieldProps: GetFieldPropsFn<T> = (name) => {
    return { name, value: get(formValues, name, ''), onChange: handleChange };
  };

  const handleReset = (shouldForceEmptyState = false) => {
    return setFormValues(shouldForceEmptyState ? undefined : initialState);
  };

  useEffect(() => {
    if (validationSchema) {
      try {
        validationSchema.validateSync(formValues, validationOptions);
        setValidationErrors([]);
      } catch (error: any) {
        // probably "ValidationError" from yup
        setValidationErrors(error?.errors);
      }
    }
    // eslint-disable-next-line -- Validation schema and options won't be changing dynamically
  }, [formValues]);

  useEffect(() => {
    if (!shouldEnableReinitialization) {
      return;
    }

    if (!isEqual(formValues, initialState)) {
      setFormValues(initialState);
    }
    // eslint-disable-next-line -- We only want to reinitialize the form when the initial state changes
  }, [initialState, shouldEnableReinitialization]);

  return {
    canSubmit,
    formValues,
    formError,
    formErrors,
    validationErrors,
    hasChanges,
    isLoading,
    isValid,
    onSubmit: handleSubmit,
    getFieldProps,
    reset: handleReset,
    setFormError,
    setFormErrors,
    setFormValues,
  };
};
