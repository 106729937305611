import type { StateFromBlock } from '@ContractBuilder/modules/block-edit';
import { isNonNullish } from '@root/helpers';
import { get, isEmpty } from 'lodash-es';

import { isConditionValid } from '../utils/is-condition-valid';

export interface UseVisibilityFormStateArgs {
  formValues: Pick<StateFromBlock, 'visibility' | 'variations'>;
}

export const useVisibilityFormState = ({ formValues }: UseVisibilityFormStateArgs): boolean => {
  const { visibility, variations = [] } = formValues;
  // the -1 is to account for the "default" variation
  const variantsCount = variations.length - 1;

  if (variantsCount > 0) {
    const variantsLogic = get(visibility, 'variations', []).filter(isNonNullish);

    if (isEmpty(variantsLogic)) return true;
    if (variantsLogic.length !== variantsCount) return false;

    return variantsLogic.flatMap((variation) => get(variation, 'conditions', [])).every(isConditionValid);
  }

  return get(visibility, 'block.conditions', []).every(isConditionValid);
};
