import ListItemExtension from '@tiptap/extension-list-item';
import type { SelectionRange } from '@tiptap/pm/state';
import type { Node } from 'prosemirror-model';

export const ListItem = ListItemExtension.extend({
  addKeyboardShortcuts() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      Tab: () => {
        return false;
      },
      'Shift-Tab': () => {
        return false;
      },
    };
  },
  addCommands() {
    return {
      setMarkerSize:
        (fontSize: string, depth: number = 0) =>
        ({ commands }: any) => {
          // work around to prevent command bubbling to parents https://github.com/ueberdosis/tiptap/issues/3545
          commands.command(({ tr, state, dispatch }: any) => {
            if (dispatch) {
              tr.selection.ranges.forEach((range: SelectionRange) => {
                const selectionLength = range.$to.pos - range.$from.pos;

                if (selectionLength === 0) {
                  // Skip marker size change if nothing is selected
                  return;
                }

                state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node: Node, pos: number) => {
                  const sharedDepth = range.$from.sharedDepth(pos);

                  if (this.type === node.type && sharedDepth >= depth - 1) {
                    return tr.setNodeAttribute(pos, 'markerSize', fontSize);
                  }
                });
              });
            }
          });
        },
    };
  },
  addAttributes() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      markerSize: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-marker-size'),
        renderHTML: ({ markerSize }: { markerSize: string }) => {
          if (!markerSize) {
            return {};
          }
          return { 'data-marker-size': markerSize, style: `--marker-size: ${markerSize}` };
        },
      },
      style: {
        parseHTML: () => {
          return '';
        },
      },
    };
  },
});
