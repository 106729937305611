import IconMdi from '@components/IconMdi';
import { mdiAlert } from '@mdi/js';

export const isPasswordExpired = (message?: string) =>
  message === 'Temporary password has expired and must be reset by an administrator.';

export const PasswordExpiredBanner = () => (
  <div className="mb-3 flex w-full items-start gap-4 rounded-md bg-error-50 p-4">
    <IconMdi centerVertical={false} path={mdiAlert} className={'text-error-500'} />
    <div className="flex flex-col gap-2 text-error-800">
      <div className="flex items-center gap-3  text-sm font-medium leading-tight ">
        Your temporary password has expired
      </div>
      <p className="text-sm leading-tight">Please contact an administrator to request a new password.</p>
    </div>
  </div>
);
