import type { BaseUser, Team, User } from '@root/@types/types';
import { UserRole } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import { isNonNullish } from '@root/helpers';
import { get } from 'lodash-es';

const TEAMS = tenantConfig.teams ?? [];

export const getUserRole = (user: User | BaseUser | null): UserRole | undefined =>
  get(user, 'attributes["custom:role"]') ?? get(user, 'role');

export const getUserTeamNames = (user: BaseUser | null, showAllIfUserNotAssignedToAny = false): string[] => {
  const tenantIds = user?.tenantIds ?? [];
  const userTeams = tenantIds
    .map((teamId: number) => TEAMS?.find((team) => team.id === teamId)?.name ?? '')
    .filter((i: string) => i);

  if (showAllIfUserNotAssignedToAny && userTeams.length === 0) {
    return TEAMS.map((team) => team.name);
  }

  return userTeams;
};

export const getUserTeams = (user: BaseUser | null, showAllIfUserNotAssignedToAny = false): Team[] => {
  const tenantIds = user?.tenantIds ?? [];
  const userTeams = tenantIds.map((teamId: number) => TEAMS?.find((team) => team.id === teamId)).filter(isNonNullish);

  if (showAllIfUserNotAssignedToAny && userTeams.length === 0) {
    return TEAMS;
  }

  return userTeams;
};

export const getAllTeams = (): Team[] => TEAMS;

/**
 *
 * @param user - BaseUser object
 * @param matchStrict - if true, only UserRole.Admin will be considered as an admin
 */
export const isAdmin = (user: User | BaseUser | null, matchStrict: boolean = false) => {
  const role = getUserRole(user);

  if (!role) {
    return false;
  }

  if (matchStrict) {
    return role === UserRole.Admin;
  }

  return [UserRole.Admin, UserRole.Superadmin, UserRole.TemplateManager].includes(role);
};

export const isSuperadmin = (user: User | BaseUser | null) => {
  const role = getUserRole(user);
  return role === UserRole.Superadmin;
};
