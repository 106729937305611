import { cva } from 'class-variance-authority';

export const fileNameTabContainerClasses = cva(
  [
    'bg-gray-50',
    'border border-gray-200',
    'cursor-pointer',
    'flex items-center gap-3',
    'h-[41px]',
    'px-3 py-2',
    'truncate',
    'transition',
    'text-gray-500',
    'hover:text-gray-700',
  ],
  {
    variants: {
      isActive: {
        true: 'bg-white border-b-white z-10 translate-y-[1px] text-gray-700',
        false: 'border-b-0',
      },
    },
  },
);

export const placementButtonClasses = [
  'border-r border-gray-200',
  'cursor-pointer',
  'flex items-center justify-center',
  'px-3.5 py-1',
];

export const pdfDocumentClasses = [
  'bg-gray-200',
  'border border-gray-200',
  'flex grow flex-col gap-3.5',
  'h-full w-full',
  'overflow-y-auto overflow-x-hidden',
  'relative',
];
