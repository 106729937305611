import type { ResourceBlock } from '@root/@types/base';
import { find, isNil, mergeWith, pick } from 'lodash-es';

import { DEFAULT_VARIATION_ID, OLD_DEFAULT_VARIATION_ID } from './constants';

export const VARIATION_REQUIRED_PICKS = ['content', 'linkedDatapoints', 'repeaterIds', 'title', 'type'];

type UpliftVariationInput = Pick<ResourceBlock, 'selectedVariationId' | 'variations'>;

type ResourceBlockProperty = ResourceBlock[keyof ResourceBlock];
const mergeVariationCustomizer = (obj: ResourceBlockProperty, src: ResourceBlockProperty) => {
  if (!isNil(src)) {
    return src;
  }
  return obj;
};

export const upliftVariation = <T extends UpliftVariationInput>(
  values: T,
  newSelectedVariationId = DEFAULT_VARIATION_ID,
): T => {
  if (!values?.variations) {
    return values;
  }

  const block = structuredClone(values);
  const { variations } = block;

  const parsedNewSelectedVariationId = newSelectedVariationId;
  const variation =
    find(variations, { id: parsedNewSelectedVariationId }) || find(variations, { id: OLD_DEFAULT_VARIATION_ID });

  if (!variation) {
    return values;
  }

  const newBlockValues = pick(variation, VARIATION_REQUIRED_PICKS);
  return mergeWith(
    block,
    newBlockValues,
    { selectedVariationId: parsedNewSelectedVariationId },
    mergeVariationCustomizer,
  );
};
