import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import Tooltip from '@root/src/components/Tooltip';

import { controlClasses } from '../../classes';
import { TooltipIcon } from '../TooltipIcon';

import { controlRowIconBackgroundClasses } from './classes';

interface ControlRowOptionParentProps {
  icon: string;
  title: string;
  isDisabled?: boolean;
  onClick?: () => void;
  isOpen?: boolean;
}

export const ControlRowOptionParent = ({
  isDisabled = false,
  title,
  onClick,
  isOpen,
  icon,
}: ControlRowOptionParentProps) => {
  return (
    <div className="flex content-center">
      <div className={controlRowIconBackgroundClasses({ isOpen })}>&nbsp;</div>
      <Tooltip content={title}>
        <button
          onClick={onClick}
          disabled={isDisabled}
          className={controlClasses({
            isActive: isOpen,
            isDisabled,
            hasDropdown: true,
          })}
        >
          <TooltipIcon icon={icon} />
          <Icon path={mdiChevronDown} size={'18px'} className="-mx-1 -my-1" />
        </button>
      </Tooltip>
    </div>
  );
};
