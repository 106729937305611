import type { IncludeFieldSet } from '@root/@types/api';
import { getFeatureFlag } from '@root/helpers/feature-flags';

export const getResourcesListToFetch = (
  isEndorsement: boolean,
  isViewingRevisionHistory: boolean,
): IncludeFieldSet[] => {
  const hasAttachmentsFeature = getFeatureFlag('Attachments');
  const hasRevisionsFeature = getFeatureFlag('Revisions');
  const hasCommentsFeature = getFeatureFlag('Comments');
  const hasDataExtractionFeature = getFeatureFlag('MrcExtraction');

  const include: IncludeFieldSet[] = ['blocks'];

  if (hasAttachmentsFeature) {
    include.push('attachments');
  }

  if (hasCommentsFeature) {
    include.push('comments');
  }

  if (hasDataExtractionFeature) {
    include.push('data_extractions');
  }

  if (isViewingRevisionHistory && hasRevisionsFeature) {
    include.push('revision_history');
  }

  return include;
};
