import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const formatTime = (value?: string, format = 'HH:mm') => {
  const parsedTime = dayjs(value, 'HH:mm:ss.SSSZ');
  if (!parsedTime.isValid()) {
    if (!process.env.CI) {
      console.error(`error: formatTime: Incorrect time: ${value}`);
    }
    return value;
  }

  const timeWithoutTimezone = value?.substring(0, value?.length - 6);
  const parsedTimeWithoutTimezone = dayjs(timeWithoutTimezone, 'HH:mm:ss.SSS');

  if (!parsedTimeWithoutTimezone.isValid()) {
    if (!process.env.CI) {
      console.error(`error: formatTime: Incorrect time: ${value}`);
    }
    return value;
  }

  return parsedTimeWithoutTimezone.format(format);
};
