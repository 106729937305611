import type { FC } from 'react';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { isTemplatePath } from '@utils/app-paths';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface DatapointsProgressIndicatorProps {
  isValid?: boolean;
}

const innerIconClasses = cva([], {
  variants: {
    isValid: {
      true: 'text-success-400',
      false: 'text-warning-400',
    },
  },
});

export const DatapointsProgressIndicator: FC<DatapointsProgressIndicatorProps> = ({ isValid }) => {
  const isTemplate = isTemplatePath();

  if (isTemplate) {
    return null;
  }

  const IconComponent = isValid ? CheckCircleIcon : InformationCircleIcon;

  return (
    <div className="absolute right-0 top-0 h-4 w-4 -translate-y-[4px] translate-x-[4px]">
      <div className="rounded-full bg-white p-[1px]">
        <div className={clsx('rounded-full border border-white')}>
          <IconComponent className={innerIconClasses({ isValid })} />
        </div>
      </div>
    </div>
  );
};
