import type { FC, ReactNode } from 'react';
import Tooltip from '@components/Tooltip';
import type { TippyProps } from '@tippyjs/react';

import { Show } from './Show';

export interface FieldSchemaIdTooltipProps {
  cdrIds?: string[];
  cdrNames?: string[];
  schemaId?: string;
  fallbackName?: ReactNode;
  children: TippyProps['children'];
  /**
   * Tooltip won't be rendered. Already takes into account `cdrId` and `cdrName` props.
   * Implemented as: `(!cdrId && !cdrName) || isDisabled`;
   */
  isDisabled?: boolean;
}

export const FieldSchemaIdTooltip: FC<FieldSchemaIdTooltipProps> = ({
  cdrIds,
  cdrNames,
  schemaId,
  children,
  fallbackName,
  isDisabled,
}) => {
  if ((!cdrIds?.length && !cdrNames?.length) || isDisabled) return <>{children}</>;

  return (
    <Tooltip
      content={cdrNames?.map((name, idx) => {
        const parsedSchemaId = cdrIds?.[idx] ?? schemaId;
        return (
          <div key={parsedSchemaId}>
            {name ?? fallbackName}
            <Show when={!!parsedSchemaId}>
              <span className="pl-1 text-xs text-info-400"> {parsedSchemaId}</span>
            </Show>
          </div>
        );
      })}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
