export const BlockEditModalLoadingView = () => {
  return (
    <div className="page-template relative mt-2 flex h-[708px] w-full border-t" aria-live="polite">
      <div className="relative flex w-[794px] flex-col">
        <div className="z-50 flex h-[51px] min-h-[51px] w-full flex-wrap items-center space-x-3 overflow-visible border-b border-info-200 bg-white p-2">
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-full w-0 border-l border-info-300"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-full w-0 border-l border-info-300"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-full w-0 border-l border-info-300"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-full w-0 border-l border-info-300"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-full w-0 border-l border-info-300"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-5 animate-pulse rounded bg-slate-200"></div>
          <div className="h-5 w-20 animate-pulse rounded bg-slate-200"></div>
        </div>
        <div className="page-A4-width relative mt-2 flex cursor-text px-mrc-block-padding py-3 opacity-100 transition-colors duration-500">
          <div className="min-w-mrc-block-title mr-mrc-block-grid-cell h-5 w-mrc-block-title animate-pulse resize-none break-words rounded border-none bg-slate-200 text-xs font-semibold uppercase leading-normal text-info-600 outline-none placeholder:pt-1 placeholder:text-xs placeholder:uppercase placeholder:text-info-300"></div>
          <div className="flex w-full flex-col gap-y-2">
            <div className="block-content relative h-5 w-full animate-pulse overflow-y-auto rounded bg-slate-200"></div>
            <div className="block-content relative h-5 w-[50%] animate-pulse overflow-y-auto rounded bg-slate-200"></div>
            <div className="block-content relative h-5 w-[75%] animate-pulse overflow-y-auto rounded bg-slate-200"></div>
          </div>
        </div>
        <div className="justify-right absolute bottom-0 right-0 flex w-full gap-3 border-t p-4">
          <div className="mr-auto h-8 w-28 animate-pulse rounded-md bg-error-700 px-4 shadow-sm"></div>
          <div className="h-8 w-20 animate-pulse rounded-md border border-info-300 bg-white px-4 shadow-sm"></div>
          <div className="h-8 w-16 animate-pulse rounded-md bg-primary-600 px-4 shadow-sm"></div>
        </div>
      </div>
      <div className="w-[280px] border-l">
        <div className="relative z-50 flex h-[51px] min-h-[51px] w-full flex-wrap items-center space-x-3 overflow-visible border-b border-info-200 bg-white p-2">
          <div className="h-5 w-1/2 animate-pulse rounded bg-slate-200"></div>
        </div>
      </div>
    </div>
  );
};
