import React, { forwardRef } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { cva } from 'class-variance-authority';

const textAreaClasses = cva(
  [
    'appearance-none',
    'block',
    'border-info-300',
    'border',
    'focus:border-primary-500',
    'focus:ring-primary-500',
    'focus:shadow-outline',
    'px-3',
    'py-2',
    'rounded-md',
    'shadow-sm',
    'text-sm',
    'text-info-700',
    'w-full',
  ],
  {
    variants: {
      isIncomplete: { true: 'field-incomplete' },
    },
  },
);

export interface InputTextareaProps
  extends Omit<InputFieldProps, 'children' | 'size'>,
    React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  errors?: string;
  id?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
}
const InputTextarea = forwardRef<HTMLTextAreaElement, InputTextareaProps>(function InputTextArea(props, ref) {
  const {
    cdrId,
    cdrName,
    className,
    descriptionText,
    errors,
    id,
    isDisabled,
    isReadOnly,
    isRequired,
    helperText,
    labelIcon,
    labelText,
    mrcId,
    name,
    shortName,
    value,
    isIncomplete,
    hideErrors,
    ...rest
  } = props;

  const disabled = isDisabled || isReadOnly;
  const _id = id ?? name;

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={_id}
      isRequired={isRequired}
      helperText={helperText}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      shortName={shortName}
      isIncomplete={isIncomplete}
      hideErrors={hideErrors}
    >
      <textarea
        {...rest}
        data-testid={`textarea in ${name}`}
        className={textAreaClasses({ isIncomplete })}
        id={_id}
        name={name}
        required={isRequired}
        value={value}
        rows={3}
        disabled={disabled}
        ref={ref}
      />
    </InputField>
  );
});

export default InputTextarea;
