import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import { getCurrentDocumentApiUrl } from './getCurrentDocumentApiUrl';

const IMAGES_URI = 'images';

interface ImagesPresignedUrlResponse {
  url: string;
  id: string;
}

export const getImagesPresignedUrl = async (
  fileName: string,
  size: number,
  type: string,
  templateId?: string,
  submissionId?: string,
  endorsementId?: string,
): Promise<ImagesPresignedUrlResponse | undefined> => {
  const url = [getCurrentDocumentApiUrl(templateId, submissionId, endorsementId), IMAGES_URI].join('/');
  if (!url) {
    return undefined;
  }

  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url,
    data: {
      fileName,
      size,
      type,
    },
  });

  const response = await axios(networkPayload);
  return response.data;
};
