import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useInterval } from 'react-use';
import { useEntityStore } from '@ContractBuilder/store';
import { isFinalDataExtractionStatus } from '@ContractBuilder/utils/get-pending-data-extraction';
import { getDataExtractionResult } from '@queries/getDataExtractionResult';
import { MrcExtractionResult } from '@root/@types/api';
import { Line } from 'rc-progress';

interface ContractBuilderDataExtractionLoaderProps {
  extractionId: string;
  submissionId: string;
}

const PROCESSING_SECONDS_PER_PAGE = 5.2;
const TOTAL_PAGES_PLACEHOLDER = 28;
const INITIAL_PERCENTAGE_START = 2;

export const ContractBuilderDataExtractionLoader: FC<ContractBuilderDataExtractionLoaderProps> = ({
  extractionId,
  submissionId,
}) => {
  const { reloadSubmission } = useEntityStore(({ reloadSubmission }) => ({ reloadSubmission }));
  const [percentage, setPercentage] = useState(INITIAL_PERCENTAGE_START);

  const percentageStep = Math.floor(100 / TOTAL_PAGES_PLACEHOLDER);
  const isRunning = percentage < 99;

  // Keep polling to trigger the Contract View to refetch once the extraction data gets populated in the db
  const { data } = useQuery('mrc-extraction-result', () => getDataExtractionResult(submissionId, extractionId), {
    refetchOnWindowFocus: false,
    refetchInterval: (data) => (isFinalDataExtractionStatus(data?.status) ? false : 1000),
  });

  useEffect(() => {
    if (isFinalDataExtractionStatus(data?.status)) {
      reloadSubmission();
    }

    if (data?.status === MrcExtractionResult.Failed) {
      toast(
        <div className="text-sm">
          <h3 className="self-center font-medium leading-relaxed text-info-700">Data extraction has failed.</h3>
          <p className="whitespace-nowrap text-info-400">Please try again later.</p>
        </div>,
        { type: 'error' },
      );
    }
  }, [data?.status, reloadSubmission]);

  useInterval(
    () => {
      setPercentage((prev) => Math.min(prev < 99 ? prev + percentageStep : 99, 99));
    },
    isRunning ? PROCESSING_SECONDS_PER_PAGE * 1000 : null,
  );

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-y-4">
      <div role="status" className="mb-2 flex w-96 items-center gap-x-2">
        <Line className="[&>:nth-child(2)]:animate-pulse" percent={percentage} strokeWidth={2} strokeColor="#3B82F6" />
        <span className="font-bold text-info-400">{percentage}%</span>
      </div>
      <p className="font-normal leading-3">Extracting your data...</p>
    </div>
  );
};
