import type { FC, ReactNode } from 'react';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import type { FormError } from '@root/@types/types';

import { Show, ShowFirstMatching } from '../Show';
import Tooltip from '../Tooltip';

import { containerClasses, validationStateIconClasses } from './classes';
import { ErrorsList } from './ErrorsList';
import { getValidationState } from './getValidationState';

interface ValidationStateIconProps {
  id?: string;
  isValid?: boolean;
  isDirty?: boolean;
  isParentDirty?: boolean;
  idleStateNode?: ReactNode;
  className?: string;
  withMarginLeft?: boolean;
  disableTooltip?: boolean;
  errors?: FormError[];
}

export const ValidationStateIcon: FC<ValidationStateIconProps> = ({
  className,
  id,
  isValid,
  isDirty,
  isParentDirty,
  idleStateNode = null,
  withMarginLeft,
  errors,
  disableTooltip,
}) => {
  const state = getValidationState({ isValid, isParentDirty, isDirty });
  const hasErrors = Boolean(errors?.length);

  return (
    <Tooltip
      content={
        <ShowFirstMatching>
          <Show when={state === 'valid'}>Heading complete</Show>
          <Show when={state === 'dirty'}>
            <ErrorsList sectionId={id} errors={errors} />
          </Show>
          <Show when={state === 'parentDirty'}>
            <ShowFirstMatching>
              <Show when={hasErrors}>
                <ErrorsList sectionId={id} errors={errors} />
              </Show>
              <Show when={true}>Heading incomplete</Show>
            </ShowFirstMatching>
          </Show>
          <Show when={true}>Not started</Show>
        </ShowFirstMatching>
      }
      disabled={disableTooltip}
    >
      <div className={containerClasses({ state, className, withMarginLeft })}>
        <ShowFirstMatching>
          <Show when={state === 'valid'}>
            <CheckCircleIcon className={validationStateIconClasses({ color: 'success' })} strokeWidth="3" />
          </Show>
          <Show when={state === 'dirty'}>
            <InformationCircleIcon className={validationStateIconClasses({ color: 'warning' })} strokeWidth="3" />
          </Show>
          <Show when={state === 'parentDirty'}>
            <InformationCircleIcon className={validationStateIconClasses({ color: 'warning' })} strokeWidth="3" />
          </Show>
          <Show when={true}>{idleStateNode}</Show>
        </ShowFirstMatching>
      </div>
    </Tooltip>
  );
};
