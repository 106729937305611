import type { MouseEvent } from 'react';
import Button from '@components/Button';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { INLINE_BLOCK_EDITOR_NAME, useEditorsMap } from '@ContractBuilder/modules/editors-map';
import { useEntityStore } from '@ContractBuilder/store';
import { mdiChevronRight, mdiHomeOutline } from '@mdi/js';
import { isBlockContentEmpty } from '@root/helpers/blocks';
import IconMdi from '@root/src/components/IconMdi';
import { useEntitySections } from '@src/hooks';
import { isTemplatePath } from '@utils/app-paths';

export const ContractBuilderFooter = () => {
  const { formValues: state, hasChanges } = useBlockEditFormStore(({ formValues, hasChanges, reset }) => ({
    formValues,
    hasChanges,
    reset,
  }));
  const { onSubmit, onSubmitAsNew, setEditingBlock } = useBlockEdit();
  const isLoading = useEntityStore(({ isLoading }) => isLoading);
  const isTemplate = isTemplatePath();
  const { getEditor } = useEditorsMap();
  const sections = useEntitySections();

  const editor = getEditor(INLINE_BLOCK_EDITOR_NAME);
  const hasEmptyContent = isBlockContentEmpty(state);

  const isSaveButtonDisabled = !editor || hasEmptyContent;
  const activeSection = sections.find((section) => section.id === state?.section_id);

  const handlePropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleCancel = () => setEditingBlock(undefined);

  if (!editor) {
    return null;
  }

  const shouldDisableSaveButton = !hasChanges || isLoading || isSaveButtonDisabled || !state?.name;

  return (
    <div className="flex w-full justify-between border-t !border-info-200 py-2 shadow-2xl" onClick={handlePropagation}>
      <div className="inline-block max-w-[58vw] overflow-hidden text-ellipsis whitespace-nowrap py-2 pl-6 text-sm font-medium text-info-500">
        {activeSection ? (
          <div className="flex items-center gap-1">
            <IconMdi path={mdiHomeOutline} />
            <IconMdi path={mdiChevronRight} />
            <span>{activeSection.label}</span>
            <IconMdi path={mdiChevronRight} />
            <span className="truncate">{state?.name}</span>
          </div>
        ) : (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 w-48 rounded-full bg-info-200" />
          </div>
        )}
      </div>
      <div className="inline-block whitespace-nowrap">
        <Button className="mr-4 h-8" onClick={handleCancel} size="sm">
          Cancel
        </Button>
        <Button className="mr-4 h-8" kind="primary" size="sm" isDisabled={shouldDisableSaveButton} onClick={onSubmit}>
          {state?.id ? 'Save' : 'Create'}
        </Button>
        {isTemplate && state?.blockLibraryId && editor && (
          <Button
            className="mr-4 h-8"
            kind="primary"
            size="sm"
            isDisabled={shouldDisableSaveButton}
            onClick={onSubmitAsNew}
          >
            Save as new
          </Button>
        )}
      </div>
    </div>
  );
};
