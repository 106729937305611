import type { ReactElement, ReactNode } from 'react';

export interface RenderControlArgs {
  isActive: boolean;
  isDisabled: boolean;
  editor: any;
}

export interface InlineOptionProps {
  isActive?: boolean;
  isDisabled?: boolean;
  iconSize?: string;
  displayLabel?: boolean;
  title: string;
  key: string;
  icon?: string | ReactNode;
  enableCheckmark?: true;
  format: () => void;
}
export interface CustomOptionProps {
  custom: () => ReactElement | null;
  key: string;
}
export interface ParentOptionProps extends Omit<InlineOptionProps, 'format'> {
  options: Array<InlineOptionProps | CustomOptionProps>;
}

export type Option = InlineOptionProps | ParentOptionProps | CustomOptionProps;
export type ControlOptions = Array<Option | false>;
export type TableControlsOptions = Array<InlineOptionProps | CustomOptionProps>;

export enum OrderedListType {
  NUMBERS = '1',
  ROMAN = 'i',
  LETTERS = 'a',
  DECIMAL = '1.1',
}
