import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type { LibraryBlockType } from '@ContractBuilder/types';
import { useBlocksLibrary } from '@hooks/useBlocksLibrary';
import type { DynamoDBLibraryBlock } from '@root/@types/database';

import { useFilteredBlocks } from '../hooks/use-filtered-blocks';
import type { FiltersState } from '../types';
import { BlockLibraryListSuccess } from '../views/BlockLibraryListSuccess';
import { BlocksLibraryLoading } from '../views/BlocksLibraryLoading';

interface BlockLibraryCreatorListControllerProps {
  onInsertBlock: (blockLibraryId: string, block: DynamoDBLibraryBlock | null) => Promise<void>;
}

const INITIAL_FILTERS_STATE: FiltersState = {
  type: [],
  clause_type: [],
};

const stringifyFilters = (filters: FiltersState) => {
  try {
    return JSON.stringify(Object.keys(filters).map((key) => ({ key, value: filters[key as 'type' | 'clause_type'] })));
  } catch {
    return '[]';
  }
};

export const BlockLibraryCreatorListController: FC<BlockLibraryCreatorListControllerProps> = ({ onInsertBlock }) => {
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<FiltersState>(INITIAL_FILTERS_STATE);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [previouslyLoadedItems, setPreviouslyLoadedItems] = useState<LibraryBlockType[]>([]);
  const {
    data: blocks = [],
    totalAmount,
    isFetching,
  } = useBlocksLibrary({
    page,
    limit: 20,
    search,
    filters: stringifyFilters(filters),
  });
  const filteredBlocks = useFilteredBlocks(blocks, search);

  useEffect(() => {
    if (!isFetching && total !== totalAmount) {
      setTotal(totalAmount);
    }
  }, [isFetching, total, totalAmount]);

  const resetPagination = () => {
    setPreviouslyLoadedItems([]);
    setPage(1);
    setTotal(0);
  };

  const onSearchApply = (searchInput: string) => {
    resetPagination();
    setSearch(searchInput);
  };
  const onSearchReset = () => {
    resetPagination();
    setSearch('');
  };

  const handleApplyFilters = async (filters: FiltersState) => {
    resetPagination();
    setFilters(filters);
  };

  const handleResetFilters = async () => {
    resetPagination();
    setFilters(INITIAL_FILTERS_STATE);
  };

  const handleLoadMoreItems = (previouslyLoadedItems: LibraryBlockType[]) => {
    setPreviouslyLoadedItems(previouslyLoadedItems);
    setPage(page + 1);
  };

  if (isFetching && !previouslyLoadedItems?.length) {
    return <BlocksLibraryLoading />;
  }

  const loadedBlocks = [...previouslyLoadedItems, ...filteredBlocks];
  const count = loadedBlocks.length ?? 0;

  return (
    <BlockLibraryListSuccess
      blocks={loadedBlocks}
      count={count}
      filters={filters}
      onApplyFilters={handleApplyFilters}
      onInsert={onInsertBlock}
      onResetFilters={handleResetFilters}
      onSearch={onSearchApply}
      onSearchReset={onSearchReset}
      searchText={search}
      total={total}
      onLoadMoreItems={handleLoadMoreItems}
      isFetching={isFetching}
    />
  );
};
