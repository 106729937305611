import React from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';

export const TableBody = <TData,>({ rows }: { rows: Row<TData>[] }) => (
  <tbody className="divide-y divide-info-200 bg-white">
    {rows.map((row) => (
      <tr data-testid="table-row" key={row.id} className="hover:bg-primary-50">
        {row.getVisibleCells().map((cell) => (
          <ShowFirstMatching key={cell.id}>
            <Show when={cell.column.columnDef.meta?.isStandaloneDataCell}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Show>
            <Show when={!cell.column.columnDef.meta?.isStandaloneDataCell}>
              <td
                className={clsx(
                  'w-42 truncate whitespace-nowrap px-3 py-4 align-top text-sm text-info-500',
                  cell.column.columnDef?.meta?.dataCellClassName,
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            </Show>
          </ShowFirstMatching>
        ))}
      </tr>
    ))}
  </tbody>
);
