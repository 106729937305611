import { Navigate, Outlet } from 'react-router-dom';
import { useUserStore } from '@Auth/store';
import { isAdmin } from '@root/helpers/permissions';

export const AdminRoutes = () => {
  const { isLoggedIn, user } = useUserStore(({ isLoggedIn, user }) => ({ isLoggedIn, user }));
  const isAdminUser = isAdmin(user);

  if (!isLoggedIn) {
    return null;
  }

  return isAdminUser ? <Outlet /> : <Navigate to="/" />;
};
