import type { ComponentType } from 'react';
import React from 'react';

import MenuLayout from './MenuLayout';
import { PageContainer } from './PageContainer';

const withMenuLayout = <P extends object>(Component: ComponentType<P>) => {
  const WrappedComponent: React.FC<P> = (props) => {
    return (
      <PageContainer>
        <MenuLayout>
          <Component {...props} />
        </MenuLayout>
      </PageContainer>
    );
  };
  return WrappedComponent;
};

export default withMenuLayout;
