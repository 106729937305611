import { SECTIONS_KEYS_SELECTOR } from '@ContractBuilder/settings';
import type { EntityData } from '@ContractBuilder/types';
import { StandardisedSectionName } from '@root/@types/types';

const isCustomName = (name: string, standardisedSectionName?: StandardisedSectionName) => {
  const section = SECTIONS_KEYS_SELECTOR.find((section) => section.value === standardisedSectionName);
  return section?.label !== name;
};

export const getInitialValues = (submission?: EntityData, activeSectionId?: string) => {
  const selectedSection = submission?.sections.find((section) => section.id === activeSectionId);

  if (!selectedSection) {
    return {
      name: '',
      shouldResetPageCounter: false,
      shouldHideTitleInPdf: false,
      standardisedSectionName: StandardisedSectionName.RiskDetails,
    };
  }

  const isCustomSectionName = isCustomName(selectedSection.label, selectedSection.standardised_name);

  return {
    name: selectedSection.label,
    shouldHideTitleInPdf: selectedSection.should_hide_title_in_pdf ?? false,
    shouldResetPageCounter: selectedSection.should_reset_page_counter ?? false,
    standardisedSectionName: isCustomSectionName ? undefined : selectedSection.standardised_name,
  };
};
