import type { DropResult } from 'react-beautiful-dnd';
import { reorder } from '@ContractBuilder/modules/navigation/modules/nav-section/utils';
import { patchEntity } from '@mutations/patchEntity';
import { ApiPatchOperation } from '@root/@types/types';
import { reorderLayout } from '@root/helpers';

import type { SectionsStoreSlice, SliceType } from './types';

export const createSectionSlice: SliceType<SectionsStoreSlice> = (set, get) => ({
  sections: {},
  updateSection: async (sectionId, payload) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    const result = await patchEntity({
      operation: ApiPatchOperation.UpdateResourceSection,
      sectionId,
      section: payload,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }
  },
  createSection: async (payload) => {
    const submission = get().submission;
    if (!submission) {
      return;
    }

    const result = await patchEntity({
      operation: ApiPatchOperation.CreateResourceSection,
      ...payload,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }
  },
  reorderSection: async (result: DropResult) => {
    const submission = get().submission;

    if (!submission || !result.destination) {
      return;
    }

    const reorderedSections = reorder(submission.sections, result.source.index, result.destination.index);
    const newLayout = reorderLayout(submission.layout, result.draggableId, result.destination.index);
    set({ submission: { ...submission, sections: reorderedSections, layout: newLayout } });

    await patchEntity({
      operation: ApiPatchOperation.ReorderResourceSection,
      reorder: {
        destinationIndex: result.destination.index,
      },
      sectionId: result.draggableId,
    });

    await get().reloadSubmission();
  },
  deleteSection: async (sectionId) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    const result = await patchEntity({
      operation: ApiPatchOperation.DeleteResourceSection,
      sectionId,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }
  },
});
