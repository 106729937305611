import { flatMap, flow, join, map, partialRight } from 'lodash-es';

export const POLICYHOLDERS_DATAPOINT_ID = 'policyholders';

interface PolicyholderItem {
  name: Array<{ name: string }>;
}

export const getAllPolicyholderNames = (parentSubmissionPolicyholderData: PolicyholderItem[] = []) => {
  const extractAndConcatNames = flow(
    partialRight(flatMap, (item: PolicyholderItem) => item.name),
    partialRight(map, (item: PolicyholderItem) => item.name),
    partialRight(join, ', '),
  );

  return extractAndConcatNames(parentSubmissionPolicyholderData);
};
