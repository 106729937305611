import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { EditModeControls } from '@components/InlineEditableText';
import InputText from '@components/InputText';
import { isEmptyValue } from '@root/helpers';

import type { VariationNameState } from '../types';

interface TabNameEditorProps {
  name: string;
  state: VariationNameState;
  canModify?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TabNameEditor: FC<TabNameEditorProps> = ({ canModify, onCancel, onConfirm, onChange, state, name }) => {
  if (!canModify || !state.edit) {
    return <div className="flex-grow overflow-hidden text-ellipsis">{state.value}</div>;
  }

  const saveOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onConfirm();
    }
  };

  return (
    <EditModeControls confirmDisabled={isEmptyValue(state.value)} onCancel={onCancel} onConfirm={onConfirm}>
      <InputText
        autoFocus
        data-testid={'variation-name-input'}
        value={state.value}
        onChange={onChange}
        onKeyDown={saveOnEnter}
        name={name}
        size="sm"
        placeholder="Variation name"
        className="!w-fit shadow-none"
        inputClassName="!w-fit"
      />
    </EditModeControls>
  );
};
