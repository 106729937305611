import React, { useMemo } from 'react';
import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { InputField, type InputFieldProps } from '@components/InputField';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import sharedClasses from '@utils/shared-classes';
import { cva } from 'class-variance-authority';

import type { UIOnChangeFn } from '../../../@types/types';
import Input from '../Input';
import InputReadOnly from '../InputReadOnly';

const classes = cva([], {
  variants: {
    isDisabled: { true: sharedClasses.disabled },
    isIncomplete: { true: 'field-incomplete' },
  },
});

interface InputNumberSchemaProps extends InputFieldProps {
  defaultValue?: string | number;
  isReadOnly: boolean;
  item: any;
  onChange: UIOnChangeFn<number | string>;
  ownKey: string;
  placeholderText?: string;
}

const InputNumberSchema: React.FC<InputNumberSchemaProps> = ({
  cdrId,
  cdrName,
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isDisabled,
  isReadOnly,
  isRequired,
  item: schema,
  labelText,
  mrcId,
  name = 'number',
  hideErrors,
  onChange,
  ownKey,
  placeholderText = '0',
  shortName,
}) => {
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  const handleChange = ({ floatValue }: NumberFormatValues, _sourceInfo: SourceInfo) => {
    if (onChange) {
      onChange(floatValue ?? undefined, name);
    }
  };
  const decimalScale = 'ui:decimal_scale' in schema ? schema['ui:decimal_scale'] : 0;

  const max = schema.maximum ?? Number.MAX_SAFE_INTEGER;
  const min = schema.minimum ?? 0;

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id ?? name}
      isDisabled={isDisabled || isReadOnly}
      isRequired={isRequired}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      hideErrors={hideErrors}
      shortName={shortName}
      isIncomplete={isIncomplete}
    >
      {isReadOnly ? (
        <InputReadOnly
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={`${value}${schema.suffix ?? ''}`}
        />
      ) : (
        <div className="relative">
          {schema.unit && (
            <div className="absolute inset-y-0 right-3 flex w-8 items-center justify-center">{schema.unit}</div>
          )}
          <NumericFormat
            disabled={isDisabled}
            allowNegative={false}
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className={classes({ isDisabled, isIncomplete })}
            customInput={Input}
            decimalScale={decimalScale ?? 0}
            defaultValue={defaultValue}
            errors={errors}
            id={id ?? name}
            isAllowed={({ floatValue = 0 }) => floatValue <= max && floatValue >= min}
            maxLength={schema.maxLength ?? 22}
            name={name}
            onValueChange={handleChange}
            placeholder={placeholderText}
            thousandSeparator={schema.thousandSeparator ?? true}
            value={value}
            suffix={schema.suffix ?? ''}
          />
        </div>
      )}
    </InputField>
  );
};

export default InputNumberSchema;
