import { Outlet } from 'react-router-dom';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import { useEffectOnce } from '@src/hooks';

export const PublicRoutes = () => {
  const removeGlobalLoader = useRemoveGlobalLoader();

  useEffectOnce(() => {
    removeGlobalLoader();
  });

  return <Outlet />;
};
