import type { FC, MouseEvent, ReactNode } from 'react';
import React, { Fragment, useEffect } from 'react';
import { useClickAway } from 'react-use';
import { POPOVER_Z_INDEX } from '@constants/z-indices';
import type { Middleware, OffsetOptions, Placement } from '@floating-ui/react';
import { flip, FloatingPortal, offset as floatingUiOffset, shift, useFloating } from '@floating-ui/react';
import { useToggle } from '@src/hooks';
import { eventPropagationTrap } from '@src/utils';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

export type PopoverClickEventHandler = (
  event: MouseEvent | React.FocusEvent<HTMLInputElement>,
  forceOpen?: boolean,
) => void;
export interface PopoverRenderTriggerRenderProps {
  onClick: PopoverClickEventHandler;
}
interface ChildrenRenderProps {
  onClose: () => void;
}

export interface PopoverProps {
  children?: ((props: ChildrenRenderProps) => ReactNode) | ReactNode;
  containerClassName?: string;
  id?: string;
  /** Whether to remove popover style **/
  isMinimal?: boolean;
  /** This value should be provided for a controlled version of the Popover **/
  forceIsOpen?: boolean;
  middleware?: Middleware[];
  offset?: OffsetOptions;
  placement?: Placement;
  renderTrigger: (props: PopoverRenderTriggerRenderProps) => ReactNode;
  shouldRenderInsidePortal?: boolean;
  wrapperClassName?: string;
}

export const Popover: FC<PopoverProps> = ({
  children,
  containerClassName,
  forceIsOpen,
  id,
  isMinimal = false,
  middleware = [flip(), shift()],
  offset = -5,
  placement = 'bottom-start',
  renderTrigger,
  shouldRenderInsidePortal = false,
  wrapperClassName,
}) => {
  const [isOpen, onOpen, onClose, onToggle] = useToggle(false);

  const { x, y, strategy, refs, update } = useFloating({
    middleware: [floatingUiOffset(offset), ...middleware],
    open: isOpen,
    placement,
  });

  useEffect(() => {
    // Wait for elements to be rendered
    const frame = requestAnimationFrame(() => {
      update();
    });

    return () => cancelAnimationFrame(frame);
  }, [isOpen, forceIsOpen, update]);

  useClickAway(refs.floating, () => {
    if (forceIsOpen) {
      return;
    }

    onClose();
  });

  const handleReferenceClick = (event: MouseEvent | React.FocusEvent<HTMLInputElement>, forceOpen?: boolean) => {
    eventPropagationTrap(event);

    if (forceOpen) {
      return onOpen();
    }

    return onToggle();
  };

  const ParentContainer = shouldRenderInsidePortal ? FloatingPortal : Fragment;
  const parentProps = shouldRenderInsidePortal ? { id } : {};

  return (
    <AnimatePresence>
      <>
        <div ref={refs.setReference}>{renderTrigger({ onClick: handleReferenceClick })}</div>
        <ParentContainer {...parentProps}>
          <div
            data-testid="popover"
            role="tooltip"
            className={clsx(
              !isMinimal &&
                'relative inline-block w-64 rounded-lg bg-white text-sm font-light text-info-500 drop-shadow-xl',
              POPOVER_Z_INDEX,
              wrapperClassName,
            )}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
            }}
          >
            {(isOpen || forceIsOpen) && (
              <motion.div className={containerClassName}>
                {typeof children === 'function' ? children({ onClose }) : children}
              </motion.div>
            )}
          </div>
        </ParentContainer>
      </>
    </AnimatePresence>
  );
};
