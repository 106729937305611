import { mrc } from '@src/language/source/naming-mrc';
export const BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING = [
  {
    value: 'mrc-heading',
    label: mrc.block.layout['mrc-heading'],
  },
  {
    value: 'clause',
    label: mrc.block.layout.clause,
  },
  {
    value: 'generic',
    label: mrc.block.layout.generic,
  },
];

export const MRC_HEADING_TYPES = [
  'Unique Market Reference',
  'Type',
  'Insured',
  'Period',
  'Interest',
  'Limits of Liability',
  'Excess',
  'Deductibles',
  'Conditions',
  'Choice of Law and Jurisdiction',
  'Premium',
  'Premium Payment Terms',
  'Taxes Payable by (Re)Insured and Administered by (Re)Insurers',
  'Taxes Payable by (Re)Insurers and Administered by (Re)Insured or their Agent',
  'Recording Transmitting & Storing Information',
  'Insurer Contract Documentation',
  "(Re)insurer's liability",
  'Mode Of Execution Clause',
  'Order Hereon',
  'Basis of written lines',
  'Signing provisions',
  'Slip Leader',
  'Basis of Agreement to Contract Changes',
  'Other Agreement Parties for Contract Changes, for Part 2 GUA Changes Only',
  'Agreement Parties for Contract Changes, for their Proportion Only',
  'Basis of Claims Agreement',
  'Claims Agreement Parties',
  'Claims Administration',
  'Rules and extent of any other delegated claims authority',
  'Expert(s) fees collection',
  'Miscellaneous Monies',
  'Settlement due date',
  'Bureaux arrangements',
  'Tax Payable by Insurer(s)',
  'Country of Origin',
  'Regulatory  Risk Location',
  'Overseas Broker',
  'Allocation of premium to coding',
  'Regulatory Client Classification',
  'Fee  Payable by Client',
  'Total Brokerage',
  'Other Deductions from Premium',
  'None',
];
