import React, { useState } from 'react';
import { LogoutError } from '@Auth/Logout/LogoutError';
import { LogoutSuccess } from '@Auth/Logout/LogoutSuccess';
import { Show, ShowFirstMatching } from '@components/Show';
import authProvider from '@helpers/auth/authProvider';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import type { AxiosError } from 'axios';

export const EntraLogout = () => {
  const [isLogoutFinished, setIsLogoutFinished] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const signOut = async () => {
    try {
      Sentry.setUser(null);
      await authProvider.logout();
      setIsLogoutFinished(true);
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLogoutFinished(true);
    }
  };

  useEffectOnce(() => {
    signOut();
  });

  if (!isLogoutFinished) {
    return (
      <div className="overlay">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full items-center justify-center overflow-hidden bg-gray-100">
      <ShowFirstMatching>
        <Show when={!!error}>
          <LogoutError />
        </Show>
        <Show when={!error}>
          <LogoutSuccess />
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
