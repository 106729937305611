import { useEffectOnce } from '@hooks/useEffectOnce';

import type { ArtificialCustomEventUnion, ArtificialOperation, EventOfType, GetEventValue } from '../types';

const isArtificialCustomEvent = (event: Event): event is ArtificialCustomEventUnion => {
  return 'detail' in event;
};

export const useCustomEventListener = <Operation extends ArtificialOperation>(
  name: Operation,
  callback: (value: GetEventValue<EventOfType<Operation>>) => void,
) => {
  useEffectOnce(() => {
    const handler = (event: Event) => {
      if (!isArtificialCustomEvent(event)) {
        throw new Error(`"useCustomEventListener" hook can only handle custom events`);
      }

      return callback(event.detail.value as GetEventValue<EventOfType<Operation>>);
    };

    document.addEventListener(name, handler);
    return () => document.removeEventListener(name, handler);
  });

  return null;
};
