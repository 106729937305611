import React, { Component } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { get } from 'lodash-es';

import type { GenericObject } from '../../@types/types';
import pickCommaSeparate from '../helpers/pickCommaSeparate';
import useScript from '../helpers/useScript';

import Input from './Input';

/*
  If this component is written in functional style, there's a problem with `onPlaceChange` giving
  stale results, because it's inside event handler. Normally you'd use useEffect to refresh values
  when they change, but Google Autocomplete won't cleanup extra dropown nodes which leads to some
  odd behaviour, multiple dropdowns being renderd etc. Beware.
*/

type Place = {
  types: string[];
  long_name: string;
} & GenericObject;

export interface InputAddressProps extends InputFieldProps {
  isReadOnly?: boolean;
  descriptionText?: string;
  allowedCountries: string[];
  defaultValue: string | number;
  labelText: string;
  onPlaceChange: (value: any) => void;
}

class InputAddress extends Component<InputAddressProps, unknown> {
  ref: any;
  autocomplete: any;
  listener: any;

  constructor(props: InputAddressProps) {
    super(props);

    this.ref = React.createRef();
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    if (this.props.isReadOnly) {
      return;
    }
    const node = this.ref.current;

    this.autocomplete = new (window as any).google.maps.places.Autocomplete(node, {
      types: ['geocode'],
      componentRestrictions: {
        country: this.props.allowedCountries ?? [],
      },
    });

    this.listener = this.autocomplete.addListener('place_changed', this.handlePlaceChange);
  }

  componentWillUnmount() {
    if (this.props.isReadOnly) {
      return;
    }
    (window as any).google.maps.event.removeListener(this.listener);
  }

  handlePlaceChange() {
    const { onPlaceChange } = this.props;
    const place = this.autocomplete.getPlace();
    const address = get(place, 'address_components', []).reduce(
      (prev: Place, next: Place) => ({ ...prev, [next.types[0]]: next.long_name }),
      {},
    );

    const line1elements = ['street_number', 'route'];
    const line2elements = ['sublocality', 'neighborhood'];

    const newPlace = {
      address_line_1: pickCommaSeparate(address, line1elements),
      address_line_2: pickCommaSeparate(address, line2elements) || undefined,
      city: address.postal_town || address.locality,
      zipcode: address.postal_code,
      state: address.administrative_area_level_1,
      country: address.country,
    };

    onPlaceChange(newPlace);
  }

  render() {
    const { className, defaultValue, descriptionText, errors, id, isReadOnly, isRequired, labelText, name } =
      this.props;

    return (
      <InputField
        className={className}
        descriptionText={descriptionText}
        errors={errors}
        id={id ?? name}
        isRequired={isRequired}
        labelText={labelText}
        name={name}
      >
        <Input
          defaultValue={defaultValue}
          id={id ?? name}
          isReadOnly={isReadOnly}
          name={name}
          ref={this.ref}
          required={isRequired}
          errors={errors}
        />
      </InputField>
    );
  }
}

const key = '';
const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;

const InputAddressWrapper: React.FC<InputAddressProps> = (props) => {
  const [loaded] = useScript(url);

  if (props.isReadOnly) {
    return <InputAddress {...props} />;
  }

  if (!loaded || !(window as any).google) {
    return null;
  }

  return <InputAddress {...props} />;
};

export default InputAddressWrapper;
