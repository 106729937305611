import type { FC, HTMLAttributes } from 'react';

import { indentationClasses } from '../DatapointInserter/classes';

import { itemClasses } from './style/classes';

interface RenderItemInnerProps extends HTMLAttributes<HTMLButtonElement> {
  highlightedId?: string;
  isConditional: boolean;
  label: string;
  level?: number;
  value: string;
  disableConditionals?: boolean;
  schema?: any;
}

export const RenderItemInner: FC<RenderItemInnerProps> = ({
  value,
  label,
  level,
  highlightedId,
  isConditional,
  disableConditionals = false,
  schema: _schema,
  ...props
}) => (
  <button
    title={value}
    {...props}
    className={itemClasses({
      isConditional: !disableConditionals && isConditional,
      isHighlighted: highlightedId === value,
      className: [indentationClasses({ level: level as any }), props.className],
    })}
  >
    {label}
  </button>
);
