import { useCallback } from 'react';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { ResourceBlock } from '@root/@types/base';

export const useSetFocussedBlock = () => {
  const dispatch = useDispatchCustomEvent();
  const togglePreview = useUIStore(({ togglePDFPreview }) => togglePDFPreview);

  return useCallback(
    async (block: ResourceBlock) => {
      const isOpen = useUIStore.getState().isPDFPreviewOpen;
      if (!isOpen) {
        togglePreview();
      }

      return dispatch(ArtificialCustomEvent.SetFocussedBlockId, block.id);
    },
    [dispatch, togglePreview],
  );
};
