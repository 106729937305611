import React from 'react';
import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

interface LabelProps extends UIComponentBase {
  htmlFor?: string;
  isRequired?: boolean;
  schemaId?: React.ReactNode;
}
const Label: React.FC<LabelProps> = ({ children, className, htmlFor, isRequired }) => (
  <label htmlFor={htmlFor} className={clsx('block', className)}>
    {children}
    {isRequired && <span className="text-error-600">*</span>}
  </label>
);

export default Label;
