/* eslint-disable react/jsx-key */
import type { Column, Row } from 'react-table';
import { useTable } from 'react-table';
import { isNonNullish } from '@root/helpers';
import clsx from 'clsx';

interface TableProps<Item extends object> {
  columns: Array<Column<Item>>;
  data: Item[];
  onRowClick?: (row: Item) => void;
}

export const Table = <Item extends object>({ columns, data, onRowClick }: TableProps<Item>) => {
  const tableInstance = useTable<Item>({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const hasInteractiveRows = isNonNullish(onRowClick);

  const handleRowClick = (row: Row<Item>) => {
    if (!hasInteractiveRows) {
      return;
    }

    return onRowClick?.(row.original);
  };

  return (
    <table {...getTableProps()} className="w-full">
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={clsx(
                  'px-4 py-2.5 text-left text-xs font-medium uppercase leading-4 tracking-wider text-info-500',
                )}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={clsx(
                'border-t border-info-200',
                hasInteractiveRows && 'cursor-pointer transition-colors hover:bg-primary-50',
              )}
              onClick={() => handleRowClick(row)}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="p-4 text-sm font-normal leading-5 text-info-900">
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
