import React from 'react';
import InputMultiSelect from '@components/InputMultiSelect';
import { Show } from '@components/Show';
import Toggle from '@components/Toggle';
import type { CheckboxFilter, DropdownFilter } from '@root/@types/types';
import { isNonNullish, sortAlphabetically } from '@root/helpers';
import type { Table } from '@tanstack/react-table';

const getColumnFilterOptions = (table: Table<any>, columnId: string): string[] =>
  Array.from(
    new Set(
      table
        .getCoreRowModel()
        .flatRows.map((row) => row.getValue(columnId))
        .filter(isNonNullish) as string[],
    ),
  ).sort(sortAlphabetically);

export const FiltersBar = ({ table }: { table: Table<any> }) => (
  <div
    data-testid="filters-bar"
    className="filters-bar mt-4 border-[1px] border-gray-200 bg-gray-50 p-4 pt-1 md:rounded-lg"
  >
    <div className="mt-4 flex flex-row items-start md:mt-0 md:flex-row xl:flex">
      <div key={JSON.stringify(table.getState().columnFilters ?? [])} className="flex flex-wrap items-start gap-5">
        {table
          .getAllColumns()
          .filter((column) => column.getCanFilter())
          .map((column) => {
            const { filter = {} } = column.columnDef.meta ?? {};
            const { className, label, type = 'dropdown', wrapperClassName } = filter;
            return (
              <Show key={column.columnDef.id} when={column.getCanFilter()}>
                <Show when={type === 'checkbox'}>
                  <div className={wrapperClassName ?? ''}>
                    <Toggle
                      value={Boolean((filter as CheckboxFilter).isChecked ? 1 : 0)}
                      className={className ?? 'mb-0 ml-2 mr-6 mt-9 xl:ml-4'}
                      labelText={label ?? ''}
                      onClick={() => {}}
                    />
                  </div>
                </Show>
                <Show when={type === 'dropdown'}>
                  <div
                    className="mr-3 mt-3 last:mr-0"
                    key={column.columnDef.id}
                    data-testid={label ?? (column.columnDef.header as string)}
                  >
                    <InputMultiSelect
                      labelText={label ?? (column.columnDef.header as string)}
                      name={column.columnDef.id}
                      placeholder={(filter as DropdownFilter).placeholder}
                      className={
                        className ??
                        'block rounded-md border-info-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm'
                      }
                      dropdownWrapperClassName="bg-white max-w-fit"
                      dropdownInnerWrapperClassName="flex-col"
                      options={
                        (column.columnDef.meta?.filter as DropdownFilter)?.options ??
                        getColumnFilterOptions(table, column.id)
                      }
                      onChange={(selectedItems) => column.setFilterValue(selectedItems)}
                      onSelect={() => {}}
                      value={(column.getFilterValue() as string[]) ?? []}
                    />
                  </div>
                </Show>
              </Show>
            );
          })}
      </div>
    </div>
  </div>
);
