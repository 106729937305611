import { forwardRef } from 'react';
import { POPOVER_Z_INDEX } from '@constants/z-indices';
import { DatapointInput } from '@ContractBuilder/modules/mentions-handler/views/DatapointInput';
import type { UseFloatingData } from '@floating-ui/react';
import clsx from 'clsx';

import type { MentionsHandlerControllerState } from '../controller/MentionsHandlerController';

interface MentionsHandlerProps extends Pick<UseFloatingData, 'x' | 'y' | 'strategy'>, MentionsHandlerControllerState {
  onClose: () => void;
}

export const MentionsHandler = forwardRef<HTMLDivElement, MentionsHandlerProps>(function MentionsHandler(
  { activeDatapointId, hasVariations, isVariationsSelected, onClose, strategy, x, y },
  ref,
) {
  if (!activeDatapointId) return null;

  return (
    <div
      role="tooltip"
      className={clsx(
        'inline-block w-64 max-w-[512px] rounded-lg border border-info-200 bg-white text-sm font-light text-info-500 drop-shadow-xl',
        POPOVER_Z_INDEX,
      )}
      ref={ref}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        width: 'max-content',
      }}
    >
      <DatapointInput
        datapointId={activeDatapointId}
        hasVariations={hasVariations}
        isVariationsSelected={isVariationsSelected}
        onClose={onClose}
      />
    </div>
  );
});
