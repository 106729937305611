import type { FC, PropsWithChildren } from 'react';
import Tooltip from '@components/Tooltip';
import { mdiArrowDown, mdiEyeOff, mdiText } from '@mdi/js';
import { FeatureIndicatorIcon } from '@root/src/components/FeatureIndicatorIcon';
import IconMdi from '@root/src/components/IconMdi';

import { sectionScrollId } from '../../../functions/section-scroll-id';

import { sectionClasses } from './classes';

interface SectionTitleProps {
  id: string;
  shouldHideTitleInPdf?: boolean;
  shouldResetPageCounter?: boolean;
  standardisedName?: string;
}

const SectionTitle: FC<PropsWithChildren<SectionTitleProps>> = ({
  children,
  id,
  shouldHideTitleInPdf,
  shouldResetPageCounter,
  standardisedName,
}) => {
  const isPDFRender = typeof document === 'undefined';

  if (isPDFRender && shouldHideTitleInPdf) {
    return null;
  }

  return (
    <h2 className={sectionClasses.title({ pdf: isPDFRender })} id={sectionScrollId(id)}>
      <strong id={standardisedName}>{children}</strong>
      {!isPDFRender && (
        <div className="mt-0.5 flex h-fit items-center gap-1">
          {shouldHideTitleInPdf && (
            <FeatureIndicatorIcon placement="top" path={mdiEyeOff} content="Title hidden in PDF" />
          )}
          {shouldResetPageCounter && (
            <Tooltip content="Page counter reset" placement="top">
              <div className="relative h-fit w-fit text-info-400">
                <IconMdi path={mdiText} size={0.6} />
                <IconMdi path={mdiArrowDown} size={0.4} className="absolute -bottom-0.5 right-0" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </h2>
  );
};

export default SectionTitle;
