import type { FC } from 'react';
import React, { useMemo, useRef } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import InputReadOnly from '@components/InputReadOnly';
import InputTime, { TIME_FORMAT } from '@components/InputTime';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { sanitizeInputProps } from '@ContractBuilder/utils';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import type { UIOnChangeFn } from '@root/@types/types';
import { formatTime } from '@root/helpers';
import { cva } from 'class-variance-authority';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const classes = cva(
  [
    'focus:shadow-outline',
    'border-info-300',
    'text-info-700',
    'shadow-sm',
    'hover:border-info-300',
    'focus:border-primary-500',
    'focus:ring-primary-500',
  ],
  {
    variants: {
      isIncomplete: { true: 'field-incomplete' },
    },
  },
);

interface InputTimeSchemaProps extends Omit<InputFieldProps, 'size'> {
  isReadOnly: boolean;
  item: any;
  name: string;
  ownKey: string;
  onChange: UIOnChangeFn<number | string>;
}

const InputTimeSchema: FC<InputTimeSchemaProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  helperText,
  hideErrors,
  id,
  isDisabled,
  isReadOnly,
  labelIcon,
  labelText,
  mrcId,
  name,
  onChange,
  shortName,
  ...props
}) => {
  const inputFieldRef = useRef<HTMLDivElement>(null);

  const { ownKey, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value = null, isIncomplete } = useDatapointsStore(selector);

  const parsedValue = value ? dayjs(value, TIME_FORMAT) : null;
  const formattedValue = parsedValue ? formatTime(value) : undefined;

  const parsedProps = sanitizeInputProps(props);

  const handleChange = (time: string | undefined) => {
    onChange(time, name);
  };

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      helperText={helperText}
      hideErrors={hideErrors}
      id={id ?? name}
      isDisabled={isReadOnly}
      isIncomplete={isIncomplete}
      isRequired={props.isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      ref={inputFieldRef}
      shortName={shortName}
    >
      {isReadOnly && (
        <InputReadOnly
          className={className}
          defaultValue={formattedValue}
          key={value}
          size="md"
          value={formattedValue}
        />
      )}
      {!isReadOnly && (
        <InputTime
          {...parsedProps}
          aria-readonly={isReadOnly}
          className={classes({ isIncomplete })}
          disabled={isDisabled || isReadOnly}
          onChange={handleChange}
          placeholder="HH:MM"
          size="md"
          value={value}
        />
      )}
    </InputField>
  );
};

export default InputTimeSchema;
