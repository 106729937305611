import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import type { UISchema } from '../../../@types/types';
import type { InputCalendarProps } from '../InputCalendar';
import InputCalendar from '../InputCalendar';

interface InputCalendarSchemaProps extends InputCalendarProps {
  item: UISchema;
  ownKey: string;
}

const InputCalendarSchema: React.FC<InputCalendarSchemaProps> = ({ item: schema, ...props }) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  return (
    <InputCalendar
      value={value}
      isIncomplete={isIncomplete}
      dateFormat={schema['ui:component:format'] as string}
      helperText={schema?.helperText}
      shortName={schema?.shortName}
      {...props}
    />
  );
};

export default InputCalendarSchema;
