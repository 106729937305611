import type { FC } from 'react';
import React from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Button from '@components/Button';
import InputText from '@components/InputText';
import type { UIInputValue } from '@root/@types/types';
import { useLanguage } from '@root/src/language';
import clsx from 'clsx';

import { uploadAttachmentPageContainerClasses, uploadAttachmentWrapperClasses } from '../classes';
import { AttachmentLoaded } from '../components/AttachmentLoaded';

interface AttachmentUploadProps {
  error?: string;
  isUploadDisabled: boolean;
  attachmentName?: string;
  attachmentFileName?: string;
  isFileDropped: boolean;
  isUploading: boolean;
  onFileUpload: (files: File[]) => void;
  onFileRejected: (fileRejections: FileRejection[], event: DropEvent) => void;
  setAttachmentName: (value: UIInputValue) => void;
  handleClickUpload: () => void;
  handleClose: () => void;
}

export const AttachmentUpload: FC<AttachmentUploadProps> = ({
  error,
  onFileUpload,
  onFileRejected,
  handleClose,
  attachmentName,
  attachmentFileName,
  setAttachmentName,
  handleClickUpload,
  isUploadDisabled,
  isFileDropped,
  isUploading,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    onDrop: onFileUpload,
    multiple: false,
    onDropRejected: onFileRejected,
  });

  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.attachment' });

  return (
    <>
      <div className="mb-6 flex justify-items-start text-sm text-gray-400">{getContent('subtitle')}</div>
      <InputText
        className="mb-6"
        labelText={getContent('fields.name.label')}
        onChange={(event) => setAttachmentName(event.target.value)}
        value={attachmentName ?? ''}
        data-testid="attachment-name"
      />
      {isFileDropped && <AttachmentLoaded fileName={attachmentFileName} />}
      {!isFileDropped && (
        <div className={clsx(uploadAttachmentPageContainerClasses)}>
          <div {...getRootProps()} className={clsx(uploadAttachmentWrapperClasses)}>
            <p className="mt-2 text-sm font-normal leading-5 text-gray-700">
              <span className="cursor-pointer text-[#2563EB]">Upload a file</span> or drag and drop
            </p>
            <p className="mt-2 text-[12px] font-normal leading-5 text-gray-400">{getContent('limit')}</p>
            <input
              {...getInputProps()}
              id="attachment-file-upload"
              name="attachment-file-upload"
              type="file"
              className="sr-only"
            />
            {error && <p className="mt-4 text-[12px] text-red-500">{error}</p>}
          </div>
        </div>
      )}
      <div className="mt-8 flex w-full justify-end gap-4">
        <Button onClick={handleClose} className="h-10">
          Cancel
        </Button>
        <Button
          onClick={handleClickUpload}
          className="h-10"
          kind="primary"
          type="submit"
          isDisabled={isUploadDisabled}
          loading={isUploading}
        >
          Attach
        </Button>
      </div>
    </>
  );
};
