import { useMemo } from 'react';

import { alwaysArray } from '../utils/always-array';

interface UseFieldSchemaIdsInput {
  cdrId?: string | string[];
  cdrName?: string | string[];
  mrcId?: string;
}

interface UseFieldSchemaIdsOutput {
  parsedCdrIds?: string[];
  parsedCdrNames?: string[];
  schemaId?: string;
}

export const useFieldSchemaIds = ({ cdrId, cdrName, mrcId }: UseFieldSchemaIdsInput): UseFieldSchemaIdsOutput =>
  useMemo(() => getFieldSchemaIds({ cdrId, cdrName, mrcId }), [cdrId, cdrName, mrcId]);

export const getFieldSchemaIds = ({ cdrId, cdrName, mrcId }: UseFieldSchemaIdsInput): UseFieldSchemaIdsOutput => {
  const parsedCdrIds = alwaysArray(cdrId);
  const parsedCdrNames = alwaysArray(cdrName);
  const schemaId = parsedCdrIds?.[0] ?? mrcId;
  return {
    schemaId,
    parsedCdrIds,
    parsedCdrNames,
  };
};
