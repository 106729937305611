import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { isEndorsementData } from '@ContractBuilder/types';
import type { ResourceSection } from '@root/@types/base';
import { get } from 'lodash-es';

export const useEntitySections = (): ResourceSection[] => {
  const submission = useEntityStore(({ submission }) => submission);
  const isEndorsementView = useUIStore(({ isEndorsementView }) => isEndorsementView);

  if (!submission) {
    return [];
  }

  if (isEndorsementView && isEndorsementData(submission)) {
    return get(submission, 'summary.sections', []);
  }

  return get(submission, 'sections', []);
};
