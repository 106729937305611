import { RepeaterInner } from '@WysiwygEditor/components/Editor/extensions/repeater/RepeaterInner';

export const ContentRepeaterGroupInner = (props: any) => (
  <RepeaterInner
    borderClassName="border-success-200"
    headerClassName="bg-success-200 text-success-800"
    defaultLabel="Section Repeater"
    name="ContentRepeaterGroup"
    shouldHideSectionLabel
    {...props}
  />
);
