import type { ResourceBlock } from '@root/@types/base';
import { create } from 'zustand';

interface VariationsTabsState {
  map: Record<string, number>;
  setCurrentVariationIdx: (blockId: string, newCurrentVariationIdx: number) => void;
  getBlockCurrentVariationIdx: (blockId: string) => number;
  setBlockSelectedVariationIdx: (block: ResourceBlock) => void;
}

export const useVariationsTabsStore = create<VariationsTabsState>((set, get) => ({
  map: {},
  getBlockCurrentVariationIdx: (blockId) => get().map[blockId] ?? 0,
  setCurrentVariationIdx: (blockId, newCurrentVariationIdx) => {
    const map = { ...get().map, [blockId]: newCurrentVariationIdx };
    return set({ map });
  },
  setBlockSelectedVariationIdx: (block) => {
    if (block.variations?.length) {
      const selected = block.variations.findIndex((item) => item.id === block.selectedVariationId);
      get().setCurrentVariationIdx(block.id, Math.max(0, selected));
    }
  },
}));
