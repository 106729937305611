import { useMemo, useState } from 'react';

export const useMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClose = () => setIsOpen(false);
  const handleMenuOpen = () => setIsOpen(true);

  return useMemo(
    () => ({
      isOpen,
      onClose: handleMenuClose,
      onOpen: handleMenuOpen,
    }),
    [isOpen],
  );
};
