import type { JSONSchemaType } from 'ajv';

import { UserRole } from '../types';

interface CreateUserPayload {
  first_name: string;
  last_name: string;
  role: UserRole;
  email: string;
  teams: number[];
  identity_provider: string;
}

interface UpdateUserPayload {
  action: 'update';
  first_name: string;
  last_name: string;
  role: UserRole;
  email: string;
  identity_provider: string;
  teams: number[];
}

interface ChangeUserStatePayload {
  action: 'change-state';
  enabled: boolean;
}

interface UserResendInvitePayload {
  action: 'resend-invite';
}

export const createUserPayloadSchema: JSONSchemaType<CreateUserPayload> = {
  type: 'object',
  properties: {
    first_name: { type: 'string' },
    last_name: { type: 'string' },
    email: { type: 'string' },
    role: { type: 'string', enum: [UserRole.Admin, UserRole.Superadmin, UserRole.User] },
    teams: { type: 'array', items: { type: 'number' } },
    identity_provider: { type: 'string' },
  },
  required: ['first_name', 'last_name', 'email', 'role'],
  additionalProperties: false,
};

export const updateUserPayloadSchema: JSONSchemaType<UpdateUserPayload> = {
  type: 'object',
  properties: {
    action: { type: 'string', const: 'update' },
    first_name: { type: 'string' },
    last_name: { type: 'string' },
    email: { type: 'string' },
    role: { type: 'string', enum: [UserRole.Admin, UserRole.Superadmin, UserRole.User] },
    teams: { type: 'array', items: { type: 'number' } },
    identity_provider: { type: 'string' },
  },
  required: ['action', 'first_name', 'last_name', 'role'],
  additionalProperties: false,
};

export const changeUserStatePayloadSchema: JSONSchemaType<ChangeUserStatePayload> = {
  type: 'object',
  properties: {
    action: { type: 'string', const: 'change-state' },
    enabled: { type: 'boolean' },
  },
  required: ['action', 'enabled'],
  additionalProperties: false,
};

export const userResendInvitePayloadSchema: JSONSchemaType<UserResendInvitePayload> = {
  type: 'object',
  properties: {
    action: { type: 'string', const: 'resend-invite' },
  },
  required: ['action'],
  additionalProperties: false,
};
