import { BlockError } from '@root/@types/types';

export const blockErrorMessages = {
  [BlockError.BlockEmpty]:
    'There is a missing block within a contract. Please check all required blocks have been fulfilled before finalising the contract.',
  [BlockError.DatapointEmpty]:
    'There is missing data within the contract, please check all required data points have been fulfilled before finalising the contract.',
  [BlockError.VariationNotSelected]:
    'There are blocks that require you to select a variation before the contract can be finalised.',
};
