import type { DocumentContext } from '@ContractBuilder/utils';
import type { ResourceBlock } from '@root/@types/base';
import type { BaseUser } from '@root/@types/types';
import { isAdmin, isSuperadmin } from '@root/helpers/permissions';

import { ctxIsBlocks, ctxIsTemplate } from './is-ctx';

export const crossCheckBlockUserCtx = (
  block: ResourceBlock | undefined,
  context: DocumentContext,
  user: BaseUser | null,
) => {
  if (!user || !block) {
    return {
      canBeDeletedWithCurrentPermissions: false,
      canBeEditedWithCurrentPermissions: false,
    };
  }

  const isSuperAdmin = isSuperadmin(user);

  if (isSuperAdmin) {
    return {
      canBeDeletedWithCurrentPermissions: true,
      canBeEditedWithCurrentPermissions: true,
    };
  }

  const {
    //
    canEdit = true,
    canDelete = true,
    canEditOnTemplate = true,
    canDeleteOnTemplate = true,
  } = block;

  const isBlocks = ctxIsBlocks(context);
  const isTemplate = ctxIsTemplate(context);
  const isAdminContext = isBlocks || isTemplate;

  if (isAdminContext) {
    if (!isAdmin(user)) {
      return {
        canBeDeletedWithCurrentPermissions: false,
        canBeEditedWithCurrentPermissions: false,
      };
    }

    return {
      canBeDeletedWithCurrentPermissions: canDeleteOnTemplate,
      canBeEditedWithCurrentPermissions: canEditOnTemplate,
    };
  }

  return {
    canBeDeletedWithCurrentPermissions: canDelete,
    canBeEditedWithCurrentPermissions: canEdit,
  };
};
