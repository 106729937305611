export const waitForElement = (selector: string, maxRetries: number, intervalMs = 1000): Promise<Element | undefined> =>
  new Promise((resolve) => {
    let currentTry = 0;

    const intervalId = setInterval(() => {
      if (currentTry >= maxRetries) {
        clearInterval(intervalId);
        return resolve(undefined);
      }

      const element = document.querySelector(selector);

      if (element) {
        clearInterval(intervalId);
        return resolve(element);
      }

      currentTry += 1;
    }, intervalMs);
  });
