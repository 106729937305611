import React from 'react';
import type { EntityData, StatusType } from '@ContractBuilder/types';
import StatusBadge from '@pages/User/components/StatusBadge';
import { tagsAccessor } from '@pages/User/helpers/columns/shared/tagsAccessor';
import { teamsAccessor } from '@pages/User/helpers/columns/shared/teamsAccessor';
import type { Tag } from '@root/@types/types';
import { SUBMISSION_STATUSES } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';

type Submission = EntityData & { created_by: string };

const columnHelper = createColumnHelper<Submission>();

export const getSubmissionsTableColumns = ({
  tags,
  setIsLoading,
}: {
  tags: Tag[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('ref', {
    id: 'ref',
    enableColumnFilter: false,
    header: 'Submission ID',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All IDs',
      },
      dataCellClassName: '!w-36',
      headingCellClassName: '!w-36',
    },
    sortingFn: (rowA, rowB) =>
      Number((rowA.original.ref ?? '').replace('ART-S-', '')) - Number((rowB.original.ref ?? '').replace('ART-S-', '')),
  }),
  columnHelper.accessor('name', {
    id: 'name',
    enableColumnFilter: false,
    header: 'Name',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        label: 'Submission name',
        placeholder: 'All submissions',
      },
    },
  }),
  columnHelper.accessor('umr', {
    id: 'umr',
    enableColumnFilter: false,
    header: 'UMR',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All UMRs',
      },
    },
  }),
  teamsAccessor<Submission>(columnHelper, 'Team'),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<Submission, StatusType>) => <StatusBadge status={cellContext.getValue()} />,
    meta: {
      filter: {
        placeholder: 'All statuses',
        selected: SUBMISSION_STATUSES.filter((status) => status !== 'ARCHIVED'),
      },
      dataCellClassName: '!w-24 align-top !p-[1px]',
      headingCellClassName: '!w-24',
      headingLinkClassName: '!pl-0',
    },
  }),
  tagsAccessor<Submission>(columnHelper, tags, setIsLoading),
  columnHelper.accessor('class_of_business', {
    id: 'class_of_business',
    header: 'Class of Business',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All classes',
        options: ALL_CLASSES_OF_BUSINESS,
      },
    },
  }),
  columnHelper.accessor('created_by', {
    id: 'created_by',
    header: 'Created by',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All created by',
      },
    },
  }),
  columnHelper.accessor('sourceResourceDisplayName', {
    id: 'sourceResourceDisplayName',
    header: 'Template',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All templates',
      },
    },
  }),
  columnHelper.accessor('branding', {
    id: 'branding',
    header: 'Branding',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All brands',
      },
    },
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Submission, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD/MM/YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-36',
    },
  }),
  columnHelper.display({
    id: 'view',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Submission, unknown>) => (
      <a
        className="font-light text-primary-600 hover:text-primary-900"
        target="_self"
        href={`/submissions/${cellContext.row.getValue('id')}/contract`}
        rel="noreferrer"
      >
        View
      </a>
    ),
    meta: {
      dataCellClassName:
        'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  }),
];
