import type { FC } from 'react';
import H3 from '@components/H3';
import { InlineEditableText } from '@components/InlineEditableText';
import InputText from '@components/InputText';
import { documentTitleClasses } from '@ContractBuilder/contract-builder-header/classes';
import type { OnSaveFn } from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import { useCurrentRevision } from '@ContractBuilder/modules/revision-history';
import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { isEndorsementData } from '@ContractBuilder/types';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import type { UIInputValue } from '@root/@types/types';
import { isTemplatePath } from '@root/src/utils/app-paths';

import { useEditableField } from '../hooks';

const getParsedTitle = ({
  entity,
  isViewingRevisionHistory = false,
  revisionName,
}: {
  entity: EntityData | EndorsementData;
  isViewingRevisionHistory?: boolean;
  revisionName?: string;
}) => {
  if (isViewingRevisionHistory) {
    return revisionName;
  }

  if (isEndorsementData(entity)) {
    return entity.reference ?? entity.name;
  }

  return entity.name;
};

interface EditableNameFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn;
}

export const EditableNameField: FC<EditableNameFieldProps> = ({ entity, hasOperationsDisabled, onSave }) => {
  const isViewingRevisionHistory = useIsRevisionHistory();
  const { revisionName } = useCurrentRevision();

  const isTemplate = isTemplatePath();

  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField({
    name: 'name',
    onSave,
    defaultValue: entity.name,
  });

  const parsedTitle = getParsedTitle({ entity, isViewingRevisionHistory, revisionName });

  return (
    <InlineEditableText
      editor={
        <InputText
          value={state.value as UIInputValue}
          onChange={(event) => onChange(event.target.value)}
          name="submission_name"
          className="[&>div>input]:h-8"
        />
      }
      editMode={state.isEditing}
      disabled={hasOperationsDisabled}
      className="min-w-0"
      display={
        <H3
          className={documentTitleClasses({
            isDisabled: hasOperationsDisabled,
            isEndorsement: isViewingRevisionHistory,
            isTemplate,
          })}
        >
          {parsedTitle}
        </H3>
      }
      onClick={onClick}
      onCancel={onCancel}
      onConfirm={onUpdate}
      confirmDisabled={!state.value}
      tooltipContent={parsedTitle}
      withEditIcon={false}
    />
  );
};
