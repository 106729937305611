import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import IconMdi from '@components/IconMdi';
import { useMenu } from '@components/menu';
import { Show } from '@components/Show';
import { VisibilityReasons } from '@ContractBuilder/modules/block/components/VisibilityReasons';
import { useBlockEditFieldProps } from '@ContractBuilder/modules/block-edit/hooks/use-block-edit-field-props';
import { mdiCheckCircle, mdiCheckCircleOutline, mdiChevronDown } from '@mdi/js';
import type { BlockVariation } from '@root/@types/database';
import { isDefaultVariation } from '@root/helpers/variations';
import { TooltipedIcon } from '@root/src/components/TooltipedIcon';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import { isBlocksPath, isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';

import { autoSelectedIconClasses, checkMarkIconClasses, tabClasses } from '../classes';
import { useVariationsTabs } from '../context/context';
import { getDefaultIconTooltip, getDefaultTabIcon, getTabLogicIcon } from '../helpers';
import type { VariationNameState } from '../types';

import { TabNameEditor } from './TabNameEditor';
import { TAB_MIN_W, VariationMenu } from './VariationMenu';

export interface VariationTabProps {
  variation: BlockVariation;
}

export const VariationTab = ({ variation }: VariationTabProps) => {
  const { id, name = '' } = variation;

  const { currentVariationIdx, selectedVariationId, variationInView, visibilityDetails, hasLogic, select, setCurrent } =
    useVariationsTabs();
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isTabOpen = variationInView ? id === variationInView : false;
  const isSelected = id === selectedVariationId;
  const isDefault = isDefaultVariation(id);
  const [state, setState] = useState<VariationNameState>({ edit: false, value: '' });
  const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useMenu();

  const { onChange: nameChangeHandler } = useBlockEditFieldProps(true, currentVariationIdx, 'name');

  useEffect(() => {
    setState((s) => ({ ...s, value: name }));
    // eslint-disable-next-line -- We don't want to react to `name` changing
  }, [id]);

  const onEnterRename = () => {
    setState((s) => ({ ...s, edit: true }));
  };

  const handleCancel = () => {
    setState({ edit: false, value: name });
  };

  const handleConfirm = () => {
    nameChangeHandler(state.value);
    setState((s) => ({ ...s, edit: false }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({ ...s, value: e.target.value }));
  };

  const variationDetails = useMemo(() => visibilityDetails?.find((v) => v.id === id), [visibilityDetails, id]);

  const isEditingName = state.edit;
  const canModify = isTabOpen && (isTemplate || isBlock);
  const canRemove = canModify && !isEditingName && !isDefault;
  const menuEnabled = canModify && !isEditingName;
  const isAutoSelected = isSelected && hasLogic;
  const showSelectIcon = !hasLogic && !isTemplate && !isBlock && (isTabOpen || isSelected);

  const autoSelectIcon = getTabLogicIcon(!!variationDetails, isAutoSelected);
  const defaultIconCopy = getDefaultIconTooltip(isTemplate);
  const defaultIcon = getDefaultTabIcon(isSelected);

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (state.edit && !canModify) {
      handleCancel();
    }
    // eslint-disable-next-line -- handleCancel won't change
  }, [canModify, state.edit]);

  const tab = useCallback(
    ({ onClick }: any) => (
      <VariationMenu
        // px-3 from tabClasses is 12px on both sides
        width={width + 2 * 12}
        isOpen={isMenuOpen}
        onClose={onMenuClose}
        onRename={onEnterRename}
        variationId={id}
        canRemove={canRemove}
      >
        <div
          ref={ref}
          key={id}
          id={id}
          data-testid={name}
          title={name}
          className={tabClasses({ isTabOpen, isSelected })}
          onClick={setCurrent.bind(null, id)}
          style={{
            minWidth: TAB_MIN_W,
          }}
        >
          <Show when={hasLogic && !isDefault}>
            <IconMdi
              path={autoSelectIcon}
              onClick={onClick}
              className={autoSelectedIconClasses({ tabAutoSelected: isSelected })}
            />
          </Show>
          <Show when={isDefault}>
            <TooltipedIcon path={defaultIcon} content={defaultIconCopy} placement="left" />
          </Show>
          <TabNameEditor
            name={name}
            state={state}
            canModify={canModify}
            onChange={handleChange}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
          <div className="flex w-fit flex-shrink-0 items-center">
            <Show when={showSelectIcon}>
              <TooltipedIcon
                path={isSelected ? mdiCheckCircle : mdiCheckCircleOutline}
                className={checkMarkIconClasses({ isSelected })}
                onClick={select.bind(null, id)}
                data-testid="select-variation"
                placement="top"
                content={isSelected ? undefined : 'Select this variation'}
              />
            </Show>
          </div>
          <Show when={menuEnabled}>
            <IconMdi
              path={mdiChevronDown}
              onClick={isMenuOpen ? onMenuClose : onMenuOpen}
              data-testid={getDataTestId('variations.tabMenu')}
              className={clsx(
                'flex cursor-pointer items-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
                'transition-transform duration-300 ease-in-out',
                isMenuOpen && 'rotate-180',
              )}
            />
          </Show>
        </div>
      </VariationMenu>
    ),
    // eslint-disable-next-line -- We don't want to react to changes of functions
    [
      autoSelectIcon,
      canModify,
      canRemove,
      currentVariationIdx,
      hasLogic,
      id,
      isMenuOpen,
      isSelected,
      isTabOpen,
      menuEnabled,
      name,
      showSelectIcon,
      state.edit,
      state.value,
      width,
      variationInView,
      isEditingName,
      isDefault,
      defaultIcon,
      defaultIconCopy,
    ],
  );

  return <VisibilityReasons visibilityDetails={variationDetails} trigger={tab} mode="variation" />;
};
