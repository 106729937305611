import { useEffect, useRef } from 'react';
import { useFuseSearch } from '@hooks/use-fuse-search';
import { resolveSchemaTreeFromForm } from '@root/helpers/schema';
import schema from '@root/helpers/schema/schema-cdr.json';
import type MentionExtension from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import { DatapointsInserterExpanded } from '@WysiwygEditor/components/DatapointInserter/DatapointsInserterExpanded';
import tippy from 'tippy.js';

import { InsertMenuExpandedInner } from '../../InsertMenu';
import { Mention } from '../extensions';
import type { UseEditorHookProps } from '../types';

interface UseMentionExtensionProps extends Pick<UseEditorHookProps, 'enableDatapointsInsert' | 'submission'> {
  initialOptions: { label: string; value: string }[];
}

type MentionExtensionReturnType = undefined | typeof MentionExtension;

export const useMentionExtension = ({
  enableDatapointsInsert,
  submission,
  initialOptions,
}: UseMentionExtensionProps): MentionExtensionReturnType => {
  const resultRef = useRef<{ label: string; value: string }[]>(initialOptions);

  const { onSearch, searchResult } = useFuseSearch({ options: initialOptions });

  useEffect(() => {
    resultRef.current = searchResult;
  }, [searchResult]);

  if (!enableDatapointsInsert) return undefined;

  return Mention().configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: {
      char: '{{',
      items: ({ query }: { query: string }) => {
        onSearch(query);
        return resultRef.current;
      },
      render: () => {
        let reactRenderer: ReactRenderer<typeof InsertMenuExpandedInner, any>;
        let popup: any;

        return {
          onStart: (props: any) => {
            const resolvedSchemaTree = resolveSchemaTreeFromForm(schema, submission?.data_items);
            const handleCancel = () => popup?.[0]?.hide();
            reactRenderer = new ReactRenderer(InsertMenuExpandedInner, {
              props: {
                ...props,
                className: 'max-h-[364px]',
                label: 'Data points',
                isSearchable: false,
                items: initialOptions.map((item) => ({
                  ...item,
                  id: item.value,
                })),
                onCancel: handleCancel,
                renderExpanded: (renderExpandedProps: any) => (
                  <DatapointsInserterExpanded
                    editor={props.editor}
                    onClose={handleCancel}
                    resolvedSchemaTree={resolvedSchemaTree[0]}
                    search={props.query}
                    {...renderExpandedProps}
                    titleTextClasses="max-w-[330px] truncate"
                    sectionRepeaterTitleTextClasses="max-w-[225px] truncate"
                  />
                ),
              },
              editor: props.editor,
            });

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
              theme: 'mention-tooltip',
              arrow: false,
              sticky: false,
            });
          },

          onUpdate(props: any) {
            const parsedItems = props.items.map((item: any) => ({
              ...item,
              id: item.value,
            }));
            reactRenderer?.updateProps({ ...props, items: parsedItems, search: props.query });

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props: any) {
            //@ts-expect-error
            return !!reactRenderer?.ref.handleKeyDown(props.event);
          },
          onExit() {
            popup[0].destroy();
            reactRenderer?.destroy();
          },
        };
      },
    },
  });
};
