import { cva } from 'class-variance-authority';

export const CONTROL_ACTIVE = ['bg-primary-50', 'border-primary-500 ', '!text-primary-600', '!fill-primary-600'];

const controlClassesBase = [
  'bg-transparent',
  'text-info-500',
  'hover:bg-primary-50',
  'rounded',
  'relative',
  'inline-flex',
  'items-center',
  'p-1',
  'text-sm',
  'font-medium',
];

export const controlClasses = cva([...controlClassesBase], {
  variants: {
    isActive: {
      true: CONTROL_ACTIVE,
      false: [],
    },
    isDisabled: {
      true: ['cursor-not-allowed', 'opacity-30'],
      false: [],
    },
    isOpen: {
      true: ['!bg-info-100'],
      false: [],
    },
    hasDropdown: {
      true: ['flex', 'gap-1', 'items-center'],
      false: [],
    },
  },
});
