import type { FC } from 'react';
import Tooltip from '@components/Tooltip';
import { tenantConfig } from '@root/config/tenants';

const TEAMS = tenantConfig.teams ?? [];

interface ItemTeamsProps {
  teams: number[];
}

const ItemTeams: FC<ItemTeamsProps> = ({ teams }) => (
  <td className="group py-4 align-top">
    {teams.map((teamId) => {
      const teamName = TEAMS?.find((team) => team.id === teamId)?.name ?? '';
      if (!teamName) {
        return null;
      }
      return (
        <Tooltip key={teamId} content={teamName}>
          <span className="inline-flex max-w-[2rem] items-center truncate whitespace-nowrap rounded-full bg-info-100 px-2.5 py-0.5 text-xs font-medium text-info-800 sm:max-w-[2rem] md:max-w-[3rem] lg:max-w-[3.2rem] xl:max-w-[6rem] 2xl:max-w-[10rem]">
            <span className="truncate">{teamName}</span>
          </span>
        </Tooltip>
      );
    })}
  </td>
);

export default ItemTeams;
