import { StandardisedSectionName } from '@root/@types/types';
import * as yup from 'yup';

export const sectionSchema = yup.object().shape(
  {
    name: yup
      .string()
      .when('standardisedSectionName', {
        is: (standardisedSectionName: StandardisedSectionName) =>
          !standardisedSectionName || standardisedSectionName.length === 0,
        then: yup.string().required(),
        otherwise: yup.string(),
      })
      .trim(),
    shouldResetPageCounter: yup.boolean().required(),
    shouldHideTitleInPdf: yup.boolean(),
    standardisedSectionName: yup
      .mixed<StandardisedSectionName>()
      .oneOf(Object.values(StandardisedSectionName))
      .when('name', {
        is: (name: string) => !name || name.length === 0,
        then: yup.mixed<StandardisedSectionName>().oneOf(Object.values(StandardisedSectionName)).required(),
        otherwise: yup.mixed<StandardisedSectionName>().oneOf(Object.values(StandardisedSectionName)),
      }),
  },
  [['name', 'standardisedSectionName']],
);
