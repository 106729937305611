import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Show } from '@components/Show';
import Nav from '@root/src/routes';

import Button from '../Button';

interface SignInButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  signInName: string;
  customProvider: string;
}

const SignInButton: FC<SignInButtonProps> = ({ signInName, customProvider }) => {
  const navigate = useNavigate();

  const handleLogin = async () => {
    await Auth.federatedSignIn({ customProvider });
    return navigate(Nav.Submissions);
  };

  return (
    <Button type="button" className="h-10 w-full" onClick={handleLogin}>
      <Show when={signInName === 'Microsoft'}>
        <img src="/assets/microsoft-logo.svg" alt="Microsoft logo" className="h-5 w-5" />
      </Show>
      Sign in with {signInName}
    </Button>
  );
};

export default SignInButton;
