import { cva } from 'class-variance-authority';

const borderColor = 'border-gray-200';

export const containerClasses = cva(
  [
    borderColor,
    'bg-white',
    'border',
    'box-border',
    'flex-col',
    'flex',
    'max-h-64',
    'h-fit',
    'relative',
    'rounded-md',
    'rounded',
    'shadow-md',
    'shadow',
    'text-black',
    'text-sm',
    '!min-w-96',
    'whitespace-nowrap',
    'focus:outline-none',
  ],
  {
    variants: {},
  },
);

export const itemClasses = cva(
  [
    borderColor,
    '!border-b',
    'block',
    'font-medium',
    'hover:bg-primary-50/50',
    'px-4',
    'h-10',
    'flex',
    'items-center',
    'text-info-700',
    'text-left',
    'w-full',
  ],
  {
    variants: {
      isHighlighted: {
        true: ['!bg-primary-50'],
        false: [],
      },
      isConditional: {
        true: ['opacity-50'],
        false: [],
      },
    },
  },
);

export const sectionTitleClasses = cva(
  [
    'py-1',
    'px-4',
    'mb-0.5',
    'text-sm',
    'capitalize',
    'font-bold',
    'text-info-800',
    'sticky',
    'bg-white',
    'top-0',
    'pointer-events-none',
  ],
  {
    variants: {
      isFirst: {
        true: [],
        false: ['mt-3'],
      },
    },
  },
);

export const listHeaderClasses = [
  'bg-info-50',
  'text-gray-500',
  'px-3',
  'py-1',
  'font-medium',
  'border-b',
  borderColor,
];
