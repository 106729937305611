import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { isDefaultVariation } from '@root/helpers/variations';
import { isTemplatePath } from '@utils/app-paths';

import { autoSelectedIconClasses, checkMarkIconClasses, tabClasses } from '../classes';
import { useVariationsTabsLite } from '../context/context-lite';
import { getDefaultIconTooltip, getDefaultTabIcon, getTabLogicIcon } from '../helpers';

interface TabViewLiteProps {
  id: string;
  name: string;
  isTabOpen?: boolean;
  isSelected?: boolean;
  tabAutoSelected?: boolean;
  tabHasLogicDefined?: boolean;
  showSelectIcon?: boolean;
  icon: string;
  onClick?: any;
}

export const TabViewLite: FC<TabViewLiteProps> = ({
  id,
  name,
  isTabOpen,
  isSelected,
  tabAutoSelected,
  showSelectIcon,
  tabHasLogicDefined,
  icon,
  onClick,
}) => {
  const { select: selectVariation, setCurrent: setCurrentVariation, hasLogic } = useVariationsTabsLite();
  const isTemplate = isTemplatePath();
  const onSelect = () => selectVariation(id);
  const onTabClick = () => setCurrentVariation(id);

  const isDefault = isDefaultVariation(id);
  const autoSelectIcon = getTabLogicIcon(tabHasLogicDefined, tabAutoSelected);
  const defaultIconCopy = getDefaultIconTooltip(isTemplate);
  const defaultIcon = getDefaultTabIcon(isSelected);

  return (
    <div
      key={id}
      id={id}
      data-testid={name}
      title={name}
      className={tabClasses({ isTabOpen, isSelected })}
      onClick={onTabClick}
    >
      {hasLogic && !isDefault && (
        <IconMdi path={autoSelectIcon} onClick={onClick} className={autoSelectedIconClasses({ tabAutoSelected })} />
      )}
      {isDefault && <TooltipedIcon path={defaultIcon} content={defaultIconCopy} placement="left" />}

      <div className="flex-shrink overflow-hidden text-ellipsis">{name}</div>
      <div className="flex w-fit flex-shrink-0 items-center">
        {showSelectIcon && (
          <TooltipedIcon
            path={icon}
            className={checkMarkIconClasses({ isSelected })}
            onClick={onSelect}
            data-testid="select-variation"
            placement="top"
            content={isSelected ? undefined : 'Select this variation'}
          />
        )}
      </div>
    </div>
  );
};
