import type { FC } from 'react';
import { FieldSchemaIdTooltip } from '@components/FieldCdrId';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { getFieldSchemaIds } from '@hooks/use-schema-ids';
import { mdiHelpCircleOutline } from '@mdi/js';
import { sortBy } from 'lodash-es';

import type { FormError, UIChildrenMappingProps, UIMapChildrenFn, UIMapChildrenFnArgs } from '../../../../@types/types';
import formatErrors from '../../../formatErrors';

import type { cxInputMatrixElementArgs, cxInputMatrixRowArgs } from './classes';
import { cxInputMatrixElement, cxInputMatrixRow } from './classes';

type Item = any;

interface MakeComponentProps extends Omit<UIMapChildrenFnArgs, 'validationErrors' | 'showQuestionKey'> {
  item: Item;
  hideErrors: boolean;
  validationErrors: FormError[] | null;
  mapChildren: UIMapChildrenFn;
  span?: cxInputMatrixElementArgs['span'];
}

interface InputMatrixProps extends UIChildrenMappingProps {
  item: Item;
  ownKey: string;
  mapChildren: UIMapChildrenFn;
  MakeComponent: FC<MakeComponentProps>;
  columns?: cxInputMatrixRowArgs['columns'];
}

const InputMatrix: FC<InputMatrixProps> = ({
  MakeComponent,
  isDisabled,
  isReadOnly,
  item,
  mapChildren,
  onChange,
  ownKey,
  validationErrors,
  columns = 3,
  hideErrors,
}) => {
  const sortedRows = sortBy(Object.keys(item.properties), (key) => item.properties[key].qid);

  return (
    <div className="[&:not(:first-child)]:mt-2">
      {sortedRows.map((rowKey) => {
        const rows = item.properties[rowKey].properties;
        const sortedColumns = sortBy(Object.keys(rows), (columnKey) => rows[columnKey].qid);

        const { schemaId, parsedCdrIds, parsedCdrNames } = getFieldSchemaIds({
          cdrId: item.properties[rowKey].cdrId,
          cdrName: item.properties[rowKey].cdrName,
          mrcId: item.properties[rowKey].mrcId,
        });

        return (
          <div className="[&:not(:first-child)]:mt-2" key={rowKey}>
            <div className="flex items-center justify-start gap-2 font-semibold">
              <Show when={item.properties[rowKey]['ui:hideTitle'] !== true}>
                <FieldSchemaIdTooltip schemaId={schemaId} cdrIds={parsedCdrIds} cdrNames={parsedCdrNames}>
                  {item.properties[rowKey].title}
                </FieldSchemaIdTooltip>
              </Show>
              {item.properties[rowKey].helperText && (
                <TooltipedIcon
                  path={mdiHelpCircleOutline}
                  placement="top"
                  content={item.properties[rowKey].helperText}
                />
              )}
            </div>
            <div className={cxInputMatrixRow({ columns })}>
              {sortedColumns.map((columnKey) => {
                const inputSchema = {
                  ...item.properties[rowKey].properties[columnKey],
                  key: columnKey,
                };
                const inputKey = [ownKey, rowKey].join('.');
                const span = inputSchema['ui:span'];

                return (
                  <div className={cxInputMatrixElement({ span })} key={`${rowKey}${columnKey}`}>
                    <MakeComponent
                      isDisabled={isDisabled}
                      isReadOnly={isReadOnly}
                      item={inputSchema}
                      mapChildren={mapChildren}
                      hideErrors={Boolean(hideErrors)}
                      onChange={onChange}
                      parentKey={inputKey}
                      parentSchema={item.properties[rowKey]}
                      validationErrors={formatErrors(validationErrors)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InputMatrix;
