import type { FC } from 'react';

import type { BlockLibraryListProps, BlocksCountProps } from '../components';
import { BlocksCount, BlocksLibraryList } from '../components';

interface BlockLibraryListSuccessProps extends BlockLibraryListProps, BlocksCountProps {}

export const BlockLibraryListSuccess: FC<BlockLibraryListSuccessProps> = ({
  count,
  total,
  ...blockLibraryListProps
}) => {
  return (
    <>
      <div className="flex flex-col gap-2 !text-xs text-info-700">
        <BlocksLibraryList {...blockLibraryListProps} total={total} className="col-span-4 h-full overflow-y-auto" />
      </div>
      <BlocksCount count={count} total={total} />
    </>
  );
};
