import clsx from 'clsx';

import { bannerContainerClasses } from '../constants';

export const ContractEndorsementBannerLoading = () => {
  return (
    <div className={clsx(bannerContainerClasses, 'animate-pulse')}>
      <div className="h-5 w-5 rounded-full bg-primary-200" />
      <div className="h-3 w-72 rounded-md bg-primary-200" />
      <div className="h-3 w-32 rounded-md bg-primary-200" />
    </div>
  );
};
