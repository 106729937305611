import type { ResourceBlock } from '@root/@types/base';
import type { LinkedDatapoints } from '@root/@types/resources';
import { isEmpty } from 'lodash-es';

import type { BlockEditFormState } from '../types';

import type { LinkedDatapoint } from './../../../../../../@types/resources';

type ValidationListItem = { name?: string; datapoints: string[] };

export interface ValidateRequiredDatapointsResult {
  list: Array<ValidationListItem | null> | null;
  hasVariations: boolean;
}

export const validateRequiredDatapoints = (
  block: ResourceBlock | undefined,
  formValues: BlockEditFormState | undefined,
): ValidateRequiredDatapointsResult => {
  if (!block || !formValues) {
    return {
      list: null,
      hasVariations: false,
    };
  }

  const hasVariations = !isEmpty(block.variations);

  switch (true) {
    case hasVariations:
      return {
        list: (block.variations ?? [])
          .map((block, idx) => getMissingRequiredDatapoints(block, formValues.variations?.[idx]?.linkedDatapoints))
          .filter(Boolean),
        hasVariations,
      };

    default: {
      const blockRequired = getMissingRequiredDatapoints(block, formValues.linkedDatapoints);
      return {
        list: blockRequired ? [blockRequired] : null,
        hasVariations,
      };
    }
  }
};

const getRequiredIds = (block: Partial<ResourceBlock>): string[] =>
  block.linkedDatapoints?.filter((dp: LinkedDatapoint) => dp.isRequired).map((dp: LinkedDatapoint) => dp.id) ?? [];

const getMissingIds = (datapoints: LinkedDatapoints = [], required: string[]): string[] =>
  required.filter((id) => !datapoints.some((dp) => dp.id === id));

const getMissingRequiredDatapoints = (block: Partial<ResourceBlock>, newLinkedDatapoints?: LinkedDatapoints) => {
  const required = getRequiredIds(block);
  const datapoints = getMissingIds(newLinkedDatapoints, required);
  return !isEmpty(datapoints) ? { name: block.name, datapoints } : null;
};
