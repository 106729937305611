import type { DocumentOperation, SubmissionStatus, TemplateStatus } from '@root/@types/types';
import { DocumentMode } from '@root/@types/types';

type DocumentStatuses = {
  [DocumentMode.SUBMISSIONS]: SubmissionStatus[];
  [DocumentMode.ENDORSEMENTS]: SubmissionStatus[];
  [DocumentMode.TEMPLATES]: TemplateStatus[];
};

const OPERATIONS_ALLOWED_IN_DOC_STATUS: Record<DocumentOperation, DocumentStatuses> = {
  edit: {
    [DocumentMode.SUBMISSIONS]: ['DRAFT'],
    [DocumentMode.ENDORSEMENTS]: ['DRAFT'],
    [DocumentMode.TEMPLATES]: ['DRAFT'],
  },
  'status-change': {
    [DocumentMode.SUBMISSIONS]: ['DRAFT', 'ARCHIVED'],
    [DocumentMode.ENDORSEMENTS]: ['DRAFT', 'ARCHIVED'],
    [DocumentMode.TEMPLATES]: ['DRAFT', 'PUBLISHED', 'ARCHIVED'],
  },
};

export default OPERATIONS_ALLOWED_IN_DOC_STATUS;
