import type { ChangeEvent } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { get, set } from 'lodash-es';

const isEvent = (
  value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): value is ChangeEvent<HTMLInputElement> => {
  return typeof value === 'object' && 'target' in value;
};

export const useBlockEditFieldProps = <T extends FieldValues>(
  hasVariations: boolean,
  currentVariationIdx: number,
  fieldName: string,
) => {
  const { formValues, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));
  const accessor = (hasVariations ? ['variations', currentVariationIdx, fieldName].join('.') : fieldName) as Path<T>;

  const onChange = (value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    return setFormValues((prev) => {
      const newObjectValue = structuredClone(prev) ?? {};
      set(newObjectValue, accessor, isEvent(value) ? value.target.value : value);

      return newObjectValue;
    });
  };

  return {
    onChange,
    name: accessor,
    value: get(formValues, accessor),
  };
};
