import { sectionClasses } from '@ContractBuilder/components/SectionView/Section/classes';
import SectionTitle from '@ContractBuilder/components/SectionView/Section/SectionTitle';
import type { Attachment } from '@ContractBuilder/types';
import clsx from 'clsx';

import { AttachmentItem } from '../components/AttachmentItem';

export const AttachmentSection = ({ attachments }: { attachments: Attachment[] }) => {
  return (
    <div className="mb-8 mt-20 bg-white shadow-lg">
      <div className={clsx(sectionClasses.body)}>
        <div className="group relative">
          <SectionTitle
            standardisedName={'information'}
            id={'attachments'}
            shouldHideTitleInPdf={false}
            shouldResetPageCounter={false}
          >
            Attachments
          </SectionTitle>
        </div>
        {attachments?.map((attachment, idx) => (
          <AttachmentItem attachment={attachment} key={`attachment-section-item-${idx}`} withContextMenu={false} />
        ))}
      </div>
    </div>
  );
};
