import { createContext, useContext } from 'react';
import type { BlockEditFormState, CallbackState } from '@ContractBuilder/modules/block-edit/types';
import type { FormSubmitHandler, HandleSubmitFn } from '@hooks/form';

export interface HandleSubmitArgs {
  skipBlockUsedInTemplatesCheck?: boolean;
}

export type BlockEditHandleSubmitFn = HandleSubmitFn<HandleSubmitArgs>;
export type UseBlockEditFormSubmitHandler = FormSubmitHandler<BlockEditFormState, HandleSubmitArgs>;

export type SetOnCancelCallback = (state: CallbackState) => void;

export interface BlockEditContextValue {
  onCancel: (skipUnsavedChangesCheck?: boolean) => void;
  onSubmit: () => void;
  onSubmitAsNew: () => void;
  onUpdateRequiredDatapoint: (datapointId: string, isRequired: boolean, variationIndex?: number) => void;
  resetState: () => void;
  setEditingBlock: (blockId?: string) => void;
  setOnCancelCallback: SetOnCancelCallback;
  onMandatoryDatapointsModalOpen: () => void;
}

export const BlockEditContext = createContext<BlockEditContextValue | null>(null);

export const useBlockEdit = () => {
  const context = useContext(BlockEditContext);

  if (!context) {
    throw new Error(`"BlockEditProvider" must be present in React DOM tree.`);
  }

  return context;
};
