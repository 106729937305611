import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import { getCurrentDocumentApiUrl } from './getCurrentDocumentApiUrl';

const ATTACHMENT_DOCUMENT_UPLOAD_URI = 'attachments';

interface AttachmentDocumentPresignedUrlResponse {
  data: {
    url: string;
    id: string;
  };
}

export const getAttachmentDocumentPresignedUrl = async (
  fileName: string,
  name: string,
  size: number,
  templateId?: string,
  submissionId?: string,
  endorsementId?: string,
) => {
  const url = [getCurrentDocumentApiUrl(templateId, submissionId, endorsementId), ATTACHMENT_DOCUMENT_UPLOAD_URI].join(
    '/',
  );

  if (!url) {
    return;
  }

  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url,
    data: {
      fileName,
      name,
      size,
    },
  });

  const response = await axios<AttachmentDocumentPresignedUrlResponse>(networkPayload);
  return response;
};
