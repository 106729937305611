import type { BaseUser } from '@root/@types/types';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import type { IAuthProvider } from './types';

const TTL = 1000 * 60 * 60 * 1; // 1 hr

export class EntraAuthProvider implements IAuthProvider {
  async getIsLoggedIn() {
    try {
      await this.refreshUser();
      return true;
    } catch {
      return false;
    }
  }

  async getUserInfo(): Promise<BaseUser | null> {
    let user = this.getCachedUser();

    if (!user?.updatedAt || Date.now() - new Date(user.updatedAt).getTime() > TTL) {
      user = await this.refreshUser();
    }

    return user;
  }

  async getNetworkAuthPayload(): Promise<AxiosRequestConfig> {
    const user = await this.getUserInfo();

    const payload: AxiosRequestConfig = {
      withCredentials: true,
    };

    if (user) {
      payload.headers = {
        companyId: user.companyId,
        aliasId: user.email,
      };
    }

    return payload;
  }

  async logout() {
    const user = this.getCachedUser();

    if (user) {
      await axios({
        baseURL: import.meta.env.VITE_API_URL,
        url: '/auth',
        method: 'delete',
        withCredentials: true,
      });
    }

    this.removeCachedUser();
  }

  private getCachedUser(): BaseUser | null {
    try {
      this.setCompanyIdFromParams(); // Update user with companyId from URL params if available
      const userData = sessionStorage.getItem('user');
      if (userData) {
        return JSON.parse(userData);
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  private removeCachedUser() {
    sessionStorage.removeItem('user');
  }

  private getCompanyIdFromParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const companyId = urlParams.get('CompanyId');

    if (companyId) {
      return companyId;
    }

    return null;
  }

  private setCompanyIdFromParams() {
    try {
      const userData = sessionStorage.getItem('user');
      const companyId = this.getCompanyIdFromParams();

      if (userData && companyId) {
        const user = JSON.parse(userData);
        user.companyId = companyId;
        sessionStorage.setItem('user', JSON.stringify(user));
      }
    } catch {
      return;
    }
  }

  async refreshUser(): Promise<BaseUser | null> {
    try {
      const user = this.getCachedUser();

      const response = await axios({
        baseURL: import.meta.env.VITE_API_URL,
        url: '/me',
        method: 'get',
        withCredentials: true,
        headers: {
          companyid: user?.companyId,
          aliasid: user?.email,
        },
      });

      const data = {
        companyId: response.data.companyId,
        companyName: response.data.companyName,
        email: response.data.aliasId,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        organisationName: response.data.organisationName,
        role: response.data.role,
        teamName: response.data.teamName,
        tenantId: '',
        tenantIds: [],
        updatedAt: new Date().getTime(),
        username: response.data.user,
      } as BaseUser;

      sessionStorage.setItem('user', JSON.stringify(data));
      return data;
    } catch (error) {
      this.removeCachedUser();
      await this.logout();
      return null;
    }
  }
}
