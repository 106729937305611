import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const formatDate = (date?: Date | string, format = 'DD/MMM/YYYY') => {
  if (!date) {
    return undefined;
  }

  if (!dayjs(date).isValid()) {
    if (!process.env.CI) {
      console.error(`error: formatDate: Incorrect date: ${date}`);
    }
    return undefined;
  }

  return dayjs.utc(date).tz('Europe/London').format(format);
};
