import type { MouseEventHandler } from 'react';
import React from 'react';
import { FieldSchemaIdTooltip } from '@components/FieldCdrId';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useContractFormPreventableLink } from '@ContractBuilder/hooks/useContractFormPreventableLink';
import { UnsavedChangesModal } from '@ContractBuilder/modules/block-edit/views/UnsavedChangesModal';
import { useFieldSchemaIds } from '@hooks/use-schema-ids';
import { mdiFileDocumentArrowRightOutline, mdiHelpCircleOutline } from '@mdi/js';
import { type JSONSchemaProperties, StandardisedSectionName, type UIChildrenMappingProps } from '@root/@types/types';
import sharedClasses from '@root/src/utils/shared-classes';
import { useLanguage } from '@src/language';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

import H3 from '../../H3';
import CollapsibleValidatedItem from '../CollapsibleValidatedItem/CollapsibleValidatedItem';

import SectionBody from './SectionBody';

interface SectionProps extends UIChildrenMappingProps {
  className?: string;
  descriptionText: string;
  isHidden: boolean;
  item: any;
  ownKey: any;
  parentSchema?: JSONSchemaProperties;
  cdrId?: string | string[];
  cdrName?: string | string[];
  mrcId?: string;
}

const ItemTitle = ({ title, shortName, helperText }: { title: string; shortName?: string; helperText?: string }) => (
  <>
    {shortName ?? title}
    {!!helperText && <TooltipedIcon path={mdiHelpCircleOutline} placement="top" content={helperText} />}
  </>
);

const containerClasses = cva(['p-6 pb-2 mb-5'], {
  variants: {
    isHidden: {
      true: 'hidden',
    },
    isLevel2: {
      false: ['bg-white', 'shadow', 'rounded-lg'],
    },
  },
});

const headerClasses = cva(['flex', 'items-center', 'mb-4'], {
  variants: {
    helperText: {
      true: ['justify-start', 'gap-2'],
      false: ['justify-between'],
    },
    level: {
      1: [],
      2: ['pb-4'],
      3: [],
      4: ['justify-between'],
    },
  },
});

const getContractSectionHashLink = (ownKey: string) => {
  const standardisedName =
    ownKey === 'broker_remuneration_and_deductions' ? StandardisedSectionName.BrokerRemunerationAndDeductions : ownKey;
  return `${window.location.pathname.replace('/form', '/contract')}#${standardisedName}`;
};

const Section: React.FC<SectionProps> = (props) => {
  const { isHidden, item, ownKey, className, cdrId, cdrName, mrcId } = props;
  const {
    helperText,
    shortName,
    title,
    'ui:startCollapsed': startCollapsed = false,
    'ui:hideTitle': hideTitle = false,
    'ui:collapsible': isCollapsible = true,
  } = item;

  const level = ownKey && ownKey.split('.').length + 1;
  const isLevel2 = level === 2;

  const isRequired = props.parentSchema?.required?.includes(item.key);
  const { schemaId, parsedCdrIds, parsedCdrNames } = useFieldSchemaIds({ cdrId, cdrName, mrcId });

  const { getContent } = useLanguage({ prefix: 'naming.mrc.contractForm' });

  const { isBlocked, onDisregardChanges, onLinkClick, onReset, onSaveChanges } = useContractFormPreventableLink();

  const hashLinkToCorrespondingContractSection = getContractSectionHashLink(ownKey);

  const handleNavigateToSectionClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    return onLinkClick(hashLinkToCorrespondingContractSection);
  };

  return (
    <>
      {isLevel2 || !isCollapsible ? (
        <div className={isCollapsible ? containerClasses({ isHidden, isLevel2, className }) : ''}>
          <Show when={!hideTitle}>
            <FieldSchemaIdTooltip schemaId={schemaId} cdrIds={parsedCdrIds} cdrNames={parsedCdrNames}>
              <H3 data-testid={item.title} className={headerClasses({ level, helperText })} id={item.key}>
                <ItemTitle {...item} />
                &nbsp;
                <button onClick={handleNavigateToSectionClick}>
                  <TooltipedIcon
                    path={mdiFileDocumentArrowRightOutline}
                    content={getContent('viewInContractButtonLabel')}
                    className={clsx(
                      'cursor-pointer text-info-400 hover:text-info-600',
                      sharedClasses.microInteractions,
                    )}
                  />
                </button>
              </H3>
            </FieldSchemaIdTooltip>
          </Show>
          <SectionBody {...props} />
        </div>
      ) : (
        <CollapsibleValidatedItem
          id={ownKey}
          isRequired={isRequired}
          title={shortName ?? title}
          ownKey={ownKey}
          key={ownKey}
          helperText={helperText}
          className={className}
          startCollapsed={startCollapsed}
        >
          <SectionBody {...props} />
        </CollapsibleValidatedItem>
      )}
      <UnsavedChangesModal
        isOpen={isBlocked}
        onCancel={onReset}
        onDisregardChanges={onDisregardChanges}
        onSaveChanges={onSaveChanges}
      />
    </>
  );
};

export default Section;
