import type { ChangeEvent } from 'react';
import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { sanitizeInputProps } from '@ContractBuilder/utils';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import type { JSONSchemaProperties } from '@root/@types/schema';

import type { InputTextareaProps } from '../InputTextarea';
import InputTextarea from '../InputTextarea';

interface InputTextareaSchemaProps extends InputTextareaProps {
  item: JSONSchemaProperties;
  ownKey: string;
}

const InputTextareaSchema: React.FC<InputTextareaSchemaProps> = ({ item, ...props }) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);
  const parsedProps = sanitizeInputProps(props);
  const { onChange } = parsedProps;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (typeof onChange === 'function') {
      const { name, value } = event.target;
      onChange(value === '' ? undefined : value, name);
    }
  };

  return (
    <InputTextarea
      value={value}
      {...parsedProps}
      onChange={handleChange}
      hideErrors={props.hideErrors}
      isIncomplete={isIncomplete}
      minLength={item.minLength}
      maxLength={item.maxLength}
    />
  );
};

export default InputTextareaSchema;
