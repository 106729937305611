import type { FC } from 'react';
import { memo } from 'react';
import { BlockBody } from '@ContractBuilder/modules/block';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import type { ResourceBlock } from '@root/@types/base';
import { isTemplatePath } from '@utils/app-paths';
import { isEqual } from 'lodash-es';

import type { BlockEditFormState } from '../../block-edit/types';
import { blockContentClasses, blockTitleClasses } from '../constants/classes';

interface BlockViewProps {
  block: ResourceBlock | BlockEditFormState;
  className?: string;
  isVisible?: boolean;
  isPdfRender?: boolean;
  isEditing?: boolean;
  isHighlighted?: boolean;
  isInCreateMode?: boolean;
  showEndorsementDiff?: boolean;
}

export const BlockContentInner: FC<BlockViewProps> = ({
  block,
  className,
  isVisible,
  isPdfRender,
  isEditing,
  isHighlighted,
  isInCreateMode,
  showEndorsementDiff,
}) => {
  const isTemplate = isTemplatePath();

  const { title, content = '', order, type, id, name, canEdit } = block;

  const isLockedFromEditing = !isTemplate && !canEdit;
  const isTwoColLayout = ['mrc-heading'].includes(type);
  const disableMentions = isTemplate;

  return (
    <BlockBody
      className={className}
      id={id}
      name={name}
      order={order}
      // flags
      isPDFRender={isPdfRender}
      isEditing={isEditing}
      isHighlighted={isHighlighted}
      isVisible={isVisible}
      isInCreateMode={isInCreateMode}
      isLockedFromEditing={isLockedFromEditing}
      showEndorsementDiff={showEndorsementDiff}
    >
      {isTwoColLayout && <div className={blockTitleClasses({ type, pdf: isPdfRender && type })}>{title}</div>}

      <div
        dangerouslySetInnerHTML={{ __html: isVisible ? sanitizeHtml(content) : '' }}
        className={blockContentClasses({
          type,
          pdf: isPdfRender && type,
          disableMentions,
        })}
      />
    </BlockBody>
  );
};

export const BlockContent = memo(BlockContentInner, isEqual);
