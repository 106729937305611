import type { Submission, UpdateEndorsementDataPayload } from '@root/@types/types';
import axios from 'axios';

import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const createEndorsement =
  (submissionId: string) =>
  async (data: UpdateEndorsementDataPayload): Promise<Pick<Submission, 'id'>> => {
    const networkPayload = await getRequestAuthPayload({
      method: 'post',
      url: `submissions-spa-al/${submissionId}/endorsements-spa-al`,
      data,
    });

    const response = await axios(networkPayload);
    return response?.data;
  };
