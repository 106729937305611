import type { FC } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination } from '@components/Table';

interface ServerSidePaginationProps {
  totalAmount?: number;
  page: number;
  pageSize: number;
}

export const ServerSidePagination: FC<ServerSidePaginationProps> = ({ totalAmount = 0, page, pageSize }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const buildLinkToNewPage = (newPageIndex: number) => {
    const newSearchParams = createSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: String(newPageIndex + 1),
    });

    return navigate({ pathname, search: newSearchParams.toString() });
  };

  return <Pagination onPageChange={buildLinkToNewPage} page={page} pageSize={pageSize} totalResults={totalAmount} />;
};
