import type { RefObject } from 'react';
import { useEffect } from 'react';
import type { Dayjs } from 'dayjs';

interface UseScrollToActiveTimeLabelArgs {
  id: string;
  isOpen: boolean;
  hrsContainerRef: RefObject<HTMLUListElement>;
  minsContainerRef: RefObject<HTMLUListElement>;
  parsedValue?: Dayjs;
}

export const useScrollToActiveTimeLabel = ({
  id,
  isOpen,
  hrsContainerRef,
  minsContainerRef,
  parsedValue,
}: UseScrollToActiveTimeLabelArgs) => {
  useEffect(() => {
    if (parsedValue && isOpen) {
      const hrsElement = document.getElementById(`${id}-hour-${parsedValue.hour()}`);
      const minsElement = document.getElementById(`${id}-minute-${parsedValue.minute()}`);

      if (hrsContainerRef.current && hrsElement) {
        hrsContainerRef.current.scrollTo({ top: hrsElement?.offsetTop, behavior: 'smooth' });
      }

      if (minsContainerRef.current && minsElement) {
        minsContainerRef.current.scrollTo({ top: minsElement?.offsetTop, behavior: 'smooth' });
      }
    }
  }, [hrsContainerRef, id, isOpen, minsContainerRef, parsedValue]);

  return null;
};
