import type { FC, MouseEventHandler } from 'react';
import type { PopoverClickEventHandler } from '@components/Popover';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import MODALS from '@constants/modals';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { ctxIsTemplate } from '@ContractBuilder/rules/block/is-ctx';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useModal } from '@hooks/useModal';
import { mdiCog, mdiCommentPlusOutline, mdiCommentProcessingOutline, mdiPencil, mdiTrashCanOutline } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import { BLOCK_ACTIONS_HOVERED_Z_INDEX, BLOCK_ACTIONS_Z_INDEX } from '@root/src/constants/z-indices';
import clsx from 'clsx';

import { blockActionsClasses, iconClasses } from './classes';
import { PreviewInPDFTooltipedIcon } from './PreviewInPDFTooltipedIcon';
import { getId } from './utils';

interface BlockActionsProps {
  block: ResourceBlock;
  handleDelete: MouseEventHandler;
  handleEdit: MouseEventHandler<HTMLDivElement>;
  onCommentClick: MouseEventHandler;
  onShowComments: PopoverClickEventHandler;
}

export const BlockActions: FC<BlockActionsProps> = ({
  block,
  handleDelete,
  handleEdit,
  onCommentClick,
  onShowComments,
}) => {
  const isInEditView = useBlockEditFormStore(({ formValues }) => formValues?.id !== undefined);
  const isEndorsementView = useUIStore(({ isEndorsementView }) => isEndorsementView);
  const { commentsCount, name, id } = block;
  const { showModal } = useModal();

  const openVisibilityModal = () => showModal(MODALS.BLOCK_VISIBILITY_MODAL, { blockId: id });

  const {
    context,
    details: { isDeletable, isEditable },
  } = useBlockFlags();
  const isTemplate = ctxIsTemplate(context);

  const hasCommentsFeature = useFeatureFlags()('Comments') && !isEndorsementView;
  const hasComments = commentsCount > 0 && hasCommentsFeature;

  return (
    <div
      className={clsx(
        'absolute right-0 top-0 translate-x-full py-1',
        isInEditView && 'hidden',
        BLOCK_ACTIONS_Z_INDEX,
        BLOCK_ACTIONS_HOVERED_Z_INDEX,
      )}
    >
      <div className={clsx(blockActionsClasses(), BLOCK_ACTIONS_Z_INDEX, BLOCK_ACTIONS_HOVERED_Z_INDEX)}>
        <Show when={hasComments}>
          <div
            className={clsx([
              'absolute',
              '-right-1.5',
              'top-0',
              'rounded-full',
              'p-1',
              'bg-primary-600',
              'text-white',
              'inline-flex',
              'items-center',
              'justify-center',
              'text-xs font-medium leading-5',
              'w-5',
              'h-5',
            ])}
          >
            {commentsCount}
          </div>
        </Show>
        <div className={'flex h-fit flex-col gap-3'}>
          <Show when={hasComments}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses({ comments: hasComments })}
              data-testid={getId('show-comments', name)}
              onClick={onShowComments}
              content="Block comments"
              path={mdiCommentProcessingOutline}
            />
          </Show>
          <Show when={isEditable}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses()}
              data-testid={getId('edit', name)}
              path={mdiPencil}
              onClick={handleEdit}
              content="Edit block"
            />
          </Show>
          <Show when={hasCommentsFeature}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses()}
              data-testid={getId('comment', name)}
              path={mdiCommentPlusOutline}
              onClick={onCommentClick}
              content="Start new comment thread"
            />
          </Show>
          <Show when={isEditable && isTemplate}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses()}
              data-testid={getId('visibility', name)}
              path={mdiCog}
              onClick={openVisibilityModal}
              content="Configure visibility"
            />
          </Show>
          {/** Temporarily disable `Jump to PDF` button **/}
          <Show when={false}>
            <PreviewInPDFTooltipedIcon block={block} />
          </Show>
          <Show when={isDeletable}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses()}
              data-testid={getId('delete', name)}
              path={mdiTrashCanOutline}
              onClick={handleDelete}
              content="Remove block"
            />
          </Show>
        </div>
      </div>
    </div>
  );
};
