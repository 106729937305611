export const getLockedBlockPermissionsMessage = (
  canEdit = true,
  canDelete = true,
  canEditOnTemplate = true,
  canDeleteOnTemplate = true,
  isTemplate = false,
) => {
  const shouldShowTemplatePermissionsDeleteMessage = isTemplate && !canDeleteOnTemplate;
  const shouldShowTemplatePermissionsEditMessage = isTemplate && !canEditOnTemplate;

  const shouldShowSubmissionPermissionsEditMessage = !isTemplate && !canEdit;
  const shouldShowSubmissionPermissionsDeleteMessage = !isTemplate && !canDelete;

  if (shouldShowTemplatePermissionsDeleteMessage && shouldShowTemplatePermissionsEditMessage) {
    return 'This block can only be edited by a super admin and not removed';
  }

  if (shouldShowTemplatePermissionsDeleteMessage) {
    return 'This block is not allowed to be removed';
  }

  if (shouldShowTemplatePermissionsEditMessage) {
    return 'This block can only be edited by a super admin';
  }

  if (shouldShowSubmissionPermissionsEditMessage && shouldShowSubmissionPermissionsDeleteMessage) {
    return `This block is not allowed to be edited or removed`;
  }

  if (shouldShowSubmissionPermissionsEditMessage || shouldShowTemplatePermissionsEditMessage) {
    return `This block is not allowed to be edited`;
  }

  if (shouldShowSubmissionPermissionsDeleteMessage) {
    return `This block is not allowed to be removed`;
  }
  return '';
};
