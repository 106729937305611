import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { TransformedCognitoUser } from '../../@types/cognito';

type UpdateUserPicks = 'email' | 'first_name' | 'last_name' | 'role' | 'teams' | 'identity_provider';
type UpdateUserAction = Pick<TransformedCognitoUser, UpdateUserPicks> & { action: 'update' };
type ResentInviteAction = { action: 'resend-invite' };
type ChangeUserStateAction = Pick<TransformedCognitoUser, 'enabled'> & {
  action: 'change-state';
};

type Data = ResentInviteAction | UpdateUserAction | ChangeUserStateAction;

export const updateUser = async (id: string, data: Data): Promise<TransformedCognitoUser> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `users-spa-al/${id}`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
