import { Fragment } from 'react';
import { useUserStore } from '@Auth/store';
import { USER_AVATAR_Z_INDEX } from '@constants/z-indices';
import { Popover, Transition } from '@headlessui/react';
import { getInitials } from '@helpers/getInitials';
import { MenuSectionUser } from '@User/Menu/MenuSection.User';
import clsx from 'clsx';
import qs from 'qs';

import Avatar from './Avatar';
import { Show } from './Show';

const AvatarMenu = () => {
  const { authToken } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const user = useUserStore(({ user }) => user);
  const initials = user ? getInitials(user) : '';

  return (
    <Show when={!authToken}>
      <Popover className="relative">
        <Popover.Button className="flex rounded-full text-sm text-white focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <Avatar initials={initials} />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className={clsx('absolute ml-8 max-w-min -translate-x-full transform', USER_AVATAR_Z_INDEX)}>
            <div className="mt-2 w-60 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Show when={!!user}>
                <MenuSectionUser user={user!} variant="light" />
              </Show>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </Show>
  );
};

export default AvatarMenu;
