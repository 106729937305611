import { CheckIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import type { BlockLayoutOption } from './types';

interface SelectorOptionProps {
  option: BlockLayoutOption;
  selected?: boolean;
  active?: boolean;
}

export const SelectorOption = ({ option, active, selected }: SelectorOptionProps) => (
  <div className="flex  place-items-center justify-between py-1">
    <div className="flex place-items-center gap-2">
      {option.element}
      <p className={clsx(active ? 'text-primary-500' : 'text-info-500', 'text-xs uppercase')}>{option.title}</p>
    </div>
    {selected ? (
      <span className={'text-primary-500'}>
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
    ) : null}
  </div>
);
