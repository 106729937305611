import type { FC, ReactNode } from 'react';
import { memo, useState } from 'react';
import type { PopoverRenderTriggerRenderProps } from '@components/Popover';
import { Popover } from '@components/Popover';
import { AddNewCommentController } from '@ContractBuilder/modules/add-new-comment/controller/AddNewCommentController';
import { autoPositionCommentPopovers } from '@ContractBuilder/modules/block/utils/auto-position-comment-popovers';
import { getSortedBlockComments } from '@ContractBuilder/modules/block/utils/get-sorted-block-comments';
import { CommentThreadPopoverInner } from '@ContractBuilder/modules/block/view/CommentThreadPopoverInner';
import { PopoverInner } from '@ContractBuilder/modules/block/view/PopoverInner';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { useEntityStore } from '@ContractBuilder/store';
import { shift } from '@floating-ui/react';
import { useDeepCompareMemo } from '@src/hooks';
import { AnimatePresence } from 'framer-motion';
import { groupBy, isEqual } from 'lodash-es';

export interface CommentsPopoverRenderProps extends PopoverRenderTriggerRenderProps {
  shouldShowCommentsBadge: boolean;
}

interface CommentsPopoverProviderProps {
  blockId: string;
  children: (props: CommentsPopoverRenderProps) => ReactNode;
}

export const CommentsPopoverProvider: FC<CommentsPopoverProviderProps> = memo(function CommentsPopoverProvider({
  blockId,
  children,
}) {
  const { submission } = useEntityStore(({ submission }) => ({ submission }));
  const blockComments = useDeepCompareMemo(
    () => getSortedBlockComments(blockId, submission),
    // eslint-disable-next-line -- We only care about `comments` changing, not the entire submission
    [blockId, submission?.comments],
  );

  const [forceIsOpen, setForceIsOpen] = useState(false);
  useCustomEventListener(ArtificialCustomEvent.ToggleCommentsVisibility, ({ blockId: payloadBlockId, isOpen }) => {
    if (payloadBlockId && payloadBlockId !== blockId) {
      return;
    }

    setForceIsOpen(isOpen);
  });

  const hasComments = blockComments.length > 0;
  const [isNewThreadActive, setIsNewThreadActive] = useState(!hasComments);

  useCustomEventListener(ArtificialCustomEvent.CreateNewCommentsThread, (id) => {
    if (id === blockId) {
      setIsNewThreadActive(true);
    }
  });

  const groupedComments = groupBy(blockComments, (comment) => comment.thread_id);

  return (
    <Popover
      id={blockId}
      containerClassName="p-1 flex flex-col gap-y-3 w-[320px]"
      forceIsOpen={blockComments.length > 0 && forceIsOpen}
      isMinimal
      renderTrigger={({ onClick }) => children({ onClick, shouldShowCommentsBadge: !forceIsOpen })}
      middleware={[shift({ mainAxis: false, crossAxis: true, padding: 8 }), autoPositionCommentPopovers]}
      offset={{ mainAxis: 45, crossAxis: 35 }}
      placement="right-start"
      wrapperClassName="w-[320px]"
    >
      {({ onClose }) => (
        <div>
          <AnimatePresence>
            {Object.entries(groupedComments).map(([threadId, threadComments]) => (
              <CommentThreadPopoverInner
                blockId={blockId}
                data={threadComments}
                key={`${threadId}-${threadComments.length}`}
                onAfterResolve={(data) => {
                  const newCommentsCount = getSortedBlockComments(blockId, data).length;

                  if (newCommentsCount === 0) {
                    return onClose();
                  }
                }}
                threadId={threadId}
              />
            ))}
          </AnimatePresence>
          {isNewThreadActive && (
            <PopoverInner>
              <AddNewCommentController blockId={blockId} onAfterSubmit={() => setIsNewThreadActive(false)} />
            </PopoverInner>
          )}
        </div>
      )}
    </Popover>
  );
}, isEqual);
