import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { range } from 'lodash-es';

import { MIN_PAGES_WITHOUT_PAGINATION } from '../constants';
import { getPaginationItems } from '../utils';

import { PaginationItem } from './PaginationItem';

interface PaginationItemsProps {
  currentPageIndex: number;
  onPageChange: (newPageIndex: number) => void;
  totalPages: number;
}

const renderDots = () => (
  <span
    className="relative inline-flex items-center border border-info-300 bg-white px-4 py-2 text-sm font-medium text-info-700"
    key="pagination-dots"
  >
    ...
  </span>
);

export const PaginationItems: FC<PaginationItemsProps> = ({ currentPageIndex, onPageChange, totalPages }) => {
  const paginationItems = useMemo(
    () => getPaginationItems(currentPageIndex, totalPages),
    [currentPageIndex, totalPages],
  );

  if (totalPages <= MIN_PAGES_WITHOUT_PAGINATION) {
    return range(1, totalPages + 1).map((i) => (
      <PaginationItem currentPageIndex={currentPageIndex} key={`page-number-${i}`} onClick={onPageChange} page={i} />
    ));
  }

  return paginationItems.map((item, idx) =>
    typeof item === 'number' ? (
      <PaginationItem
        currentPageIndex={currentPageIndex}
        key={`page-number-${item}`}
        onClick={onPageChange}
        page={item}
      />
    ) : (
      <Fragment key={`dot-${idx}`}>{renderDots()}</Fragment>
    ),
  );
};
