import { cva } from 'class-variance-authority';

export const modalTabsClasses = cva(
  [
    'text-sm',
    'h-auto',
    'py-2',
    'px-3',
    'cursor-pointer',
    'border-r',
    'border-info-300',
    'whitespace-nowrap',
    'overflow-hidden',
    'hover:bg-info-100',
    'select-none',
    'flex',
    'gap-2',
    'w-fit',
    'min-w-[10%]',
  ],
  {
    variants: {
      isTabOpen: {
        true: ['!bg-primary-100', 'flex-shrink-0'],
        false: ['flex-shrink'],
      },
      isSelected: {
        true: [],
        false: [],
      },
    },
  },
);
