import type React from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { ResourceBlock } from '@root/@types/base';
import { getAllBlocks } from '@root/helpers/blocks';
import { isEqual } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

import { getFormStateFromBlock } from './utils/get-form-state-from-block';
import { getUpdateRelevantFields } from './utils/get-update-relevant-fields';
import { updateDataPointsInForm } from './utils/update-datapoints-in-form';
import type { BlockEditFormState } from './types';

interface BlockEditFormStoreState {
  currentBlock?: ResourceBlock;
  formValues?: BlockEditFormState;
  hasChanges: boolean;
  reset: () => void;
  setFormValues: React.Dispatch<React.SetStateAction<Partial<BlockEditFormState> | undefined>>;
  setEditingBlockId: (id?: string) => void;
}

export const useBlockEditFormStore = createWithEqualityFn<BlockEditFormStoreState>(
  (set, get) => ({
    currentBlock: undefined,
    formValues: undefined,
    hasChanges: false,
    reset: () => {
      return set({
        currentBlock: undefined,
        formValues: undefined,
        hasChanges: false,
      });
    },
    setFormValues: (param) => {
      const oldFormValues = get().formValues;
      const nextFormValues = typeof param === 'function' ? param(oldFormValues) : param;

      // @ts-expect-error
      const parsedNextFormValues = updateDataPointsInForm(nextFormValues, oldFormValues);

      const currentBlock = get().currentBlock;
      const isInCreateMode = parsedNextFormValues.id === '';
      const isEndorsementView = useUIStore.getState().isEndorsementView;

      if (!isInCreateMode && currentBlock?.id !== parsedNextFormValues?.id) {
        const submission = useEntityStore.getState().submission;
        const newCurrentBlock = getAllBlocks(submission, isEndorsementView).find(
          (block) => block.id === parsedNextFormValues?.id,
        );

        return set({
          hasChanges: false,
          currentBlock: newCurrentBlock,
          formValues: parsedNextFormValues,
        });
      }
      const original = getUpdateRelevantFields(currentBlock);
      const next = getUpdateRelevantFields(parsedNextFormValues);

      return set({
        hasChanges: !isEqual(original, next),
        formValues: parsedNextFormValues,
      });
    },
    setEditingBlockId: (blockId) => {
      if (!blockId) {
        return get().reset();
      }

      const isEndorsementView = useUIStore.getState().isEndorsementView;
      const submission = useEntityStore.getState().submission;

      const block = getAllBlocks(submission, isEndorsementView).find((block) => block.id === blockId);
      const newState = getFormStateFromBlock(submission, blockId);

      return set({ formValues: newState, currentBlock: block });
    },
  }),
  isEqual,
);
