import type { FC } from 'react';
import { InlineEditableText } from '@components/InlineEditableText';
import InputSelect from '@components/InputSelect';
import Tooltip from '@components/Tooltip';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import type { OnSaveFn } from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import type { EntityData } from '@ContractBuilder/types';
import { BriefcaseIcon } from '@heroicons/react/solid';
import { getDefaultPdfBranding, getPdfBrandingList } from '@pdf/custom/config/branding/config-default';
import type { UIInputValue } from '@root/@types/types';

import { useEditableField } from '../hooks';

interface EditableBrandingFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn;
}

export const EditableBrandingField: FC<EditableBrandingFieldProps> = ({ entity, hasOperationsDisabled, onSave }) => {
  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField({
    defaultValue: entity?.branding ?? getDefaultPdfBranding(),
    name: 'branding',
    onSave,
  });

  return (
    <div className="flex items-center gap-1">
      <Tooltip content="Branding">
        <span>
          <BriefcaseIcon className="h-4 w-4" />
        </span>
      </Tooltip>
      <InlineEditableText
        editor={
          <InputSelect
            value={state.value as UIInputValue}
            options={getPdfBrandingList()?.map((label) => ({ name: label, value: label }))}
            onChange={(value) => onChange(value ?? '')}
            name="branding"
            isDisabled={false}
            size="sm"
            menuOuterClassName="min-w-fit"
          />
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={entity?.branding ?? getDefaultPdfBranding()}
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? 'Edit branding' : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
