import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { StateFromBlock } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import type { BlockVariation } from '@root/@types/database';
import type { BlockLevelVisibilityConfig, BlockVisibilityConfig } from '@root/@types/types';
import { useDeepCompareCallback, useDeepCompareMemo } from '@src/hooks';
import { isTemplatePath } from '@utils/app-paths';
import { cloneDeep, get, set, unset } from 'lodash-es';

import { EMPTY_CONDITION_STATE, INITIAL_BLOCK_VISIBILITY_STATE } from '../constants';
import type { SetFormValues } from '../types';
import { BlockVisibilityFormView } from '../view/BlockVisibilityFormView';

import type { BlockVisibilityFormContextValue } from './context';
import { BlockVisibilityFormContext } from './context';

interface BlockVisibilityFormControllerProps {
  formValues: StateFromBlock;
  setFormValues: SetFormValues;
}

export const BlockVisibilityConfigurationForm: FC<BlockVisibilityFormControllerProps> = ({
  formValues,
  setFormValues,
}) => {
  const isTemplate = isTemplatePath();
  const { isLoading } = useEntityStore(({ isLoading }) => ({ isLoading }));
  const [tab, setTab] = useState<string | null>(null);

  const variations: BlockVariation[] = formValues?.variations ?? [];
  const hasVariations = Boolean(variations.length);

  useEffect(() => {
    if (hasVariations) {
      setTab(variations[1].id);
    }
    // eslint-disable-next-line -- Run only when `hasVariations` changes
  }, [hasVariations]);

  const basePath = useMemo(() => {
    if (!tab) {
      return 'visibility.block';
    }

    const variationIdx = variations?.findIndex(({ id }) => id === tab);

    if (variationIdx === -1) {
      return 'visibility.block';
    }

    const variationsVisibilityArray = get(
      formValues,
      'visibility.variations',
      [],
    ) as BlockVisibilityConfig['variations'];

    if (!variationsVisibilityArray) {
      return 'visibility.block';
    }

    const currentVariationConditionsIndex = variationsVisibilityArray.findIndex((item) => item && item.id === tab);

    if (currentVariationConditionsIndex === -1) {
      return `visibility.variations.${variationsVisibilityArray.length}`;
    }

    return `visibility.variations.${currentVariationConditionsIndex}`;
    // eslint-disable-next-line -- Run only when `tab` changes
  }, [tab]);

  const conditionsPath = useMemo(() => basePath + '.conditions', [basePath]);
  const isReadOnly = !isTemplate;
  const isDisabled = isLoading;

  const currentBlockOrVariation = get(formValues, basePath);
  const currentConditions = get(currentBlockOrVariation, 'conditions', []);

  const handleAdd = useDeepCompareCallback(() => {
    const cloned: any = cloneDeep(formValues);
    const isVariation = typeof tab === 'string';
    const hasId = !!get(cloned, basePath + '.id');

    if (isVariation && !hasId) {
      set(cloned, basePath, { id: tab, mode: 'AUTOSELECT', match: 'OR' });
    }

    const currentVisibilityConfig = get(cloned, basePath);

    if (!currentVisibilityConfig) {
      set(cloned, basePath, INITIAL_BLOCK_VISIBILITY_STATE);
    }

    set(cloned, conditionsPath, [...currentConditions, EMPTY_CONDITION_STATE]);
    setFormValues(cloned);
  }, [basePath, conditionsPath, currentConditions, formValues, setFormValues, tab]);

  const handleRemove = useDeepCompareCallback(
    (itemIndex: number) => {
      const cloned: any = cloneDeep(formValues);
      const filtered = currentConditions.filter((_condition: any, index: number) => index !== itemIndex);

      set(cloned, conditionsPath, filtered);

      if (itemIndex === 0 && filtered.length === 0) {
        unset(cloned, basePath);
      }
      setFormValues(cloned);
    },
    [basePath, conditionsPath, currentConditions, formValues, setFormValues],
  );

  const handleRemoveAll = useDeepCompareCallback(() => {
    const cloned: any = cloneDeep(formValues);
    unset(cloned, basePath);
    setFormValues(cloned);
  }, [basePath, formValues, setFormValues]);

  const hasEmptyConditions = useMemo(() => {
    const config = get(formValues, basePath) as BlockLevelVisibilityConfig;
    return config?.conditions?.some(({ field }) => !field);
  }, [formValues, basePath]);

  const value: BlockVisibilityFormContextValue = useDeepCompareMemo(
    () => ({
      basePath,
      formValues,
      handleAdd,
      handleRemove,
      handleRemoveAll,
      hasEmptyConditions,
      isDisabled,
      isReadOnly,
      setFormValues,
      setTab,
      tab,
    }),
    [
      basePath,
      formValues,
      handleAdd,
      handleRemove,
      handleRemoveAll,
      hasEmptyConditions,
      isDisabled,
      isReadOnly,
      setFormValues,
      tab,
    ],
  );

  return (
    <BlockVisibilityFormContext.Provider value={value}>
      <BlockVisibilityFormView />
    </BlockVisibilityFormContext.Provider>
  );
};
