import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const fetchTags = async (): Promise<Tag[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/tags-spa-al',
  });

  const response = await axios(networkPayload);
  return response.data;
};
