import React, { forwardRef } from 'react';
import { sanitizeInputProps } from '@ContractBuilder/utils';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { omit } from 'lodash-es';

import type { ElementSize } from '../../@types/types';

import Icon from './Icon';
import { ELEMENT_SIZES } from './shared';

import './InputReadOnly.css';

const inputClasses = cva('-mt-2 w-full text-sm font-light', {
  variants: {
    size: ELEMENT_SIZES,
    error: {
      true: 'border-error-500 text-error-500',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

interface InputReadonlyProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  placeholderText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  name?: string;
  errors?: string | string[];
  size?: ElementSize;
}

const InputReadOnly = forwardRef<any, InputReadonlyProps>(function Input(
  { className, placeholderText, size = 'md', isDisabled, isRequired, id, name, errors, ...props },
  ref,
) {
  const parsedProps = omit(sanitizeInputProps(props), [
    'cdrId',
    'cdrName',
    'helperText',
    'labelIcon',
    'mrcId',
    'mrcName',
    'ownKey',
    'shortName',
  ]);

  return (
    <div className={'relative'}>
      <input
        className={inputClasses({ error: !!errors, size, className })}
        disabled={isDisabled}
        id={id ?? name}
        placeholder={placeholderText}
        readOnly
        ref={ref}
        required={isRequired}
        {...parsedProps}
      />
      <div className={clsx('read-only-icon absolute right-0 z-10 flex w-10 items-center justify-center')}>
        <Icon name="lock" className="w-3 fill-current text-info-800" />
      </div>
    </div>
  );
});

export default InputReadOnly;
