import React from 'react';
import clsx from 'clsx';

const H4: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
  <h4 className={clsx('text-lg font-semibold leading-relaxed', className)} {...props}>
    {children}
  </h4>
);

// eslint-disable-next-line react-refresh/only-export-components
export default H4;
