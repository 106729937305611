export const getPage = (page: string | null | undefined, totalAmount: number, limit: number) => {
  try {
    const pageAsNumber = Number(page);
    if (isNaN(pageAsNumber) || pageAsNumber < 1) {
      return 1;
    }
    const maxPage = Math.ceil(totalAmount / limit);
    if (pageAsNumber > maxPage) {
      return maxPage;
    }
    return pageAsNumber;
  } catch {
    return 1;
  }
};
