import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { CircleIconWrapper } from '@ContractBuilder/contract-builder-header/components/CircleIconWrapper';
import { ViewPath } from '@ContractBuilder/contract-builder-header/constants';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { useEntityStore } from '@ContractBuilder/store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import type { EntityData } from '@ContractBuilder/types';
import { ChatAltIcon, ClockIcon } from '@heroicons/react/outline';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import type { ResourceBlock } from '@root/@types/base';
import { getSortedEntries } from '@root/helpers';
import { scrollIntoView } from '@root/src/utils/scroll-into-view';
import clsx from 'clsx';

export const ShowCommentsAndActivitySection = () => {
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);
  const { closeDrawer, isDrawerOpen, openDrawer, state } = useDrawerStore(
    ({ closeDrawer, isOpen, openDrawer, state }) => ({
      closeDrawer,
      isDrawerOpen: isOpen,
      openDrawer,
      state,
    }),
  );
  const { submission, loadSubmissionRevision } = useEntityStore(({ submission, loadSubmissionRevision }) => ({
    submission,
    loadSubmissionRevision,
  }));
  const [areCommentsActive, setCommentsVisibility] = useState(false);
  const [currentRevision, setCurrentRevision] = useState<EntityData | null>(null);
  const { pathname } = useLocation();
  const getFeatureFlagState = useFeatureFlags();

  const hasComments = getFeatureFlagState('Comments');
  const hasRevisionHistory = getFeatureFlagState('Revisions');

  const isActivityDrawerOpen = isDrawerOpen && state?.type === DrawerLayoutComponent.Activity;
  const isRevisionHistoryDrawerOpen = isDrawerOpen && state?.type === DrawerLayoutComponent.RevisionHistory;

  const dispatchCustomEvent = useDispatchCustomEvent();
  const isEditing = editingBlockId !== undefined;

  const handleCommentsDrawerToggleClick = () => {
    const allBlocks =
      submission?.sections.flatMap(({ blocks, layout }: EntityData['sections'][0]) =>
        getSortedEntries(layout, blocks),
      ) ?? [];
    const firstBlockWithComments = allBlocks.find(
      (block: ResourceBlock) =>
        submission?.comments.some((comment) => !comment.is_resolved && comment.block_id === block.id),
    );

    if (!areCommentsActive && firstBlockWithComments) {
      return scrollIntoView(firstBlockWithComments.id, { block: 'start' }, 0, () => setCommentsVisibility(true));
    }

    return setCommentsVisibility((current) => !current);
  };

  useEffect(() => {
    dispatchCustomEvent(ArtificialCustomEvent.ToggleCommentsVisibility, { isOpen: areCommentsActive });
  }, [areCommentsActive, dispatchCustomEvent]);

  useEffect(() => {
    if (isDrawerOpen) {
      setCommentsVisibility(false);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    const currentPath = pathname.split('/').pop() as ViewPath;
    if (![ViewPath.Contract, ViewPath.Endorsements].includes(currentPath) && !isRevisionHistoryDrawerOpen) {
      closeDrawer();
    }
    // eslint-disable-next-line -- We only want to run this hook when switching between contract and form views
  }, [pathname]);

  const handleActivityDrawerToggleClick = () => {
    if (isRevisionHistoryDrawerOpen) {
      return;
    }
    if (isActivityDrawerOpen) {
      return closeDrawer();
    }

    return openDrawer({ type: DrawerLayoutComponent.Activity, props: {} });
  };

  if (!submission) {
    return null;
  }

  const commentsCount = submission?.comments?.length ?? 0;
  const areCommentsDisabled = isEditing || isDrawerOpen || commentsCount === 0;

  const handleRevisionHistoryDrawerToggleClick = () => {
    if (isRevisionHistoryDrawerOpen) {
      loadSubmissionRevision(currentRevision!);
      closeDrawer();
      return;
    }
    setCurrentRevision(submission!);
    openDrawer({ type: DrawerLayoutComponent.RevisionHistory, props: {} });
  };

  return (
    <div className="flex gap-x-3">
      <Show when={hasComments}>
        <Tooltip content={`${areCommentsActive ? 'Hide' : 'Show'} comments`} disabled={areCommentsDisabled}>
          <CircleIconWrapper
            icon={ChatAltIcon}
            isActive={areCommentsActive}
            onClick={handleCommentsDrawerToggleClick}
            wrapperClassName={clsx(areCommentsDisabled && 'pointer-events-none bg-transparent opacity-50')}
          />
        </Tooltip>
      </Show>
      <Tooltip content={`${isActivityDrawerOpen ? 'Hide' : 'Show'} activity`} disabled={isEditing}>
        <CircleIconWrapper
          icon={ClockIcon}
          isActive={isActivityDrawerOpen}
          onClick={handleActivityDrawerToggleClick}
          wrapperClassName={clsx(
            isEditing && 'pointer-events-none opacity-50',
            isRevisionHistoryDrawerOpen && 'pointer-events-none bg-transparent opacity-50',
          )}
        />
      </Tooltip>
      <Show when={hasRevisionHistory}>
        <Tooltip content="Revision history" disabled={isEditing}>
          <CircleIconWrapper
            icon={(props) => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                {...props}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                />
              </svg>
            )}
            isActive={isRevisionHistoryDrawerOpen}
            onClick={handleRevisionHistoryDrawerToggleClick}
            wrapperClassName={clsx(
              isEditing && 'pointer-events-none opacity-50',
              isActivityDrawerOpen && 'pointer-events-none bg-transparent opacity-50',
            )}
          />
        </Tooltip>
      </Show>
    </div>
  );
};
