import type { FC } from 'react';
import { Fragment, useState } from 'react';
import Button from '@components/Button';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

interface ConfirmBrandingStatusModalProps {
  handleClose: () => void;
  onConfirm: (status: string) => Promise<void>;
  status: string;
}

const ConfirmBrandingStatusModal: FC<ConfirmBrandingStatusModalProps> = ({ handleClose, onConfirm, status }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(status);
    setIsLoading(false);
    handleClose();
  };

  const isDocValid = true;

  const config = {
    valid: {
      title: 'Ready to finalise branding?',
      content:
        'By setting this branding to published it will become available for users when creating new templates and submissions.',
      buttons: {
        cancel: true,
        confirm: {
          fn: handleConfirm,
          text: 'Yes, finalise branding',
        },
      },
    },
    invalid: {
      title: 'There are issues with the branding',
      content: 'Please resolve the issues before finalising the branding.',
      buttons: {
        cancel: false,
        confirm: {
          fn: handleClose,
          text: 'OK',
        },
      },
    },
  }[isDocValid ? 'valid' : 'invalid'];

  const IconComponent = isDocValid ? QuestionMarkCircleIcon : ExclamationIcon;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)} onClose={handleClose}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-[600px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={handleClose}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div
                  className={clsx(
                    'mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    isDocValid ? 'bg-success-50' : 'bg-yellow-50',
                  )}
                >
                  <IconComponent
                    className={clsx('h-6 w-6', isDocValid ? 'text-success-600' : 'text-yellow-600')}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    {config.title}
                  </Dialog.Title>
                  <div className="mt-3">
                    <p className="text-sm font-normal leading-5 text-info-500">{config.content}</p>
                  </div>
                </div>
              </div>
              <div className="mt-8 items-center justify-end gap-x-2.5 sm:mt-8 sm:flex">
                {config.buttons.cancel && (
                  <Button onClick={handleClose} className="h-9" size="sm" kind="secondary">
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={config.buttons.confirm.fn}
                  className="h-9"
                  kind="primary"
                  loading={isLoading}
                  size="sm"
                >
                  {config.buttons.confirm.text}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmBrandingStatusModal;
