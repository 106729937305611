export const isLowerCaseMarkActive = (editor: any) => {
  const state = editor?.view?.state;

  if (!state || !state?.doc || !state?.selection) {
    return false;
  }

  const { from, to } = state.selection;
  const currentText = state.doc.textBetween(from, to, '');

  if (!currentText || typeof currentText !== 'string') {
    return false;
  }

  return currentText.toLowerCase() === currentText;
};
