import { MIN_PAGES_WITHOUT_PAGINATION } from '@components/Table/constants';
import { range } from 'lodash-es';

/** Array of pages combined with optional "..." elements **/
type PaginationResultItem = number | '...';

export function getPaginationItems(currentPageIndex: number, totalPages: number): (number | string)[] {
  const pages = range(1, totalPages + 1);

  if (totalPages <= MIN_PAGES_WITHOUT_PAGINATION) {
    return pages;
  }

  const pagination: PaginationResultItem[] = [];
  const currentPage = pages[currentPageIndex];

  const endOffset = totalPages - currentPage;
  const shouldSkipEndingDots = endOffset < MIN_PAGES_WITHOUT_PAGINATION - 2;

  const currentPageStartOffset = shouldSkipEndingDots ? 1 : 2;
  const startBasedOnCurrentPageAndOffset = currentPage - currentPageStartOffset;
  const startBasedOnUpperLimit = totalPages - MIN_PAGES_WITHOUT_PAGINATION + 2;

  const startUpperBand = Math.min(startBasedOnCurrentPageAndOffset, startBasedOnUpperLimit);

  const start = Math.max(1, startUpperBand);
  const end = Math.min(totalPages, start + MIN_PAGES_WITHOUT_PAGINATION - 1);

  if (start > 1) {
    pagination.push(1);
    if (start > 2) {
      pagination.push('...');
    }
  }

  for (let i = start; i < end; i++) {
    pagination.push(i);
  }

  if (end < totalPages) {
    if (end < totalPages) {
      pagination.push('...');
    }
    pagination.push(totalPages);
  }

  if (end === totalPages) {
    pagination.push(end);
  }

  return pagination;
}
