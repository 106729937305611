import { isContentRepeater } from '../resolveSchemaTreeFromForm';
import type { ResolvedSchemaTree } from '../types';

import type { ConfigParams } from './types';

export const getDataType = (item: ResolvedSchemaTree): ConfigParams['data-type'] => {
  if (isContentRepeater(item['ui:component'])) {
    return 'content-repeater-group';
  }

  return 'section-repeater';
};
