import { useEffect, useRef } from 'react';
import type { ListOnItemsRenderedProps } from 'react-window';
import { VariableSizeList as List } from 'react-window';

import type { RenderItemFn } from '../types';

import { Row } from './Row';

export interface VirtualizedListProps<ItemProps = unknown> {
  className?: string;
  data: Array<Omit<ItemProps, 'index'>>;
  /** Specify the height (in px) of a single item. */
  itemHeight: number;
  /** Specify the maximum height (in px) that the container is supposed to take. */
  height: number;
  /** Extra item to highlight */
  highlighted?: {
    idx: number;
    height: number;
  };
  onItemsRendered?: (props: ListOnItemsRenderedProps) => unknown;
  renderItem: RenderItemFn<ItemProps>;
  getItemSize?: (index: number) => number;
}

const _getItemSize =
  (highlighted: VirtualizedListProps['highlighted'], itemHeight: number) =>
  (idx: number): number => {
    if (highlighted && highlighted.idx > -1) {
      return idx === highlighted?.idx ? highlighted.height : itemHeight;
    }

    return itemHeight;
  };

/**
 * Component that handles rendering of huge lists using virtualization techniques.
 * In order to achieve the most performance out of it you should provide `renderItem` prop as a memoized item in order to avoid unnecessary re-renders.
 */
export const VirtualizedList = <ItemProps,>({
  className,
  data,
  height,
  itemHeight,
  highlighted,
  getItemSize = _getItemSize(highlighted, itemHeight),
  onItemsRendered,
  renderItem,
}: VirtualizedListProps<ItemProps>) => {
  const ref = useRef<List>(null);

  useEffect(() => {
    if (highlighted && ref.current) {
      ref.current.forceUpdate();
      ref.current.resetAfterIndex(0);
    }
  }, [highlighted]);

  return (
    <List
      ref={ref}
      height={height}
      className={className}
      onItemsRendered={onItemsRendered}
      itemCount={data.length}
      itemData={{ data, renderItem: renderItem as any }}
      itemSize={getItemSize}
      width="100%"
      outerElementType="ul"
    >
      {Row}
    </List>
  );
};
