import type { DrawerComponentType, DrawerState, OpenDrawerArgs } from '@ContractBuilder/modules/drawer-layout/types';
import { create } from 'zustand';

interface DrawerStoreState {
  closeDrawer: () => void;
  isOpen: boolean;
  openDrawer: <Type extends DrawerComponentType>(props: OpenDrawerArgs<Type>) => void;
  state?: DrawerState;
}

export const useDrawerStore = create<DrawerStoreState>((set) => ({
  closeDrawer: () => set({ isOpen: false, state: undefined }),
  isOpen: false,
  openDrawer: (state) => {
    set({ isOpen: true, state: state as DrawerState });
  },
  state: undefined,
}));
