import React, { useMemo } from 'react';
import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { getElementPosition } from '../../utils/get-element-position';
import { InputField } from '../InputField';

import { toggleGroupContainer } from './classes';
import { ToggleOption } from './ToggleOption';
import type { InputToggleGroupProps, ToggleGroupOption, ToggleGroupValue } from './types';
import { getOptionKey, isOptionChecked, normaliseOptions } from './utils';

export const InputToggleGroup: React.FC<InputToggleGroupProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  id,
  isDisabled,
  isIncomplete,
  isReadOnly,
  isRequired,
  item,
  labelIcon,
  labelText,
  mrcId,
  name = 'toggle-group',
  onChange,
  options = [],
  size = 'md',
  value,
  hideErrors,
}) => {
  const _id = id ?? name;
  const normalizedOptions = useMemo(() => normaliseOptions(options), [options]);

  const disabled = isDisabled || isReadOnly;

  const handleOptionClick = (optionValue: ToggleGroupValue) => {
    if (value === optionValue) {
      return onChange?.(undefined, name);
    }
    onChange?.(optionValue, name);
  };

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      helperText={item?.helperText}
      id={_id}
      isRequired={isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      isIncomplete={isIncomplete}
      hideErrors={hideErrors}
    >
      <RadioGroup
        onChange={(value: ToggleGroupValue) => onChange?.(value, name)}
        value={value}
        id={_id}
        className={twMerge(toggleGroupContainer, className)}
        disabled={disabled}
      >
        {normalizedOptions.map((option: ToggleGroupOption, index, array) => (
          <ToggleOption
            disabled={disabled}
            isIncomplete={isIncomplete}
            checked={isOptionChecked(value, option)}
            key={getOptionKey(_id, option.value)}
            onClick={handleOptionClick}
            position={getElementPosition(array, index)}
            option={option}
            size={size}
          />
        ))}
      </RadioGroup>
    </InputField>
  );
};
