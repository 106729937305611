import { useMemo } from 'react';
import type { ResourceBlock } from '@root/@types/base';
import { getAllBlocks } from '@root/helpers/blocks';

import type { EntityData, Section as SectionType } from '../../../../types';

interface UseBlocksArgs {
  pdfBlocks?: ResourceBlock[];
  section: SectionType;
  shouldShowDiffBlocks?: boolean;
  submission?: EntityData;
}

interface UseBlocksReturns {
  blocks: ResourceBlock[];
  shouldShowDiff: boolean;
}

const blockIsNotDeleted = (block: ResourceBlock) => !block.deleted_at;
const blockHasSectionId = (id: string) => (block: ResourceBlock) => block.section_id === id;

export const useBlocks = ({
  pdfBlocks,
  section,
  shouldShowDiffBlocks,
  submission,
}: UseBlocksArgs): UseBlocksReturns => {
  const { id, blocks, deleted_at, layout = [] } = section;
  const shouldShowDiff = shouldShowDiffBlocks || !!deleted_at;

  const blocksToShow = useMemo(() => {
    if (shouldShowDiff) {
      const blocks = getAllBlocks(submission, true);
      return blocks.filter(blockHasSectionId(id));
    }
    return blocks.filter(blockIsNotDeleted);
  }, [shouldShowDiff, submission, blocks, id]);

  const sorted = useMemo(() => {
    return Array.from(pdfBlocks ?? blocksToShow).sort((a, b) => {
      const aIndex = layout.indexOf(a.id);
      const bIndex = layout.indexOf(b.id);

      // Move blocks which do not exist on a layout entity to the bottom of a section
      if (aIndex === -1) {
        return 1;
      }

      if (bIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    });
  }, [pdfBlocks, blocksToShow, layout]);

  return {
    blocks: sorted,
    shouldShowDiff,
  };
};
