import type { BlockMentionValues } from '@root/@types/types';
import { formatDate, formatTime, isEmptyValue } from '@root/helpers';
import { get, isEmpty } from 'lodash-es';

const OTHER = 'Other';

const getParsedValue = (value: any, id: string, data: any, schemaField: any = {}) => {
  if (schemaField.displayTitleForYesAnswer) {
    return value === 'Yes' ? schemaField.title : ' ';
  }

  if (Array.isArray(value) && value.includes(OTHER)) {
    const newArray = Object.assign([], value, { [value.indexOf(OTHER)]: get(data, `${id}_other`, '') });
    return newArray.join(', ');
  }

  if (Array.isArray(value)) {
    return value.join(', ');
  }

  if (typeof value === 'string' && value === OTHER) {
    return get(data, `${id}_other`);
  }

  if (schemaField.format === 'date-time') {
    return formatDate(value, 'D MMMM YYYY') ?? '';
  }

  if (schemaField.format === 'time') {
    return formatTime(value);
  }

  if (schemaField['ui:component'] === 'InputPercentage') {
    return value.toString() + '%';
  }

  if (schemaField.type === 'number') {
    const shouldUseThousandsSeparator = schemaField.thousandSeparator ?? true;
    const isInteger = Number.isInteger(Number(value));

    return Intl.NumberFormat('en-GB', {
      useGrouping: shouldUseThousandsSeparator,
      minimumFractionDigits: isInteger ? 0 : 2,
    }).format(value);
  }

  return value.toString();
};

export const getMentionValues = (data: any, ids?: string[], schemaTreeList?: any): BlockMentionValues | undefined => {
  if (!ids || isEmpty(ids)) return;
  if (!data || isEmpty(data)) return;

  return ids.reduce((acc, id) => {
    const value = get(data, id);
    const schemaField = schemaTreeList?.find((f: any) => f.id === id) || {};

    if (isEmptyValue(value)) {
      return {
        ...acc,
        [id]: undefined,
      };
    }

    const parsedValue = getParsedValue(value, id, data, schemaField);

    return {
      ...acc,
      [id]: parsedValue,
    };
  }, {});
};
