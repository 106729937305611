import type { FC } from 'react';
import Button from '@components/Button';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { resolveFieldRefs } from '@helpers/resolveSchema';
import type { JSONSchemaProperties } from '@root/@types/types';
import { findFieldFromSchema } from '@root/helpers/schema';
import { MakeComponent } from '@root/src/mapChildren';
import { cloneDeep, get, isEqual } from 'lodash-es';

import { FallbackInfoContainer } from './FallbackInfoContainer';

interface DatapointInputProps {
  isVariationsSelected?: boolean;
  hasVariations?: boolean;
  datapointId: string;
  onClose: () => void;
}

const getResolvedItem = (resolvedField: any, resolvedParentField: any): JSONSchemaProperties & { key: string } => {
  const split = resolvedField.id.split('.');
  const id = split.pop();

  const isCurrencyInput = resolvedParentField?.['ui:component'] === 'InputCurrency';

  if (isCurrencyInput) {
    return {
      ...resolvedParentField,
      title: undefined,
      key: resolvedParentField.id,
    };
  }

  return { ...resolvedField, key: id };
};

export const DatapointInput: FC<DatapointInputProps> = ({
  datapointId,
  onClose,
  hasVariations,
  isVariationsSelected,
}) => {
  const { formValues, isSubmitting, onChange, onSubmit } = useDatapointsStore(
    ({ formValues, isSubmitting, onChange, onSubmit }) => ({ formValues, isSubmitting, onChange, onSubmit }),
  );
  const { findField, schema } = useSchemaStore(({ findField, schema }) => ({
    findField,
    schema,
  }));
  const { submission } = useEntityStore(({ submission }) => ({ submission }));

  if (hasVariations && !isVariationsSelected) {
    return (
      <FallbackInfoContainer onClose={onClose}>
        <p>Please select your variation before editing data points.</p>
      </FallbackInfoContainer>
    );
  }

  const field = findField(datapointId);

  if (!field) {
    return (
      <FallbackInfoContainer onClose={onClose}>
        <p>This field was not found in your form and might be conditional.</p>
        <p>Are you sure this field is present?</p>
      </FallbackInfoContainer>
    );
  }

  const split = field.id.split('.');
  const id = split.pop();
  const parentKey = split.join('.');

  const clonedSchema = cloneDeep(schema);
  // We hide the label of a field inside datapoints input purposefully
  const { title, ...resolvedField } = resolveFieldRefs(clonedSchema, field);

  const parentField = findField(parentKey, true);
  const resolvedParentField = resolveFieldRefs(clonedSchema, parentField);

  const handleCountrySubDivisionCleanup = () => {
    const subDivisionField = findField(`${field.id}_sub_division`, true);

    if (!subDivisionField || subDivisionField['ui:component'] !== 'InputCountrySubDivision') {
      return;
    }

    return onChange(undefined, subDivisionField.id);
  };

  const handleSubmit = async () => {
    handleCountrySubDivisionCleanup();

    await onSubmit();
    return onClose();
  };

  const mentionValue = get(formValues, datapointId);
  const hasOther = (Array.isArray(mentionValue) && mentionValue.includes('Other')) || mentionValue === 'Other';
  let resolvedFieldOther;

  if (hasOther) {
    const fieldOther = findFieldFromSchema(`${id}_other`);
    resolvedFieldOther = resolveFieldRefs(clonedSchema, fieldOther);
  }

  const isCurrencyInput = parentField?.['ui:component'] === 'InputCurrency';
  const parsedItem = getResolvedItem(resolvedField, resolvedParentField);

  const valuesKey = isCurrencyInput ? resolvedParentField?.id : field.id;

  const values = get(formValues, valuesKey);
  const originalValues = get(submission?.data_items, valuesKey);
  const hasChanged = !isEqual(values, originalValues);

  return (
    <>
      <div className="min-w-96 rounded-t-lg border-b border-info-200 bg-info-100 px-3 py-2 dark:border-info-600 dark:bg-info-700">
        <h3 className="font-semibold text-info-900 dark:text-white">
          {isCurrencyInput ? resolvedParentField?.title : title}
        </h3>
      </div>
      <div className="px-3 py-2">
        <div className="flex flex-col">
          <MakeComponent
            autoFocus
            item={parsedItem}
            isReadOnly={false}
            parentKey={parentKey}
            parentSchema={clonedSchema}
            onChange={onChange}
            validationErrors={null}
            useParentKey={!!isCurrencyInput}
          />
          {hasOther && (
            <MakeComponent
              item={{ ...resolvedFieldOther, key: `${id}_other` } as JSONSchemaProperties & { key: string }}
              isReadOnly={false}
              parentKey={parentKey}
              parentSchema={clonedSchema}
              onChange={onChange}
              validationErrors={null}
            />
          )}
          <div className="flex items-center justify-end gap-x-2">
            <Button className="h-10 w-24" isDisabled={isSubmitting} onClick={onClose} kind="secondary">
              Cancel
            </Button>
            <Button
              isDisabled={!hasChanged}
              className="h-10 w-24"
              loading={isSubmitting}
              onClick={handleSubmit}
              kind="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
