import { createContext, useContext } from 'react';

import type { MODALS } from '../constants';

export type ModalContextState = MODALS | undefined;

export type ModalContextType = {
  modal: ModalContextState;
  showModal: (modalName: ModalContextState, nextProps: any) => void;
  closeModal: () => void;
  props: any;
};

export const ModalContext = createContext({} as ModalContextType);

// eslint-disable-next-line react-refresh/only-export-components
export const useModal = () => useContext(ModalContext);
