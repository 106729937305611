import type { DynamoDBResourceComment } from '@root/@types/database';
import { groupBy, sortBy } from 'lodash-es';

export const getThreadIdForBlock = (comments: DynamoDBResourceComment[], blockId?: string, threadId?: string) => {
  if (!blockId) {
    return undefined;
  }

  if (threadId) {
    return threadId;
  }

  const blockComments = comments.filter((comment) => comment.block_id === blockId);

  if (blockComments.length === 0) {
    return `${blockId}-thread-0`;
  }

  const blockCommentsGrouped = groupBy(comments, (comment) => comment.thread_id);
  const threadIds = sortBy(
    Object.keys(blockCommentsGrouped).map((key) => {
      const value = key.replace(`${blockId}-thread-`, '').trim();
      return parseInt(value);
    }),
  );

  const lastThreadId = threadIds[threadIds.length - 1];
  return `${blockId}-thread-${lastThreadId + 1}`;
};
