import { useCallback, useMemo } from 'react';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import type { AuditLogItem } from '@ContractBuilder/types';
import { AuditLogChangeType } from '@root/@types/types';
import { debounce } from 'lodash-es';

import { useEntityStore } from '../store';

export const useBlockHighlight = () => {
  const dispatchCustomEvent = useDispatchCustomEvent();
  const { setHighlightedBlock } = useEntityStore(({ setHighlightedBlock }) => ({
    setHighlightedBlock,
  }));

  const handleSectionClick = useCallback(
    (blockId: string, auditLogItem?: AuditLogItem) => {
      if (auditLogItem?.type === AuditLogChangeType.COMMENT_ADDED) {
        dispatchCustomEvent(ArtificialCustomEvent.ToggleCommentsVisibility, {
          blockId,
          isOpen: true,
        });
      }

      return setHighlightedBlock(blockId, true);
    },
    [dispatchCustomEvent, setHighlightedBlock],
  );

  // eslint-disable-next-line -- It's fine to use inlined debounce here
  const handleMouseLeave = useCallback(
    debounce(() => {
      return setHighlightedBlock(undefined);
    }, 100),
    [setHighlightedBlock],
  );

  // eslint-disable-next-line -- It's fine to use inlined debounce here
  const handleMouseEnter = useCallback(
    debounce((blockId: string) => {
      return setHighlightedBlock(blockId, false);
    }, 100),
    [setHighlightedBlock],
  );

  return useMemo(
    () => ({
      onClick: handleSectionClick,
      onMouseLeave: handleMouseLeave,
      onMouseEnter: handleMouseEnter,
    }),
    [handleSectionClick, handleMouseLeave, handleMouseEnter],
  );
};
