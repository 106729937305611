import { isEmptyValue } from '@root/helpers';
import { get } from 'lodash-es';

// allow only root sections of the main form
const ALLOWED_ROOTS = [
  'risk_details',
  'security_details',
  'subscription_agreement',
  'fiscal_and_regulatory',
  'broker_remuneration_and_deductions',
];

export const getRootSectionId = (id: string) => id.split('.')[0];

export const isRootParentStarted = (rootId: string, formValues: any): boolean => !isEmptyValue(get(formValues, rootId));

/**
 * Incomplete detection for combined fields with common root like `InputCurrency`
 * @param ids string[]
 * @param isRequired boolean
 * @param formValues any
 * @returns boolean
 */
export const isIncomplete = (ids: string[], isRequired: boolean, formValues: any, errors?: string[]): boolean => {
  if (!isRequired) return false;

  // assuming all ids are from the same root
  const rootId = getRootSectionId(ids[0]);

  if (!ALLOWED_ROOTS.includes(rootId)) return false;

  const isParentSectionStarted = isRootParentStarted(rootId, formValues);

  if (!isParentSectionStarted) return false;

  return ids.some((id: string | number) => isEmptyValue(get(formValues, id)) || !isEmptyValue(errors));
};
