import type { FC, PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { NavigationContext } from '@ContractBuilder/modules/navigation/context/NavigationContext';

export const NavigationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSectionId, setActiveSectionId] = useState<string>();

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);

    setTimeout(() => {
      // Account for modal exit animation
      setActiveSectionId(undefined);
    }, 200);
  }, []);

  const handleOpenModal = useCallback((sectionId?: string) => {
    setActiveSectionId(sectionId);
    setIsOpen(() => true);
  }, []);

  const value = useMemo(
    () => ({
      activeSectionId,
      closeSectionModal: handleCloseModal,
      isSectionModalOpen: isOpen,
      openSectionModal: handleOpenModal,
    }),
    [activeSectionId, handleCloseModal, handleOpenModal, isOpen],
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
