import type { DropdownFilter, Filter } from '@root/@types/types';
import { filter, flow, get, map, partialRight, sortBy, uniqBy } from 'lodash-es';

export const accumulateFilterOptions = <T = Record<string, string>>(data: T[], key: string): string[] => {
  const result = flow(
    //
    partialRight(map, (i: any) => get(i, key)),
    partialRight(filter, Boolean),
    uniqBy,
    sortBy,
  )(data);

  return result ?? [];
};

export const insertNewOptions = (filters: Filter[], options: string[], key: string) => {
  const clonedFilters = [...filters];
  const index = clonedFilters.findIndex((i) => i.key === key);
  if (index > -1) {
    (clonedFilters[index] as DropdownFilter).options = options;
  }

  return clonedFilters;
};
