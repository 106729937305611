import { updateImage } from '@mutations/updateImage';
import { ApiPatchOperation } from '@root/@types/types';
import { create } from 'zustand';

interface ImagesStore {
  updateStatus: (id: string, status: 'pending' | 'ready') => Promise<void>;
  remove: (id: string) => Promise<void>;
}

export const useImagesStore = create<ImagesStore>(() => ({
  updateStatus: async (id: string, status: 'pending' | 'ready') => {
    await updateImage({ id, status }, ApiPatchOperation.UpdateStatus);
  },
  remove: async (id: string) => {
    await updateImage({ id }, ApiPatchOperation.SoftDelete);
  },
}));
