import type { ResourceBlock } from '@root/@types/base';
import { pick } from 'lodash-es';

import { UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS, UPDATE_TEMPLATE_BLOCK_PERMISSIONS_KEYS } from '../constants';
import type { BlockEditFormState } from '../types';

export const getUpdateRelevantFields = (block?: ResourceBlock | BlockEditFormState) => {
  if (!block) {
    return undefined;
  }

  return pick(block, [...UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS, ...UPDATE_TEMPLATE_BLOCK_PERMISSIONS_KEYS, 'section_id']);
};
