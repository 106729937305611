import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { TableControlsInner } from '@WysiwygEditor/components/Controls/components/TableControlsInner';

const MAX_SIZE = 10;

const INITIAL_X_STATE = 2;
const INITIAL_Y_STATE = 2;
const INITIAL_COLUMNS = 5;
const INITIAL_ROWS = 5;

interface TableControlsProps {
  isActive: boolean;
  isDisabled?: boolean;
  editor: any;
}

export const TableControls = ({ isActive, isDisabled = false, editor }: TableControlsProps) => {
  const [x, setX] = useState(INITIAL_X_STATE);
  const [y, setY] = useState(INITIAL_Y_STATE);
  const [rows, setRows] = useState(INITIAL_ROWS);
  const [columns, setColumns] = useState(INITIAL_COLUMNS);

  const select = (newX: number, newY: number) => {
    setX(newX);
    setY(newY);
    if (rows === newX && rows < MAX_SIZE) {
      setRows(rows + 1);
    }
    if (columns === newY && columns < MAX_SIZE) {
      setColumns(columns + 1);
    }
  };

  const onSelected = () => {
    editor.chain().focus().insertTable({ rows: y, cols: x, withHeaderRow: false }).run();
    editor.chain().focus().toggleLock().run();
  };

  const handleClose = () => {
    setTimeout(() => {
      x !== INITIAL_X_STATE && setX(INITIAL_X_STATE);
      y !== INITIAL_Y_STATE && setY(INITIAL_Y_STATE);
      rows !== INITIAL_ROWS && setRows(INITIAL_ROWS);
      columns !== INITIAL_COLUMNS && setColumns(INITIAL_COLUMNS);
    }, 100);
  };

  return (
    <Popover className="relative" key="Table">
      {({ open }) => (
        <TableControlsInner
          columns={columns}
          isActive={isActive}
          isDisabled={isDisabled}
          onClose={handleClose}
          onSelected={onSelected}
          open={open}
          rows={rows}
          select={select}
          x={x}
          y={y}
        />
      )}
    </Popover>
  );
};
