import { cva } from 'class-variance-authority';

export const indentationClasses = cva([], {
  variants: {
    level: {
      0: ['pl-2'],
      1: ['pl-4'],
      2: ['pl-8'],
      3: ['pl-12'],
      4: ['pl-16'],
      5: ['pl-20'],
    },
  },
});
