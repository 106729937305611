import type React from 'react';
import H2 from '@components/H2';
import AddressAutocomplete from '@components/schema/AddressAutocomplete';
import Group from '@components/schema/Group';
import InputCalendar from '@components/schema/InputCalendar';
import InputCheckbox from '@components/schema/InputCheckbox';
import InputCheckboxGroup from '@components/schema/InputCheckboxGroup';
import InputCountrySubDivision from '@components/schema/InputCountrySubDivision';
import InputCurrency from '@components/schema/InputCurrency';
import InputInteger from '@components/schema/InputInteger';
import { InputMatrix } from '@components/schema/InputMatrix/';
import InputMultiSelect from '@components/schema/InputMultiSelect';
import InputNumber from '@components/schema/InputNumber';
import InputPercentage from '@components/schema/InputPercentage';
import InputRadioGroup from '@components/schema/InputRadioGroup';
import InputSelect from '@components/schema/InputSelect';
import InputSelectApiConnected from '@components/schema/InputSelectApiConnected';
import InputSelectCurrency from '@components/schema/InputSelectCurrency';
import InputSelectSearchable from '@components/schema/InputSelectSearchable';
import InputText from '@components/schema/InputText';
import InputTextarea from '@components/schema/InputTextarea';
import InputTime from '@components/schema/InputTime';
import InputToggleGroup from '@components/schema/InputToggleGroup';
import { Repeater } from '@components/schema/Repeater';
import Section from '@components/schema/Section/Section';
import SectionRepeater from '@components/schema/SectionRepeater/SectionRepeater';
import ToggleableSectionRepeater from '@components/schema/ToggleableSectionRepeater';

export const componentMap: Record<string, React.FC<any>> = {
  AddressAutocomplete,
  Group,
  H2,
  InputCalendar,
  InputCheckbox,
  InputCheckboxGroup,
  InputCountrySubDivision,
  InputCurrency,
  InputInteger,
  InputMatrix,
  InputMultiSelect,
  InputNumber,
  InputPercentage,
  InputRadioGroup,
  InputSelect,
  InputSelectApiConnected,
  InputSelectCurrency,
  InputSelectSearchable,
  InputText,
  InputTextarea,
  InputTime,
  InputToggleGroup,
  Repeater,
  Section,
  SectionRepeater,
  ToggleableSectionRepeater,
};

export const getComponentType = (item: any): React.FC<any> | false => {
  const component = item['ui:component'];
  if (component) {
    return componentMap[component];
  }

  if (item.type === 'object' && 'amount' in item.properties && 'currency' in item.properties) {
    return componentMap.InputCurrency;
  }

  if (item.type === 'array' && 'items' in item) {
    return componentMap.Repeater;
  }

  if ('title' in item && 'properties' in item) {
    return componentMap.H2;
  }

  if (item.type === 'string' && (item.format === 'date-time' || item.format === 'date')) {
    return componentMap.InputCalendar;
  }

  if ('enum' in item && JSON.stringify(item.enum) === JSON.stringify(['Yes', 'No'])) {
    return componentMap.InputToggleGroup;
  }

  if ('enum' in item) {
    return componentMap.InputSelect;
  }

  if (item.type === 'string') {
    return componentMap.InputText;
  }

  if (item.type === 'number') {
    return componentMap.InputNumber;
  }

  if (item.type === 'object' && !item.title) {
    return componentMap.Group;
  }

  if (item.type === 'string' && item.format === 'time') {
    return componentMap.InputTime;
  }

  return false;
};
