export const uploadWrapperClasses = [
  'relative',
  'flex',
  'h-3/5',
  'flex-col',
  'justify-center',
  'rounded-lg',
  'bg-info-50',
  'p-6',
  'text-center',
];

export const uploadPageContainerClasses = ['h-full', 'px-8'];
