import { memo } from 'react';
import { Dialog } from '@headlessui/react';
import { useLanguage } from '@root/src/language';

import { AttachmentRenameController } from '../controller/AttachmentRenameController';

import { AttachmentModal } from './AttachmentModal';

export const AttachmentRenameModal = memo(function AttachmentRenameModal({
  handleClose,
  attachmentId,
  attachmentName,
  attachmentFileName,
}: {
  handleClose: () => void;
  attachmentId: string;
  attachmentName: string;
  attachmentFileName: string;
}) {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.attachment' });

  return (
    <AttachmentModal handleClose={handleClose}>
      <>
        <Dialog.Title as="h3" className="mb-2 !text-xl leading-6 !text-info-900">
          {getContent(`title.rename`)}
        </Dialog.Title>
        <AttachmentRenameController
          handleClose={handleClose}
          attachmentId={attachmentId}
          attachmentBaseName={attachmentName}
          attachmentFileName={attachmentFileName}
        />
      </>
    </AttachmentModal>
  );
});
