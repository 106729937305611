import { cva } from 'class-variance-authority';

export const buttonClasses = cva(
  [
    'min-w-fit',
    'inline-flex',
    'items-center',
    'gap-x-1.5',
    'rounded-md',
    'border-[1px]',
    'px-2.5',
    'py-1.5',
    'text-sm',
    'font-medium',
    'shadow-sm',
    'focus-visible:outline',
    'focus-visible:outline-2',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-gray-600',
    'transition-colors',
    'duration-300',
    'ease-in-out',
  ],
  {
    variants: {
      variant: {
        dark: 'hover:bg-white/5',
        light: 'text-info-700 hover:bg-black/5',
      },
    },
  },
);

export const containerClasses = cva(['relative', 'flex w-full', 'shrink-0', 'flex-col', 'p-4'], {
  variants: {
    variant: {
      dark: 'text-info-300',
      light: 'text-info-800',
    },
    isOpen: {
      true: 'gap-5',
      false: 'gap-2',
    },
  },
  defaultVariants: {
    variant: 'dark',
  },
});
export const nameClasses = cva(['text-sm', 'font-medium'], {
  variants: {
    variant: {
      dark: 'text-white',
      light: 'text-info-500',
    },
  },
});
