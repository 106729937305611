import type { FC } from 'react';
import { AuthCard } from '@Auth/AuthCard';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { mdiCloseCircle } from '@mdi/js';
import { isNonNullish } from '@root/helpers';

interface LogoutErrorProps {
  errorCode?: number;
}

export const LogoutError: FC<LogoutErrorProps> = ({ errorCode }) => {
  return (
    <AuthCard>
      <IconMdi path={mdiCloseCircle} className="text-error-400" size={0.8} />
      <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-2">
        <div className="self-stretch text-sm font-medium leading-tight text-red-800">
          Sorry, you are not authorised, please contact your administrator to confirm your permissions.
        </div>
        <Show when={isNonNullish(errorCode)}>Error: {errorCode}</Show>
      </div>
    </AuthCard>
  );
};
