import React from 'react';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

interface ControlBarProps {
  isDisabled?: boolean;
  showTableControls?: boolean;
  className?: string;
  children: React.ReactNode;
}

const classes = cva(
  [
    //
    'border-info-200',
    'overflow-visible',
    'border-b',
    'flex',
    'space-x-3',
    'p-2',
    'bg-white ',
    'z-10',
    'flex-wrap',
    'items-center',
  ],
  {
    variants: {
      showTableControls: {
        true: 'w-auto',
        false: 'w-full',
      },
      isDisabled: {
        true: ['cursor-not-allowed'],
      },
    },
  },
);

export const ControlBar = ({ showTableControls, className, isDisabled, children }: ControlBarProps) => (
  <div className={twMerge(classes({ showTableControls, className, isDisabled }))}>{children} </div>
);
