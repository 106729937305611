import React, { useState } from 'react';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { ContentItem } from '@ContractBuilder/components/Modal/EndorsementSummaryModal/components/ContentItem';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { get } from 'lodash-es';

import { useEntityStore } from '../../../store';

interface EndorsementSummaryModalProps {
  handleClose: () => void;
  onSaveAndExit: () => void;
  onSaveAndGeneratePdf: () => void;
}

export type EndorsementSummaryMode = 'endorsement_summary' | 'endorsement_amendment_text';

interface EndorsementSummaryModalState {
  active?: EndorsementSummaryMode;
  endorsement_summary: string;
  endorsement_amendment_text: string;
}

export const EndorsementSummaryModal = ({ handleClose }: EndorsementSummaryModalProps) => {
  const { submission, updateResourceData } = useEntityStore(({ submission, updateResourceData }) => ({
    submission,
    updateResourceData,
  }));

  const isViewingRevisionHistory = useIsRevisionHistory();
  const [isSaving, setSaving] = useState(false);
  const isEditable = canChange(submission?.status) && !isViewingRevisionHistory;

  const [state, setState] = useState<EndorsementSummaryModalState>({
    active: undefined,
    endorsement_summary: get(submission, 'endorsement_summary', ''),
    endorsement_amendment_text: get(submission, 'endorsement_amendment_text', ''),
  });

  const onCancel = () => {
    return handleClose();
  };

  const onSaveAndExit = async () => {
    setSaving(true);
    await updateResourceData({
      endorsement_summary: state.endorsement_summary,
      endorsement_amendment_text: state.endorsement_amendment_text,
    });
    setSaving(false);
    handleClose();
  };

  const handleSetActive = (key?: EndorsementSummaryMode) => () => {
    if (!isEditable) {
      return;
    }

    if (key === state.active) {
      return setState((prev) => ({ ...prev, active: undefined }));
    }

    setState((prev) => ({ ...prev, active: key }));
  };

  const handleChange = (key: EndorsementSummaryMode) => (value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const isEndorsementSummaryActive = state.active === 'endorsement_summary';
  const isEndorsementAmendmentTextActive = state.active === 'endorsement_amendment_text';

  return (
    <Modal
      open
      className="w-fit min-w-[842px]"
      onClose={handleClose}
      title="Endorsement Summary"
      titleClassName="!text-left"
    >
      <ContentItem
        content={state.endorsement_amendment_text}
        isActive={isEndorsementAmendmentTextActive}
        isEditable={isEditable}
        isSaving={isSaving}
        onChange={handleChange('endorsement_amendment_text')}
        onSetActive={handleSetActive('endorsement_amendment_text')}
        title="Contract Changes Summary"
      />
      <ContentItem
        content={state.endorsement_summary}
        isActive={isEndorsementSummaryActive}
        isEditable={isEditable}
        isSaving={isSaving}
        onChange={handleChange('endorsement_summary')}
        onSetActive={handleSetActive('endorsement_summary')}
        title="Endorsement Terms & Conditions"
      />

      {isEditable && (
        <div className="mt-8 flex justify-end gap-4">
          <Button className="h-9" onClick={onCancel} kind="secondary" size="sm">
            Cancel
          </Button>
          <Button className="h-9" onClick={onSaveAndExit} kind="primary" loading={isSaving} size="sm">
            Save
          </Button>
        </div>
      )}
    </Modal>
  );
};
