import { mdiFormatHeader1, mdiFormatHeader2, mdiFormatHeader3, mdiFormatHeader4, mdiFormatParagraph } from '@mdi/js';

import type { Option } from '../types';

const toggleHeading = (editor: any, level: number) => {
  const isRepeaterHeading = editor?.isActive({ dataType: 'repeater-heading' });
  return editor
    ?.chain()
    .focus()
    .unsetAllMarks()
    .toggleHeading({ level, dataType: isRepeaterHeading ? 'repeater-heading' : undefined })
    .run();
};

export const getHierarchyControlOptions = (editor: any, onSelected: () => void): Option[] => [
  {
    format: () => {
      toggleHeading(editor, 1);
      return onSelected();
    },
    isActive: editor?.isActive('heading', { level: 1 }),
    icon: mdiFormatHeader1,
    key: 'heading-1',
    title: 'Heading Large Red',
  },
  {
    format: () => {
      toggleHeading(editor, 2);
      return onSelected();
    },
    isActive: editor?.isActive('heading', { level: 2 }),
    icon: mdiFormatHeader2,
    key: 'heading-2',
    title: 'Heading Large Black',
  },
  {
    format: () => {
      toggleHeading(editor, 3);
      return onSelected();
    },
    isActive: editor?.isActive('heading', { level: 3 }),
    icon: mdiFormatHeader3,
    key: 'heading-3',
    title: 'Heading Medium Black',
  },
  {
    format: () => {
      toggleHeading(editor, 4);
      return onSelected();
    },
    isActive: editor?.isActive('heading', { level: 4 }),
    icon: mdiFormatHeader4,
    key: 'heading-4',
    title: 'Heading Large Underline',
  },
  {
    format: () => {
      const isRepeaterHeading = editor?.isActive({ dataType: 'repeater-heading' });

      editor?.chain().focus().setParagraph().run();

      if (isRepeaterHeading) {
        editor?.commands.updateAttributes('paragraph', { dataType: 'repeater-heading' });
      }

      return onSelected();
    },
    isActive: editor?.isActive('paragraph'),
    icon: mdiFormatParagraph,
    key: 'paragraph',
    title: 'Paragraph',
  },
];
