import { type FC } from 'react';
import { isBlockValid } from '@ContractBuilder/utils/block-validation';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import type { ResourceBlock } from '@root/@types/base';
import { BlockError } from '@root/@types/types';
import { BLOCK_ACTIONS_Z_INDEX } from '@root/src/constants/z-indices';
import clsx from 'clsx';

import Tooltip from '../../../../../components/Tooltip';

const messages = {
  [BlockError.BlockEmpty]: 'Block is empty',
  [BlockError.DatapointEmpty]: 'Block has incomplete datapoints',
  [BlockError.VariationNotSelected]: 'Select a variation of this block',
};

interface BlockAlertProps {
  block: ResourceBlock;
}

export const BlockAlert: FC<BlockAlertProps> = ({ block }) => {
  const isValid = useDeepCompareMemo(() => isBlockValid(block), [block]);

  if (isValid) {
    return null;
  }

  return (
    <Tooltip
      content={
        <ul className="text-center">
          {block.validationErrors?.map((problem) => <li key={problem}>{messages[problem]}</li>)}
        </ul>
      }
    >
      <div
        className={clsx([
          'absolute',
          'left-0',
          'transform',
          '-translate-x-1/2',
          'hover:cursor-pointer',
          'transition-all',
          'duration-200',
          'ease-in-out',
          'top-1',
          'text-xs',
          'rounded-full',
          'p-0.25',
          'bg-white',
          'shadow-xl',
          BLOCK_ACTIONS_Z_INDEX,
        ])}
      >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13.6367" cy="14.4297" r="13" fill="#FBBF24" stroke="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6367 14.4297C21.6367 18.848 18.055 22.4297 13.6367 22.4297C9.21844 22.4297 5.63672 18.848 5.63672 14.4297C5.63672 10.0114 9.21844 6.42969 13.6367 6.42969C18.055 6.42969 21.6367 10.0114 21.6367 14.4297ZM14.6367 10.4297C14.6367 10.982 14.189 11.4297 13.6367 11.4297C13.0844 11.4297 12.6367 10.982 12.6367 10.4297C12.6367 9.8774 13.0844 9.42969 13.6367 9.42969C14.189 9.42969 14.6367 9.8774 14.6367 10.4297ZM12.6367 13.4297C12.0844 13.4297 11.6367 13.8774 11.6367 14.4297C11.6367 14.982 12.0844 15.4297 12.6367 15.4297V18.4297C12.6367 18.982 13.0844 19.4297 13.6367 19.4297H14.6367C15.189 19.4297 15.6367 18.982 15.6367 18.4297C15.6367 17.8774 15.189 17.4297 14.6367 17.4297V14.4297C14.6367 13.8774 14.189 13.4297 13.6367 13.4297H12.6367Z"
            fill="#FFFBEB"
          />
        </svg>
      </div>
    </Tooltip>
  );
};
