import type { DynamoDBResourceBlock } from '@root/@types/database';

/** Make sure that the visibility config is always an object with either a block or variations key */
export const performVisibilityConfigUpdates = <T extends Pick<DynamoDBResourceBlock, 'visibility' | 'variations'>>(
  block: T,
) => {
  const { variations, visibility } = block;

  if (!visibility) {
    return block;
  }

  const { block: blockVisibility, variations: variationsVisibility } = visibility;
  const hasVariations = Boolean(variations?.length);

  if (hasVariations) {
    return {
      ...block,
      visibility: {
        variations: variationsVisibility,
      },
    };
  }

  return {
    ...block,
    visibility: {
      block: blockVisibility,
    },
  };
};
