import type { TemplateSchemaField } from '@root/@types/types';
import traverse from 'json-schema-traverse';
import { filter, flow, get, map, partialRight, sortBy, uniq } from 'lodash-es';

import { isDynamicSchema } from './isDynamicSchema';
import schema from './schema-cdr.json';
import fields from './schema-fields.json';
import sections from './schema-sections.json';
import type { FoundField, TemplateSchemaSection } from './types';

export const findSection = (sectionId: string, schemaId?: string): TemplateSchemaSection | undefined => {
  if (isDynamicSchema(schemaId)) {
    return (sections as TemplateSchemaSection[]).find(({ id }) => id === sectionId);
  }
  // @ts-expect-error
  return { title: sectionId.replace('_', ' '), id: sectionId };
};

export const findField = (sectionId: string, fieldId: string): FoundField | null => {
  const field = (fields as TemplateSchemaField[]).find((f) => f.id === fieldId && f.section_id === sectionId);

  if (!field) {
    return null;
  }

  const [root, nested] = field.id.split('.');
  return { root, nested, value: field.value };
};

export const findFieldFromSchema = (id: string, schemaId?: string) => {
  if (isDynamicSchema(schemaId)) {
    return getField(id);
  }

  // Step 1: Insert '.properties.' in the place of the dots
  const path = `properties.SubmissionForm.properties.`.concat(
    id
      .replace(/\./g, '.properties.')
      .replace(/\.\d+\./g, '.items.')
      .replace(/\.properties\.items\./g, '.items.'),
  );

  const schemaField = get(schema, path);

  if (schemaField) {
    return schemaField;
  }

  let field = null;

  traverse(schema, {
    cb: (currSchema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, key) => {
      if (currSchema.id === id || key === id) {
        field = currSchema;
      }
    },
  });

  return field;
};

export const getTreeOfFields = (fields: string[]) =>
  fields.reduce(
    (acc: Record<'notNested' | string, string[]>, id) => {
      const [root, nested] = id.split('.');

      if (nested) {
        if (!acc[root]) {
          acc[root] = [];
        }

        acc[root] = uniqSorted([...acc[root], nested]);
      } else {
        acc.notNested = uniqSorted([...acc.notNested, root]);
      }

      return acc;
    },
    { notNested: [] },
  );

export const uniqSorted = flow(uniq, sortBy);

export const getField = (fieldId: string) => {
  return (fields as TemplateSchemaField[]).find((f) => f.id === fieldId || f.id.includes(fieldId));
};

export const checkRequired =
  (section: string, fields: TemplateSchemaField[]) =>
  (id: string, parent?: string): boolean => {
    const search = parent ? `${parent}.${id}` : id;

    const result = fields.find((f) => f.id === search && f.section_id === section);

    return !!result?.isRequired;
  };

export const pickIdsWith = (
  ids: string[],
  validator: (id: string, parent?: string) => boolean,
  parent?: string,
): string[] =>
  flow(
    //
    partialRight(map, (id: string) => validator(id, parent) && id),
    partialRight(filter, Boolean),
    uniqSorted,
  )(ids);

export const getFormSections = () =>
  flow(
    partialRight(sortBy, 'qid'),
    partialRight(map, ({ id }: TemplateSchemaSection) => id),
  )(sections as TemplateSchemaSection[]);
