import type { FC, PropsWithChildren } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { Show } from '@components/Show';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { useEntityStore } from '@ContractBuilder/store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { useHistoryBackTrap } from '@hooks/use-history-back-trap';
import { useToggle } from '@root/src/hooks';
import { isBlocksPath, isTemplatePath } from '@utils/app-paths';
import { isEmpty } from 'lodash-es';

import { initialCallbackState } from '../constants';
import type { BlockEditFormState, CallbackState } from '../types';
import { getFormStateFromBlock } from '../utils/get-form-state-from-block';
import { handleDatapointsUpdate } from '../utils/handle-datapoints-update';
import { validateRequiredDatapoints } from '../utils/validate-required-datapoints';
import { BlockUsedInTemplatesModal } from '../views/BlockUsedInTemplatesModal';
import { MissingMandatoryDatapointsModal } from '../views/MissingMandatoryDatapointsModal';
import { UnsavedChangesModal } from '../views/UnsavedChangesModal';

import type { SetOnCancelCallback } from './context';
import { BlockEditContext } from './context';

const hasCreateMode = (formVales?: BlockEditFormState) => {
  return formVales?.id === '';
};

const hasChangesInCreateMode = (formValues?: BlockEditFormState) => {
  return hasCreateMode(formValues) && formValues?.name;
};

export const BlockEditProviderInner: FC<PropsWithChildren> = ({ children }) => {
  const onCancelCallbackRef = useRef<CallbackState>({ fn: undefined });
  const [isUnsavedChangedModalOpen, onUnsavedChangesModalOpen, onCloseUnsavedChangesModal] = useToggle();
  const [isMandatoryDatapointsModalOpen, onMandatoryDatapointsModalOpen, onCloseMandatoryDatapointsModal] = useToggle();
  const { isBlockUsedInTemplatesModalOpen, setBlockUsedInTemplatesModalVisibility } = useUIStore(
    ({ isBlockUsedInTemplatesModalOpen, setBlockUsedInTemplatesModalVisibility }) => ({
      isBlockUsedInTemplatesModalOpen,
      setBlockUsedInTemplatesModalVisibility,
    }),
  );

  const { submission, isLoading } = useEntityStore(({ submission, isLoading }) => ({ submission, isLoading }));
  const { closeDrawer } = useDrawerStore(({ closeDrawer }) => ({ closeDrawer }));
  const dispatchEvent = useDispatchCustomEvent();

  const isBlocks = isBlocksPath();
  const isTemplate = isTemplatePath();

  const onSubmit = useCallback(() => {
    dispatchEvent(ArtificialCustomEvent.SubmitInlineBlockEdit);
  }, [dispatchEvent]);

  const onSubmitAsNew = useCallback(() => {
    dispatchEvent(ArtificialCustomEvent.SubmitAsNewInlineBlockEdit);
  }, [dispatchEvent]);

  const setOnCancelCallback: SetOnCancelCallback = useCallback((callback) => {
    onCancelCallbackRef.current = callback;
  }, []);

  const exitBlockCreator = useCallback(() => {
    dispatchEvent(ArtificialCustomEvent.ExitBlockCreator);
  }, [dispatchEvent]);

  const resetCallback = () => setOnCancelCallback(initialCallbackState);

  const resetState = useCallback(() => {
    onCancelCallbackRef.current.fn?.();
    onCloseUnsavedChangesModal();
    onCloseMandatoryDatapointsModal();
    exitBlockCreator();
    setBlockUsedInTemplatesModalVisibility(false);
    resetCallback();
    reset();
    return closeDrawer();
    // eslint-disable-next-line -- Missing dependencies are all functions - it's ok to skip them as deps here
  }, []);

  const { currentBlock, formValues, reset, setFormValues } = useBlockEditFormStore(
    ({ currentBlock, formValues, hasChanges, reset, setFormValues }) => ({
      currentBlock,
      formValues,
      hasChanges,
      reset,
      setFormValues,
    }),
  );

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formValues?.id || isLoading) {
        event.preventDefault();
        return (event.returnValue = 'Are you sure you want to exit?');
      }
    },
    [formValues?.id, isLoading],
  );

  const handleTrap = useCallback(
    async (resume: () => void) => {
      if (formValues?.id || isLoading) {
        handleCancel();
        return false;
      } else {
        resume();
        return true;
      }
    },
    // eslint-disable-next-line -- Missing dependency is a fn that won't change
    [formValues?.id, isLoading],
  );
  useHistoryBackTrap(handleTrap);
  useBeforeUnload(handleUnload);

  useEffect(() => {
    if (!submission?.id) {
      resetState();
    }
    // eslint-disable-next-line -- Missing dependency is a fn that won't change
  }, [submission?.id]);

  const handleCancel = useCallback(
    (skipUnsavedChangesCheck = false) => {
      const editingBlockId = useBlockEditFormStore.getState().formValues?.id;
      const latestHasChanges = useBlockEditFormStore.getState().hasChanges;

      if (!skipUnsavedChangesCheck && latestHasChanges && !isUnsavedChangedModalOpen && (editingBlockId || isBlocks)) {
        onUnsavedChangesModalOpen();
        return;
      }

      return resetState();
    },
    // eslint-disable-next-line -- Missing dependencies are all functions - we only care about modal state and path
    [isBlocks, isUnsavedChangedModalOpen],
  );

  const handleExitBlockCreator = useCallback(() => {
    return dispatchEvent(ArtificialCustomEvent.ExitBlockCreator, undefined);
  }, [dispatchEvent]);

  const handleSetEditingBlock = useCallback(
    (blockId?: string) => {
      const latestFormValues = useBlockEditFormStore.getState().formValues;
      const latestHasChanges = useBlockEditFormStore.getState().hasChanges;
      const latestSubmission = useEntityStore.getState().submission;

      const isInCreateMode = hasCreateMode(latestFormValues);
      const hasInitiatedCreateProcess = isInCreateMode && hasChangesInCreateMode(latestFormValues);

      if (hasInitiatedCreateProcess) {
        onUnsavedChangesModalOpen();
        return;
      }

      if (isInCreateMode && !hasInitiatedCreateProcess) {
        handleExitBlockCreator();
      }

      if (!isInCreateMode && blockId !== latestFormValues?.id && latestHasChanges) {
        onUnsavedChangesModalOpen();
        return;
      }

      const state = getFormStateFromBlock(latestSubmission, blockId);

      if (!state) {
        return resetState();
      }

      return setFormValues(state);
    },
    // eslint-disable-next-line -- Missing dependencies are all functions
    [],
  );

  const handleUpdateRequiredDatapoint = useCallback(
    (id: string, isRequired: boolean, variationIndex?: number) => {
      const latestFormValues = useBlockEditFormStore.getState().formValues;

      if (latestFormValues) {
        const updated = handleDatapointsUpdate({ values: latestFormValues, id, isRequired, index: variationIndex });
        setFormValues(updated);
      }
    },
    [setFormValues],
  );

  const onBlockUsedInTemplatesCancel = useCallback(() => {
    setBlockUsedInTemplatesModalVisibility(false);
  }, [setBlockUsedInTemplatesModalVisibility]);

  const value = useMemo(() => {
    return {
      onCancel: handleCancel,
      onMandatoryDatapointsModalOpen,
      onSubmit,
      onSubmitAsNew,
      onUpdateRequiredDatapoint: handleUpdateRequiredDatapoint,
      resetState,
      setEditingBlock: handleSetEditingBlock,
      setOnCancelCallback,
    };
  }, [
    handleCancel,
    handleSetEditingBlock,
    handleUpdateRequiredDatapoint,
    onMandatoryDatapointsModalOpen,
    onSubmit,
    onSubmitAsNew,
    resetState,
    setOnCancelCallback,
  ]);

  return (
    <BlockEditContext.Provider value={value}>
      <UnsavedChangesModal
        isSaveChangesDisabled={isEmpty(formValues?.name) || isEmpty(formValues?.content)}
        isOpen={isUnsavedChangedModalOpen}
        onCancel={onCloseUnsavedChangesModal}
        onDisregardChanges={resetState}
        onSaveChanges={onSubmit}
        saveChangesDisabledTooltip="Either block's name or content field is empty. Please fill it before proceeding."
      />
      <MissingMandatoryDatapointsModal
        isOpen={isMandatoryDatapointsModalOpen}
        missingDatapoints={validateRequiredDatapoints(currentBlock, formValues)}
        onCancel={onCloseMandatoryDatapointsModal}
        onConfirm={onCloseMandatoryDatapointsModal}
      />
      <Show when={isTemplate}>
        <BlockUsedInTemplatesModal isOpen={isBlockUsedInTemplatesModalOpen} onCancel={onBlockUsedInTemplatesCancel} />
      </Show>
      {children}
    </BlockEditContext.Provider>
  );
};

export const BlockEditProvider = memo(BlockEditProviderInner);
