import { mdiArrowDecisionAuto, mdiArrowDecisionOutline, mdiPin, mdiPinOutline } from '@mdi/js';

export const VARIATION_TAB_ICONS = {
  AUTO: mdiArrowDecisionAuto,
  AUTO_NO_LOGIC: mdiArrowDecisionOutline,
  AUTO_SELECTED: mdiArrowDecisionAuto,
  DEFAULT: mdiPinOutline,
  DEFAULT_SELECTED: mdiPin,
};

export const getDefaultIconTooltip = (isTemplate: boolean) =>
  isTemplate ? 'Default variation. Cannot be removed' : 'Default variation';

export const getTabLogicIcon = (isLogicDefined?: boolean, isSelected?: boolean) => {
  if (!isLogicDefined) {
    return VARIATION_TAB_ICONS['AUTO_NO_LOGIC'];
  }

  return VARIATION_TAB_ICONS[isSelected ? 'AUTO_SELECTED' : 'AUTO'];
};

export const getDefaultTabIcon = (isSelected?: boolean) =>
  VARIATION_TAB_ICONS[isSelected ? 'DEFAULT_SELECTED' : 'DEFAULT'];
