import { mdiChevronDown } from '@mdi/js';
import { cva } from 'class-variance-authority';

import type { IconMdiProps } from './IconMdi';
import IconMdi from './IconMdi';

interface DropdownChevronProps extends Omit<IconMdiProps, 'icon' | 'path'> {
  open?: boolean;
  direction?: 'down' | 'right';
}

const chevron = cva(['text-info-400', 'ease-in-out', 'duration-300', 'hover:text-info-800'], {
  variants: {
    open: {
      true: [],
      false: [],
    },
    direction: {
      right: [],
      left: [],
      down: [],
    },
  },
  compoundVariants: [
    {
      open: true,
      direction: 'down',
      class: ['rotate-90'],
    },
    {
      open: true,
      direction: 'right',
      class: ['-rotate-90'],
    },
  ],
  defaultVariants: {
    open: false,
    direction: 'right',
  },
});

const DropdownChevron = ({ open, direction, className, ...props }: DropdownChevronProps) => (
  <IconMdi path={mdiChevronDown} className={chevron({ open, direction, className })} aria-hidden="true" {...props} />
);

export default DropdownChevron;
