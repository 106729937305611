export const bannerContainerClasses = [
  'bg-primary-50',
  'p-2.5',
  'flex',
  'items-center',
  'justify-center',
  'text-center',
  'w-full',
  'gap-x-2.5',
  'text-sm',
];
