import React, { useState } from 'react';
import { DATAPOINT, DERIVED_CONTENT, ENDORSEMENT_DATA } from '@components/SelectMenu/constants';
import { INSERT_MENU_Z_INDEX } from '@constants/z-indices';
import { KeyCode } from '@root/@types/types';
import { createMention } from '@root/helpers/editor';
import { createEndorsementDataMention } from '@root/helpers/editor/create-endorsement-data-mention';
import clsx from 'clsx';

import { containerClasses, listHeaderClasses } from './style/classes';
import { SearchBox } from './SearchBox';
import type { SelectMenuItem } from './TreeView';
import { TreeView } from './TreeView';

type SelectMenuListProps = {
  items: SelectMenuItem[];
  isSearchable: boolean;
  label: string;
  editor: any;
  onClose: Function;
  className?: string;
  disableMotion?: boolean;
  onBadgeClick?: (item: SelectMenuItem) => void;
};

export const SelectMenu = (props: SelectMenuListProps) => {
  const { isSearchable, editor, items, disableMotion, label, className, onBadgeClick, onClose } = props;

  const [searchText, setSearchText] = useState('');

  const wrapperRef = React.createRef<HTMLDivElement>();

  const onItemClick = (item: SelectMenuItem) => {
    if (item.type === DATAPOINT) {
      onClose();
      editor.commands.insertContent(createMention(item.id));
      editor.commands.focus();
    }

    if (item.type === DERIVED_CONTENT) {
      onClose();
      const mention = createMention(item.id, 'mention-list');
      editor.commands.insertContent(mention, { parseOptions: { preserveWhitespace: false } });
    }

    if (item.type === ENDORSEMENT_DATA) {
      onClose();
      editor.commands.insertContent(createEndorsementDataMention(item.id));
      editor.commands.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyCode.Esc) {
      onClose?.();
      return true;
    }

    return false;
  };

  return (
    <>
      <div
        className={clsx(
          'mt-2 flex w-[540px] flex-col divide-y divide-info-200 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
          INSERT_MENU_Z_INDEX,
        )}
      >
        <div
          className={containerClasses({ className })}
          onKeyDown={handleKeyDown}
          ref={wrapperRef}
          tabIndex={0}
          role="group"
        >
          {label ? <div className={clsx(listHeaderClasses)}>{label}</div> : null}
          {isSearchable ? <SearchBox onChange={setSearchText} searchText={searchText} /> : null}
          <TreeView
            items={items}
            searchText={searchText}
            onItemClick={onItemClick}
            onBadgeClick={onBadgeClick}
            disableMotion={disableMotion}
          />
        </div>
      </div>
    </>
  );
};
