import { useCallback } from 'react';

import type { ArtificialEventDetail, ArtificialOperation, EventOfType, GetEventValue } from '../types';

export const useDispatchCustomEvent = () => {
  return useCallback(
    <Operation extends ArtificialOperation>(name: Operation, payload?: GetEventValue<EventOfType<Operation>>) => {
      const event = new CustomEvent(name, {
        detail: {
          operation: name,
          value: payload,
        } as ArtificialEventDetail,
      });

      return document.dispatchEvent(event);
    },
    [],
  );
};
