import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useUnmount } from 'react-use';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { AnimatePresence } from 'framer-motion';
import pdfJSWorkerURL from 'pdfjs-dist/build/pdf.worker?url';

import { PDFPreviewController } from './controller/PDFPreviewController';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

export const PDFPreviewDrawer = () => {
  const { isOpen, togglePDFPreview } = useUIStore(({ isPDFPreviewOpen, togglePDFPreview }) => ({
    isOpen: isPDFPreviewOpen,
    togglePDFPreview,
  }));
  const [focussedCandidate, setFocussedCandidate] = useState<string | undefined>();
  const [focussedBlockId, setFocussedBlockId] = useState<string | undefined>();

  useCustomEventListener(ArtificialCustomEvent.SetDocumentFocussedCandidate, setFocussedCandidate);
  useCustomEventListener(ArtificialCustomEvent.SetFocussedBlockId, setFocussedBlockId);

  useEffect(() => {
    if (!isOpen) {
      setFocussedCandidate(undefined);
    }
  }, [isOpen]);

  useUnmount(() => {
    if (isOpen) {
      return togglePDFPreview();
    }
  });

  return (
    <AnimatePresence initial={false}>
      {isOpen && <PDFPreviewController focussedBlockId={focussedBlockId} focussedCandidate={focussedCandidate} />}
    </AnimatePresence>
  );
};
