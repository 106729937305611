import type { FC } from 'react';
import React from 'react';
import Tooltip from '@components/Tooltip';
import { Icon } from '@mdi/react';
import type { IconProps } from '@mdi/react/dist/IconProps';
import type { TippyProps } from '@tippyjs/react';
import clsx from 'clsx';

import IconMdi from './IconMdi';

export type TooltipedIconProps = IconProps & {
  onClick?: React.HTMLAttributes<HTMLDivElement>['onClick'];
  placement?: 'top' | 'right' | 'bottom' | 'left';
  'data-testid'?: string;
  subIcon?: string;
} & Pick<TippyProps, 'content' | 'maxWidth' | 'placement'>;

export const TooltipedIcon: FC<TooltipedIconProps> = ({
  'data-testid': dataTestId,
  onClick,
  placement = 'right',
  content,
  size = 0.7,
  subIcon,
  maxWidth,
  ...props
}) => (
  <Tooltip content={content} placement={placement} disabled={!content} maxWidth={maxWidth}>
    <div onClick={onClick} className="relative my-auto" data-testid={dataTestId}>
      <Icon {...props} size={size} />
      {subIcon && (
        <IconMdi path={subIcon} size={0.5} className={clsx(props.className, 'absolute -bottom-2 -right-2')} />
      )}
    </div>
  </Tooltip>
);
