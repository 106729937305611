import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import { PanelPlacement, useResizeableContainer } from '@ContractBuilder/hooks';
import { DataExtractionPDFPreviewController } from '@ContractBuilder/modules/data-extraction-pdf-preview';
import { LivePDFPreviewController } from '@ContractBuilder/modules/live-pdf-preview';
import { pdfPreviewPanelClasses, unfoldMoreButtonClasses } from '@ContractBuilder/modules/pdf-preview/views/classes';
import { mdiUnfoldMoreVertical } from '@mdi/js';
import { Icon } from '@mdi/react';
import type { DataExtractionData } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import { motion } from 'framer-motion';

import { getAnimationProps } from '../constants';
import { SelectedMode } from '../types';
import { useInitialWidth } from '../utils/useInitialWidth';
import { usePDFPreviewState } from '../utils/usePDFPreviewState';
import { DataExtractionSplitPane } from '../views/DataExtractionSplitPane';
import { HeaderRow } from '../views/HeaderRow';

interface PDFPreviewProps {
  dataExtractionItem?: DataExtractionData;
  documentName: string;
  documentUrl?: string;
  documentVersion?: number;
  expectedVersion?: number;
  extractionFileUrl?: string;
  focussedBlockId?: string;
  focussedCandidate?: string;
  onClose: () => void;
  onDownloadExtractionDocument: () => Promise<void>;
  onDownloadSubmissionDocument: () => Promise<void>;
}

const DOCUMENT_PADDING_PX = 27;
const MAX_WIDTH_OFFSET_PX = 320;

export const PDFPreview: FC<PDFPreviewProps> = ({
  dataExtractionItem,
  documentName,
  documentUrl,
  documentVersion,
  expectedVersion,
  extractionFileUrl,
  focussedBlockId,
  focussedCandidate,
  onClose,
  onDownloadExtractionDocument,
  onDownloadSubmissionDocument,
}) => {
  const [placement, setPlacement] = useState<PanelPlacement>(PanelPlacement.Right);
  const [selectedMode, setSelectedMode] = useState<SelectedMode>(SelectedMode.Document);

  const { initialWidth, onResize, windowWidth } = useInitialWidth();
  const hasDataExtraction = dataExtractionItem !== undefined;

  useEffect(() => {
    if (focussedBlockId && !(isInSplitMode || isInDocumentMode)) {
      setSelectedMode(SelectedMode.Document);
    }
    // eslint-disable-next-line -- We only want this to run when id of a focussed block changes
  }, [focussedBlockId]);

  useEffect(() => {
    if (focussedCandidate && !(isInSplitMode || isInDataExtractionDocumentMode)) {
      setSelectedMode(SelectedMode.DataExtractionDocument);
    }
    // eslint-disable-next-line -- We only want this to run when focussedCandidate changes
  }, [focussedCandidate]);

  const animationProps = useMemo(() => getAnimationProps(placement), [placement]);
  const { isLeftSidePlacement, isRightSidePlacement, isInDataExtractionDocumentMode, isInDocumentMode, isInSplitMode } =
    usePDFPreviewState({ placement, selectedMode });

  const isLoading = isEmptyValue(documentVersion) || isEmptyValue(expectedVersion);

  const { onMouseDown, width, pageWidth } = useResizeableContainer({
    debounceMs: 100,
    initialWidth,
    maxWidthOffset: isInSplitMode ? MAX_WIDTH_OFFSET_PX : 0,
    onResized: onResize,
    placement,
  });

  const handleChangePlacement = (newPlacement: PanelPlacement) => {
    if (isInSplitMode) {
      setSelectedMode(SelectedMode.Document);
    }

    return setPlacement(newPlacement);
  };

  const handleChangeSelectedMode = (newMode: SelectedMode) => {
    if (newMode === SelectedMode.Split) {
      setPlacement(PanelPlacement.Right);
    }

    if (selectedMode === SelectedMode.Split) {
      return setSelectedMode(SelectedMode.Document);
    }

    return setSelectedMode(newMode);
  };

  return (
    <div className="flex">
      <Show when={isInSplitMode && hasDataExtraction}>
        <DataExtractionSplitPane
          dataExtractionItem={dataExtractionItem}
          fileName={dataExtractionItem?.file_name}
          fileUrl={extractionFileUrl}
          onDownload={onDownloadExtractionDocument}
          pageWidth={windowWidth - pageWidth - DOCUMENT_PADDING_PX * 2}
          placement={placement}
          width={windowWidth - width}
        />
      </Show>
      <motion.div
        {...animationProps}
        className={pdfPreviewPanelClasses({
          isLeftSidePlacement,
          isRightSidePlacement: isRightSidePlacement || isInSplitMode,
        })}
        style={{ width }}
      >
        <div className="relative flex h-full w-full shrink-0 grow-0 flex-col bg-gray-100 px-3 py-1.5">
          <HeaderRow
            dataExtractionItem={dataExtractionItem}
            documentName={documentName}
            documentVersion={documentVersion}
            isInDataExtractionDocumentMode={isInDataExtractionDocumentMode}
            isInDocumentMode={isInDocumentMode}
            isInSplitMode={isInSplitMode}
            isLeftSidePlacement={isLeftSidePlacement}
            isLoading={isLoading}
            isRightSidePlacement={isRightSidePlacement}
            onChangePlacement={handleChangePlacement}
            onChangeSelectedMode={handleChangeSelectedMode}
            onClose={onClose}
          />

          <ShowFirstMatching>
            <Show when={isInDataExtractionDocumentMode && hasDataExtraction}>
              <DataExtractionPDFPreviewController
                dataExtractionItem={dataExtractionItem}
                fileUrl={extractionFileUrl}
                focussedCandidate={focussedCandidate}
                onDownload={onDownloadExtractionDocument}
                pageWidth={pageWidth}
                placement={placement}
              />
            </Show>
            <Show when={isInSplitMode || isInDocumentMode}>
              <LivePDFPreviewController
                documentUrl={documentUrl}
                documentVersion={documentVersion}
                expectedVersion={expectedVersion}
                focussedBlockId={focussedBlockId}
                onDownload={onDownloadSubmissionDocument}
                pageWidth={pageWidth}
                placement={placement}
              />
            </Show>
          </ShowFirstMatching>

          <button
            className={unfoldMoreButtonClasses({
              isLeftSidePlacement,
              isRightSidePlacement: isRightSidePlacement || isInSplitMode,
            })}
            onMouseDown={onMouseDown}
          >
            <Icon className="cursor-col-resize" path={mdiUnfoldMoreVertical} size={1} />
          </button>
        </div>
      </motion.div>
    </div>
  );
};
