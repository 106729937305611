import type { Option } from '../../types';
import { OptionMapper } from '../OptionMapper';

import { controlRow as controlRowContainerClasses, controlRowItemsClasses } from './classes';

interface ControlRowProps {
  options: Option[];
  isDisabled?: boolean;
}

export const ControlRow = ({ options, isDisabled = false }: ControlRowProps) => {
  if (!options?.length) return null;

  return (
    <div className={controlRowContainerClasses()}>
      <div className={controlRowItemsClasses}>
        {options?.map((option: Option) => <OptionMapper option={option} isDisabled={isDisabled} key={option.key} />)}
      </div>
    </div>
  );
};
