import type { FC } from 'react';
import Button from '@components/Button';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { scrollIntoView } from '@utils/scroll-into-view';
import clsx from 'clsx';
import { get } from 'lodash-es';

import { NavItem } from './NavItem';

interface FormNavigationProps {
  canSubmit: boolean;
  handleSubmit: (...args: any) => void;
  schema: any;
  loading?: boolean;
}

const FormNavigation: FC<FormNavigationProps> = ({ canSubmit, handleSubmit, schema, loading }) => {
  const { formValues } = useDatapointsStore(({ formValues }) => ({ formValues }));
  const properties = get(schema, 'properties', {});
  const sortedKeys = Object.keys(properties)
    .filter((key) => properties[key].qid >= 1)
    .sort((a, b) => properties[a].qid - properties[b].qid);

  const handleLinkClick = (linkId: string) => scrollIntoView(linkId);

  const isViewingRevisionHistory = useIsRevisionHistory();

  return (
    <div className="relative">
      <div
        className={clsx(
          'fixed top-32 ml-6 min-w-96 rounded-lg bg-white px-8 py-4 shadow',
          isViewingRevisionHistory && 'mt-12',
        )}
      >
        <div className="relative mb-3">
          <div className="absolute bottom-4 left-3 top-4 bg-info-200" />
          {sortedKeys.map((key) => {
            const section = properties[key] || {};
            const requiredKeys = section.required || [];
            const sectionFormValues = get(formValues, key) || {};
            const formKeys = Object.keys(sectionFormValues).filter((value) => requiredKeys.includes(value));

            return (
              <NavItem
                key={key}
                onClick={() => handleLinkClick(key)}
                title={properties?.[key]?.title}
                schema={schema}
                section={section}
                sectionKey={key}
                required={formKeys}
              />
            );
          })}
        </div>
        <div className="mr-4 flex justify-between border-t border-info-300 pb-4 pt-6">
          <Button
            as="button"
            size="sm"
            className="h-10"
            isDisabled={!canSubmit}
            kind="primary"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Save progress
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormNavigation;
