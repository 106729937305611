import type { BlockError } from '@root/@types/types';
import { blockErrorMessages } from '@root/helpers/constants';

interface InvalidInfoProps {
  errors?: BlockError[];
}

export const InvalidInfo = ({ errors }: InvalidInfoProps) => (
  <>
    {errors?.map((error) => (
      <p key={error} className="mb-2 text-sm text-info-400">
        {blockErrorMessages[error]}
      </p>
    ))}
  </>
);
