import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { DrawerSectionTitle } from './DrawerSectionTitle';

interface DrawerSectionProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  condition?: boolean;
}

export const DrawerSection = ({ title, children, className, condition = undefined }: DrawerSectionProps) =>
  condition !== undefined && !condition ? null : (
    <div className={twMerge('mb-6 h-fit', className)}>
      <DrawerSectionTitle>{title}</DrawerSectionTitle>
      <div data-testid={'block-placement'} className="flex flex-col gap-3">
        {children}
      </div>
    </div>
  );
