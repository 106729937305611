export const uploadAttachmentWrapperClasses = [
  'relative',
  'flex',
  'h-3/5',
  'flex-col',
  'justify-center',
  'rounded-lg',
  'border-2',
  'border-dashed',
  'pl-12',
  'pr-12',
  'pt-6',
  'pb-6',
  'text-center',
];

export const uploadAttachmentPageContainerClasses = ['h-full'];
