import type { FC } from 'react';
import { useQuery } from 'react-query';
import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { isEndorsementData } from '@ContractBuilder/types';
import { getDocumentContext } from '@ContractBuilder/utils';
import { downloadDocument } from '@helpers/downloadDocument';
import { usePullPdfDocumentUrl } from '@hooks/pullDocumentUrl';
import { getDataExtractionResult } from '@queries/getDataExtractionResult';
import { first } from 'lodash-es';

import { getResourceNameAndDocumentType } from '../utils/getResourceNameAndDocumentType';
import { usePrefetchPDFs } from '../utils/usePrefetchPDFs';
import { PDFPreview } from '../views/PDFPreview';

interface PDFPreviewControllerProps {
  focussedBlockId?: string;
  focussedCandidate?: string;
}

export const PDFPreviewController: FC<PDFPreviewControllerProps> = ({ focussedBlockId, focussedCandidate }) => {
  const submission = useEntityStore(({ submission }) => submission);
  const togglePreview = useUIStore(({ togglePDFPreview }) => togglePDFPreview);

  const documentContext = getDocumentContext();
  const { documentType, resourceName } = getResourceNameAndDocumentType(documentContext);

  const {
    documentVersion,
    expectedVersion,
    refetch: refetchDocumentUrl,
    url,
    documentPullUpdatedAt,
  } = usePullPdfDocumentUrl(resourceName, documentType, submission);

  const dataExtractionItem = first(submission?.dataExtractions);

  const { data, refetch } = useQuery(
    ['get-data-extraction-result', dataExtractionItem?.id],
    () => getDataExtractionResult(submission?.id as string, dataExtractionItem?.id as string),
    {
      enabled: dataExtractionItem?.id !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  usePrefetchPDFs({ documentPullUpdatedAt, documentUrl: url, extractionFileUrl: data?.fileUrl });

  const handleExtractionDocumentDownload = async () => {
    const response = await refetch();

    if (response.data?.fileUrl) {
      downloadDocument(response.data?.fileUrl);
    }
  };

  const handleSubmissionDocumentDownload = async () => {
    const response = await refetchDocumentUrl();

    if (response.data?.data.url) {
      downloadDocument(response.data?.data.url);
    }
  };

  const documentName =
    (submission && isEndorsementData(submission) ? submission.reference : submission?.name) ?? 'PDF Document';

  return (
    <PDFPreview
      dataExtractionItem={dataExtractionItem}
      documentName={documentName}
      documentUrl={url}
      documentVersion={documentVersion}
      expectedVersion={expectedVersion}
      extractionFileUrl={data?.fileUrl}
      focussedBlockId={focussedBlockId}
      focussedCandidate={focussedCandidate}
      onClose={togglePreview}
      onDownloadExtractionDocument={handleExtractionDocumentDownload}
      onDownloadSubmissionDocument={handleSubmissionDocumentDownload}
    />
  );
};
