export const reorderLayout = (layout: string[], itemId: string, toIndex: number): string[] => {
  const result = Array.from(layout ?? []);
  const isRemoving = toIndex === -1;

  if (isRemoving) {
    return result.filter((id) => id !== itemId);
  }

  const fromIndex = result.indexOf(itemId);
  const isAdding = fromIndex === -1;

  if (isAdding) {
    result.splice(toIndex, 0, itemId);
    return result;
  }

  const [item] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, item);

  return result;
};
