import type { DependencyList, EffectCallback } from 'react';
import { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export function useDidUpdateEffect(fn: EffectCallback, inputs: DependencyList) {
  const didMountRef = useRef(false);

  useDeepCompareEffect(() => {
    if (didMountRef.current) {
      return fn();
    }

    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
