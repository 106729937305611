import { isNil } from 'lodash-es';

const allowedSortDirections = ['asc', 'desc'];

export const getSortDirection = (sortDirection: string | null | undefined, defaultSortDirection = 'desc') => {
  if (isNil(sortDirection)) {
    return defaultSortDirection;
  }
  const sortDirectionAsLowercaseString = sortDirection.toLowerCase();
  if (!allowedSortDirections.includes(sortDirectionAsLowercaseString)) {
    return defaultSortDirection;
  }
  return sortDirectionAsLowercaseString;
};
