import type { DatapointsStoreState } from '@ContractBuilder/store/datapoints.store';
import { alwaysArray } from '@utils/always-array';
import { get } from 'lodash-es';

import { isIncomplete } from './is-incomplete';

interface DatapointFieldSelectorProps {
  ownKey: string;
  isRequired?: boolean;
  childrenIds?: string[];
  errors?: string | string[];
}

export const datapointFieldSelector =
  ({ ownKey: id, isRequired, childrenIds, errors }: DatapointFieldSelectorProps) =>
  ({ formValues }: Pick<DatapointsStoreState, 'formValues'>) => ({
    value: get(formValues, id),
    isIncomplete: isIncomplete(
      childrenIds?.length ? childrenIds : [id],
      Boolean(isRequired),
      formValues,
      alwaysArray(errors),
    ),
  });
