interface Data {
  file: File;
}

export const uploadImage = async <T extends Data>(url: string, data: T) => {
  await fetch(url, {
    method: 'PUT',
    body: data.file,
    headers: {
      'Content-Type': data.file.type,
      'Content-Length': `${data?.file?.length || 0}`,
    },
  });
};
