import { createBranding } from '@mutations/createBranding';
import { updateBranding } from '@mutations/updateBranding';
import { ApiPatchOperation, type BrandingDetailsType, type BrandingStatus } from '@root/@types/types';
import { isEqual } from 'lodash-es';
import { create } from 'zustand';

import { getAuthorship } from '../utils/get-authorship';

export enum BrandingEditorKey {
  Header = 'branding-header',
  Footer = 'branding-footer',
}

interface BrandingStore {
  originalBranding: Partial<BrandingDetailsType> | null;
  branding: Partial<BrandingDetailsType> | null;
  isLoading: boolean;
  dateInfo: string | null;
  form: {
    isDirty: boolean;
    editorKey: BrandingEditorKey | null;
  };
  reset: () => void;
  setBranding: (branding: Partial<BrandingDetailsType>) => void;
  setLoading: (isLoading: boolean) => void;
  saveChanges: () => Promise<void>;
  saveNameChange: (name: string) => Promise<void>;
  handleChange: (key: keyof BrandingDetailsType) => (value: string) => void;
  updateStatus: (status: BrandingStatus) => void;
  restore: () => void;
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => void;
  duplicateBranding: (sourceId: string) => Promise<string>;
}

export const useBrandingStore = create<BrandingStore>((set, get) => ({
  originalBranding: null,
  branding: null,
  isLoading: false,
  dateInfo: null,
  form: {
    editorKey: null,
    isDirty: false,
  },
  reset: () => {
    return set({
      dateInfo: null,
      isLoading: false,
      originalBranding: null,
      branding: null,
      form: {
        editorKey: null,
        isDirty: false,
      },
    });
  },
  setBranding: (branding) => {
    const cleanedBranding = {
      ...branding,
      header_content: branding.header_content ?? '',
      footer_content: branding.footer_content ?? '',
    };
    set({ branding: cleanedBranding, originalBranding: cleanedBranding, dateInfo: getAuthorship(branding) });
  },
  setLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => {
    set((state) => ({ form: { ...state.form, editorKey } }));
  },
  handleChange: (key: keyof BrandingDetailsType) => (value: string) => {
    const original = get().originalBranding;
    const branding = get().branding;
    const newBranding = { ...branding, [key]: value };
    const isDirty = !isEqual(original, newBranding);
    set((state) => ({
      branding: newBranding as BrandingDetailsType,
      form: { ...state.form, isDirty },
    }));
  },
  restore: () => {
    set((s) => ({ isLoading: false, form: { isDirty: false, editorKey: null }, branding: s.originalBranding }));
  },
  saveChanges: async () => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return;
    }

    await updateBranding(branding, ApiPatchOperation.UpdateContent);
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
  },
  duplicateBranding: async (sourceId) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding?.name) {
      throw new Error('Branding name is required');
    }

    const id: string = (await createBranding({ name: branding.name, clone_from_id: sourceId })).id;
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });

    return id;
  },
  saveNameChange: async (name) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding || !branding.id) {
      return;
    }

    const updated = { ...branding, name };

    await updateBranding(updated, ApiPatchOperation.UpdateName);
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    get().setBranding(updated);
  },
  updateStatus: async (status) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return;
    }

    const updated = { ...branding, status };
    await updateBranding(updated, ApiPatchOperation.UpdateStatus);

    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    get().setBranding(updated);
  },
}));
