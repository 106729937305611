import { useSchemaStore } from '@ContractBuilder/store';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import type { BlockVisibilityCondition, BlockVisibilityOutput, BlockVisibilityValueType } from '@root/@types/types';
import { formatDate, formatTime } from '@root/helpers';

export const formatItemValue = (item: any | null, value: BlockVisibilityValueType | undefined) => {
  if (!item) return value;

  const { type, properties, format } = item;

  switch (true) {
    case type === 'object' && 'amount' in properties && 'currency' in properties:
      return `${value} ${properties.currency}`;
    case type === 'object' && 'value' in properties && 'unit' in properties:
      return `${value} ${properties.unit}`;
    case type === 'string' && 'date' === format:
      return formatDate(value as string, 'D MMMM YYYY');
    case type === 'string' && 'time' === format:
      return formatTime(value as string);
    case type === 'string' && 'date-time' === format:
      return formatDate(value as string, 'D MMMM YYYY HH:mm');
    case type === 'string':
    case type === 'number':
    default:
      return value;
  }
};

export type NamedVisibilityCondition = BlockVisibilityCondition & {
  result: boolean;
};

export const useBlockVisibilityNamedReasons = (
  visibilityDetails: BlockVisibilityOutput,
): NamedVisibilityCondition[] => {
  const { findField } = useSchemaStore(({ findField }) => ({ findField }));

  const list = useDeepCompareMemo(
    () =>
      visibilityDetails.conditions?.map(({ field, value, operator, result }) => {
        const resolved = findField(field, false, true);
        return {
          result,
          operator,
          field: resolved?.title ?? field,
          value: formatItemValue(resolved, value ?? ''),
        };
      }),
    [findField, visibilityDetails.conditions],
  );

  return list;
};
