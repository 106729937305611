import type { ChangeEventHandler, FC, ReactNode } from 'react';
import { useState } from 'react';
import { AnimateHeight } from '@components/AnimateHeight';
import Button from '@components/Button';
import InputCheckbox from '@components/InputCheckbox';
import type { PopoverProps } from '@components/Popover';
import { Popover } from '@components/Popover';
import type { BlockType, ClauseType } from '@root/@types/types';
import { useLanguage } from '@src/language';

import type { FiltersState } from '../types';

interface SearchFiltersDropdownProps {
  filters: FiltersState;
  onApplyFilters: (value: FiltersState) => void;
  onResetFilters: () => void;
  renderTrigger: PopoverProps['renderTrigger'];
  isFetching: boolean;
}

const FilterTitle = ({ children }: { children: ReactNode }) => (
  <p className=" mb-0 mt-3 font-semibold uppercase leading-[18px] text-info-500 first:mt-1">{children}</p>
);

export const SearchFiltersDropdown: FC<SearchFiltersDropdownProps> = ({
  filters: currentFiltersState,
  onApplyFilters,
  onResetFilters,
  renderTrigger,
  isFetching,
}) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });
  const [filters, setFilters] = useState<FiltersState>(currentFiltersState);

  const getChangeHandler =
    (type: 'type' | 'clause_type'): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      const isChecked = event.target.checked;
      const name = event.target.name;

      return setFilters((current) => {
        const nextValue = isChecked ? [...current[type], name] : current[type].filter((item) => item !== name);

        if (name === 'clause' && !isChecked) {
          return {
            type: nextValue,
            clause_type: [],
          } as FiltersState;
        }

        return {
          ...current,
          [type]: nextValue,
        };
      });
    };
  const handleBlockTypeChangeHandler = getChangeHandler('type');
  const handleClauseTypeChangeHandler = getChangeHandler('clause_type');

  const getBlockTypeInputProps = (value: BlockType) => {
    return {
      isChecked: filters.type.includes(value),
      name: value,
      onChange: handleBlockTypeChangeHandler,
    };
  };

  const getClauseTypeInputProps = (value: ClauseType) => {
    return {
      isChecked: filters.clause_type.includes(value),
      name: value,
      onChange: handleClauseTypeChangeHandler,
    };
  };

  const clauseProps = getBlockTypeInputProps('clause');

  return (
    <Popover
      containerClassName="relative flex flex-col bg-white grow w-[270px] rounded-lg"
      isMinimal
      middleware={[]}
      offset={2}
      renderTrigger={renderTrigger}
      shouldRenderInsidePortal
      wrapperClassName="ring-1 ring-black ring-opacity-5 bg-white shadow-lg w-[270px] flex rounded-lg"
    >
      {({ onClose }) => (
        <>
          <div className="flex flex-col gap-1.5 rounded-lg rounded-b-none bg-white p-4 text-sm">
            <FilterTitle>Block Type</FilterTitle>
            <InputCheckbox {...getBlockTypeInputProps('generic')} labelText={getContent('generic')} />
            <InputCheckbox
              {...getBlockTypeInputProps('mrc-heading')}
              id="generic"
              labelText={getContent('mrc-heading')}
            />
            <InputCheckbox {...clauseProps} labelText={getContent('clause')} />
            <AnimateHeight isVisible={clauseProps.isChecked}>
              <div className="ml-4 flex flex-col gap-1.5">
                <InputCheckbox {...getClauseTypeInputProps('Condition')} labelText="Condition" />
                <InputCheckbox {...getClauseTypeInputProps('Endorsement')} labelText="Endorsement" />
                <InputCheckbox {...getClauseTypeInputProps('Exclusion')} labelText="Exclusion" />
                <InputCheckbox {...getClauseTypeInputProps('Standard Warranties')} labelText="Standard Warranties" />
                <InputCheckbox {...getClauseTypeInputProps('Express Warranties')} labelText="Express Warranties" />
                <InputCheckbox {...getClauseTypeInputProps('Wording')} labelText="Wording" />
              </div>
            </AnimateHeight>
          </div>
          <div className="flex justify-end gap-4 rounded-lg rounded-t-none bg-info-50 p-4">
            <Button
              className="h-8 px-2"
              kind="secondary"
              onClick={() => {
                onClose();
                return onResetFilters();
              }}
              isDisabled={isFetching}
              size="sm"
            >
              Reset
            </Button>
            <Button
              className="h-8 px-2"
              kind="primary"
              onClick={() => {
                onClose();
                return onApplyFilters(filters);
              }}
              isDisabled={isFetching}
              size="sm"
            >
              Apply filters
            </Button>
          </div>
        </>
      )}
    </Popover>
  );
};
