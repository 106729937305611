import { useCallback, useState } from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const handleOn = useCallback(() => {
    setValue(true);
  }, []);

  const handleOff = useCallback(() => {
    setValue(false);
  }, []);

  const handleToggle = useCallback(() => {
    setValue((current) => !current);
  }, []);

  return [value, handleOn, handleOff, handleToggle] as const;
};
