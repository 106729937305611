import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Stick from 'react-stick';
import { INSERT_MENU_Z_INDEX } from '@constants/z-indices';
import { useToggle } from '@src/hooks';
import clsx from 'clsx';

import { InsertIcon } from './components/InsertIcon';
import { SECTIONS_MAP } from './constants';

type SectionsKey = keyof typeof SECTIONS_MAP;

interface InsertMenuProps {
  editor: any;
}

export const InsertMenu: FC<InsertMenuProps> = ({ editor }) => {
  const [isOpen, _onOpen, onClose, onToggle] = useToggle(false);
  const [expandedSectionId, setExpandedSectionId] = useState<keyof typeof SECTIONS_MAP>();

  useEffect(() => {
    if (!isOpen) {
      setExpandedSectionId(undefined);
    }
  }, [isOpen]);

  return (
    <Stick
      style={{
        node: {
          zIndex: 1200,
        },
      }}
      node={
        isOpen ? (
          <div
            className={clsx(
              'mt-2 flex w-[540px] flex-col divide-y divide-info-200 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5',
              INSERT_MENU_Z_INDEX,
            )}
          >
            {Object.entries(SECTIONS_MAP).map(([key, Component]) => {
              return (
                <Component
                  editor={editor}
                  expandedSectionId={expandedSectionId}
                  id={key}
                  key={key}
                  onClose={onClose}
                  onViewMore={() => setExpandedSectionId(key as SectionsKey)}
                />
              );
            })}
          </div>
        ) : null
      }
      position="bottom left"
      align="top left"
      onClickOutside={onClose}
    >
      <button
        data-testid="insert-datapoint-button"
        onClick={onToggle}
        className="flex cursor-pointer items-center gap-x-2 rounded-md px-1 py-0.5 text-sm text-info-800 transition-colors hover:bg-primary-50"
        disabled={!editor}
      >
        <InsertIcon />
        Insert
      </button>
    </Stick>
  );
};
