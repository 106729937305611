import { AuthCard } from '@Auth/AuthCard';
import IconMdi from '@components/IconMdi';
import { mdiInformationVariantCircle } from '@mdi/js';

export const LogoutSuccess = () => {
  return (
    <AuthCard className="bg-primary-50">
      <IconMdi path={mdiInformationVariantCircle} className="text-primary-400" size={0.8} />
      <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-2">
        <div className="self-stretch text-sm font-medium leading-tight text-primary-800">
          You are now safely logged out, you can close this tab and return to PPL
        </div>
      </div>
    </AuthCard>
  );
};
