import { patchEntity } from '@mutations/patchEntity';
import { ApiPatchOperation } from '@root/@types/types';

import type { CommentsStoreSlice, SliceType } from './types';

export const createCommentsSlice: SliceType<CommentsStoreSlice> = (set, get) => ({
  addComment: async (content, blockId, threadId) => {
    set({ isLoading: true });
    const submission = get().submission;

    if (!submission) {
      return;
    }

    await patchEntity({
      operation: ApiPatchOperation.AddNewComment,
      content,
      blockId,
      threadId,
    });

    await get().reloadSubmission(['blocks', 'comments']);
    set({ isLoading: false });
  },

  resolveConversation: async (threadId) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    const result = await patchEntity({
      operation: ApiPatchOperation.ResolveCommentsThread,
      threadId,
    });

    if (result?.message === 'success') {
      const response = await get().reloadSubmission();
      return response;
    }
  },
});
