export const OneColumn = () => (
  <svg width="80" height="37" viewBox="0 0 80 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.867188" width="79" height="35" rx="3.5" fill="white" />
    <path d="M8 8.36719H72" stroke="#2563EB" />
    <path d="M8 12.3672H72" stroke="#2563EB" />
    <path d="M8 16.3672H72" stroke="#2563EB" />
    <path d="M8 20.3672H72" stroke="#2563EB" />
    <path d="M8 24.3672L72 24.3672" stroke="#2563EB" />
    <path d="M8 28.3672L72 28.3672" stroke="#2563EB" />
    <rect x="0.5" y="0.867188" width="79" height="35" rx="3.5" stroke="#2563EB" />
  </svg>
);
