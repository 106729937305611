export const minLimit = 12;
export const maxLimit = 50;

export const getLimit = (
  limitQueryStringParam: string | null | undefined,
  totalAmount: number,
  defaultLimit = minLimit,
  defaultMaxLimit = maxLimit,
) => {
  try {
    const limitAsNumber = Number(limitQueryStringParam);
    if (isNaN(limitAsNumber) || limitAsNumber < defaultLimit) {
      return defaultLimit;
    }
    if (limitAsNumber > defaultMaxLimit || limitAsNumber > totalAmount) {
      return defaultMaxLimit;
    }
    return limitAsNumber;
  } catch {
    return defaultLimit;
  }
};
