import React from 'react';
import clsx from 'clsx';

import type { SvgIconName } from './Svg';
import Svg from './Svg';

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: SvgIconName;
}

const Icon: React.FC<IconProps> = ({ name, className, ...props }) => {
  return (
    <div {...props} className={clsx('fill-current', className)}>
      <Svg name={name} />
    </div>
  );
};

export default Icon;
