import { cva } from 'class-variance-authority';
import clsx from 'clsx';

export const controlRowItemsClasses = clsx([
  'items-center',
  'gap-1',
  'relative',
  'flex',
  'cursor-pointer',
  'whitespace-nowrap',
  'text-left',
]);

export const controlRow = cva([
  //
  'absolute',
  'bottom-0',
  'right-0',
  'w-full',
  'bg-info-100',
  'transform',
  'translate-y-full',
  'h-10',
  'flex',
  'items-center',
  'px-5',
  'border-b',
]);

export const controlRowIconBackgroundClasses = cva(
  [
    //
    'absolute',
    'bottom-0',
    'transform',
    'bg-info-100',
    '-translate-x-1',
    'h-11',
    'w-10',
    'rounded-t-md',
  ],
  {
    variants: {
      isOpen: {
        true: ['opacity-100'],
        false: ['opacity-0'],
      },
    },
  },
);
