import type { FC } from 'react';
import MODALS from '@constants/modals';
import type { BlockVisibilityWarningsProps } from '@ContractBuilder/components/Modal/BlockVisibilityWarnings';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useModal } from '@hooks/useModal';
import type { ResourceBlock } from '@root/@types/base';
import Toggle from '@root/src/components/Toggle';

import { useVariationsTabs } from '../../variations-tabs/context/context';
import type { BlockConfigurationPanelFormState } from '../types';

export interface VariationToggleProps {
  block?: BlockConfigurationPanelFormState;
  label: string;
}

type ModalProps = Omit<BlockVisibilityWarningsProps, 'handleClose'>;

const blockHasConditionalVisibility = (block?: ResourceBlock) => !!block?.visibility?.block;
const variantsHaveConditionalVisibility = (block?: ResourceBlock) => !!block?.visibility?.variations?.length;

export const VariationToggle: FC<VariationToggleProps> = ({ label }) => {
  const { variationsCount, create, removeAll } = useVariationsTabs();
  const { currentBlock } = useBlockEditFormStore(({ currentBlock }) => ({
    currentBlock,
  }));
  const { showModal } = useModal();

  const hasVariations = !!variationsCount;

  const turnOffVariations = () => {
    if (variantsHaveConditionalVisibility(currentBlock)) {
      showModal(MODALS.BLOCK_VISIBILITY_WARNING_MODAL, {
        mode: 'variations',
        onDisregardWarning: removeAll,
        isOpen: true,
      } satisfies ModalProps);
      return;
    }

    removeAll();
  };

  const turnOnVariations = () => {
    if (blockHasConditionalVisibility(currentBlock)) {
      showModal(MODALS.BLOCK_VISIBILITY_WARNING_MODAL, {
        mode: 'block',
        isOpen: true,
        onDisregardWarning: create,
      } satisfies ModalProps);
      return;
    }

    create();
  };

  const toggleVariations = hasVariations ? turnOffVariations : turnOnVariations;

  return <Toggle labelText={label} data-testid="toggle-variations" value={hasVariations} onClick={toggleVariations} />;
};
