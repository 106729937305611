import { SECTIONS_KEYS_SELECTOR } from '@ContractBuilder/settings';
import type { StandardisedSectionName } from '@root/@types/types';

export const getStandardisedSectionLabel = (sectionName?: StandardisedSectionName) => {
  if (!sectionName) {
    return undefined;
  }

  const section = SECTIONS_KEYS_SELECTOR.find((item) => item.value === sectionName);

  if (!section) {
    return sectionName;
  }

  return section.label;
};
