/* eslint-disable no-redeclare */
import { isString } from 'lodash-es';

export function sortAlphabetically(a: string, b: string): number;
export function sortAlphabetically<T extends { [key: string]: any }, K extends keyof T>(
  a: T,
  b: T,
  key: K & (T[K] extends string ? K : never),
): number;

export function sortAlphabetically<T extends { [key: string]: any } | string, K extends keyof T>(
  a: T,
  b: T,
  key?: K & (T[K] extends string ? K : never),
): number {
  if (isString(a) && isString(b)) {
    return a.localeCompare(b);
  }

  if (key && !isString(a) && !isString(b)) {
    const aValue = a[key];
    const bValue = b[key];

    if (isString(aValue) && isString(bValue)) {
      return aValue.localeCompare(bValue);
    }
  }

  return 0;
}
