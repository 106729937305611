import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { TransformedCognitoUser } from '../../@types/cognito';

export const createUser = async (
  data: Pick<TransformedCognitoUser, 'first_name' | 'last_name' | 'email' | 'role' | 'teams'>,
): Promise<Pick<TransformedCognitoUser, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: `users-spa-al`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
