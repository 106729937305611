import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { Show, ShowFirstMatching } from '@components/Show';
import { Spinner } from '@components/Spinner';
import Tooltip from '@components/Tooltip';
import { PanelPlacement } from '@ContractBuilder/hooks';
import { mdiClose, mdiCompareHorizontal, mdiSwapHorizontal } from '@mdi/js';
import type { DataExtractionData } from '@root/@types/types';
import clsx from 'clsx';

import { FileNameTab } from '../components/FileNameTab';
import { PlacementButton } from '../components/PlacementButton';
import { SelectedMode } from '../types';

import { buttonDisabledClasses, documentFileNameTabClasses, splitDocumentsButtonClasses } from './classes';

interface HeaderRowProps {
  dataExtractionItem?: DataExtractionData;
  documentName: string;
  documentVersion?: number;
  isInDataExtractionDocumentMode: boolean;
  isInDocumentMode: boolean;
  isInSplitMode: boolean;
  isLeftSidePlacement: boolean;
  isLoading: boolean;
  isRightSidePlacement: boolean;
  onChangePlacement: (newValue: PanelPlacement) => void;
  onClose: () => void;
  onChangeSelectedMode: (mode: SelectedMode) => void;
}

export const HeaderRow: FC<HeaderRowProps> = ({
  dataExtractionItem,
  documentName,
  documentVersion,
  isInDataExtractionDocumentMode,
  isInDocumentMode,
  isInSplitMode,
  isLeftSidePlacement,
  isLoading,
  isRightSidePlacement,
  onChangePlacement,
  onChangeSelectedMode,
  onClose,
}) => {
  const hasDataExtractionItem = dataExtractionItem !== undefined;

  return (
    <div className="flex items-center text-gray-500">
      <div className="mr-3 flex shrink-0 flex-row overflow-hidden rounded-md border border-gray-200 bg-white">
        <PlacementButton
          className={clsx(isLeftSidePlacement && buttonDisabledClasses)}
          onClick={onChangePlacement}
          placement={PanelPlacement.Left}
        />
        <Show when={hasDataExtractionItem}>
          <Tooltip content={isInSplitMode ? 'Close compare view' : 'Compare view'}>
            <button
              className={splitDocumentsButtonClasses({ isInSplitMode })}
              onClick={() => onChangeSelectedMode(SelectedMode.Split)}
              title="Split documents"
            >
              <IconMdi
                className="transition-colors hover:text-gray-400"
                path={isInSplitMode ? mdiCompareHorizontal : mdiSwapHorizontal}
              />
            </button>
          </Tooltip>
        </Show>
        <PlacementButton
          className={clsx(isRightSidePlacement && buttonDisabledClasses)}
          onClick={onChangePlacement}
          placement={PanelPlacement.Right}
        />
      </div>
      <FileNameTab
        className={documentFileNameTabClasses({ marginFull: isInSplitMode || !hasDataExtractionItem })}
        content={
          <>
            <p className="min-w-[24px] truncate text-xs font-normal leading-5" title={documentName}>
              {documentName}
            </p>
            <Tooltip content={<div>PDF Save no. {documentVersion}</div>} placement="bottom">
              <div className="flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full bg-gray-200 p-1">
                <span className="text-medium text-xs text-gray-800" data-testid="pdf-document-version">
                  <ShowFirstMatching fallback={documentVersion}>
                    <Show when={isLoading}>
                      <span
                        aria-live="polite"
                        className="flex h-4 w-4 items-center justify-center"
                        data-testid="loading-overlay"
                      >
                        <Spinner />
                      </span>
                    </Show>
                  </ShowFirstMatching>
                </span>
              </div>
            </Tooltip>
          </>
        }
        isActive={isInDocumentMode || isInSplitMode}
        mode={SelectedMode.Document}
        onClick={onChangeSelectedMode}
      />
      <Show when={!isInSplitMode && hasDataExtractionItem}>
        <FileNameTab
          className="mr-auto"
          content={dataExtractionItem?.file_name ?? 'Data Extraction PDF'}
          isActive={isInDataExtractionDocumentMode}
          onClick={onChangeSelectedMode}
          mode={SelectedMode.DataExtractionDocument}
        />
      </Show>
      <IconMdi
        className="translate-x-1 cursor-pointer transition-colors hover:text-gray-400"
        data-cypress="close-pdf-button"
        onClick={onClose}
        path={mdiClose}
        title="Close preview"
      />
    </div>
  );
};
