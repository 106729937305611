import type { FC } from 'react';
import { useMemo } from 'react';
import { EDIT_TOOLBAR_Z_INDEX } from '@constants/z-indices';
import { useEditorsMap } from '@ContractBuilder/modules/editors-map';
import { WysiwygControls } from '@WysiwygEditor/components';
import {
  getBrandingDatapointControls,
  getDatapointControls,
  getEndorsementSummaryBlockDatapointControls,
} from '@WysiwygEditor/components/Controls/utils/datapoint-controls';
import clsx from 'clsx';

import { TOOLBAR_HEIGHT } from '../../../settings';

const datapointControls = {
  contract: getDatapointControls,
  branding: getBrandingDatapointControls,
  endorsementSummary: getEndorsementSummaryBlockDatapointControls,
};

interface EditorToolbarControllerProps {
  currentEditorKey: string | null;
  type?: keyof typeof datapointControls;
}

export const EditorToolbarController: FC<EditorToolbarControllerProps> = ({ currentEditorKey, type = 'contract' }) => {
  const { getEditor } = useEditorsMap();

  const editor = currentEditorKey ? getEditor(currentEditorKey) : null;
  const isDisabled = !editor;

  const datapointsControl = useMemo(() => datapointControls[type], [type]);

  if (isDisabled) {
    return null;
  }

  return (
    <div className={clsx('mx-auto flex w-full items-center border-b bg-white py-2 pl-4 text-center', TOOLBAR_HEIGHT)}>
      <WysiwygControls
        className={clsx('grow border-none', EDIT_TOOLBAR_Z_INDEX)}
        datapointsControl={datapointsControl}
        editor={editor}
      />
    </div>
  );
};
