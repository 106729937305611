import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputMultiSelect from '@components/InputMultiSelect';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import type { Team } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';

import type { StepProps } from '../../types';

interface Step1Props extends StepProps {
  userTeams: Team[];
}

const getRequiredMessage = (message: string = '') => (message === 'Required' ? 'A template requires a name' : message);

export const Step1: FC<Step1Props> = ({ control, formValues, userTeams }) => {
  return (
    <>
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputText
            {...field}
            errors={getRequiredMessage(error?.message)}
            isDisabled={field.disabled}
            labelText="Template name"
            placeholder="New template"
          />
        )}
        name="name"
      />
      <Controller
        control={control}
        render={({ field: { disabled, onChange, ref: _ref, ...fieldProps }, fieldState: { error } }) => (
          <InputMultiSelect
            {...fieldProps}
            data-testid="assign-to-teams-dropdown"
            errors={error?.message}
            isDisabled={disabled}
            labelText="Assign to teams"
            onChange={(value) => onChange(value ?? [])}
            options={userTeams.map(({ id, name }) => ({ name, value: id }))}
            placeholder="Select a team"
          />
        )}
        name="team"
      />
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            errors={error?.message}
            isClearable
            isDisabled={field.disabled}
            labelText="Class of business"
            options={ALL_CLASSES_OF_BUSINESS}
            placeholder="Select the class of business"
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={formValues?.class_of_business}
          />
        )}
        name="class_of_business"
      />
    </>
  );
};
