import CognitoLogin from './CognitoLogin';
import EntraLogin from './EntraLogin';

const getLogin = () => {
  const authMethod = import.meta.env.VITE_AUTH_METHOD;

  switch (authMethod) {
    case 'entra':
      return EntraLogin;
    default:
      return CognitoLogin;
  }
};

const Login = getLogin();
export default Login;
