import { Bold } from '@tiptap/extension-bold';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import { Italic } from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import {
  ContentRepeaterGroup,
  ContentRepeaterGroupChild,
  FontSize,
  Indent,
  ListItem,
  OrderedList,
  Paragraph,
  SectionRepeater,
  SectionRepeaterChild,
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableRow,
  UppercaseMark,
} from '.';

export const prepareBaseEditorExtensions = (additionalExtensions: any[]): any[] =>
  [
    StarterKit.configure({
      orderedList: false,
      listItem: false,
      hardBreak: false,
      bold: false,
      code: false,
      codeBlock: false,
      horizontalRule: false,
      blockquote: false,
      strike: false,
      heading: false,
      italic: false,
      paragraph: false,
    }),
    Underline,
    Table(),
    TableRow(),
    TableHeader,
    OrderedList,
    ListItem,
    Indent,
    Paragraph,
    FontSize,
    Heading.extend({
      addAttributes() {
        return {
          dataType: {
            default: null,
            parseHTML: (element: HTMLElement) => element.getAttribute('data-type'),
            renderHTML: (attributes: Record<string, any>) => {
              return {
                'data-type': attributes.dataType,
              };
            },
          },
          level: {
            default: 1,
            rendered: false,
          },
        };
      },
      addInputRules() {
        return [];
      },
    }),
    UppercaseMark,
    HardBreak.extend({
      addKeyboardShortcuts() {
        return {
          'Shift-Enter': () => this.editor.commands.setHardBreak(),
        };
      },
    }).configure({
      keepMarks: false,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    TextStyle,
    TableCell(),
    Bold.extend({
      addInputRules() {
        return [];
      },
    }),
    Italic.extend({
      addInputRules() {
        return [];
      },
    }),

    Strike.extend({
      addInputRules() {
        return [];
      },
    }),
    Subscript,
    Superscript,
    SectionRepeater,
    SectionRepeaterChild,
    ContentRepeaterGroup,
    ContentRepeaterGroupChild,
    ...additionalExtensions,
  ].filter(Boolean);
