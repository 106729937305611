import type { FC, ReactNode } from 'react';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import Button from '@components/Button';
import { Show } from '@components/Show';
import clsx from 'clsx';

import { DEFAULT_CREATOR_PADDING } from '../constants';
import { useBlockCreator } from '../context';
import type { BlockCreatorStepId } from '../types';

import { StepIndicator } from './StepIndicator';

interface StepViewProps {
  children: ReactNode;
  isLoading?: boolean;
  isValid?: boolean;
  nextButtonLabel?: string;
  onNext?: () => void;
  onPrev?: () => void;
  secondHeaderTitle?: string;
  stepId: BlockCreatorStepId;
}

export const StepView: FC<StepViewProps> = ({
  children,
  isLoading,
  isValid,
  nextButtonLabel,
  onNext,
  onPrev,
  secondHeaderTitle,
  stepId,
}) => {
  const { setStep, handleCancel } = useBlockCreator();
  return (
    <>
      <div className={clsx('relative flex justify-between font-bold', DEFAULT_CREATOR_PADDING)}>
        <div className="flex items-center gap-6">
          <StepIndicator
            name="Select Type"
            order={1}
            completed={!!secondHeaderTitle}
            onClick={secondHeaderTitle ? () => setStep('start') : undefined}
          />
          <Show when={!!secondHeaderTitle}>
            <StepIndicator name={secondHeaderTitle!} order={2} />
          </Show>
        </div>
        <CloseIconButton size={1} onClick={handleCancel} content={undefined} />
      </div>
      <div className="grow">{children}</div>
      <div className={clsx('flex items-center justify-between', DEFAULT_CREATOR_PADDING)} id="buttons">
        <Button size="sm" kind="secondary" className={'h-9'} onClick={stepId !== 'start' ? onPrev : handleCancel}>
          {stepId !== 'start' ? 'Back' : 'Cancel'}
        </Button>
        <Show when={!!onNext}>
          <Button size="sm" kind="primary" loading={isLoading} className={'h-9'} isDisabled={!isValid} onClick={onNext}>
            {nextButtonLabel}
          </Button>
        </Show>
      </div>
    </>
  );
};
