import React, { forwardRef } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import type { ElementSize } from '@root/@types/types';
import { isEmpty } from 'lodash-es';

import { alwaysArray } from '../utils/always-array';

import InputReadOnly from './InputReadOnly';
import { inputClasses } from './shared-classes';

export interface InputTextProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    Pick<InputFieldProps, 'labelIcon' | 'isIncomplete' | 'hideErrors'> {
  cdrId?: string;
  cdrName?: string;
  descriptionText?: string;
  endAdornment?: React.ReactNode;
  errors?: string | string[];
  formatter?: (value: unknown) => string;
  helperText?: string;
  inputClassName?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: React.ReactNode;
  link?: React.ReactNode;
  mrcId?: string;
  name?: string;
  shortName?: string;
  size?: ElementSize;
  startAdornment?: React.ReactNode;
  shouldHideTitle?: boolean;
  isIncomplete?: boolean;
  isPublic?: boolean;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(props, ref) {
  const {
    autoFocus,
    cdrId,
    cdrName,
    className,
    descriptionText,
    endAdornment,
    errors,
    helperText,
    id,
    inputClassName,
    isDisabled = false,
    isReadOnly,
    isRequired,
    labelIcon,
    labelText,
    link,
    max,
    maxLength,
    min,
    minLength,
    mrcId,
    name,
    onChange,
    onInput,
    pattern,
    placeholder,
    shortName,
    shouldHideTitle,
    size = 'md',
    startAdornment,
    step,
    type,
    value,
    isIncomplete,
    isPublic,
    hideErrors,
    ...rest
  } = props;

  const errorsArray = alwaysArray(errors);
  const hasErrors = !isEmpty(errorsArray);
  const isErrorVisible = Boolean(hasErrors && !hideErrors);

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      endAdornment={endAdornment}
      errors={errors}
      helperText={helperText}
      labelIcon={labelIcon}
      id={id ?? name}
      isRequired={isRequired}
      labelText={shouldHideTitle ? '' : labelText}
      link={link}
      mrcId={mrcId}
      name={name}
      shortName={shortName}
      startAdornment={startAdornment}
      isIncomplete={isIncomplete}
      isPublic={isPublic}
      hideErrors={hideErrors}
    >
      {isReadOnly ? (
        <InputReadOnly
          {...rest}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={value}
          size={size}
        />
      ) : (
        <input
          autoFocus={autoFocus}
          className={inputClasses({
            errors: isErrorVisible,
            size,
            className: inputClassName,
            isIncomplete,
          })}
          disabled={isDisabled}
          id={id ?? name}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          name={name}
          onChange={onChange}
          onInput={onInput}
          pattern={pattern}
          placeholder={placeholder}
          ref={ref}
          required={isRequired}
          step={step}
          type={type}
          value={value || ''}
          {...rest}
        />
      )}
    </InputField>
  );
});

export default InputText;
