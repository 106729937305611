import type { FormError } from '@root/@types/types';

export const formatSectionName = (section: string) => `/${section.replace(/\./g, '/')}`;

export const getSectionsErrors = (section: string, errors?: FormError[] | null): FormError[] => {
  if (!errors) {
    return [];
  }

  const formattedSection = formatSectionName(section);
  return errors.filter((error) => error.instancePath.startsWith(formattedSection));
};
