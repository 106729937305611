import type { FC } from 'react';
import { useMemo } from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import InputSelect from '@components/InputSelect';
import { Show } from '@components/Show';
import { useSchemaStore } from '@ContractBuilder/store';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { mdiTrashCanOutline } from '@mdi/js';
import type { BlockVisibilityCondition, BlockVisibilityValueType, UIOnChangeFn } from '@root/@types/types';
import clsx from 'clsx';
import { get } from 'lodash-es';

import { MATCH_OPTIONS } from '../constants';
import { useBlockVisibilityForm } from '../controllers/context';
import type { VisibilityConfigPath } from '../types';

import { BlockVisibilityFieldSelector } from './BlockVisibilityFieldSelector';
import { ValueComponent } from './BlockVisibilityValue';
import { ComparatorTooltipText } from './ComparatorTooltipText';
import { RowPrefix } from './RowPrefix';

const OPERATOR_OPTIONS = [
  { name: 'IS', value: 'IS' },
  { name: 'IS NOT', value: 'IS NOT' },
  { name: 'IS EMPTY', value: 'IS EMPTY' },
  { name: 'IS NOT EMPTY', value: 'IS NOT EMPTY' },
];

interface BlockVisibilityRowProps {
  condition: BlockVisibilityCondition;
  idx: number;
  onChange: (value: BlockVisibilityValueType | undefined, name: VisibilityConfigPath) => void;
  onDelete: () => void;
}

export const BlockVisibilityRow: FC<BlockVisibilityRowProps> = ({ onChange, onDelete, condition, idx }) => {
  const { findField } = useSchemaStore(({ findField }) => ({ findField }));
  const { isDisabled, isReadOnly, basePath, formValues } = useBlockVisibilityForm();
  const values = useDeepCompareMemo(() => get(formValues, basePath), [formValues, basePath]);
  const sharedProps = { isDisabled, isReadOnly };
  const hideValue = ['IS EMPTY', 'IS NOT EMPTY'].includes(condition.operator);

  const parentAccessor = basePath + `.conditions.${idx}`;
  const fieldAccessor = parentAccessor + '.field';
  const matchAccessor = basePath + '.match';
  const operatorAccessor = parentAccessor + '.operator';

  const selectedField = useMemo(() => findField(condition.field), [condition.field, findField]);

  const prefix = values?.match ?? '-';
  const isFirst = idx === 0;
  const hasMatchSelector = idx === 1;
  const hasPrefix = !hasMatchSelector && !isFirst;

  return (
    <div id={parentAccessor}>
      <div
        className={clsx(
          'grid w-full place-items-center gap-3',
          hideValue ? 'grid-cols-[80px_360px_180px_30px]' : 'grid-cols-[80px_360px_180px_200px_30px]',
        )}
      >
        {isFirst && <RowPrefix className="mt-8 justify-self-start">WHEN</RowPrefix>}
        {hasMatchSelector && (
          <InputSelect
            isSearchable={false}
            options={MATCH_OPTIONS}
            onChange={onChange as UIOnChangeFn}
            value={values?.match}
            id={matchAccessor}
            name={matchAccessor}
            className="w-fit justify-self-start"
            {...sharedProps}
          />
        )}
        {hasPrefix && <RowPrefix className="justify-self-start">{prefix}</RowPrefix>}
        <BlockVisibilityFieldSelector
          label={isFirst ? 'Field' : undefined}
          onChange={onChange}
          value={selectedField}
          name={fieldAccessor}
          {...sharedProps}
          idx={idx}
        />
        <InputSelect
          labelText={isFirst ? 'Comparator' : undefined}
          helperText={isFirst ? <ComparatorTooltipText /> : undefined}
          data-testid="Comparator"
          value={condition.operator}
          options={OPERATOR_OPTIONS}
          isSearchable={false}
          {...sharedProps}
          onChange={onChange as UIOnChangeFn}
          name={operatorAccessor}
        />
        <Show when={!hideValue}>
          <div className={clsx('shrink-0', 'w-full', 'min-w-fit')}>
            <ValueComponent
              label={isFirst ? 'Value' : undefined}
              fieldOption={selectedField}
              isReadOnly={isReadOnly}
              isDisabled={isDisabled}
              onChange={onChange}
              value={condition.value}
              parentPath={parentAccessor}
            />
          </div>
        </Show>

        {isReadOnly || isDisabled ? null : (
          <Button className={clsx('w-fit border-none !p-2 shadow-none', isFirst && 'mt-7')} onClick={onDelete}>
            <IconMdi path={mdiTrashCanOutline} />
          </Button>
        )}
      </div>
    </div>
  );
};
