export const InsertIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.85312 0.599609C1.52764 0.599609 0.453125 1.67413 0.453125 2.99961V5.39961C0.453125 6.72509 1.52764 7.79961 2.85312 7.79961H5.25312C6.57861 7.79961 7.65312 6.72509 7.65312 5.39961V2.99961C7.65312 1.67413 6.57861 0.599609 5.25312 0.599609H2.85312Z"
        fill="#1F2937"
      />
      <path
        d="M2.85312 10.1996C1.52764 10.1996 0.453125 11.2741 0.453125 12.5996V14.9996C0.453125 16.3251 1.52764 17.3996 2.85312 17.3996H5.25312C6.57861 17.3996 7.65312 16.3251 7.65312 14.9996V12.5996C7.65312 11.2741 6.57861 10.1996 5.25312 10.1996H2.85312Z"
        fill="#1F2937"
      />
      <path
        d="M10.0531 2.99961C10.0531 1.67413 11.1276 0.599609 12.4531 0.599609H14.8531C16.1786 0.599609 17.2531 1.67413 17.2531 2.99961V5.39961C17.2531 6.72509 16.1786 7.79961 14.8531 7.79961H12.4531C11.1276 7.79961 10.0531 6.72509 10.0531 5.39961V2.99961Z"
        fill="#1F2937"
      />
      <path
        d="M13.6531 10.1996C14.3159 10.1996 14.8531 10.7369 14.8531 11.3996V12.5996H16.0531C16.7159 12.5996 17.2531 13.1369 17.2531 13.7996C17.2531 14.4623 16.7159 14.9996 16.0531 14.9996H14.8531V16.1996C14.8531 16.8623 14.3159 17.3996 13.6531 17.3996C12.9904 17.3996 12.4531 16.8623 12.4531 16.1996V14.9996H11.2531C10.5904 14.9996 10.0531 14.4623 10.0531 13.7996C10.0531 13.1369 10.5904 12.5996 11.2531 12.5996H12.4531V11.3996C12.4531 10.7369 12.9904 10.1996 13.6531 10.1996Z"
        fill="#1F2937"
      />
    </svg>
  );
};
