import type { FC } from 'react';
import { Show } from '@components/Show';
import { useContractFormPreventableLink } from '@ContractBuilder/hooks/useContractFormPreventableLink';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { useDocumentLocation } from '@hooks/useDocumentLocation';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import sharedClasses from '@root/src/utils/shared-classes';
import { cva } from 'class-variance-authority';

import { UnsavedChangesModal } from '../../modules/block-edit/views/UnsavedChangesModal';
import { ViewPath } from '../constants';

const containerClasses = cva(
  ['flex', 'w-fit', 'overflow-hidden', 'rounded-md', 'text-center', 'text-sm', 'font-medium', 'transition'],
  {
    variants: {
      disabled: {
        true: [...sharedClasses.disabledContainer, '!border-opacity-50'],
      },
      isTemplate: {
        false: 'border border-primary-600',
      },
    },
  },
);
const buttonClasses = cva(
  [
    'border-r-primary-600 ',
    'border-r',
    'cursor-pointer',
    'hover:bg-info-200',
    'last:border-0',
    'px-4',
    'py-1',
    'text-primary-600',
    'whitespace-nowrap',
  ],
  {
    variants: {
      selected: {
        true: ['text-white bg-primary-600 hover:bg-primary-600 active'],
      },
      disabled: {
        true: sharedClasses.disabled,
        false: 'pointer-events-auto',
      },
    },
    defaultVariants: {
      selected: false,
      disabled: true,
    },
  },
);

export const ViewSelectionTabs: FC = () => {
  const isEditing = useBlockEditFormStore((state) => state.formValues?.id !== undefined);
  const { isEndorsementView, setEndorsementView } = useUIStore(({ isEndorsementView, setEndorsementView }) => ({
    isEndorsementView,
    setEndorsementView,
  }));

  const { isContractView, isEndorsement, isFormView, isTemplate } = useDocumentLocation();
  const hasEndorsementsFeature = useFeatureFlags()('Endorsements');

  const { isBlocked, onDisregardChanges, onLinkClick, onReset, onSaveChanges } = useContractFormPreventableLink();

  const handleChangesOnlyClick = () => {
    setEndorsementView((value) => !value);
  };

  const handleLinkClick = (to: string) => {
    const isNextPathForm = to === ViewPath.Form;
    const isNextPathContract = to === ViewPath.Contract;

    if ((isNextPathForm || isNextPathContract) && isEndorsementView) {
      setEndorsementView(false);
    }

    return onLinkClick(to);
  };

  return (
    <>
      <div
        className={containerClasses({
          disabled: isEditing,
          isTemplate,
        })}
        id="contract-actions"
      >
        {isTemplate ? (
          <div>&nbsp;</div>
        ) : (
          <>
            <a
              onClick={() => handleLinkClick(ViewPath.Form)}
              className={buttonClasses({ selected: isFormView, disabled: isEditing })}
            >
              Form View
            </a>
            <a
              onClick={() => handleLinkClick(ViewPath.Contract)}
              className={buttonClasses({ selected: isContractView && !isEndorsementView, disabled: isEditing })}
            >
              Contract View
            </a>
            <Show when={isEndorsement && hasEndorsementsFeature}>
              <a
                onClick={handleChangesOnlyClick}
                className={buttonClasses({ selected: isEndorsementView, disabled: isEditing })}
              >
                Endorsement View
              </a>
            </Show>
          </>
        )}
      </div>
      <UnsavedChangesModal
        isOpen={isBlocked}
        onCancel={onReset}
        onDisregardChanges={onDisregardChanges}
        onSaveChanges={onSaveChanges}
      />
    </>
  );
};
