import type { BlockEditFormState } from '../types';

interface HandleDatapointsUpdateArgs {
  values: BlockEditFormState;
  id: string;
  isRequired: boolean;
  index?: number;
}

export const handleDatapointsUpdate = ({ values, id, isRequired, index: variationIdx }: HandleDatapointsUpdateArgs) => {
  const hasVariations = !!values.variations?.length && typeof variationIdx === 'number';

  if (hasVariations) {
    const updatedVariations = values.variations?.map((variation, idx) => {
      if (variationIdx === idx) {
        return {
          ...variation,
          linkedDatapoints: variation?.linkedDatapoints?.map((item) =>
            item.id === id ? { ...item, isRequired } : item,
          ),
        };
      }
      return variation;
    });

    return { ...values, variations: updatedVariations };
  } else {
    const updatedLinkedDatapoints = values?.linkedDatapoints?.map((item) =>
      item.id === id ? { ...item, isRequired } : item,
    );

    return { ...values, linkedDatapoints: updatedLinkedDatapoints };
  }
};
