import { useEffect } from 'react';

interface UsePrefetchPDFsArgs {
  documentPullUpdatedAt?: number;
  documentUrl?: string;
  extractionFileUrl?: string;
}

/** Pre-fetch PDF files to avoid pre-signed URL expiring if a user has spent more than 5 minutes before first switching the other file tab (thus initially loading it after the link has already expired) **/
export const usePrefetchPDFs = (params: UsePrefetchPDFsArgs) => {
  const { documentPullUpdatedAt, documentUrl, extractionFileUrl } = params;

  useEffect(() => {
    const documentPullDiff = documentPullUpdatedAt ? new Date().getTime() - documentPullUpdatedAt : undefined;
    if (documentUrl && documentPullDiff && documentPullDiff > 4 * 60 * 1000) {
      fetch(documentUrl).then((res) => res.blob());
    }
  }, [documentUrl, documentPullUpdatedAt]);

  useEffect(() => {
    if (extractionFileUrl) {
      fetch(extractionFileUrl).then((res) => res.blob());
    }
  }, [extractionFileUrl]);

  return null;
};
