export const layoutSelectorClasses = {
  optionsContainer: [
    'origin-top-right',
    'absolute',
    'z-20',
    'mt-2',
    'w-full',
    'rounded-md',
    'shadow-lg',
    'overflow-hidden',
    'bg-white',
    'divide-y',
    'divide-info-200',
    'ring-1',
    'ring-black',
    'ring-opacity-5',
    'focus:outline-none',
  ],
  button: [
    'border-info-200',
    'relative',
    'inline-flex',
    'items-center',
    'border',
    'p-2',
    'py-1',
    'rounded-md',
    'text-sm',
    'focus:outline-none',
    'focus:z-10',
    'focus:ring-2',
    'focus:ring-offset-2',
    'focus:ring-offset-info-50',
    'focus:ring-primary-500',
    'flex',
    'justify-between',
    'w-full',
    'bg-white',
  ],
};
