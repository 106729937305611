import React, { useMemo } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import clsx from 'clsx';

import type { UIOnChangeFn } from '../../../@types/types';
import InputCheckbox from '../InputCheckbox';

type Value = string | string[];

interface InputCheckboxGroupProps extends InputFieldProps {
  isReadOnly: boolean;
  onChange: UIOnChangeFn<Value>;
  value: Value;
  item: { enum: string };
  ownKey: string;
}

const InputCheckboxSchema: React.FC<InputCheckboxGroupProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  item,
  labelText,
  mrcId,
  name = 'checkbox-schema',
  onChange,
  ownKey,
}) => {
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);
  const option = item?.enum?.[0];

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id ?? name}
      isRequired={isRequired}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
    >
      <InputCheckbox
        className={clsx('my-6', isIncomplete && 'field-incomplete')}
        errors={errors}
        id={[name, option].join('.')}
        isChecked={(value || []).includes(option)}
        isRequired={isRequired}
        key={option}
        labelText={option}
        name={name}
        onChange={(e) => {
          const defaultValue = Array.isArray(value) ? value : [];
          const nextValue = defaultValue.includes(e.target.value)
            ? defaultValue.filter((i) => i !== e.target.value)
            : [...defaultValue, e.target.value];

          return onChange(nextValue, name);
        }}
        value={option}
        isDisabled={isReadOnly}
        readOnly={isReadOnly}
      />
    </InputField>
  );
};

export default InputCheckboxSchema;
