import type { FC, ReactElement } from 'react';
import { Fragment } from 'react';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

interface AttachmentModalProps {
  children: ReactElement;
  handleClose: () => void;
}

export const AttachmentModal: FC<AttachmentModalProps> = ({ children, handleClose }) => (
  <Transition.Root show={true} as={Fragment}>
    <Dialog
      as="div"
      className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
      data-cypress="section-modal"
      onClose={handleClose}
    >
      <div className="flex min-h-screen min-w-96 items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative inline-block transform rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
            <div className="absolute right-0 top-0 hidden pr-4 pt-7 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={handleClose}
              >
                <span className="sr-only">Cancel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="sm:flex sm:items-start sm:justify-center">
              <div className="mt-3 w-full text-left sm:mt-0">{children}</div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);
