import type { FC, PropsWithChildren } from 'react';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';

import { componentsMap } from '../constants/components-map';
import type { DrawerComponentProps } from '../types';
import { DrawerLayout } from '../views/DrawerLayout';

interface DrawerLayoutControllerProps {
  className?: string;
}

export const DrawerLayoutController: FC<PropsWithChildren<DrawerLayoutControllerProps>> = ({ children, className }) => {
  const { isOpen, state } = useDrawerStore(({ isOpen, state }) => ({ isOpen, state }));

  const Component = state && componentsMap[state.type];
  const drawerContent =
    Component && state ? <Component {...(state.props as DrawerComponentProps<any>)} key={state.type} /> : null;

  return (
    <DrawerLayout className={className} drawerContent={drawerContent} isOpen={isOpen}>
      {children}
    </DrawerLayout>
  );
};
