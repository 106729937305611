import type { FC, ReactNode } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';

import type { SelectedMode } from '../types';

import { fileNameTabContainerClasses } from './classes';

interface FileNameTabProps {
  className?: string;
  content: ReactNode;
  isActive: boolean;
  mode: SelectedMode;
  onClick: (mode: SelectedMode) => void;
}

export const FileNameTab: FC<FileNameTabProps> = ({ className, content, isActive, mode, onClick }) => {
  return (
    <button
      className={fileNameTabContainerClasses({
        isActive,
        className,
      })}
      onClick={() => onClick(mode)}
    >
      <ShowFirstMatching fallback={content}>
        <Show when={typeof content === 'string'}>
          <p className="truncate text-xs font-normal leading-5">{content}</p>
        </Show>
      </ShowFirstMatching>
    </button>
  );
};
