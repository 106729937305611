import { get } from 'lodash-es';

import type { GenericObject } from '../../@types/types';

import commaSeparate from './commaSeparate';

const pickCommaSeparate = (obj: GenericObject<string>, arr: string[]): string =>
  commaSeparate(arr.map((item) => get(obj, item)));

export default pickCommaSeparate;
