import { type FC, useMemo } from 'react';
import { InputToggleGroup, type InputToggleGroupProps } from '@components/InputToggleGroup';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

interface InputToggleGroupSchemaProps extends InputToggleGroupProps {
  ownKey: string;
}

const InputToggleGroupSchema: FC<InputToggleGroupSchemaProps> = (props) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);

  const { value, isIncomplete } = useDatapointsStore(selector);

  return <InputToggleGroup {...props} value={value} isIncomplete={isIncomplete} />;
};

export default InputToggleGroupSchema;
