import type { FC } from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { SortedBlockItem } from '@ContractBuilder/modules/navigation/modules/nav-section/views/SortedBlockItem';
import type { ResourceBlock } from '@root/@types/base';
import clsx from 'clsx';

interface NavSectionBlocksProps {
  blocks: ResourceBlock[];
  isDragDisabled: boolean;
  onClick: (blockId: string) => void;
  onMouseEnter: (blockId: string) => void;
  onMouseLeave: () => void;
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
}

export const NavSectionBlocks: FC<NavSectionBlocksProps> = ({
  blocks,
  isDragDisabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  provided,
  snapshot,
}) => {
  return (
    <ol className={clsx(snapshot.isDraggingOver && 'bg-info-100', 'ml-3 max-w-xs truncate')}>
      {blocks?.map((block, index) => (
        <Draggable key={block.id} draggableId={block.id} index={index} isDragDisabled={isDragDisabled}>
          {(provided) => (
            <li
              id={`${block.id}-draggable`}
              data-testid={`${block.name}-draggable-block-parent`}
              key={block.id}
              className="group group w-full cursor-pointer rounded p-1 py-1 pl-4 text-sm hover:bg-info-50"
              onClick={onClick.bind(null, block.id)}
              onMouseEnter={onMouseEnter.bind(null, block.id)}
              onMouseLeave={onMouseLeave}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <SortedBlockItem block={block} dragHandleProps={provided.dragHandleProps} />
            </li>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
    </ol>
  );
};
