import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { UpdateBlockInLibraryPayload } from '@root/@types/types';
import axios from 'axios';

export const createBlock = async (data: UpdateBlockInLibraryPayload): Promise<{ id: string }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: `blocks-spa-al`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
