import type { FC } from 'react';
import { memo, useEffect } from 'react';
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useMenu } from '@components/menu';
import BlockMenu from '@ContractBuilder/components/BlockMenu';
import { SwitchVerticalIcon as DragAndDropIcon } from '@heroicons/react/outline';
import type { ResourceBlock } from '@root/@types/base';
import { scrollIntoView } from '@root/src/utils/scroll-into-view';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';

import { useBlockEditFormStore } from '../../../../block-edit';
import { navBlockIconClasses } from '../classes';

const visibleOnHoverClasses = ['group-hover:opacity-100', 'opacity-0'];

const blockTextClasses = cva(['truncate', 'group-hover:font-bold', 'font-normal'], {
  variants: {
    bold: {
      true: 'font-bold',
      false: '',
    },
  },
  defaultVariants: {
    bold: false,
  },
});

interface SortedBlockItemProps {
  block: ResourceBlock;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const SortedBlockItemInner: FC<SortedBlockItemProps> = ({ block, dragHandleProps }) => {
  const { isOpen: isMenuOpen, onClose, onOpen } = useMenu();
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);

  const isEditing = editingBlockId === block.id;
  const scrollableId = `${block.id}-navigation`;

  useEffect(() => {
    if (editingBlockId === block.id) {
      scrollIntoView(scrollableId, { inline: 'nearest' });
    }
    // eslint-disable-next-line -- We only want to react to `editingBlockId` changing
  }, [editingBlockId]);

  return (
    <div className="flex w-full flex-nowrap place-items-center justify-between" id={scrollableId}>
      <div className="flex w-full flex-nowrap place-items-center gap-2 truncate">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(navBlockIconClasses)}
        >
          <rect width="16" height="16" rx="4" fill={isEditing ? '#2563EB' : '#6B7280'} />
          <path
            d="M7.99935 4.5H12.666M7.99935 8H12.666M3.04102 4.5H5.37435M7.99935 11.5H12.666"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className={blockTextClasses({ bold: isMenuOpen || isEditing })}>{block.name}</p>
      </div>
      <div
        data-testid={`${block.name}-draggable-block-trigger`}
        className={clsx(
          visibleOnHoverClasses,
          'cursor-pointer pl-1 group-hover:text-info-800',
          isMenuOpen ? 'text-info-800 opacity-100' : 'text-info-400',
        )}
      >
        {dragHandleProps && (
          <div className="relative h-6 w-6" {...dragHandleProps}>
            <DragAndDropIcon className="h-6 w-6" />
          </div>
        )}
      </div>
      <BlockMenu
        block={block}
        className={clsx(
          visibleOnHoverClasses,
          'cursor-pointer group-hover:text-info-800',
          isMenuOpen ? 'text-info-800 opacity-100' : 'text-info-400',
        )}
        isOpen={isMenuOpen}
        onClose={onClose}
        onOpen={onOpen}
        section_id={block.section_id}
      />
    </div>
  );
};

export const SortedBlockItem = memo(SortedBlockItemInner, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
