import { useCallback, useEffect, useRef } from 'react';
import type { MarkerSdk } from '@marker.io/browser';
import markerSDK from '@marker.io/browser';

const MarkerIOPlugin = () => {
  const widget = useRef<MarkerSdk>();
  const key: string | undefined = import.meta.env.VITE_MARKER_IO_API_KEY;

  const startWidget = useCallback(async (destination: string) => {
    widget.current = await markerSDK.loadWidget({ destination });
  }, []);

  useEffect(() => {
    if (key) {
      startWidget(import.meta.env.VITE_MARKER_IO_API_KEY);
    }

    return () => {
      widget.current?.unload?.();
    };
  }, [key, startWidget, widget]);

  return null;
};

export default MarkerIOPlugin;
