import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { TransformedCognitoUser } from '@root/@types/cognito';
import axios from 'axios';

export const fetchUsers = async (): Promise<TransformedCognitoUser[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/users-spa-al',
  });

  const response = await axios(networkPayload);
  return response.data;
};
