import { memo } from 'react';
import { Dialog } from '@headlessui/react';
import { useLanguage } from '@root/src/language';

import { AttachmentUploadController } from '../controller/AttachmentUploadController';

import { AttachmentModal } from './AttachmentModal';

export const AttachmentUploadModal = memo(function AttachmentUploadModal({ handleClose }: { handleClose: () => void }) {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.attachment' });

  return (
    <AttachmentModal handleClose={handleClose}>
      <>
        <Dialog.Title as="h3" className="mb-2 !text-xl leading-6 !text-info-900">
          {getContent(`title.create`)}
        </Dialog.Title>
        <AttachmentUploadController handleClose={handleClose} />
      </>
    </AttachmentModal>
  );
});
