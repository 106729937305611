import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { BlockLibraryCreatorListController } from '@ContractBuilder/modules/block-library-creator-list';
import { useEntityStore } from '@ContractBuilder/store';
import type { DynamoDBLibraryBlock } from '@root/@types/database';

import { useBlockCreator } from '../../context';
import { StepView } from '../StepView';

export const StepLibrary = () => {
  const { onPrevStep, handleCancel } = useBlockCreator();
  const { formValues } = useBlockEditFormStore(({ formValues }) => ({ formValues }));
  const { addBlockFromLibrary } = useEntityStore(({ addBlockFromLibrary }) => ({ addBlockFromLibrary }));

  const onInsertBlock = async (blockLibraryId: string, block: DynamoDBLibraryBlock | null) => {
    await addBlockFromLibrary({
      canDelete: block?.canDelete,
      canEdit: block?.canEdit,
      blockLibraryId,
      sectionId: formValues?.section_id ?? '',
      order: (formValues?.order ?? 0) + 1,
    });

    handleCancel();
  };

  return (
    <StepView stepId="library" secondHeaderTitle="Search Library" onPrev={onPrevStep}>
      <BlockLibraryCreatorListController onInsertBlock={onInsertBlock} />
    </StepView>
  );
};
