import Section from '@ContractBuilder/components/SectionView/Section/Section';
import { useEntityStore } from '@ContractBuilder/store';
import { isEndorsementData } from '@ContractBuilder/types';
import { getDocumentContext } from '@ContractBuilder/utils';
import clsx from 'clsx';
import { get } from 'lodash-es';

export const EndorsementSummaryController = () => {
  const context = getDocumentContext();
  const { endorsementParent, submission } = useEntityStore(({ endorsementParent, submission }) => ({
    endorsementParent,
    submission,
  }));

  if (!isEndorsementData(submission)) {
    return null;
  }

  if (!submission?.summary) {
    return null;
  }

  const endorsementSummarySections = get(submission, 'summary.sections', []);

  return endorsementSummarySections.map((section) => {
    const isSummarySection = section.sk.startsWith('summary_sections#');
    return (
      <div className={clsx('mb-8 mt-20 bg-white shadow-lg ')} key={section.id}>
        <Section
          context={context}
          disableControls={!isSummarySection}
          endorsementParent={endorsementParent}
          key={`${section.id}-${section.blocks.length}`}
          section={section}
          shouldShowCreator={isSummarySection}
          shouldShowDiffBlocks
          submission={submission}
        />
      </div>
    );
  });
};
