// @ts-expect-error: We need to wait until [this](https://github.com/ueberdosis/tiptap/issues/3488) is resolved.
import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SectionRepeaterInner } from './SectionRepeaterInner';

export interface SectionRepeaterChildOptions {
  HTMLAttributes: Record<string, any>;
}

export const SectionRepeaterChild = Node.create<SectionRepeaterChildOptions>({
  name: 'SectionRepeaterChild',
  content: 'block*',
  allowGapCursor: false,
  selectable: false,
  group: 'block',

  addAttributes() {
    return {
      parentId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-parentid'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-parentid': attributes.parentId,
          };
        },
      },
      order: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-order'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-order': attributes.order,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div`,
        getAttrs: (node: HTMLElement) => node.getAttribute('data-type') === 'section-repeater-child' && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['div', mergeAttributes({ 'data-type': 'section-repeater-child' }, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SectionRepeaterInner);
  },
});
