export const OrderedListDecimal = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 16H9.5V16.5H8.5V17.5H9.5V18H7.5V19H10.5V15H7.5V16ZM8.5 9H9.5V5H7.5V6H8.5V9ZM7.5 11H9.3L7.5 13.1V14H10.5V13H8.7L10.5 10.9V10H7.5V11ZM12 6V8H21.5V6H12ZM12 18H21.5V16H12V18ZM12 13H21.5V11H12V13Z"
      fill="#374151"
    />
    <path d="M4.5 9H3.5V6H2.5V5H4.5V9Z" fill="#374151" />
    <path d="M4.5 14H3.5V11H2.5V10H4.5V14Z" fill="#374151" />
    <path d="M4.5 19H3.5V16H2.5V15H4.5V19Z" fill="#374151" />
    <path d="M5.5 8H6.5V9H5.5V8Z" fill="#374151" />
    <path d="M5.5 13H6.5V14H5.5V13Z" fill="#374151" />
    <path d="M5.5 18H6.5V19H5.5V18Z" fill="#374151" />
  </svg>
);
