import { cva } from 'class-variance-authority';
import clsx from 'clsx';

export const responsiveTruncateClasses = (classes?: string[] | string) => clsx(['truncate', 'max-w-[20vw]', classes]);

export const documentTitleClasses = cva(
  ['!leading-tight ', '!px-0', 'border-transparent', 'border', 'gap-1', 'py-0.5', 'rounded', 'truncate'],
  {
    variants: {
      isEndorsement: {
        true: [],
        false: [],
      },
      isTemplate: {
        true: ['!max-w-[50vw]'],
        false: [],
      },
      isDisabled: {
        true: [],
        false: ['hover:border-gray-300'],
      },
      isPlaceholder: {
        true: ['text-gray-400'],
        false: [],
      },
    },
  },
);
