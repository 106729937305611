import TableRowExtenstion from '@tiptap/extension-table-row';
//@ts-expect-error
import type { CommandProps } from '@tiptap/react';

import { findFirstNodeAbove } from './helpers';

export const TableRow = () =>
  TableRowExtenstion.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        monofont: {
          default: null,
          parseHTML: (element: any) => {
            return element.getAttribute('monofont');
          },
        },
        locked: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('locked') === 'true'),
        },
        'data-hidden': {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('data-hidden') === 'true'),
        },
      };
    },
    addCommands() {
      return {
        ...(this.parent?.() || {}),
        toggleLock: () => (args: CommandProps) => {
          const found = findFirstNodeAbove('table', args.state.selection);
          if (!found) return;
          const { node, before } = found;
          // @ts-expect-error
          const tableRow = node.content.content[0];
          args.tr.setNodeMarkup(before + 1, tableRow.type, {
            ...tableRow.attrs,
            locked: !tableRow.attrs.locked,
          });
        },
      };
    },
  });
