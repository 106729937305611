import { type ChangeEvent, type FC } from 'react';
import InputText from '@components/InputText';
import { useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { resolveFieldRefs } from '@helpers/resolveSchema';
import type { JSONSchema, JSONSchemaProperties } from '@root/@types/schema';
import type { BlockVisibilityValueType } from '@root/@types/types';
import type { SchemaTreeToFlatListResult } from '@root/helpers/schema/schemaTreeToFlatList';
import { MakeComponent } from '@src/mapChildren';
import clsx from 'clsx';
import { cloneDeep, omit } from 'lodash-es';

import type { VisibilityConfigPath } from '../types';

interface ValueComponentProps {
  fieldOption?: SchemaTreeToFlatListResult | null;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onChange: (value: BlockVisibilityValueType | undefined, name: VisibilityConfigPath) => void;
  parentPath: string;
  value: any;
  label?: string;
}

const classes = clsx(['!py-0']);

export const ValueComponent: FC<ValueComponentProps> = ({
  fieldOption,
  isDisabled,
  isReadOnly,
  onChange,
  parentPath,
  value,
  label = '',
}) => {
  const { schema } = useSchemaStore(({ schema }) => ({ schema }));
  const datapointIsInitialised = useDatapointsStore(({ formValues }) => formValues)?.visibility;

  const valuePath = (parentPath + '.value') as VisibilityConfigPath;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    return onChange(event.target.value, valuePath);
  };

  const handleDatapointInputChange = (...args: any[]) => {
    if (args.length === 1) {
      const [event] = args;
      onChange(event.target.value, event.target.name);
    }

    if (args.length === 2) {
      const [value, name] = args;
      onChange(value, name);
    }
  };

  if (!fieldOption) {
    return (
      <div>
        <InputText
          labelText={label}
          className={classes}
          onChange={handleInputChange}
          value={value as string}
          id={valuePath}
          name={valuePath}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
      </div>
    );
  }

  const clonedSchema = cloneDeep(schema) as JSONSchema;
  const resolvedField = omit(resolveFieldRefs(clonedSchema, fieldOption), [
    'cdrId',
    'schemaId',
    'title',
    'value',
  ]) as JSONSchemaProperties;

  return (
    !!datapointIsInitialised && (
      <div>
        <MakeComponent
          item={
            {
              ...resolvedField,
              title: label,
              key: 'value',
              'ui:className': classes,
              'ui:limitValueLengthPx': 140,
              showAll: resolvedField['ui:component'] === 'InputCountrySubDivision',
            } as JSONSchemaProperties & { key: string }
          }
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={handleDatapointInputChange}
          parentKey={parentPath}
          parentSchema={clonedSchema}
          validationErrors={null}
        />
      </div>
    )
  );
};
