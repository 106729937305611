import React, { useEffect, useState } from 'react';
import { Show } from '@components/Show';
import clsx from 'clsx';

interface GlobalSearchProps {
  entitiesLabel: string;
  onSearchClick: (searchPhrase: string) => void;
  initialSearchPhrase?: string;
  isDebouncedSearch?: boolean;
}

const DEBOUNCE = 500;

export const GlobalSearch = ({
  entitiesLabel,
  onSearchClick,
  initialSearchPhrase = '',
  isDebouncedSearch = true,
}: GlobalSearchProps) => {
  const [searchPhrase, setSearchPhrase] = useState(initialSearchPhrase);
  const handleSetSearchPhrase = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };
  const handleSearchClick = () => {
    onSearchClick(searchPhrase);
  };
  const handleClearSearch = () => {
    setSearchPhrase('');
    onSearchClick('');
  };

  useEffect(() => {
    if (!isDebouncedSearch) {
      return;
    }
    const timeout = setTimeout(() => {
      handleSearchClick();
    }, DEBOUNCE);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line -- Listen only to searchPhrase change
  }, [searchPhrase]);

  return (
    <div className="flex h-full rounded-md shadow-sm" data-testid="global-search">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.29583 11.5892 8.49572 10.8907 9.47653L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L9.47653 10.8907C8.49572 11.5892 7.29583 12 6 12C2.68629 12 0 9.31371 0 6Z"
              fill="#9CA3AF"
            />
          </svg>
        </div>
        <input
          type="text"
          name="global-search-input"
          id="global-search-input"
          className={clsx(
            'block w-full border-0 py-[9px] pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6',
            isDebouncedSearch ? 'rounded-md' : 'rounded-none rounded-l-md',
          )}
          placeholder={`Search ${entitiesLabel}`}
          data-testid="global-search-input"
          value={searchPhrase}
          onChange={handleSetSearchPhrase}
        />
        <Show when={!!searchPhrase}>
          <button
            data-testid="clear-search-button"
            onClick={handleClearSearch}
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
          >
            <svg className="h-4 w-4 text-gray-400" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99961 13.4016C10.5342 13.4016 13.3996 10.5362 13.3996 7.00156C13.3996 3.46694 10.5342 0.601562 6.99961 0.601562C3.46499 0.601562 0.599609 3.46694 0.599609 7.00156C0.599609 10.5362 3.46499 13.4016 6.99961 13.4016ZM5.9653 4.83588C5.65288 4.52346 5.14634 4.52346 4.83392 4.83588C4.5215 5.1483 4.5215 5.65483 4.83392 5.96725L5.86824 7.00156L4.83392 8.03588C4.5215 8.3483 4.5215 8.85483 4.83392 9.16725C5.14634 9.47967 5.65288 9.47967 5.96529 9.16725L6.99961 8.13293L8.03392 9.16725C8.34634 9.47967 8.85288 9.47967 9.16529 9.16725C9.47771 8.85483 9.47771 8.3483 9.16529 8.03588L8.13098 7.00156L9.16529 5.96725C9.47771 5.65483 9.47771 5.1483 9.16529 4.83588C8.85288 4.52346 8.34634 4.52346 8.03392 4.83588L6.99961 5.87019L5.9653 4.83588Z"
                fill="#6B7280"
              />
            </svg>
          </button>
        </Show>
      </div>
      <Show when={!isDebouncedSearch}>
        <button
          disabled={!searchPhrase && isDebouncedSearch}
          type="button"
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-gray-50 p-2 px-6 py-2 text-sm font-medium leading-5 text-gray-700 ring-1 ring-inset ring-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
          onClick={handleSearchClick}
        >
          Search
        </button>
      </Show>
    </div>
  );
};
