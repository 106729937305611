import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import traverse from 'json-schema-traverse';
import { cloneDeep, unset } from 'lodash-es';

import schema from '../../helpers/schema/schema-cdr.json';

import { uiComponent } from './keywords';

export const prepareSchemaForValidation = (schema: any) => {
  traverse(schema, {
    cb: (currSchema) => {
      unset(currSchema, '$ref');
      if (currSchema?.isIf !== true && currSchema['ui:component'] === 'InputMultiSelect') {
        unset(currSchema, 'enum');
      }
    },
  });
  return schema.properties.SubmissionForm;
};

const ajv = new Ajv({
  strict: false,
  allErrors: true,
  verbose: true,
  coerceTypes: true,
  useDefaults: true,
});

ajv.addKeyword(uiComponent);
ajv.addKeyword({ keyword: 'cdrId', schemaType: 'string' });
ajv.addKeyword({ keyword: 'cdrName', schemaType: 'string' });
ajv.addKeyword({ keyword: 'displayTitleForYesAnswer', schemaType: 'string' });
ajv.addKeyword({ keyword: 'formatting', schemaType: 'string' });
ajv.addKeyword({ keyword: 'hasHeading', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'helperText', schemaType: 'string' });
ajv.addKeyword({ keyword: 'isIf', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'placeholderText', schemaType: 'string' });
ajv.addKeyword({ keyword: 'qid', schemaType: 'number' });
ajv.addKeyword({ keyword: 'section_id', schemaType: 'string' });
ajv.addKeyword({ keyword: 'shortName', schemaType: 'string' });
ajv.addKeyword({ keyword: 'thousandSeparator', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'ui:className', schemaType: 'string' });
ajv.addKeyword({ keyword: 'ui:collapsible', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'ui:columns', schemaType: 'number' });
ajv.addKeyword({ keyword: 'ui:hideTitle', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'ui:placeholder', schemaType: 'string' });
ajv.addKeyword({ keyword: 'ui:span', schemaType: ['string', 'number'] });
ajv.addKeyword({ keyword: 'ui:startCollapsed', schemaType: 'boolean' });
ajv.addKeyword({ keyword: 'ui:toggleDependsOn', schemaType: 'string' });
ajv.addKeyword({ keyword: 'unit', schemaType: 'string' });
ajv.addKeyword({ keyword: 'wsId', schemaType: 'string' });

addFormats(ajv);

export const compiledSchema = ajv.compile(prepareSchemaForValidation(cloneDeep(schema)));

export default ajv;
