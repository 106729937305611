import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';

import { useSchemaStore } from './schema.store';
import type { DataItems } from './types';

export const updateSchemaInForeignStore = (dataItems: DataItems) =>
  useSchemaStore.getState().updateSchemaStore(dataItems);

export const updateFormValuesInForeignStore = (dataItems: DataItems) =>
  useDatapointsStore.getState().setFormValues(dataItems);
