import { delay as wait } from '@root/helpers/promise';

import { debug } from './debug';

// eslint-disable-next-line no-undef
const defaultOptions: ScrollIntoViewOptions = { block: 'start', behavior: 'smooth' };

export const scrollIntoView = (
  id: string,
  // eslint-disable-next-line no-undef
  options: ScrollIntoViewOptions | undefined = undefined,
  delay: number = 0,
  onScrollFinished?: Function,
) => {
  if (!id) {
    debug(`id ${id} is undefined`, 'scrollIntoView');
    return;
  }

  wait(delay).then(() => {
    const element = document.getElementById(id);

    if (!element) {
      debug(`element with id: ${id} is undefined`, 'scrollIntoView');
      return;
    }

    if (onScrollFinished) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onScrollFinished();
            observer.disconnect();
          }
        },
        { threshold: 0.5 },
      );

      observer.observe(element);
    }

    element.scrollIntoView(options ? { ...defaultOptions, ...options } : defaultOptions);
  });
};
