import type { FC } from 'react';
import React, { useState } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import IconMdi from '@components/IconMdi';
import { InputField } from '@components/InputField';
import { Show, ShowFirstMatching } from '@components/Show';
import { Spinner } from '@components/Spinner';
import { TooltipedIcon } from '@components/TooltipedIcon';
import {
  uploadAttachmentPageContainerClasses,
  uploadAttachmentWrapperClasses,
} from '@ContractBuilder/modules/attachment/classes';
import { mdiCloseCircleOutline, mdiFileDocumentOutline } from '@mdi/js';
import { useLanguage } from '@src/language';
import clsx from 'clsx';

import type { StepProps } from '../../types';
import { MAX_FILE_SIZE } from '../../validation';

interface UploadState {
  error?: string;
  isFileDropped: boolean;
  isUploading: boolean;
}

interface UploadMrcDocumentStepProps extends StepProps {
  isCreating: boolean;
  value?: File;
}
const INITIAL_UPLOAD_STATE = {
  error: undefined,
  isFileDropped: false,
  isUploading: false,
};

export const UploadMrcDocumentStep: FC<UploadMrcDocumentStepProps> = ({
  control,
  isCreating,
  setValue: setFile,
  value,
}) => {
  const [attachment, setAttachment] = useState<File | undefined>(value);
  const [uploadState, setUploadState] = useState<UploadState>(INITIAL_UPLOAD_STATE);

  const handleFileUpload = async (files: File[]) => {
    setUploadState((prev) => ({ ...prev, error: undefined }));

    if (files.length < 1) {
      return;
    }

    setAttachment(files[0]);
    setUploadState((current) => ({ ...current, isFileDropped: true }));
    setFile('file', files[0]);
  };

  const handleFileRejected = (fileRejections: FileRejection[], _event: DropEvent) => {
    const latestFileError = fileRejections?.pop()?.errors?.pop();
    const code = latestFileError?.code;
    let message = latestFileError?.message;

    if (code === 'file-too-large') {
      message = 'Max file size is 10MB.';
    }

    if (code === 'file-invalid-type') {
      message = 'Only .pdf files are accepted.';
    }

    setUploadState((current) => ({ ...current, error: message }));
  };

  const { getContent } = useLanguage({ prefix: 'naming.mrc.modals.attachment' });
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: MAX_FILE_SIZE,
    maxFiles: 1,
    onDrop: handleFileUpload,
    multiple: false,
    onDropRejected: handleFileRejected,
  });

  const handleReset = () => {
    setFile('file', undefined);
    setAttachment(undefined);
    setUploadState(INITIAL_UPLOAD_STATE);
  };

  return (
    <div className="flex flex-col">
      <InputField errors={uploadState.error} labelText="Extract data from an existing MRC">
        <div className={clsx(uploadAttachmentPageContainerClasses)}>
          <Controller
            control={control}
            name="file"
            render={() => (
              <>
                <input
                  {...getInputProps()}
                  id="attachment-file-upload"
                  key={attachment?.name ?? 'input-file-placeholder'}
                  name="file"
                  type="file"
                  className="sr-only"
                />
                <ShowFirstMatching>
                  <Show when={isCreating}>
                    <div
                      className={clsx(
                        uploadAttachmentWrapperClasses,
                        'flex !flex-row items-center justify-center gap-2',
                      )}
                    >
                      <Spinner className="[&>path]:fill-primary-500" size="sm" />
                      <p className="text-sm font-medium leading-5 text-gray-600">Uploading your document...</p>
                    </div>
                  </Show>
                  <Show when={!isCreating}>
                    <Show when={!attachment}>
                      <div {...getRootProps()} className={clsx(uploadAttachmentWrapperClasses)}>
                        <p className="mt-2 text-sm font-normal leading-5 text-gray-700">
                          <span className="cursor-pointer text-[#2563EB]">Upload a file</span> or drag and drop
                        </p>
                        <p className="mt-2 text-[12px] font-normal leading-5 text-gray-400">{getContent('limit')}</p>
                      </div>
                    </Show>
                    <Show when={!!attachment}>
                      <div className={clsx(uploadAttachmentWrapperClasses, 'flex-col items-center')}>
                        <div className="mt-2 inline-flex max-w-full items-center text-sm font-normal leading-5 text-gray-700">
                          <IconMdi path={mdiFileDocumentOutline} />
                          <div className="ml-1 h-5 overflow-hidden text-ellipsis whitespace-nowrap">
                            {attachment?.name || 'document.pdf'}
                          </div>
                          <TooltipedIcon
                            placement="top"
                            path={mdiCloseCircleOutline}
                            className="ml-1.5 cursor-pointer text-info-500 transition-colors hover:text-info-400"
                            size={0.625}
                            content="Remove selected file"
                            onClick={handleReset}
                          />
                        </div>
                      </div>
                    </Show>
                  </Show>
                </ShowFirstMatching>
              </>
            )}
          />
        </div>
      </InputField>
      <span className="pt-2 text-xs font-normal leading-5 text-gray-400">
        The system will extract as much data as possible from the uploaded MRC document and pre-populate this new
        contract for you.
      </span>
    </div>
  );
};
