import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiCheckCircle, mdiNumeric1CircleOutline, mdiNumeric2CircleOutline } from '@mdi/js';
import clsx from 'clsx';

interface StepIndicatorProps {
  name: string;
  order: 1 | 2;
  completed?: boolean;
  onClick?: () => void;
}

export const StepIndicator: FC<StepIndicatorProps> = ({ name, order, completed, onClick }) => {
  const icons = {
    1: mdiNumeric1CircleOutline,
    2: mdiNumeric2CircleOutline,
  };

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex items-center gap-2 text-base font-normal text-primary-500',
        completed ? 'cursor-pointer' : 'cursor-default',
      )}
    >
      <IconMdi path={completed ? mdiCheckCircle : icons[order]} size={1.1} />
      <span>{name}</span>
    </div>
  );
};
