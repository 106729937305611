import type { FC, MouseEventHandler } from 'react';
import { useState } from 'react';
import { Spinner } from '@components/Spinner';
import Tooltip from '@components/Tooltip';
import { useEntityStore } from '@ContractBuilder/store';
import type { EntityData } from '@ContractBuilder/types';
import { CheckCircleIcon } from '@heroicons/react/outline';
import type { DynamoDBResourceComment } from '@root/@types/types';
import { eventPropagationTrap } from '@src/utils';
import clsx from 'clsx';

import { BlockItem } from '../../../components/BlockItem';

type CommentBlockSectionProps = {
  data: DynamoDBResourceComment;
  onAfterResolve?: (data?: EntityData) => void;
} & ({ showResolveButton: boolean; threadId: string } | { showResolveButton?: never; threadId?: never });

export const CommentSection: FC<CommentBlockSectionProps> = ({ data, onAfterResolve, showResolveButton, threadId }) => {
  const [isResolving, setIsResolving] = useState(false);
  const { content, created_at, id, user } = data;
  const { resolveConversation } = useEntityStore(({ resolveConversation }) => ({
    resolveConversation,
  }));

  const handleResolveConversation: MouseEventHandler = async (event) => {
    setIsResolving(true);
    eventPropagationTrap(event);

    if (threadId) {
      const response = await resolveConversation(threadId);
      onAfterResolve?.(response);
    }

    setIsResolving(false);
  };

  return (
    <div className={clsx('flex flex-col gap-y-4 border-b border-info-200 py-2.5 pl-3 pr-5')}>
      <BlockItem
        createdAt={created_at}
        icon={
          showResolveButton && (
            <Tooltip content="Resolve conversation">
              <div
                className="ml-auto flex cursor-pointer items-center justify-center rounded border border-info-200 bg-white p-1 transition hover:bg-info-200"
                onClick={handleResolveConversation}
              >
                {isResolving ? <Spinner className="h-4 w-4" size="xs" /> : <CheckCircleIcon className="h-4 w-4" />}
              </div>
            </Tooltip>
          )
        }
        key={id}
        user={user}
      >
        {content}
      </BlockItem>
    </div>
  );
};
