import { isFinite } from 'lodash-es';

import type { Address } from '../../@types/types';

type CurrencyFormatterArgs = Intl.NumberFormatOptions & {
  amount?: number;
  currency?: string;
};

const DEFAULT_CURRENCY = 'USD';

export const currencyFormatter = (args: CurrencyFormatterArgs = {}): string | false => {
  const { amount, currency = DEFAULT_CURRENCY, ...options } = args;

  if (!amount && amount !== 0) {
    return false;
  }

  const isInteger = Number.isInteger(Number(amount));
  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: currency || DEFAULT_CURRENCY,
    currencyDisplay: 'code',
    minimumFractionDigits: isInteger ? 0 : 2,
    maximumFractionDigits: 2,
    ...options,
  });
};

export const numberFormatter = (value: number | string): number | string => {
  const parsedAmount = Number.parseFloat(value.toString());

  if (!isFinite(parsedAmount)) {
    return value;
  }

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: 'USD',
  }).format(parsedAmount);
};

export const addressFormatter =
  (separator: string) =>
  (values: string[]): string =>
    values.filter(Boolean).join(separator);

export const formatNumber = (num: number, dec = 0): string => (isNaN(num) ? '' : num.toFixed(dec));

export const fullNameFormatter = (values: string[] | { firstName?: string; lastName?: string }): string => {
  const [firstName, lastName] = Array.isArray(values) ? values : [values?.firstName, values?.lastName];

  return `${firstName} ${lastName}`;
};

export const fullAddress = (values?: Address) => {
  const { address_line_1, address_line_2, state, zipcode } = values || {};
  return `${address_line_1 || ''} ${address_line_2 || ''} ${state || ''} ${zipcode || ''}`;
};
