import type { DropResult } from 'react-beautiful-dnd';
import { patchEntity } from '@mutations/patchEntity';
import { ApiPatchOperation } from '@root/@types/types';
import { getSortedEntries } from '@root/helpers/layout/get-sorted-entries';

import type { AttachmentsStoreSlice, SliceType } from './types';

export const createAttachmentsSlice: SliceType<AttachmentsStoreSlice> = (set, get) => ({
  attachments: [],
  reloadAttachments: () => {
    const submission = get().submission;

    set({
      attachments: getSortedEntries(submission?.attachments_layout ?? [], submission?.attachments ?? []),
    });
  },
  reorderAttachment: async (result: DropResult) => {
    if (
      result?.draggableId === undefined ||
      result?.source?.index === undefined ||
      result?.destination?.index === undefined
    ) {
      return;
    }

    await patchEntity({
      operation: ApiPatchOperation.ReorderResourceAttachment,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
      attachmentId: result.draggableId,
    });

    await patchEntity({
      operation: ApiPatchOperation.RecreatePdfWithAttachment,
    });

    await get().reloadSubmission();
  },
  recreatePdfWithAttachment: async () => {
    await patchEntity({
      operation: ApiPatchOperation.RecreatePdfWithAttachment,
    });

    await get().reloadSubmission();
  },
  renameAttachment: async (attachmentId: string, attachmentName: string) => {
    await patchEntity({
      operation: ApiPatchOperation.RenameResourceAttachment,
      attachmentId,
      attachmentName,
    });

    await get().reloadSubmission();
  },
  updateAttachmentStatus: async (attachmentId: string, attachmentStatus: 'pending' | 'ready') => {
    await patchEntity({
      operation: ApiPatchOperation.UpdateResourceAttachmentStatus,
      attachmentId,
      attachmentStatus,
    });
  },
  deleteAttachment: async (attachmentId: string) => {
    await patchEntity({
      operation: ApiPatchOperation.RemoveResourceAttachment,
      attachmentId,
    });

    await patchEntity({
      operation: ApiPatchOperation.RecreatePdfWithAttachment,
    });

    await get().reloadSubmission();
  },
});
