import type { FC } from 'react';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiPlusCircle } from '@mdi/js';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';

import { addIconClasses } from '../classes';

interface AddBlockIconProps {
  onCreate: () => void;
}

export const AddBlockIcon: FC<AddBlockIconProps> = ({ onCreate }) => (
  <div className={addIconClasses()} data-testid={getDataTestId('block.addNew')} onClick={onCreate} aria-hidden="true">
    <div className="flex-grow border-t border-primary-500"></div>
    <span className="flex-shrink text-primary-500">
      <TooltipedIcon path={mdiPlusCircle} size={1} content="Add block" placement="top" />
    </span>
    <div className="flex-grow border-t border-primary-500"></div>
  </div>
);
