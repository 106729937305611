import { cva } from 'class-variance-authority';

export const containerClasses = cva(
  ['rounded-full', 'flex', 'justify-center', 'items-center', 'font-semibold', 'text-xs', 'relative'],
  {
    variants: {
      state: {
        dirty: null,
        valid: null,
        idle: ['bg-info-500', 'text-white', 'h-[24px]', 'w-[24px]', 'text-xs', 'leading-4', 'font-semibold'],
        parentDirty: null,
        false: null,
      },

      withMarginLeft: {
        true: 'ml-[2px]',
      },
    },
  },
);

export const validationStateIconClasses = cva(['h-6', 'w-6'], {
  variants: {
    color: {
      warning: 'text-warning-400',
      success: 'text-success-400',
    },
  },
});
