import type { FC } from 'react';
import React, { useState } from 'react';
import { getTemplatesTableColumns } from '@pages/User/helpers/columns/getTemplatesTableColumns';
import type { Action } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchTemplates } from '@src/queries';

import { MODALS } from '../../constants';

import { ReactTable } from './components';
import { useDataQuery } from './hooks';

export const Templates: FC = () => {
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { data, status, tags } = useDataQuery('templates', [], fetchTemplates);

  const columns = getTemplatesTableColumns({ tags, setIsLoading });

  const actions: Action[] = [
    {
      key: 'add_template',
      label: 'New Template',
      onClick: () => {
        showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data });
      },
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
        class_of_business: false,
      }}
      entitiesLabel="templates"
      filteredData={data}
      initialFilters={[{ id: 'status', value: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED') }]}
      initialSorting={[{ id: 'ref', desc: true }]}
      status={status}
      isLoading={isLoading}
    />
  );
};

export const UserTemplatesPage = withMenuLayout(Templates);
