import { useState } from 'react';
import { useEntityStore } from '@ContractBuilder/store';

import { AttachmentRename } from '../views/AttachmentRename';

export const AttachmentRenameController = ({
  handleClose,
  attachmentId,
  attachmentBaseName,
  attachmentFileName,
}: {
  handleClose: () => void;
  attachmentId: string;
  attachmentBaseName: string;
  attachmentFileName: string;
}) => {
  const [attachmentName, setAttachmentName] = useState<string | undefined>(attachmentBaseName);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  const { renameAttachment } = useEntityStore(({ renameAttachment }) => ({
    renameAttachment,
  }));

  const handleClickRename = async () => {
    setIsRenaming(true);

    await renameAttachment(attachmentId, attachmentName || 'document');

    setIsRenaming(false);

    handleClose();
  };

  return (
    <div className="relative h-full">
      <div className="relative flex flex-col justify-center rounded-lg text-center">
        <AttachmentRename
          isRenamingDisabled={!attachmentName || isRenaming}
          isLoading={isRenaming}
          attachmentName={attachmentName}
          attachmentFileName={attachmentFileName || 'document.pdf'}
          setAttachmentName={setAttachmentName}
          handleClickRename={handleClickRename}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};
