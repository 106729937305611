import type { EndorsementStoreSlice, SliceType } from '@ContractBuilder/store/types';
import { ApiPatchOperation } from '@root/@types/payloads';
import { patchEndorsementSummary } from '@src/mutations';

export const createEndorsementSlice: SliceType<EndorsementStoreSlice> = (set, get) => ({
  createSummaryBlock: async (payload) => {
    set({ isLoading: true });

    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.CreateSummaryBlock });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      get().highlightChangedBlock(result.id);
    }

    set({ isLoading: false });
  },
  deleteSummaryBlock: async (payload) => {
    set({ isLoading: true });

    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.DeleteSummaryBlock });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }

    set({ isLoading: false });
  },
  updateSummaryBlock: async (payload) => {
    set({ loadingBlockId: payload.blockId });

    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.UpdateSummaryBlock });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      get().highlightChangedBlock(payload.blockId);
    }

    set({ loadingBlockId: undefined, isLoading: false });
  },
});
