import type { FC, ReactNode } from 'react';
import Icon from '@components/Icon';

interface BlockInstructionsProps {
  helperText?: string;
  subHelperText?: string | ReactNode;
}

export const BlockInstructions: FC<BlockInstructionsProps> = ({ helperText = '', subHelperText }) => {
  return (
    <div
      data-testid="block-instruction-text"
      className="flex gap-x-5 border-b border-info-100 bg-info-50 p-5 align-baseline text-info-500"
    >
      <Icon className="h-3.5 w-3.5 shrink-0 pt-0.5" name="book" />
      <p className="text-xs font-medium !leading-[18px]">
        {helperText}
        {!!subHelperText && (
          <>
            {helperText && <br />}
            {subHelperText}
          </>
        )}
      </p>
    </div>
  );
};
