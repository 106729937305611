import type { FC } from 'react';
import clsx from 'clsx';

interface SeparatorProps {
  className?: string;
}

export const Separator: FC<SeparatorProps> = ({ className }) => (
  <div className={clsx(['border-l', 'border-info-300', 'h-full', 'w-0'], className)}>&nbsp;</div>
);
