import type { FC } from 'react';
import clsx from 'clsx';

import { paginationItemContainerClasses } from './classes';

interface PaginationItemProps {
  currentPageIndex: number;
  onClick: (value: number) => void;
  page: number;
}

export const PaginationItem: FC<PaginationItemProps> = ({ currentPageIndex, onClick, page }) => {
  const pageIndex = page - 1;
  const isCurrentPage = pageIndex === currentPageIndex;

  return (
    <a
      onClick={() => onClick(pageIndex)}
      aria-current={isCurrentPage ? 'page' : undefined}
      className={clsx(paginationItemContainerClasses({ isCurrentPage }))}
    >
      {page}
    </a>
  );
};
