import { useEffect } from 'react';

import { waitForElement } from './waitForElement';

// Remove unnecessary empty spaces from block's ID
const getParsedSpanContent = (node: Element) => node.textContent?.replace(/\s/g, '');

export const useScrollToFocussedBlock = (focussedBlockId?: string, pages: number[] = []) => {
  useEffect(() => {
    if (focussedBlockId && pages.length > 0) {
      const pdfDocumentWrapper = document.querySelector('div.react-pdf__Document');

      if (!pdfDocumentWrapper) {
        return;
      }

      waitForElement(`div.react-pdf__Page[data-page-number="${pages.length}"]`, 20, 500).then((element) => {
        if (element) {
          const spanNodes = pdfDocumentWrapper.querySelectorAll('span[role="presentation"]');
          const nodeToScrollTo = [...spanNodes].find((node) => getParsedSpanContent(node) === focussedBlockId);

          if (!nodeToScrollTo) {
            return;
          }

          return nodeToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  }, [focussedBlockId, pages.length]);

  return null;
};
