import type { LinkedDatapoints } from '@root/@types/types';
import { extractDataPointsFromHTML } from '@root/helpers/schema';

export const updateDatapointsFromContent = (
  content: string | undefined,
  currentLinkedDatapoints: LinkedDatapoints = [],
) => {
  if (content) {
    return extractDataPointsFromHTML(content ?? '', currentLinkedDatapoints);
  }

  return currentLinkedDatapoints;
};
