import type { FC } from 'react';
import { CreateEndorsementButton } from '@ContractBuilder/contract-builder-header/modules/endorsements-button/views/CreateEndorsementButton';
import { ListEndorsementsButton } from '@ContractBuilder/contract-builder-header/modules/endorsements-button/views/ListEndorsementsButton';
import type { EntityData } from '@ContractBuilder/types';
import { canChange } from '@helpers/canChange';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { DocumentMode } from '@root/@types/types';
import { useEndorsementData, useFeatureFlags } from '@src/hooks';

interface EndorsementsButtonControllerProps {
  submission: EntityData;
}

export const EndorsementsButtonController: FC<EndorsementsButtonControllerProps> = ({ submission }) => {
  const { status } = submission;
  const documentMode = getDocumentMode();

  const getFeatureFlagState = useFeatureFlags();
  const hasEndorsementsFeature = getFeatureFlagState('Endorsements');

  const shouldRunEndorsementQuery =
    !canChange(status) && documentMode.mode === DocumentMode.SUBMISSIONS && hasEndorsementsFeature;
  const isViewingRevisionHistory = useIsRevisionHistory();

  const { data, isFetching, refetch } = useEndorsementData({
    enabled: shouldRunEndorsementQuery,
    refetchOnWindowFocus: false,
  });

  const hasEndorsements = (data?.length ?? 0) > 0;
  const showEndorsementsButton = shouldRunEndorsementQuery && (status !== 'ARCHIVED' || hasEndorsements);

  if (!showEndorsementsButton) {
    return null;
  }

  if (isFetching) {
    return (
      <div className="animate-pulse">
        <div className="h-9 w-44 rounded-md bg-info-100" />
      </div>
    );
  }

  if (!hasEndorsements || !data) {
    return isViewingRevisionHistory ? null : <CreateEndorsementButton onCreateEndorsement={refetch} />;
  }

  return <ListEndorsementsButton data={data} onCreateEndorsement={refetch} submission={submission} />;
};
