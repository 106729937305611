import type { EntityData } from '@ContractBuilder/types';
import { patchEntity } from '@mutations/patchEntity';
import { ApiPatchOperation } from '@root/@types/types';

import type { RevisionStoreSlice, SliceType } from './types';
import { updateSchemaInForeignStore } from './utils';

export const createRevisionSlice: SliceType<RevisionStoreSlice> = (set, get) => ({
  createRevision: async (revisionName: string) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    const result = await patchEntity({
      revisionName,
      operation: ApiPatchOperation.CreateRevision,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission(['blocks', 'revision_history']);
    }
  },
  restoreRevision: async (revisionId: string) => {
    const submission = get().submission;

    if (!submission) {
      return;
    }

    try {
      set({ isLoading: true });
      const result = await patchEntity({
        revisionId,
        operation: ApiPatchOperation.RestoreRevision,
      });
      if (result?.message === 'success') {
        return await get().reloadSubmission();
      }
    } finally {
      set({ isLoading: false });
    }
  },
  loadSubmissionRevision: async (submissionRevisionData: EntityData) => {
    set({ isLoading: true });

    let submissionRevision = submissionRevisionData;

    const { comments, revision_history } = get().submission!;

    const newData = { ...submissionRevision, comments, revision_history };

    set({ submission: newData });
    set({ loadingBlockId: undefined });

    updateSchemaInForeignStore(newData.data_items);

    set({ isLoading: false });
    return newData;
  },
});
