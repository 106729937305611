import React from 'react';
import { Spinner } from '@components/Spinner';
import { livePreviewUpdatingPillClasses } from '@ContractBuilder/modules/live-pdf-preview/components/classes';
import clsx from 'clsx';

export const LoadingIndicator = () => {
  return (
    <div className={clsx(livePreviewUpdatingPillClasses)}>
      <span className="text-xs font-medium leading-4">Loading</span>
      <Spinner
        className="[&>*:first-child]:!fill-gray-300 [&>*:first-child]:!opacity-100 [&>path]:!fill-blue-600"
        size="xs"
      />
    </div>
  );
};
