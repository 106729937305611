import type { BlockVisibilityCondition, BlockVisibilityOperators, BlockVisibilityValueType } from '@root/@types/types';
import { isNonNullish } from '@root/helpers';
import { initial, last } from 'lodash-es';

import type { VisibilityConfigPath } from './types';

export const buildRowKey = (
  tabId: string | null,
  condition: BlockVisibilityCondition,
  idx: number,
  allConditions: BlockVisibilityCondition[],
) => {
  const uniquePrefix = tabId?.split('-')?.[0];
  return [uniquePrefix, condition.field, idx, allConditions.length].filter(isNonNullish).join('-');
};

const CLEARED_OPERATORS: BlockVisibilityOperators[] = ['IS NOT EMPTY', 'IS EMPTY'];

export const shouldClearValueForOperator = (isOperator: boolean, value?: BlockVisibilityValueType) => {
  if (!value) return false;
  return isOperator && CLEARED_OPERATORS.includes(value as BlockVisibilityOperators);
};

export const getPathDetails = (key: VisibilityConfigPath, value?: BlockVisibilityValueType) => {
  const parts = key.split('.');
  const kind = last(parts);
  const valuePath = [...initial(parts), 'value'].join('.');

  const isField = kind === 'field';
  const isOperator = kind === 'operator';
  const isValue = kind === 'value';

  const shouldClearValue = shouldClearValueForOperator(isOperator, value) || isField;
  return {
    isValue,
    valuePath,
    shouldClearValue,
  };
};
