import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import type { Node } from 'prosemirror-model';
import type { Selection, Transaction } from 'prosemirror-state';
import { TableMap } from 'prosemirror-tables';
import type { ContentNodeWithPos } from 'prosemirror-utils';

import type { BorderSettings, SelectionRect } from './types';

// assuming that there is a given node above
export const findFirstNodeAbove = (
  nodeName: string,
  { $from }: Selection,
): { node: Node; before: number; depth: number } | null => {
  for (let depth = $from.depth; depth >= 0; depth--) {
    let node = $from.node(depth);
    if (node.type.name === nodeName) {
      return { node, before: $from.before(depth), depth };
    }
  }
  return null;
};

export const setCellBorders =
  (tr: Transaction) =>
  (node: Node, posInTable: number, { isTop, isBottom, isLeft, isRight }: BorderSettings) => {
    const attrs = {
      ...node.attrs,
      ...(isTop && { hidetop: isTop }),
      ...(isBottom && { hidebottom: isBottom }),
      ...(isLeft && { hideleft: isLeft }),
      ...(isRight && { hideright: isRight }),
    };

    const newNode = node.type.create(attrs, node.content, node.marks);
    tr.setNodeMarkup(posInTable, newNode.type, newNode.attrs, newNode.marks);
  };

export const setAdjacentBorders =
  (
    setBorders: (node: Node, posInTable: number, borders: BorderSettings) => void,
    table: ContentNodeWithPos,
    rect: SelectionRect,
  ) =>
  (direction: 'left' | 'top') => {
    let adjacentRect: SelectionRect = rect;

    if (direction === 'left') {
      adjacentRect = { ...rect, right: rect.left, left: rect.left - 1 };
    } else if (direction === 'top') {
      adjacentRect = { ...rect, bottom: rect.top, top: rect.top - 1 };
    }
    const tableMap = TableMap.get(table.node);
    const cellPositions = tableMap.cellsInRect(adjacentRect);

    cellPositions.forEach((cellPos: number) => {
      const cellNode = table.node.nodeAt(cellPos);
      if (!cellNode) return;
      const posInTable = table.start + cellPos;
      if (direction === 'left') {
        setBorders(cellNode, posInTable, { isRight: true });
      } else if (direction === 'top') {
        setBorders(cellNode, posInTable, { isBottom: true });
      }
    });
  };

export const getCurrentContentId = (block?: BlockEditFormState, currentVariationIdx?: number) =>
  currentVariationIdx ? block?.variations?.[currentVariationIdx]?.id : block?.id ?? 'create';
