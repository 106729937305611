import React from 'react';
import { getTemplatesUrl } from '@ContractBuilder/modules/block-edit/views/BlockUsedInTemplatesModal';
import { mdiOpenInNew } from '@mdi/js';
import { Icon } from '@mdi/react';

const ItemUsedWithin = ({ item }: { item: any }) => {
  const templatesUrl = getTemplatesUrl();
  return (
    <td className="group py-4 align-top">
      {item?.usedInTemplates?.map(({ id, name }: { id: string; name: string }) => (
        <span
          key={id}
          className="relative inline-flex items-center rounded-full bg-info-100 px-2.5 py-0.5 text-xs font-medium text-info-800"
        >
          <a
            href={`${templatesUrl}/${id}/contract`}
            target="_blank"
            className="mr-2 inline-block max-w-[140px] truncate text-primary-600 hover:underline"
            rel="noreferrer"
          >
            {name}
          </a>
          <span className="relative">
            <Icon
              className="absolute left-[-7px] top-[-6px] inline-block text-primary-600"
              path={mdiOpenInNew}
              size={0.5}
            />
          </span>
        </span>
      ))}
    </td>
  );
};

export default ItemUsedWithin;
