import React, { useMemo, useState } from 'react';
import type { QueryStatus } from 'react-query';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { Pagination } from '@components/Table';
import { ActionsBar } from '@pages/User/components/ActionsBar';
import { FiltersBar } from '@pages/User/components/FiltersBar';
import { TableBody } from '@pages/User/components/TableBody';
import { TableHeader } from '@pages/User/components/TableHeader';
import type { Action, Filter } from '@root/@types/types';
import type { RankingInfo } from '@tanstack/match-sorter-utils';
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import type { ColumnFiltersState, FilterFn, RowData, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';

interface ReactTableProps {
  entitiesLabel: string;
  filteredData: any[];
  status?: QueryStatus;
  actions?: Action[];
  columnVisibility?: VisibilityState;
  initialFilters?: ColumnFiltersState;
  initialSorting?: SortingState;
  enableColumnFilters?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  columns: any;
  pageSize?: number;
  tableClasses?: string;
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filter?: Partial<Filter>;
    dataCellClassName?: string;
    headingCellClassName?: string;
    headingLinkClassName?: string;
    isStandaloneDataCell?: boolean;
  }
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export const ReactTable = ({
  actions = [],
  columns,
  columnVisibility,
  entitiesLabel,
  filteredData,
  initialFilters,
  initialSorting,
  enableColumnFilters = true,
  isFetching = false,
  isLoading = false,
  pageSize = 12,
  status,
  tableClasses,
}: ReactTableProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize,
  });
  const [sorting, setSorting] = useState<SortingState>(initialSorting ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialFilters ?? []);
  const [globalFilter, setGlobalFilter] = useState('');

  const data = useMemo(() => filteredData, [filteredData]);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value, { threshold: rankings.CONTAINS });

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    initialState: {
      columnVisibility,
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    enableColumnFilters,
    sortDescFirst: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    globalFilterFn: 'fuzzy',
    getColumnCanGlobalFilter: (column) => !['created_at', 'updated_at'].includes(column.id),
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  const [isFiltersBarVisible, setIsFiltersBarVisible] = useState(false);
  const toggleFiltersBarVisibility = () => setIsFiltersBarVisible(!isFiltersBarVisible);

  const handleClearAllFiltersClick = () => setColumnFilters([]);

  const handleGlobalSearchClick = (searchTerm: string) => setGlobalFilter(searchTerm);

  if (!['error', 'success'].includes(status ?? '') || isFetching) {
    return (
      <div className="overlay bg-transparent md:left-64">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('px-4 sm:px-6 lg:px-8', tableClasses)}>
      <LoadingOverlay active={isLoading} />
      <ActionsBar
        actions={actions}
        entitiesLabel={entitiesLabel}
        enableColumnFilters={enableColumnFilters}
        handleClearAllFiltersClick={handleClearAllFiltersClick}
        handleGlobalSearchClick={handleGlobalSearchClick}
        shouldShowClearFilters={table.getState().columnFilters.length > 0}
        toggleFiltersBarVisibility={toggleFiltersBarVisibility}
      />
      <Show when={isFiltersBarVisible}>
        <FiltersBar table={table} />
      </Show>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table data-testid="data-table" className="w-full min-w-full table-fixed divide-y divide-info-300">
                <TableHeader tableHeaderGroups={table.getHeaderGroups()} />
                <TableBody rows={table.getRowModel().rows} />
              </table>
              <Pagination totalResults={table.getRowCount()} pageSize={pageSize} onPageChange={table.setPageIndex} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
