export const textAreaClasses = [
  'h-auto',
  'w-full',
  'border-none',
  'outline-none',
  'resize-none',
  'placeholder:uppercase',
  'placeholder:text-info-300',
  'placeholder:text-xs',
  'placeholder:pt-1',
];
