// @ts-expect-error: We need to wait until [this](https://github.com/ueberdosis/tiptap/issues/3488) is resolved.
import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ContentRepeaterGroupInner } from '@WysiwygEditor/components/Editor/extensions/content-repeater-group/ContentRepeaterGroupInner';

export interface ContentRepeaterGroupChildOptions {
  HTMLAttributes: Record<string, any>;
}

export const ContentRepeaterGroupChild = Node.create<ContentRepeaterGroupChildOptions>({
  name: 'ContentRepeaterGroupChild',
  content: 'heading* block*',
  allowGapCursor: false,
  selectable: true,
  group: 'block',

  addAttributes() {
    return {
      parentId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-parentid'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-parentid': attributes.parentId,
          };
        },
      },
      order: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-order'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-order': attributes.order,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div`,
        getAttrs: (node: HTMLElement) =>
          node.getAttribute('data-type') === 'content-repeater-group-child' &&
          node.getAttribute('mode') !== 'inline' &&
          null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['div', mergeAttributes({ 'data-type': 'content-repeater-group-child' }, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContentRepeaterGroupInner);
  },
});
