import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@mdi/react';
import { twMerge } from 'tailwind-merge';

export type IconType = string | IconProp;

interface TooltipIconProps {
  icon: IconType;
  size?: string;
  className?: string;
}

export const TooltipIcon: React.FC<TooltipIconProps> = ({ icon, className, size = '18px' }) => {
  return typeof icon === 'string' ? (
    <Icon path={icon} size={size} className={twMerge('-mx-1 -my-2', className)} />
  ) : (
    <FontAwesomeIcon icon={icon} className={twMerge(className)} />
  );
};
