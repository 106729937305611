import type { FC } from 'react';
import { useMemo, useState } from 'react';
import type { StateFromBlock } from '@ContractBuilder/modules/block-edit';
import { getFormStateFromBlock, useBlockActions } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { sanitizeVisibilityPayload } from '@root/helpers/blocks';
import { isEqual } from 'lodash-es';

import { BlockVisibilityModalView } from './view/BlockVisibilityModalView';
import { INITIAL_BLOCK_VISIBILITY_STATE } from './constants';

interface BlockVisibilityModalProps {
  handleClose: () => void;
  blockId: string;
}

const getInitialState = (blockId: string) => {
  const submission = useEntityStore.getState().submission;
  const block = getFormStateFromBlock(submission, blockId);

  if (!block) {
    return undefined;
  }

  const hasVariations = !!block?.variations?.length;

  if (hasVariations) {
    return {
      ...block,
      variations: block.variations ?? [],
      visibility: {
        variations: block?.visibility?.variations ?? [],
      },
    };
  }

  return {
    ...block,
    visibility: { block: block?.visibility?.block ?? INITIAL_BLOCK_VISIBILITY_STATE },
  };
};

export const BlockVisibilityModal: FC<BlockVisibilityModalProps> = ({ handleClose, blockId }) => {
  const initialState = useMemo(() => getInitialState(blockId), [blockId]);
  const [state, setState] = useState<StateFromBlock | undefined>(initialState);
  const resetDatapointsStore = useDatapointsStore((state) => state.reset);

  const { updateBlock } = useBlockActions();

  const resetState = () => {
    handleClose();
    return resetDatapointsStore();
  };

  const handleFormSubmit = async () => {
    if (!state) {
      return handleClose();
    }

    const parsedPayload = sanitizeVisibilityPayload(state);
    await updateBlock(parsedPayload);

    return resetState();
  };

  const isDirty = useDeepCompareMemo(() => !isEqual(state, initialState), [state, initialState]);

  if (!state) {
    return null;
  }

  return (
    <BlockVisibilityModalView
      blockName={state.title ?? state.name}
      formValues={state}
      isDirty={isDirty}
      onCancel={resetState}
      onSaveChanges={handleFormSubmit}
      setFormValues={setState}
    />
  );
};
