import { useCallback, useState } from 'react';

/** Recalculate key for `NumericInput` in order to force re-render when value gets cleared out programmatically */
export const useNumericFormatKey = () => {
  const [numericInputKey, setNumericInputKey] = useState(Math.random());

  const update = useCallback(() => {
    setNumericInputKey(Math.random());
  }, []);

  return [numericInputKey, update] as const;
};
