import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useDeepCompareCallback } from '@hooks/use-deep-compare-callback';
import clsx from 'clsx';
import { cloneDeep, get, isEmpty as isEmptyFn, set } from 'lodash-es';

import { SectionRepeaterView } from './SectionRepeater.view';
import { SectionRepeaterCollapsibleView } from './SectionRepeaterCollapsible.view';
import type { SectionRepeaterProps } from './types';

const CUSTOM_CONTENT: Record<string, string> = {
  sections: 'Adding sections will enable the option to split content into each section.',
};

const SectionRepeater: React.FC<SectionRepeaterProps> = ({
  className,
  id,
  item,
  ownKey,
  validationErrors,
  showQuestionKey,
  onChange,
  isReadOnly = false,
  isDisabled,
  collapsible = true,
  startCollapsed = false,
  hideErrors,
  isRequired,
}) => {
  const { formValues, setFormValues } = useDatapointsStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));
  const value = get(formValues, ownKey, item.value ?? '');

  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  let defaultValue = [];
  for (let i = 0; i < (item?.minimum ?? 0); i++) {
    defaultValue.push({});
  }
  const initialItems = hasInitialValue ? value : defaultValue;
  const canAdd = item?.maximum === undefined || initialItems?.length < item?.maximum;
  const total100Error = validationErrors?.find(
    (error) => error.instancePath === ownKey && error.keyword === 'total100',
  );

  const schemaItem = item.items?.type === 'object' ? item.items : { properties: { [id]: item.items } };

  const handleAdd = useDeepCompareCallback(() => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, {}]);
    setFormValues(cloned);
    // eslint-disable-next-line -- `setFormValues` will never change`
  }, [formValues, ownKey, initialItems]);

  const handleRemove = useDeepCompareCallback(
    (itemIndex: number) => {
      const cloned = cloneDeep(formValues);
      const filtered = initialItems.filter((v, index) => index !== itemIndex);

      set(cloned, ownKey, filtered);
      setFormValues(cloned);
    },
    // eslint-disable-next-line -- `setFormValues` will never change`
    [formValues, initialItems, ownKey],
  );

  const isEmpty = initialItems.length === 0;
  const emptyContent = isEmpty ? CUSTOM_CONTENT[id] : '';

  const helperText = item.helperText ?? item.description;

  const level = Number(ownKey && ownKey.split('.').length);
  const isLevel3OrMore = level >= 3;

  const hasChildrenWithErrors = useMemo(() => {
    return !isEmptyFn(value) && !!validationErrors;
  }, [value, validationErrors]);

  return collapsible ? (
    <SectionRepeaterCollapsibleView
      isRequired={isRequired || hasChildrenWithErrors}
      canAdd={canAdd}
      className={clsx(className, isLevel3OrMore && '!p-0')}
      emptyContent={emptyContent}
      handleAdd={handleAdd}
      handleRemove={handleRemove}
      helperText={helperText}
      id={id}
      initialItems={initialItems}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      item={item}
      onChange={onChange}
      ownKey={ownKey}
      schemaItem={schemaItem}
      showQuestionKey={showQuestionKey}
      title={item.title}
      total100Error={total100Error}
      validationErrors={validationErrors}
      startCollapsed={startCollapsed}
      hideErrors={hideErrors}
    />
  ) : (
    <SectionRepeaterView
      isRequired={isRequired || hasChildrenWithErrors}
      hideErrors={hideErrors}
      canAdd={canAdd}
      className={clsx(className, isLevel3OrMore && '!p-0')}
      emptyContent={emptyContent}
      handleAdd={handleAdd}
      handleRemove={handleRemove}
      helperText={helperText}
      id={id}
      initialItems={initialItems}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      item={item}
      onChange={onChange}
      ownKey={ownKey}
      schemaItem={schemaItem}
      showQuestionKey={showQuestionKey}
      title={item.title}
      total100Error={total100Error}
      validationErrors={validationErrors}
    />
  );
};

export default SectionRepeater;
