const TEMPLATES_URI = 'templates-spa-al';
const SUBMISSIONS_URI = 'submissions-spa-al';
const ENDORSEMENTS_URI = 'endorsements-spa-al';

export const getCurrentDocumentApiUrl = (templateId?: string, submissionId?: string, endorsementId?: string) => {
  switch (true) {
    case !!templateId:
      return [TEMPLATES_URI, templateId].join('/');
    case !!submissionId && !endorsementId:
      return [SUBMISSIONS_URI, submissionId].join('/');
    case !!submissionId && !!endorsementId:
      return [SUBMISSIONS_URI, submissionId, ENDORSEMENTS_URI, endorsementId].join('/');
    default:
      return undefined;
  }
};
