import { COMMENTS_BADGE_Z_INDEX } from '@constants/z-indices';
import { cva } from 'class-variance-authority';

export const baseBlockBodyClasses = [
  'relative',
  'flex',
  'px-mrc-block-padding',
  'py-3',
  'transition-colors',
  'duration-500',
  'cursor-text',
  'opacity-100',
  'page-A4-width',
];

export const leftBorderClasses = cva(
  [
    'transition',
    'duration-500',
    'ease-in-out',
    'absolute',
    'w-1',
    'h-full',
    'left-0',
    'top-0',
    'transition',
    'opacity-0',
    COMMENTS_BADGE_Z_INDEX,
  ],
  {
    variants: {
      editing: {
        false: ['group-hover:opacity-100', 'group-hover:bg-info-300', 'bg-transparent'],
        true: ['bg-primary-500', '!opacity-100'],
      },
      forceHoverEffect: {
        true: ['!bg-info-300', 'opacity-100'],
        false: '',
      },
    },
  },
);

export const blockViewBodyClasses = cva(baseBlockBodyClasses, {
  variants: {
    editing: {
      true: ['!p-0', 'drop-shadow-2xl'],
      false: [],
    },
    active: { true: ['bg-primary-100', 'hover:bg-primary-100'] },
    highlighted: { true: ['bg-primary-50'] },
    pdf: { false: ['border-b'], true: ['!pb-0'] },
    disabled: { true: ['pointer-events-none'], false: ['pointer-events-auto'] },
    conditionallyNotVisible: {
      true: ['bg-info-100', 'cursor-default'],
      false: [],
    },
    lockedFromEditing: { true: ['cursor-default'] },
  },
  defaultVariants: {
    editing: false,
    highlighted: false,
    pdf: false,
    disabled: false,
  },
});

export const indicatorIconsClasses = [
  'py-3',
  'absolute',
  'top-0',
  'right-0',
  'flex',
  'justify-evenly',
  'text-info-500',
  'w-mrc-block-padding',
  'bg-transparent',
];
