import Tooltip from '@components/Tooltip';
import { useSchemaStore } from '@ContractBuilder/store';
import type { LinkedDatapoint } from '@root/@types/resources';
import InputCheckbox from '@root/src/components/InputCheckbox';

interface DatapointElementProps {
  datapoint: LinkedDatapoint;
  currentVariationIdx?: number;
  onChange: (id: string, isRequired: boolean, variationIndex?: number) => void;
}

export const DatapointElement = ({ onChange, datapoint, currentVariationIdx }: DatapointElementProps) => {
  const { id, isRequired } = datapoint;
  const handleClick = () => onChange(id, !isRequired, currentVariationIdx);

  const { findField } = useSchemaStore(({ findField }) => ({ findField }));
  const title = findField(id)?.title || id;

  return (
    <div className="flex cursor-pointer items-center justify-between bg-none px-3 py-2 text-sm hover:bg-primary-50">
      <Tooltip content={title}>
        <span onClick={handleClick} className="w-full truncate pr-3 text-xs font-normal leading-5" title={title}>
          {title}
        </span>
      </Tooltip>
      <InputCheckbox key={id} labelText={''} isChecked={isRequired} onClick={handleClick} />
    </div>
  );
};
