import { createContext, useContext } from 'react';
import type { BlockVisibilityOutput } from '@root/@types/types';

export interface VariationsTabsContextLiteValue {
  selectedVariationId?: string;
  variationInView?: string;
  currentVariationIdx: number;
  variationsCount: number;
  loading: boolean;
  visibilityDetails?: BlockVisibilityOutput[];
  hasLogic?: boolean;
  select: (id: string) => void;
  setCurrent: (id: string) => void;
}

export const VariationsTabsContextLite = createContext<VariationsTabsContextLiteValue | null>(null);

export const useVariationsTabsLite = () => {
  const context = useContext(VariationsTabsContextLite);

  if (!context) {
    throw new Error(`"VariationsProviderLite" must be present in the DOM tree`);
  }

  return context;
};
