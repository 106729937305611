import type { FC } from 'react';
import { cva } from 'class-variance-authority';

import type { TooltipedIconProps } from './TooltipedIcon';
import { TooltipedIcon } from './TooltipedIcon';

const classes = cva(['text-info-400'], {
  variants: {
    isInteractive: {
      true: ['hover:text-primary-500', 'cursor-pointer'],
      false: ['hover:text-info-600', '!cursor-default'],
    },
    isDisabled: {
      true: ['!text-info-300', '!cursor-not-allowed'],
    },
  },
});

interface FeatureIndicatorIconProps extends TooltipedIconProps {
  isInteractive?: boolean;
  isDisabled?: boolean;
}

export const FeatureIndicatorIcon: FC<FeatureIndicatorIconProps> = ({ isInteractive, isDisabled, ...props }) => (
  <TooltipedIcon
    className={classes({
      isInteractive,
      isDisabled,
    })}
    placement="top"
    size={0.6}
    {...props}
  />
);
