import authProvider from '@helpers/auth/authProvider';
import type { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';

export const getRequestAuthPayload = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const baseConfig: AxiosRequestConfig = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: import.meta.env.VITE_API_URL,
  };

  const networkAuthPayload = await authProvider.getNetworkAuthPayload();
  return merge(baseConfig, networkAuthPayload, config);
};
