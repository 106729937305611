import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiClose } from '@mdi/js';
import type { ElementSize } from '@root/@types/types';
import { cva } from 'class-variance-authority';

const logo = {
  artificial: '/assets/Artificial_Logo_Black.svg',
  ppl: {
    black: '/assets/PPL_Logo_Black.svg',
    white: '/assets/PPL_Logo_White.svg',
  },
};

const logoClasses = cva([], {
  variants: {
    size: {
      sm: 'h-[21px]',
      md: 'h-[27px]',
      lg: 'h-[33px]',
    },
    logoColor: {
      white: 'filter invert',
      black: null,
    },
  },
});

const classes = cva(['flex', 'items-center'], {
  variants: {
    size: {
      sm: 'gap-3',
      md: 'gap-3',
      lg: 'gap-5',
    },
    logoColor: {
      black: 'text-inherit',
      white: 'text-white',
    },
  },
});

interface LogoCollaborationProps {
  logoColor?: 'white' | 'black';
  size?: ElementSize;
}

export const LogoCollaboration: FC<LogoCollaborationProps> = ({ logoColor = 'black', size = 'md' }) => (
  <div className={classes({ size, logoColor })}>
    <img src={logo.artificial} className={logoClasses({ size, logoColor })} alt="Artificial Logo" />
    <IconMdi path={mdiClose} size={0.65} />
    <img src={logo.ppl[logoColor]} className={logoClasses({ size })} alt="PPL Logo" />
  </div>
);
