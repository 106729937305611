import React, { useState } from 'react';
import clsx from 'clsx';

import type { CustomSvgComponent } from '../../@types/types';

const Svg: CustomSvgComponent = ({ stroke, fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill={fill} stroke={fill} />
    <path
      d="M12.2064 4.79279C12.3939 4.98031 12.4992 5.23462 12.4992 5.49979C12.4992 5.76495 12.3939 6.01926 12.2064 6.20679L7.20643 11.2068C7.0189 11.3943 6.76459 11.4996 6.49943 11.4996C6.23427 11.4996 5.97996 11.3943 5.79243 11.2068L3.79243 9.20679C3.61027 9.01818 3.50948 8.76558 3.51176 8.50339C3.51403 8.24119 3.6192 7.99038 3.80461 7.80497C3.99002 7.61956 4.24083 7.51439 4.50303 7.51211C4.76523 7.50983 5.01783 7.61063 5.20643 7.79279L6.49943 9.08579L10.7924 4.79279C10.98 4.60532 11.2343 4.5 11.4994 4.5C11.7646 4.5 12.0189 4.60532 12.2064 4.79279Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke={stroke} />
  </svg>
);

interface InputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  className?: string;
  descriptionText?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  labelText?: string;
  name?: string;
  value?: string;
  errors?: string | string[]; //TODO: not implemented
  isRequired?: boolean;
  'data-testid'?: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  className,
  descriptionText,
  id,
  isChecked,
  isDisabled,
  isRequired,
  labelText,
  name,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (isDisabled && '#CED4DA') || ((isFocused || isChecked) && '#3b82f6') || '#D1D5DB';
  const fill = (isChecked && !isDisabled && '#3b82f6') || (isChecked && isDisabled && '#CED4DA') || 'white';
  const dataTestId = `checkbox-${name}`;

  return (
    <label
      className={clsx(
        'relative flex cursor-pointer items-center',
        isDisabled && 'cursor-auto text-info-700',
        className,
      )}
      htmlFor={id}
    >
      <Svg stroke={stroke} fill={fill} />

      <div data-testid={descriptionText} className="ml-3">
        <div>{labelText}</div>
        {descriptionText && (
          <p className={clsx('mt-1 text-xs', !isDisabled && 'font-light text-info-600')}>{descriptionText}</p>
        )}
      </div>

      <input
        required={isRequired}
        checked={Boolean(isChecked)}
        className="absolute -z-10 opacity-0"
        disabled={isDisabled}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="checkbox"
        data-testid={dataTestId}
        value={id}
        {...props}
      />
    </label>
  );
};

export default InputCheckbox;
