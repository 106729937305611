import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const deleteTag = async (id: string): Promise<Pick<Tag, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'delete',
    url: `tags-spa-al/${id}`,
  });

  const response = await axios(networkPayload);
  return response.data;
};
