import IconMdi from '@components/IconMdi';
import { mdiFileDocumentOutline } from '@mdi/js';
import clsx from 'clsx';

import { uploadAttachmentPageContainerClasses, uploadAttachmentWrapperClasses } from '../classes';

export const AttachmentLoaded = ({ fileName }: { fileName?: string }) => {
  return (
    <div className={clsx(uploadAttachmentPageContainerClasses)}>
      <div className={clsx(uploadAttachmentWrapperClasses, 'flex-col items-center')}>
        <div className="mt-2 inline-flex max-w-full items-center text-sm font-normal leading-5 text-gray-700">
          <IconMdi path={mdiFileDocumentOutline} />
          <div className="ml-1 h-5 overflow-hidden text-ellipsis whitespace-nowrap">{fileName || 'document.pdf'}</div>
        </div>
      </div>
    </div>
  );
};
