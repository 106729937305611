import type { FC, MouseEventHandler } from 'react';
import { memo } from 'react';
import { Show } from '@components/Show';
import type { CommentsPopoverRenderProps } from '@ContractBuilder/modules/block/view/CommentsPopover';
import { CommentsPopoverProvider } from '@ContractBuilder/modules/block/view/CommentsPopover';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { ctxIsPDF } from '@ContractBuilder/rules/block/is-ctx';
import { useDeepCompareCallback, useDeepCompareMemo } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { BlockActions } from '../../block-actions/BlockActions';
import { BlockContentController } from '../../block-content';
import { useBlockFlags } from '../context/context';
import { getSystemHelperTextForVariations } from '../utils/get-system-helper-text';

import { BlockInstructions } from './BlockInstructions';
import { VARIATIONS_COPY_MAP } from './VariationsSystemHelperTextCopy';

interface BlockViewProps {
  handleDelete: MouseEventHandler;
  handleEdit: MouseEventHandler<HTMLDivElement>;
}

const getShowHelperText = (text: string | undefined | null, isPDFRender: boolean) => !isPDFRender && !isEmpty(text);

const BlockViewInner: FC<BlockViewProps> = ({ handleDelete, handleEdit }) => {
  const {
    block,
    context,
    details: {
      isVisible,
      isPreview,
      hasVariationsConditionalLogic,
      showBlockActions,
      variations: { count },
    },
  } = useBlockFlags();
  const { id, selectedVariationId, helperText } = block;
  const isPDFRender = ctxIsPDF(context);

  const dispatchCustomEvent = useDispatchCustomEvent();

  const subHelperComponent = useDeepCompareMemo(
    () =>
      getSystemHelperTextForVariations({
        hasVariations: !!count,
        hasVariationsConditionalLogic: hasVariationsConditionalLogic,
        selectedVariationId,
      }),
    // eslint-disable-next-line -- All of those dependencies come out directly from the `block`
    [block],
  );

  const systemHelperText = VARIATIONS_COPY_MAP[subHelperComponent];
  const showHelperText = getShowHelperText(helperText || systemHelperText, isPDFRender);

  const renderCommentsPopoverInner = useDeepCompareCallback(
    ({ onClick }: CommentsPopoverRenderProps) => {
      return (
        <>
          <Show when={showBlockActions}>
            <BlockActions
              block={block}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              onShowComments={onClick}
              onCommentClick={(event) => {
                dispatchCustomEvent(ArtificialCustomEvent.CreateNewCommentsThread, block.id);
                return onClick(event, true);
              }}
            />
          </Show>
          <div className="flex flex-col" onClick={handleEdit}>
            <Show when={showHelperText}>
              <BlockInstructions
                helperText={helperText}
                subHelperText={
                  <Show when={!!systemHelperText}>
                    <div className="flex flex-col gap-2">
                      <div className="text-xs font-semibold leading-normal text-info-600">{systemHelperText}</div>
                    </div>
                  </Show>
                }
              />
            </Show>
            <BlockContentController />
          </div>
        </>
      );
    },
    // eslint-disable-next-line -- We don't care about functions changing
    [block, helperText, isPreview, isVisible, showHelperText, systemHelperText],
  );

  if (isPDFRender) {
    return (
      <>
        <BlockContentController />
        {/** fix(cb-1689): `h-*` must match the py-* class applied in `baseBlockBodyClasses` **/}
        <div className="h-3">&nbsp;</div>
      </>
    );
  }

  return <CommentsPopoverProvider blockId={id}>{renderCommentsPopoverInner}</CommentsPopoverProvider>;
};

export const BlockView = memo(BlockViewInner);
