import { BLOCK_ACTIONS_Z_INDEX } from '@root/src/constants/z-indices';
import { cva } from 'class-variance-authority';

import { baseBlockBodyClasses } from '../block/classes';

export const blockCreatorClasses = cva(
  [
    //
    ...baseBlockBodyClasses,
    'h-auto',
    'border-b',
    'border-info-300',
    'border-l-4',
    'border-l-primary-500',
    'z-[16]',
    'bg-primary-50/50',
    '!px-0',
  ],
  {
    variants: {
      step: {
        start: [],
        wysiwyg: ['!p-0'],
        block: [],
        library: [],
      },
    },
  },
);

export const addIconClasses = cva([
  'absolute',
  'bottom-0',
  'cursor-pointer',
  'duration-500',
  'ease-in-out',
  'flex',
  'invisible',
  'items-center',
  'left-0',
  'opacity-0',
  'text-primary-500',
  'transition',
  'translate-y-1/2',
  'w-full',
  'group-hover:visible',
  'group-hover:pointer-events-auto',
  'group-hover:opacity-100',
  BLOCK_ACTIONS_Z_INDEX,
]);
