const sharedClasses = {
  // when pointer-events-none is used, the cursor won't be triggered to 'cursor-not-allowed'
  // that's why it needs to be above the layer that has no pointer events
  disabledContainer: ['cursor-not-allowed'],
  disabled: ['pointer-events-none', 'opacity-50', 'hover:none'],
  info: ['text-sm', 'leading-4', 'font-light', 'text-info-400', 'pt-2'],
  selection: {
    ring: {
      block: ['ring-2 ring-primary-500', 'rounded-md'],
      title: ['text-primary-500', 'font-bold'],
    },
  },
  microInteractions: ['duration-150', 'ease-in-out', 'transition-all'],
  size_sm: { height: 'h-6' },
  size_md: { height: 'h-10' },
  size_lg: { height: 'h-12' },
};

export default sharedClasses;
