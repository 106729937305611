import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { Show, ShowFirstMatching } from '@components/Show';
import { mdiCheck } from '@mdi/js';
import clsx from 'clsx';

import { stepperItemClasses } from '../classes';
import type { Step, StepperItemState } from '../types';

import './stepper.css';

const StepperItem: FC<StepperItemState> = ({ isActive = false, isCompleted = false }) => {
  return (
    <div className={clsx(stepperItemClasses({ isActive, isCompleted }))}>
      <ShowFirstMatching>
        <Show when={isActive}>
          <div className="h-2.5 w-2.5 rounded-full bg-primary-600" />
        </Show>
        <Show when={isCompleted}>
          <IconMdi className="text-white" path={mdiCheck} />
        </Show>
      </ShowFirstMatching>
    </div>
  );
};

interface StepperProps {
  getStepperItemState: (step: Step) => StepperItemState;
}

const STEPS = new Array(3).fill(0);

export const Stepper: FC<StepperProps> = ({ getStepperItemState }) => {
  return (
    <div className="stepper-container relative flex w-64 justify-between text-gray-300">
      {STEPS.map((_, idx) => (
        <StepperItem {...getStepperItemState(idx + 1)} key={`stepper-item-${idx}`} />
      ))}
    </div>
  );
};
