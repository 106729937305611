import type { ResourceBlock } from '@root/@types/base';
import { upliftVariation } from '@root/helpers/variations';
import { isEmpty } from 'lodash-es';

export const isBlockContentEmpty = <T extends Pick<ResourceBlock, 'selectedVariationId' | 'variations' | 'content'>>(
  block: T | undefined,
) => {
  if (!block) {
    return true;
  }

  const parsedValues = upliftVariation(block, block.selectedVariationId);
  const { content } = parsedValues;

  return isEmpty(content) || content === '<p></p>';
};
