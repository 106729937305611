import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import Tooltip from '@root/src/components/Tooltip';
import { cva } from 'class-variance-authority';

import { controlClasses } from '../classes';
import type { CustomOptionProps, InlineOptionProps, Option } from '../types';

import { OptionMapper } from './OptionMapper';
import { TooltipIcon } from './TooltipIcon';

const groupContainerClasses = cva(['relative', 'flex', 'cursor-pointer', 'whitespace-nowrap', 'text-left'], {
  variants: {
    direction: {
      column: ['flex-col', 'justify-start', 'items-stretch'],
      row: ['flex-row', 'items-center', 'gap-2'],
    },
  },
});

const popoverPanelClasses = cva(['absolute', 'z-50', 'px-4', 'mt-3', 'top-5'], {
  variants: {
    align: {
      left: ['-left-3'],
      right: ['-right-3'],
      center: ['left-1/2', '-translate-x-1/2'],
    },
  },
});

interface ControlGroupProps {
  title: string;
  isDisabled?: boolean;
  options: Option[];
  disableDynamicIcon?: boolean;
  direction?: 'column' | 'row';
  align?: 'left' | 'right' | 'center';
  ignoreActiveOptions?: string[];
  icon: InlineOptionProps['icon'];
}

export const ControlGroup = ({
  direction = 'column',
  options,
  isDisabled = false,
  title,
  disableDynamicIcon = false,
  ignoreActiveOptions = [],
  align = 'left',
  ...props
}: ControlGroupProps) => {
  const optsForActiveState = options.filter(
    (o) => !ignoreActiveOptions.includes(o.key) && !(o as CustomOptionProps).custom,
  ) as InlineOptionProps[];
  const active = optsForActiveState.find((o) => o.isActive);
  const isActive = !!active;

  let icon = props.icon;

  if (props.icon && active?.icon && !disableDynamicIcon) {
    icon = active.icon;
  }

  return (
    <Popover className="relative flex content-center">
      <Tooltip content={title}>
        <Popover.Button
          disabled={isDisabled}
          className={controlClasses({ isActive, isDisabled, className: ['flex', 'gap-1', 'items-center'] })}
        >
          {typeof icon === 'string' ? <TooltipIcon icon={icon} /> : icon}
          <Icon path={mdiChevronDown} size={'18px'} className="-mx-1 -my-1" />
        </Popover.Button>
      </Tooltip>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className={popoverPanelClasses({ align })}>
          <div className="overflow-hidden rounded-lg bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className={groupContainerClasses({ direction })}>
              {options.map((option) => (
                <OptionMapper option={option} isDisabled={isDisabled} key={option.key} />
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
