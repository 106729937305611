import type { FC, ReactNode } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

const iconClass = ['cursor-pointer', 'p-1 hover:bg-info-100', 'rounded-full', 'text-info-600'];

interface EditModeControlsProps {
  onCancel: () => void;
  onConfirm: () => void;
  children?: ReactNode;
  confirmDisabled?: boolean;
}

export const EditModeControls: FC<EditModeControlsProps> = ({ children, onCancel, onConfirm, confirmDisabled }) => (
  <div className="item-tags-edit-mode flex items-center" data-testid="inline-edit-controls">
    {children}
    <div className={clsx(iconClass)} onClick={onCancel} data-testid="inline-edit-cancel" aria-hidden="true">
      <XIcon className="h-4 w-4" />
    </div>
    <div
      className={clsx(iconClass, confirmDisabled && 'pointer-events-none opacity-50')}
      onClick={confirmDisabled ? undefined : onConfirm}
      data-testid="inline-edit-confirm"
      aria-hidden="true"
    >
      <CheckIcon className="h-4 w-4" />
    </div>
  </div>
);
