import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { DynamoDBLibraryBlock, ServerResponse } from '../../@types/types';

export const fetchBlock = async (id: string): Promise<ServerResponse<DynamoDBLibraryBlock>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: `blocks-spa-al/${id}`,
  });

  const response = await axios(networkPayload);
  return response.data;
};
