import type { FC } from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import { getSortedSections } from '@ContractBuilder/utils/get-sorted-sections';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { canChange } from '@root/src/helpers/canChange';

import { Navigation } from '../views/Navigation';

interface NavigationProps {
  activeSectionId?: string;
}

export const NavigationController: FC<NavigationProps> = ({ activeSectionId }) => {
  const isViewingRevisionHistory = useIsRevisionHistory();
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  if (!submission) {
    throw new Error(`Submission not found`);
  }

  const sortedSections = useDeepCompareMemo(
    () =>
      getSortedSections(
        submission.sections
          .filter((section) => !section.deleted_at)
          .map((section) => ({ ...section, blocks: section.blocks.filter((block) => !block.deleted_at) })),
        submission.layout,
      ),
    [submission.layout, submission.sections],
  );
  const operationsDisabled = !canChange(submission?.status) || isViewingRevisionHistory;

  return (
    <Navigation
      activeSectionId={activeSectionId}
      isDragDisabled={operationsDisabled}
      sections={sortedSections}
      submission={submission}
    />
  );
};
