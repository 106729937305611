import { useEntityStore } from '@ContractBuilder/store';
import { useLanguage } from '@src/language';

export const useCurrentRevision = () => {
  const { getContent } = useLanguage({ prefix: 'revision-history' });
  const { submission } = useEntityStore(({ submission }) => ({ submission }));

  const currentRevisionName = getContent('currentRevision');

  const viewedRevisionName = [submission?.revision_name, currentRevisionName].includes(submission?.name)
    ? submission?.name
    : currentRevisionName;
  const isCurrentRevision = viewedRevisionName === currentRevisionName;

  return {
    isCurrentRevision,
    revisionName: viewedRevisionName as string,
  };
};
