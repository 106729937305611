import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import type { JSONSchemaProperties } from '../../../@types/types';
import type { InputTextProps } from '../InputText';
import InputText from '../InputText';

interface InputNumberSchemaProps extends InputTextProps {
  item: JSONSchemaProperties;
  ownKey: string;
}

const InputNumberSchema: React.FC<InputNumberSchemaProps> = ({ item: schema, ownKey, ...props }) => {
  const selector = useMemo(
    () => datapointFieldSelector({ ownKey, isRequired: props.isRequired, errors: props.errors }),
    [ownKey, props.isRequired, props.errors],
  );
  const { value, isIncomplete } = useDatapointsStore(selector);
  return (
    <InputText
      isIncomplete={isIncomplete}
      type={schema.type}
      min={schema.minimum}
      max={schema.maximum}
      value={value}
      {...props}
    />
  );
};

export default InputNumberSchema;
