import React from 'react';
import type { EntityData } from '@ContractBuilder/types';
import ItemTags from '@pages/User/components/ItemTags';
import type { Tag } from '@root/@types/types';
import { isNonNullish } from '@root/helpers';
import type { ColumnHelper } from '@tanstack/react-table';

export const tagsAccessor = <T extends { tags?: string[] }>(
  columnHelper: ColumnHelper<T>,
  tags: Tag[],
  setIsLoading: (isLoading: boolean) => void,
) =>
  columnHelper.accessor(
    (submission) =>
      (submission.tags ?? [])
        .map((tagId) => {
          return tags?.find((tag) => tag.id === tagId)?.label;
        })
        .join(''),
    {
      id: 'tags',
      header: 'Tags',
      enableSorting: false,
      cell: (info) => {
        return (
          <ItemTags
            item={info.row.original as unknown as EntityData}
            tags={tags}
            setIsLoading={setIsLoading}
            queryKey="submissions"
          />
        );
      },
      filterFn: (row, _, filteredTagLabels) => {
        if (!row.original.tags) {
          return false;
        }
        return row.original.tags
          ?.map((tagId) => tags?.find((tag) => tag.id === tagId)?.label)
          ?.filter(isNonNullish)
          ?.some((tagLabel) => filteredTagLabels?.includes(tagLabel));
      },
      meta: {
        filter: {
          placeholder: 'All tags',
          options: tags.map((tag) => tag.label).filter(isNonNullish),
        },
        headingLinkClassName: '!pl-0',
        isStandaloneDataCell: true,
      },
    },
  );
