import { sanitizeHtml } from '@helpers/sanitizeHtml';
import { isNonNullish } from '@root/helpers';
import { parse } from 'node-html-parser';

export const replaceMention = (
  id: string,
  value: string | number,
  content?: string,
  shouldShowDiff = false,
  /** Whether the changes should be populated to the global `document` object or contained within the resulting output **/
  immutable = false,
  isRemoved = false,
): string | undefined => {
  if (!isNonNullish(value)) {
    return restoreMention(id, content, immutable);
  }

  const isVirtualDOM = typeof document === 'undefined' || immutable;
  const doc = isVirtualDOM ? parse(content ?? '') : document;

  const mentionsArePresent = doc.querySelectorAll('[data-type="mention"]').length > 0;

  if (!mentionsArePresent) {
    return content;
  }

  const element = doc.querySelectorAll(`[data-id="${id}"]`);

  if (element) {
    element.forEach((el) => {
      if (el.innerHTML === String(value)) {
        if (shouldShowDiff) {
          el.classList.add('show-changes');
        }
        return;
      }

      if (typeof value === 'string' && value?.includes('<ul>')) {
        el.innerHTML = String(sanitizeHtml(value));
        el.classList.add('mention-list');
      } else {
        el.textContent = String(value);
      }
      el.classList.remove('mention');
      el.classList.add('mention-data');

      if (shouldShowDiff && isRemoved) {
        el.classList.add('mention-data-removed');
      }
    });
  }

  return isVirtualDOM ? doc.toString() : '';
};

export const restoreMention = (id: string, content?: string, immutable = false): string | undefined => {
  const isVirtualDOM = typeof document === 'undefined' || immutable;
  const doc = isVirtualDOM ? parse(content ?? '') : document;

  const element = doc.querySelectorAll(`[data-id="${id}"]`);
  const lastSegment = id.split('.').pop();

  if (element) {
    element.forEach((el) => {
      el.textContent = String(`{{${lastSegment}`);
      el.classList.add('mention');
      el.classList.remove('mention-data');
    });
  }

  return isVirtualDOM ? doc.toString() : '';
};
