import type { FC, KeyboardEventHandler } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import IconMdi from '@components/IconMdi';
import type { InputProps } from '@components/Input';
import Input from '@components/Input';
import { mdiClockOutline } from '@mdi/js';
import { formatTime, isNonNullish } from '@root/helpers';
import type { UnitType } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { v4 as uuid } from 'uuid';

import { getNewInputValueFromEvent } from '../InputCalendar/utils/get-new-input-value-from-event';

import { getParsedValue } from './utils/get-parsed-value';
import { isNavigationKey } from './utils/is-navigation-key';
import { useScrollToActiveTimeLabel } from './utils/use-scroll-to-active-time-label';
import { classes } from './classes';
import { HOURS, MINUTES, TIME_FORMAT } from './constants';

dayjs.extend(customParseFormat);

const formatTimeItemLabel = (value: number) => value.toString().padStart(2, '0');

interface InputTimeProps extends Omit<InputProps, 'onChange' | 'value'> {
  onChange: (value: string | undefined) => void;
  value?: string;
}

export const InputTime: FC<InputTimeProps> = ({ onChange, value, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const hrsContainerRef = useRef<HTMLUListElement>(null);
  const minsContainerRef = useRef<HTMLUListElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const parsedValue = useMemo(() => getParsedValue(value), [value]);
  const formattedValue = parsedValue ? formatTime(value) : undefined;
  const hasValue = isNonNullish(value);

  const internalId = useMemo(() => uuid(), []);

  useScrollToActiveTimeLabel({
    id: internalId,
    isOpen,
    hrsContainerRef,
    minsContainerRef,
    parsedValue,
  });

  const handleInputChange: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (!/[0-9:]/.test(event.key) && !isNavigationKey(event.key)) {
      return;
    }

    const newValue = getNewInputValueFromEvent(event);
    const currentInputValue = event.currentTarget.value;
    const currentInputLength = currentInputValue.length;
    const newInputLength = newValue.length;

    if (currentInputLength === 5 && !isNavigationKey(event.key)) {
      return event.preventDefault();
    }

    if (newInputLength === 2 && !isNavigationKey(event.key)) {
      event.currentTarget.value = `${newValue}:`;
      return event.preventDefault();
    }

    if (newInputLength === 5) {
      const newValue = getNewInputValueFromEvent(event);
      const date = dayjs(newValue, 'HH:mm');

      onChange(date.format(TIME_FORMAT));
      return event.preventDefault();
    }
  };

  const handleTimeItemClick = (unit: UnitType, nextValue: number) => {
    const newDate = value ? dayjs(value, TIME_FORMAT) : dayjs();
    const date = newDate.set(unit, nextValue);
    onChange?.(date.format(TIME_FORMAT));
  };

  const handleClearTime = () => {
    onChange?.(undefined);
  };

  useClickAway(ref, () => setIsOpen(false));
  const handleFocus = () => setIsOpen(true);

  return (
    <div className="relative" ref={ref}>
      <div className="relative flex items-center" key={value}>
        <Input
          {...props}
          data-testid="time"
          onFocus={handleFocus}
          onKeyDown={handleInputChange}
          defaultValue={formattedValue}
        />
        <div className="absolute right-2 top-0 flex h-10 w-fit items-center">
          {hasValue ? (
            <CloseIconButton data-testid="time-input-clear" onClick={handleClearTime} />
          ) : (
            <IconMdi path={mdiClockOutline} className="mr-0.5 fill-current text-info-800" size={0.65} />
          )}
        </div>
      </div>
      <div
        className={`absolute left-0 top-full z-10 mt-1 inline-block overflow-scroll rounded-lg bg-white p-0 text-sm font-light text-info-500 drop-shadow-xl ${
          !isOpen && 'hidden'
        }`}
        tabIndex={-1}
      >
        {isOpen && (
          <div className="flex max-h-[228px] w-[128px]">
            <ul className={classes.list} ref={hrsContainerRef}>
              {HOURS.map((hour) => (
                <li
                  className={classes.listItem({ isSelected: parsedValue?.hour() === hour })}
                  id={`${internalId}-hour-${hour}`}
                  key={`${internalId}-hour-${hour}`}
                  onClick={() => handleTimeItemClick('hour', hour)}
                  onKeyDown={() => handleTimeItemClick('hour', hour)}
                >
                  {formatTimeItemLabel(hour)}
                </li>
              ))}
            </ul>
            <ul className={classes.list} ref={minsContainerRef}>
              {MINUTES.map((minute) => (
                <li
                  className={classes.listItem({ isSelected: parsedValue?.minute() === minute })}
                  id={`${internalId}-minute-${minute}`}
                  key={`${internalId}-minute-${minute}`}
                  onClick={() => handleTimeItemClick('minute', minute)}
                  onKeyDown={() => handleTimeItemClick('minute', minute)}
                >
                  {formatTimeItemLabel(minute)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
