import axios from 'axios';

import { getRequestAuthPayload } from '../helpers/getRequestAuth';

const MRC_DOCUMENT_UPLOAD_URI = 'data-extraction';
const SUBMISSIONS_URI = 'submissions-spa-al';

export const updateMRCAdapters = async (submissionId: string, extractionId: string, data: any): Promise<string> => {
  const url = [SUBMISSIONS_URI, submissionId, MRC_DOCUMENT_UPLOAD_URI, extractionId, 'adapters']
    .filter(Boolean)
    .join('/');

  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
