import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

export const cxInputMatrixRow = cva(['grid gap-4 items-start'], {
  variants: {
    columns: {
      1: 'grid-cols-1',
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
      6: 'grid-cols-6',
      7: 'grid-cols-7',
      8: 'grid-cols-8',
      9: 'grid-cols-9',
      10: 'grid-cols-10',
      11: 'grid-cols-11',
      12: 'grid-cols-12',
    },
  },
  defaultVariants: {
    columns: 3,
  },
});

export const cxInputMatrixElement = cva([], {
  variants: {
    span: {
      1: 'col-span-1',
      2: 'col-span-2',
      3: 'col-span-3',
      4: 'col-span-4',
      5: 'col-span-5',
      6: 'col-span-6',
      7: 'col-span-7',
      8: 'col-span-8',
      9: 'col-span-9',
      10: 'col-span-10',
      11: 'col-span-11',
      12: 'col-span-12',
      all: 'col-span-full',
    },
  },
  defaultVariants: {
    span: 1,
  },
});

export type cxInputMatrixRowArgs = VariantProps<typeof cxInputMatrixRow>;
export type cxInputMatrixElementArgs = VariantProps<typeof cxInputMatrixElement>;
