import type { FC } from 'react';
import { FeatureIndicatorIcon } from '@components/FeatureIndicatorIcon';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { blockViewBodyClasses, indicatorIconsClasses } from '@ContractBuilder/modules/block/classes';
import { BlockContent } from '@ContractBuilder/modules/block-content';
import { useBlockLibrary } from '@hooks/useBlockLibrary';
import { mdiFormatPageBreak, mdiPaletteSwatchVariant } from '@mdi/js';
import { isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface BlockPreviewProps {
  id: string;
}

export const BlockPreview: FC<BlockPreviewProps> = ({ id }) => {
  const { data: block, isFetching } = useBlockLibrary(id);
  const { variations, shouldInsertPageBreak, usedInTemplates } = block || {};
  const isTemplate = isTemplatePath();
  const variationsCount = variations?.length ?? 0;
  const showUsedInTemplatesControls = isTemplate && usedInTemplates;
  const showIcons = variationsCount || shouldInsertPageBreak || showUsedInTemplatesControls;

  const blockClasses = twMerge(
    blockViewBodyClasses({
      pdf: true,
      disabled: true,
      highlighted: false,
      className: ['!p-0', '-mx-5'],
    }),
  );

  return (
    <>
      <LoadingOverlay active={isFetching} />
      <Show when={!!block}>
        <div className="cb-wysiwyg group prose-sm pointer-events-none relative !cursor-default border-b bg-white py-4">
          <div className={twMerge('flex flex-col')}>
            <div className={blockClasses}>
              <BlockContent block={block!} isVisible isPdfRender />
              {showIcons && (
                <div className={clsx(indicatorIconsClasses)}>
                  {!!variationsCount && (
                    <FeatureIndicatorIcon
                      path={mdiPaletteSwatchVariant}
                      content={`${variationsCount} variations`}
                      data-testid="block-icon-indicator:variations"
                    />
                  )}
                  {shouldInsertPageBreak && (
                    <FeatureIndicatorIcon
                      path={mdiFormatPageBreak}
                      content={`Page break after block`}
                      data-testid="block-icon-indicator:page-break"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Show>
    </>
  );
};
