import { Fragment } from 'react';
import { LOADING_OVERLAY_Z_INDEX } from '@constants/z-indices';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Spinner } from './Spinner';

export interface LoadingOverlayProps {
  active?: boolean;
  className?: string;
  onClick?: any;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  transparent?: boolean;
}

const animation = clsx('duration-300', 'ease-in-out', 'transition-opacity');

const LoadingOverlay = ({ active, className, size, transparent = false, ...props }: LoadingOverlayProps) => {
  return (
    <Transition
      show={!!active}
      as={Fragment}
      unmount={false}
      enter={animation}
      leave={animation}
      enterTo="opacity-100"
      enterFrom="opacity-0"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        data-testid="loading-overlay"
        onClick={props.onClick}
        onKeyDown={() => {}}
        className={clsx(
          'absolute',
          'inset-0',
          LOADING_OVERLAY_Z_INDEX,
          'h-full',
          'w-full',
          'text-clip',
          'select-none',
          'flex',
          'items-center',
          'justify-center',
          !transparent && 'bg-black/50',
          className,
        )}
      >
        <Spinner size={size} />
      </div>
    </Transition>
  );
};
export default LoadingOverlay;
