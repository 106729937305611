import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const updateTag = async (id: string, data: Pick<Tag, 'label'>): Promise<Pick<Tag, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `tags-spa-al/${id}`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
