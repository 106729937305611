import type { FC, PropsWithChildren, ReactNode } from 'react';

interface ContractBuilderBaseLoadingProps {
  headerRow?: ReactNode;
}

export const ContractBuilderBaseLoading: FC<PropsWithChildren<ContractBuilderBaseLoadingProps>> = ({
  children,
  headerRow,
}) => {
  return (
    <div role="status" className="flex h-screen w-full flex-col bg-white" data-testid="loading-overlay">
      <div className="mb-1 flex animate-pulse items-center justify-between px-5 pb-6 pl-2 pt-3">
        <div className="flex flex-col gap-y-2">
          <div className="flex">
            <div className="h-4 w-[220px] rounded-full bg-info-200" />
          </div>
          <div className="flex items-center gap-x-1">
            <div className="h-3 w-12 rounded-full bg-info-200" />
            <div className="h-3 w-20 rounded-full bg-info-200" />
            <div className="h-3 w-36 rounded-full bg-info-200" />
          </div>
        </div>
        <div className="flex translate-x-[68px] items-center gap-x-1">
          <div className="h-8 w-[103px] rounded-md bg-info-200"></div>
          <div className="h-8 w-[126px] rounded-md bg-info-200"></div>
        </div>
        <div className="flex items-center gap-x-2.5">
          <div className="h-9 w-44 rounded-md bg-info-100" />
          <div className="h-9 w-36 rounded-md bg-info-100" />
          <div className="h-8 w-8 rounded-circle bg-info-100" />
        </div>
      </div>
      <div className="border-b border-info-200"></div>
      {headerRow}
      <div className="relative flex h-full w-full overflow-hidden overflow-y-hidden bg-info-50">{children}</div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
