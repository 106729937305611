import type { FC } from 'react';
import type { PanelPlacement } from '@ContractBuilder/hooks';
import { DataExtractionPDFPreviewController } from '@ContractBuilder/modules/data-extraction-pdf-preview';
import type { DataExtractionData } from '@root/@types/types';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import {
  animationProps,
  dataExtractionSplitPaneClasses,
  dataExtractionSplitPaneHeight,
  fileNameWrapperClasses,
} from './classes';

interface DataExtractionSplitPaneProps {
  dataExtractionItem?: DataExtractionData;
  fileName?: string;
  fileUrl?: string;
  onDownload: () => Promise<void>;
  pageWidth: number;
  placement: PanelPlacement;
  width: number;
}

export const DataExtractionSplitPane: FC<DataExtractionSplitPaneProps> = ({
  dataExtractionItem,
  fileName = 'Data Extraction Document',
  fileUrl,
  onDownload,
  pageWidth,
  placement,
  width,
}) => {
  return (
    <motion.div
      {...animationProps}
      className={clsx(dataExtractionSplitPaneClasses)}
      style={{ width, height: dataExtractionSplitPaneHeight }}
    >
      <div className="flex items-center text-gray-500">
        <button className={clsx(fileNameWrapperClasses)}>
          <p className="truncate text-xs font-normal leading-5 text-gray-600">{fileName}</p>
        </button>
      </div>
      <DataExtractionPDFPreviewController
        dataExtractionItem={dataExtractionItem}
        fileUrl={fileUrl}
        onDownload={onDownload}
        pageWidth={pageWidth}
        placement={placement}
      />
    </motion.div>
  );
};
