import { useCallback } from 'react';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { useUIStore } from '@ContractBuilder/store/ui.store';

export const useSetFocussedCandidate = () => {
  const dispatch = useDispatchCustomEvent();
  const togglePreview = useUIStore(({ togglePDFPreview }) => togglePDFPreview);

  return useCallback(
    async (candidateId?: string) => {
      const isOpen = useUIStore.getState().isPDFPreviewOpen;
      if (!isOpen) {
        togglePreview();
      }

      return dispatch(ArtificialCustomEvent.SetDocumentFocussedCandidate, candidateId);
    },
    [dispatch, togglePreview],
  );
};
