import type { ResolvedSchemaField, ResolvedSchemaTree } from './types';

export type SchemaTreeToFlatListResult = Omit<ResolvedSchemaField, 'children' | 'type'> & {
  type?: string;
};

export const schemaTreeToFlatList = (
  fields: ResolvedSchemaTree[],
  shouldIncludeParentNodes = false,
): SchemaTreeToFlatListResult[] => {
  return fields.flatMap(({ children, ...item }) => {
    if (!children?.length) {
      return item;
    }

    if (shouldIncludeParentNodes) {
      return [item, ...children.flatMap((child) => schemaTreeToFlatList([child], shouldIncludeParentNodes))];
    }

    return children.flatMap((child) => schemaTreeToFlatList([child], shouldIncludeParentNodes));
  });
};
