import { useEffect, useState } from 'react';
import { useEffectOnce } from '@hooks/useEffectOnce';
import { insertNewOptions } from '@pages/User/components/prepareDropdownOptions';

import type { Filter, Tag } from '../../@types/types';
import { fetchTags } from '../queries';

export const useTags = (filters: Filter[], setFilters: (filters: Filter[]) => void, isEnabled: boolean): Tag[] => {
  const [tagsLibraryData, setTagsLibraryData] = useState<Tag[]>([]);

  useEffectOnce(() => {
    (async () => {
      try {
        if (!isEnabled) {
          return;
        }

        const tagsData = await fetchTags();
        tagsData?.sort((a, b) => {
          return a.label.trim() > b.label.trim() ? 1 : -1;
        });

        setTagsLibraryData(tagsData);
      } catch (_error) {
        // Swallow the error for now
      }
    })();
  });

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    const filtersWithTagsOptions = insertNewOptions(
      filters,
      tagsLibraryData.map((i) => i.label),
      'tags',
    );
    setFilters([...filtersWithTagsOptions]);
    // eslint-disable-next-line -- run only when `tagsLibraryData` changes
  }, [tagsLibraryData]);

  return tagsLibraryData;
};
