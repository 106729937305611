import { ContractEndorsementBannerLoading } from '@ContractBuilder/modules/contract-endorsement-banner';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { DocumentMode } from '@root/@types/types';

import { NavigationLoading } from '../../navigation';

import { ContractBuilderBaseLoading } from './ContractBuilderBaseLoading';

export const ContractBuilderContractViewLoading = () => {
  const { mode } = getDocumentMode(location.pathname);
  const isEndorsement = mode === DocumentMode.ENDORSEMENTS;

  return (
    <ContractBuilderBaseLoading headerRow={isEndorsement && <ContractEndorsementBannerLoading />}>
      <>
        <div className="w-1/3 max-w-drawer bg-white">
          <div className="flex h-[50px] animate-pulse flex-col gap-y-2 px-2 py-3">
            <div className="flex justify-between">
              <div className="h-3 w-1/2 rounded-full bg-info-200" />
              <div className="rounded-sm-border h-4 w-4 self-center"></div>
            </div>
            <div className="flex items-center">
              <div className="mr-2 h-2 w-12 rounded-full bg-info-200" />
              <div className="h-2 w-12 rounded-full bg-info-200" />
            </div>
          </div>
          <div className="mb-2 border-b border-info-200"></div>
          <NavigationLoading />
        </div>
        <div className="grid grow items-center">
          <div className="page-A4-width mx-8 flex h-[90%] animate-pulse flex-col gap-y-5 bg-white px-5 py-5 shadow-lg 2xl:mx-44 3xl:mx-auto">
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-full rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-full rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
          </div>
        </div>
      </>
    </ContractBuilderBaseLoading>
  );
};
