import type { FC } from 'react';
import React, { Fragment, memo } from 'react';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { getTemplatesUrl } from '@ContractBuilder/modules/block-edit/views/BlockUsedInTemplatesModal';
import type { UsedInTemplates } from '@ContractBuilder/types';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { mdiOpenInNew } from '@mdi/js';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';

interface BlockUsedWithinPublishedProps {
  isOpen: boolean;
  onCancel: () => void;
  templateId: string;
  message: string;
  usedInPublishedTemplates: UsedInTemplates;
}

export const BlockUsedWithinPublished: FC<BlockUsedWithinPublishedProps> = memo(function BlockUsedWithinPublihed({
  isOpen,
  onCancel,
  templateId,
  message,
  usedInPublishedTemplates,
}) {
  const templatesUrl = getTemplatesUrl();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className={clsx('fixed inset-0', MODAL_Z_INDEX)} onClose={onCancel}>
        <div
          className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
          data-testid="block-used-in-templates-modal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={onCancel}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-error-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-error-600" aria-hidden="true" />
                </div>
                <div className="mt-3 flex flex-col gap-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    {message}
                  </Dialog.Title>
                  <ul role="list" className="flex max-h-96 flex-col gap-5 overflow-y-auto">
                    {usedInPublishedTemplates
                      ?.filter(({ id }) => id !== templateId)
                      ?.map(({ id, name }) => (
                        <li key={id} className="group relative flex justify-between bg-white pl-0 pr-8">
                          <a
                            href={`${templatesUrl}/${id}/contract`}
                            target="_blank"
                            className="mr-1 inline-block max-w-[220px] cursor-pointer truncate text-sm font-medium leading-[17px] text-primary-500 hover:underline group-hover:text-primary-600"
                            rel="noreferrer"
                          >
                            {name}
                          </a>
                          <span className="relative">
                            <Icon
                              className="absolute top-[-1px] inline-block text-primary-500 group-hover:text-primary-600"
                              path={mdiOpenInNew}
                              size={0.7}
                            />
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}, isEqual);
