import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import { cloneDeep, get, set } from 'lodash-es';

import type { GenericObject, UIMapChildrenFn } from '../../../@types/types';
import pickCommaSeparate from '../../helpers/pickCommaSeparate';

import type { InputSelectSchemaProps } from './InputSelect';
import InputSelectSearchable from './InputSelectSearchable';

type Entity = GenericObject & {
  id: string;
  name: string;
  address: GenericObject<string>;
};

interface InputSelectApiConnectedProps extends InputSelectSchemaProps {
  ownKey: string;
  mapChildren: UIMapChildrenFn;
}

const InputSelectApiConnected = ({ ownKey, ...props }: InputSelectApiConnectedProps) => {
  const queryClient = useQueryClient();
  const entityData: Entity[] = get(queryClient.getQueryData(['brokers']), 'data', []);
  const { errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { formValues, setFormValues, isIncomplete } = useDatapointsStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
    ...selector({ formValues }),
  }));

  const handleSelectChange = (id: string) => {
    const clonedFormValues = cloneDeep(formValues);
    const entity = entityData.find((e: Entity) => e.id === id);
    set(clonedFormValues, ownKey, entity?.id);
    setFormValues(clonedFormValues);
  };

  const itemFormatter = (value: string) => {
    const entity = entityData.find((e) => e.id === value);
    const addressElements = ['addressLine1', 'addressLine2', 'city', 'country', 'postCode'];

    return entity ? (
      <div>
        <div>{entity.name}</div>
        <div className="text-sm text-info-700">{pickCommaSeparate(entity.address, addressElements)}</div>
      </div>
    ) : null;
  };

  const formatter = (value: string): string | undefined => {
    const entity = entityData.find((e) => e.id === value);
    return entity?.name;
  };

  return (
    <InputSelectSearchable
      {...props}
      isIncomplete={isIncomplete}
      item={{ enum: entityData.map((entity) => ({ name: entity.name, value: entity.id })) }}
      onChange={handleSelectChange}
      formatter={formatter}
      itemFormatter={itemFormatter}
    />
  );
};

export default InputSelectApiConnected;
