import type { FC } from 'react';
import { Page } from 'react-pdf';

import type { PageItem } from '../types';

import { BoundingBox } from './BoundingBox';

interface StrictWrapperProps {
  focussedCandidate?: string;
  index: number;
  item: PageItem;
  pageWidth: number;
}

export const StrictWrapper: FC<StrictWrapperProps> = ({ focussedCandidate, index, item, pageWidth }) => {
  return (
    <div data-testid={`mrc-page-${index}`} id={`mrc-page-index-${index}`} className="relative">
      <Page pageNumber={index + 1} width={pageWidth} />
      {item.candidates?.map((candidate: any) => {
        return (
          <BoundingBox
            key={candidate.id}
            id={candidate.id}
            boundingBox={candidate.boundingBox}
            focussed={candidate.id === focussedCandidate}
          />
        );
      })}
    </div>
  );
};
