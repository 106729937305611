import { omit } from 'lodash-es';

export const sanitizeInputProps = (props: Record<string, unknown>) =>
  omit(props, [
    'MakeComponent',
    'hideTitle',
    'iconPosition',
    'isClearable',
    'isHidden',
    'isSearchable',
    'keyFormValues',
    'hideErrors',
    'parentKey',
    'parentSchema',
    'placeholderText',
    'showQuestionId',
    'showQuestionKey',
    'startCollapsed',
    'textTransform',
    'toggleDependsOn',
    'validationErrors',
  ]);
