import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';

import type { JSONSchemaEnum } from '../../../@types/types';
import type { InputSelectProps } from '../InputSelect';
import InputSelect from '../InputSelect';

export interface InputSelectSchemaProps extends InputSelectProps {
  item: {
    helperText?: string;
    shortName?: string;
    enum: JSONSchemaEnum[];
  };
  ownKey: string;
}

const InputSelectSchema: React.FC<InputSelectSchemaProps> = ({ item, ...props }) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  return (
    <InputSelect
      {...props}
      isIncomplete={isIncomplete}
      helperText={item?.helperText}
      shortName={item?.shortName}
      options={item?.enum?.map((value) =>
        typeof value === 'object' ? { name: value.name, value: value.value } : { name: value, value },
      )}
      value={value}
    />
  );
};

export default InputSelectSchema;
