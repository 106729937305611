import { CognitoLogout } from './CognitoLogout';
import { EntraLogout } from './EntraLogout';

const getLogout = () => {
  const authMethod = import.meta.env.VITE_AUTH_METHOD;

  switch (authMethod) {
    case 'entra':
      return EntraLogout;
    default:
      return CognitoLogout;
  }
};

const Logout = getLogout();
export default Logout;
