import type { FC } from 'react';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { insertSectionRepeaterContent, resolveSchema } from '@root/helpers/schema';
import { getDataType } from '@root/helpers/schema/repeaters/get-data-type';
import { getRepeaterTitle } from '@root/helpers/schema/repeaters/get-repeater-title';
import { cloneDeep, differenceBy } from 'lodash-es';

import type { RenderExpandedProps } from '../InsertMenu/components/types';

import { DatapointsInserterGroup } from './DatapointsInserterGroup';

interface DatapointsInserterExpandedProps extends RenderExpandedProps {
  editor: any;
  forceCollapsed?: boolean;
  forceExpanded?: boolean;
  onClose: () => void;
  titleTextClasses?: string;
  sectionRepeaterTitleTextClasses?: string;
  disableMotion?: boolean;
}

export const DatapointsInserterExpanded: FC<DatapointsInserterExpandedProps> = ({
  editor,
  forceCollapsed = false,
  onClose,
  renderItem,
  search,
  titleTextClasses,
  sectionRepeaterTitleTextClasses,
  disableMotion = false,
  forceExpanded = false,
}) => {
  const submission = useEntityStore(({ submission }) => submission);
  const { schema, schemaTreeList, schemaTreeListWithConditionalFields } = useSchemaStore(
    ({ schema, schemaTreeList, schemaTreeListWithConditionalFields, schemaTreeWithConditionalFields }) => ({
      schema,
      schemaTreeList,
      schemaTreeListWithConditionalFields,
      schemaTreeWithConditionalFields,
    }),
  );

  const resolvedOriginalSchema = resolveSchema(cloneDeep(schema), {}, true);

  const conditionalFields = differenceBy(schemaTreeListWithConditionalFields ?? [], schemaTreeList ?? [], 'id');

  const handleSectionRepeaterInsert = (item: any, path: string) => {
    const formValues = submission?.data_items ?? {};

    const dataType = getDataType(item);

    onClose();

    item.id = path;

    return insertSectionRepeaterContent(
      editor,
      item,
      {
        'data-type': dataType,
        'data-parentid': path,
        getTitle: getRepeaterTitle(item, formValues),
      },
      search,
    );
  };

  const childrenOriginal = Object.entries(resolvedOriginalSchema?.properties?.SubmissionForm?.properties).map(
    ([key, value]: [string, any]) => ({ ...value, id: key }),
  );

  return (
    <div>
      {childrenOriginal
        .sort((a, b) => (a.qid ?? Infinity) - (b.qid ?? Infinity))
        .map((item) => (
          <div className="datapoints-inserter-expanded" key={item.id}>
            <DatapointsInserterGroup
              forceCollapsed={forceCollapsed}
              forceExpanded={forceExpanded}
              conditionalFields={conditionalFields}
              level={0}
              onSectionRepeaterInsert={handleSectionRepeaterInsert}
              renderItem={renderItem}
              schema={item}
              searchText={search}
              titleTextClasses={titleTextClasses}
              sectionRepeaterTitleTextClasses={sectionRepeaterTitleTextClasses}
              path={item.id}
              disableMotion={disableMotion}
            />
          </div>
        ))}
    </div>
  );
};
