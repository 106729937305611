import React, { useMemo } from 'react';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { sanitizeInputProps } from '@ContractBuilder/utils';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import type { JSONSchemaProperties } from '@root/@types/schema';
import { get } from 'lodash-es';

import type { InputTextProps } from '../InputText';
import InputText from '../InputText';

type SchemaType = undefined | 'text' | 'number' | 'object' | 'array';

interface InputTextSchemaProps extends InputTextProps {
  item: JSONSchemaProperties;
  ownKey: string;
}

const getInputType = (schema: JSONSchemaProperties): SchemaType => {
  if ((schema.type === 'string' || schema.type === 'number') && 'enum' in schema) {
    return undefined;
  }

  if (schema.type === 'string') {
    return 'text';
  }

  return schema.type;
};

const InputTextSchema: React.FC<InputTextSchemaProps> = ({ item: schema, ...props }) => {
  const { ownKey, errors, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value, isIncomplete } = useDatapointsStore(selector);

  const parsedProps = sanitizeInputProps(props);
  const shouldHideTitle = get(schema, 'ui:hideTitle', false);
  const handleChange = (event: any) => {
    const parsedPropsOnChange = parsedProps.onChange as (...args: any) => void;
    const textTransform = get(schema, 'ui:textTransform', '');
    let value;
    switch (textTransform) {
      case 'uppercase':
        value = event.target.value.toUpperCase();
        break;
      case 'lowercase':
        value = event.target.value.toLowerCase();
        break;
      case 'capitalize':
        value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        break;
      default:
        value = event.target.value;
    }
    parsedPropsOnChange(value === '' ? undefined : value, event.target.name);
  };

  return (
    <InputText
      {...parsedProps}
      isIncomplete={isIncomplete}
      type={getInputType(schema)}
      min={schema.minimum}
      minLength={schema.minLength}
      max={schema.maximum}
      maxLength={schema.maxLength}
      placeholder={schema.placeholderText}
      shouldHideTitle={shouldHideTitle}
      value={value}
      hideErrors={props.hideErrors}
      onChange={handleChange}
    />
  );
};

export default InputTextSchema;
