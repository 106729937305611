import { Show } from '@components/Show';
import { cva } from 'class-variance-authority';
import { isEmpty } from 'lodash-es';

import { useBrandingTemplateStore } from './branding-templates.store';

const brandingTemplateClasses = cva(
  [
    'flex cursor-pointer items-center justify-start px-3 py-4',
    'hover:bg-primary-50 micro-interactions',
    'text-info-700',
  ],
  {
    variants: {
      isSelected: {
        true: ['bg-primary-100/50', 'text-primary-500'],
        false: [],
      },
    },
  },
);

export const InputSelectBranding = () => {
  const { selectedBrandingId, setSelectedBrandingId, getFilteredBrandingTemplates } = useBrandingTemplateStore(
    (s) => s,
  );
  const filtered = getFilteredBrandingTemplates();
  return (
    <div className="flex h-48 w-full flex-col overflow-y-auto">
      {filtered?.map(({ id, name }) => (
        <div
          className={brandingTemplateClasses({ isSelected: id === selectedBrandingId })}
          key={id}
          onClick={() => setSelectedBrandingId(id)}
        >
          <div className="text-sm font-normal leading-tight ">{name}</div>
        </div>
      ))}

      <Show when={isEmpty(filtered)}>
        <div className="flex h-full w-full items-center justify-center text-center text-sm leading-tight text-gray-400">
          No branding found
        </div>
      </Show>
    </div>
  );
};
