import type { ReactNode } from 'react';
import { Children } from 'react';
import type { DropdownProps } from 'react-day-picker';
import { useDayPicker } from 'react-day-picker';
import clsx from 'clsx';
import { useSelect } from 'downshift';
import { get } from 'lodash-es';

const getParsedOptionsFromChild = (child: ReactNode) => {
  const label = get(child, 'props.children', '');
  const value = get(child, 'props.value', '');

  return {
    label,
    value,
  };
};

export const InputCalendarDropdownComponent = (props: DropdownProps) => {
  const { onChange, name, value, children, caption } = props;
  const dayPicker = useDayPicker();

  const parsedOptions = Children.map(children, getParsedOptionsFromChild) ?? [];

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: parsedOptions,
    selectedItem: parsedOptions.find((item) => item.value === value),
    onSelectedItemChange: ({ selectedItem }) => {
      // @ts-expect-error
      return onChange?.({ target: { name, value: selectedItem.value } });
    },
  });

  return (
    <div>
      <div>
        <div
          className={clsx(dayPicker.classNames.caption_label, 'cursor-pointer')}
          style={dayPicker.styles.caption_label}
          {...getToggleButtonProps()}
        >
          <span>{caption}</span>
          <span className="pl-2">{isOpen ? <>&#8593;</> : <>&#8595;</>}</span>
        </div>
      </div>
      <ul
        className={`absolute z-10 inline-block max-h-80 overflow-scroll rounded-lg bg-white p-0 text-sm font-light text-info-500 drop-shadow-xl ${
          !isOpen && 'hidden'
        }`}
        style={dayPicker.styles.dropdown}
        {...getMenuProps()}
      >
        {isOpen &&
          parsedOptions?.map((item, index) => {
            return (
              <li
                className={clsx(
                  highlightedIndex === index && 'bg-info-100',
                  value === item.value && 'font-bold',
                  'flex cursor-default flex-col px-3 py-2 shadow-sm transition-colors',
                )}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
