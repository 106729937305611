import { type FC } from 'react';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import type { NamedVisibilityCondition } from '@hooks/use-block-visibility-named-reasons';
import { mdiCheck } from '@mdi/js';
import type { BlockVisibilityOperators } from '@root/@types/types';
import { isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';

import { quoted, toDisplayValue } from './utils';

interface VisibilityReasonEntryProps {
  reason: NamedVisibilityCondition;
  isFirst: boolean;
  match?: string;
}

const VALUED_OPERATORS: BlockVisibilityOperators[] = ['IS', 'IS NOT'];

export const VisibilityReasonEntry: FC<VisibilityReasonEntryProps> = ({
  match,
  isFirst,
  reason: { field, operator, value, result },
}) => {
  const prefix = isFirst ? 'WHERE' : match;
  const isTemplate = isTemplatePath();
  const shouldShowValidationResult = !isTemplate && result;
  const showValue = VALUED_OPERATORS.includes(operator);
  const displayValue = toDisplayValue(value);
  const title = `${prefix} ${quoted(field)} ${operator} ${displayValue}`;

  return (
    <div className={clsx('flex gap-2 ', shouldShowValidationResult && 'text-primary-500')}>
      <div className="truncate" title={title}>
        {prefix} &quot;{field}&quot; {operator}
        <Show when={showValue}>&nbsp;{displayValue}</Show>
      </div>
      <Show when={shouldShowValidationResult}>
        <IconMdi path={mdiCheck} className="text-success-500" />
      </Show>
    </div>
  );
};
