import React from 'react';
import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { InputField, type InputFieldProps } from '@components/InputField';
import clsx from 'clsx';

import Input from './Input';
import InputReadOnly from './InputReadOnly';
import { Show, ShowFirstMatching } from './Show';

export interface InputPercentageProps extends InputFieldProps {
  decimalScale?: number;
  descriptionText?: string;
  id?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: string;
  onChange?: (floatValue: number | undefined, name?: string) => void;
  placeholderText?: string;
  shortName?: string;
  value?: number;
  maximum?: number;
}

const InputPercentage: React.FC<InputPercentageProps> = ({
  cdrId,
  cdrName,
  className,
  decimalScale = 7,
  descriptionText,
  errors,
  id,
  isAllowed,
  isReadOnly,
  isRequired,
  labelIcon,
  labelText,
  mrcId,
  name,
  onChange,
  placeholderText = '0',
  shortName,
  value,
  isIncomplete,
  hideErrors,
  maximum = Number.MAX_SAFE_INTEGER,
  ...props
}) => {
  const handleChange = ({ floatValue }: NumberFormatValues, _sourceInfo: SourceInfo) => {
    if (onChange) onChange(floatValue, name);
  };

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id ?? name}
      isRequired={isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      shortName={shortName}
      isIncomplete={isIncomplete}
      hideErrors={hideErrors}
    >
      <ShowFirstMatching>
        <Show when={isReadOnly}>
          <InputReadOnly {...props} defaultValue={value} />
        </Show>
        <Show when={!isReadOnly}>
          <div className="relative">
            <div className="absolute inset-y-0 right-0 flex w-8 items-center justify-center">%</div>
            <NumericFormat
              allowNegative={false}
              className={clsx('pr-6', isIncomplete && 'field-incomplete')}
              customInput={Input}
              decimalScale={decimalScale}
              errors={errors}
              id={id ?? name}
              isAllowed={isAllowed}
              isRequired={isRequired}
              name={name}
              onValueChange={handleChange}
              placeholder={placeholderText}
              value={value}
              valueIsNumericString
              max={maximum}
            />
          </div>
        </Show>
      </ShowFirstMatching>
    </InputField>
  );
};

export default InputPercentage;
