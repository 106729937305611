import type { FC } from 'react';
import React from 'react';
import { WysiwygEditor } from '@ContractBuilder/components/Wysiwyg';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import clsx from 'clsx';

interface EndorsementModalContentItemProps {
  content: string;
  isActive: boolean;
  isEditable: boolean;
  isSaving: boolean;
  onChange: (value: string) => void;
  onSetActive: () => void;
  title: string;
}

export const ContentItem: FC<EndorsementModalContentItemProps> = ({
  content,
  isActive,
  isEditable,
  isSaving,
  onChange,
  onSetActive,
  title,
}) => {
  return (
    <div className="overflow-y-auto rounded-md py-4">
      <p className="mb-3 text-sm font-medium text-info-800">{title}</p>
      {isActive ? (
        <div className="cb-wysiwyg flex w-full justify-between rounded-lg border-2 border-blue-600">
          <WysiwygEditor
            bodyClassName="!px-0 !pt-0"
            blockType="generic"
            className="h-60 overflow-y-auto p-5"
            content={content}
            enableDatapointsInsert={false}
            focusPosition="end"
            hasEditorUnsavedChanges={true}
            isDisabled={isSaving || !isEditable}
            name="EndorsementSummaryModalWysiwygEditor"
            onUpdate={onChange}
            placeholderText="Enter endorsement summary..."
            toolbarControlsClassName="rounded-lg pl-4 rounded-bl-none rounded-br-none"
            wrapperClassName="rounded-lg"
          />
        </div>
      ) : (
        <div
          className={clsx(
            'relative h-36 cursor-pointer overflow-y-auto rounded-md border border-info-200 p-5 text-sm font-normal text-info-800 transition-colors hover:border-info-100',
            (isSaving || !isEditable) && 'pointer-events-none opacity-50',
          )}
          onClick={onSetActive}
          role="presentation"
          tabIndex={-1}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
        </div>
      )}
    </div>
  );
};
