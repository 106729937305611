import { cva } from 'class-variance-authority';

import sharedClasses from '../../utils/shared-classes';

const { microInteractions, disabled, size_sm, size_lg, size_md } = sharedClasses;

export const toggleGroupContainer = ['!py-0', microInteractions, 'relative', 'inline-flex', 'rounded-md'];

export const toggleGroupButton = cva(
  [
    microInteractions,
    'z-0',
    'bg-white',
    'border-info-300',
    'border-y',
    'focus:border-primary-300',
    'focus:dark:border-primary-400',
    'focus:dark:ring-primary-300',
    'focus:invalid:border-error-500',
    'focus:invalid:ring-error-200',
    'focus:z-10',
    'font-medium',
    'hover:bg-info-100',
    'inline-flex',
    'items-center',
    'relative',
    'text-info-700',
    'text-sm',
    'cursor-pointer',
    'select-none',
    'whitespace-nowrap',
    'bg-info-50',
    'text-info-900',
    'hover:bg-info-100',
  ],
  {
    variants: {
      position: {
        first: ['rounded-l-md', 'border-x', 'z-10'],
        middle: ['border-r'],
        last: ['rounded-r-md', 'border-r'],
      },
      checked: {
        true: ['!bg-primary-500', 'text-white', 'font-bold'],
      },
      disabled: { true: disabled },
      size: {
        sm: ['px-2', 'text-[13px]', 'sm:text-xs', size_sm.height],
        md: ['px-3', 'text-xs', 'sm:text-sm', size_md.height],
        lg: ['px-4', 'text-sm', 'sm:text-base', size_lg.height],
      },
      isIncomplete: { true: 'field-incomplete' },
    },
  },
);
