import type { FC } from 'react';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import clsx from 'clsx';

import { VISIBILITY_WARN_INFO, VISIBILITY_WARN_TITLE } from './constants';
import type { BlockVisibilityWarningMode } from './types';

export interface BlockVisibilityWarningsProps {
  mode: BlockVisibilityWarningMode;
  isOpen: boolean;
  handleClose: () => void;
  onDisregardWarning: () => void;
}

export const BlockVisibilityWarningsModal: FC<BlockVisibilityWarningsProps> = ({
  mode,
  isOpen,
  handleClose,
  onDisregardWarning,
}) => {
  const handleConfirm = () => {
    onDisregardWarning();
    handleClose();
  };

  return (
    <Modal
      title={VISIBILITY_WARN_TITLE[mode]}
      description={VISIBILITY_WARN_INFO[mode]}
      onClose={handleClose}
      open={isOpen}
      className={clsx('max-w-[500px]', MODAL_Z_INDEX)}
      data-testid={getDataTestId('modal.blockVisibilityWarning.modal').toString()}
    >
      <div className="ml-14 mt-12 items-center justify-end gap-x-2.5 sm:mt-10 sm:flex">
        <Button
          className="h-auto px-4 py-2"
          onClick={handleConfirm}
          kind="primary"
          size="sm"
          data-testid={getDataTestId('modal.blockVisibilityWarning.button.continue').toString()}
        >
          Continue
        </Button>
        <Button
          className="h-auto px-4 py-2"
          onClick={handleClose}
          kind="secondary"
          size="sm"
          data-testid={getDataTestId('modal.blockVisibilityWarning.button.cancel').toString()}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
