import { cva } from 'class-variance-authority';

export const sectionClasses = {
  body: [
    'page-A4-width',
    'mx-auto',
    'rounded',
    'relative',
    'h-auto',
    'prose',
    'prose-sm',
    'lg:prose-lg',
    'mrc',
    'cb-wysiwyg',
    'contract-builder',
    'section',
  ],
  title: cva(
    [
      //
      'font-bold',
      'capitalize',
      'w-full',
      'px-mrc-block-padding',
      'section-heading',
      '!m-0',
      'flex',
      'gap-x-2',
    ],
    {
      variants: {
        pdf: {
          true: ['pt-0', 'mb-0'],
          false: ['pt-5', 'pb-1', 'border-b', 'border-info-300'],
        },
      },
    },
  ),
};
