import { Step } from '../types';
import { step1Schema, step2SchemaRefined, step3Schema, step4Schema } from '../validation';

export const getValidationSchema = (currentStep: Step) => {
  switch (currentStep) {
    case Step.GeneralEntityData:
      return step1Schema;

    case Step.BaseEntityData:
      return step2SchemaRefined;

    case Step.MrcFileUpload:
      return step4Schema;

    default:
      return step3Schema;
  }
};
