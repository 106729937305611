import { cva } from 'class-variance-authority';

export const layoutChildrenClasses = cva(['flex'], {
  variants: {
    overflow: {
      true: ['h-full', 'overflow-y-auto'],
      false: ['h-screen'],
    },
    padding: { true: ['py-6'], false: [] },
  },
  defaultVariants: {
    overflow: true,
    padding: true,
  },
});
