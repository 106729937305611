import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { AuthError } from '@Auth/Login/Error';
import { useUserStore } from '@Auth/store';
import { Spinner } from '@components/Spinner';
import type { BaseUser } from '@root/@types/types';
import Nav from '@src/routes';
import axios from 'axios';
import { get } from 'lodash-es';

interface ErrorState {
  hasError: boolean;
  errorCode?: number;
}

const Login = () => {
  const [error, setError] = useState<ErrorState>({ hasError: false });

  const navigate = useNavigate();
  const { setUser } = useUserStore(({ setUser }) => ({
    setUser,
  }));

  const initiateLogin = useCallback(async (params: URLSearchParams): Promise<void> => {
    if (!params.get('CompanyId') || !params.get('User')) {
      setError({ hasError: true });
      return;
    }

    try {
      const { data } = await axios({
        method: 'get',
        withCredentials: true,
        url: `${import.meta.env.VITE_API_URL}/auth?${params.toString()}`,
      });
      window.location.href = `${data.authRedirectUrl}`;
    } catch (err) {
      setError({ hasError: true, errorCode: get(err, 'response.data.artificialErrorCode') });
    }
  }, []);

  const handleRedirect = useCallback(
    async (code: string, state: string) => {
      try {
        const data = {
          code,
          state,
        };

        const response = await axios({
          method: 'post',
          url: `${import.meta.env.VITE_API_URL}/auth`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        });

        const user = {
          username: response.data.user,
          email: response.data.aliasId,
          teamName: response.data.teamName,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          companyId: response.data.companyId,
          companyName: response.data.companyName,
          organisationName: response.data.organisationName,
          role: response.data.role,
          tenantId: '',
          tenantIds: [],
        } as BaseUser;

        setUser(user);
        sessionStorage.setItem('user', JSON.stringify({ ...user, updatedAt: new Date().getTime() }));

        const pathName = get(location, 'state.from.pathname', '').trim();
        return navigate(pathName ? pathName : Nav.Templates);
      } catch (error) {
        console.error('Error exchanging the authorization code:', error);
        setError({ hasError: true, errorCode: get(error, 'response.data.artificialErrorCode') });
      }
    },
    [navigate, setUser],
  );

  useEffectOnce(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const errorCode = window.history.state?.errorCode;

    if (errorCode) {
      setError({ hasError: true, errorCode: parseInt(errorCode) });
      return;
    }

    if (code && state) {
      handleRedirect(code, state);
    } else {
      initiateLogin(params);
    }
  });

  if (error.hasError) {
    return <AuthError errorCode={error.errorCode} />;
  }

  return <Spinner />;
};

export default Login;
