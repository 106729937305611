import { Show } from '@components/Show';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { mdiArchive, mdiCreationOutline } from '@mdi/js';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import clsx from 'clsx';

import { useBlockCreator } from '../../context';
import { StepperCard } from '../Card';
import { StepView } from '../StepView';

export const StepStart = () => {
  const isEndorsementView = useUIStore(({ isEndorsementView }) => isEndorsementView);
  const { setStep } = useBlockCreator();
  const createBlockDescription = isEndorsementView ? 'Create a new summary block' : 'Create a new custom block';

  return (
    <StepView isValid stepId="start">
      <div className={'m-auto flex h-full w-fit items-center gap-10'}>
        <Show when={!isEndorsementView}>
          <StepperCard
            cta="Select block"
            icon={mdiArchive}
            description="Select a block or clause from library"
            onClick={() => setStep('library')}
            data-testid={getDataTestId('block.addFromLibrary').toString()}
          />
        </Show>
        <StepperCard
          cta="Create block"
          icon={mdiCreationOutline}
          description={createBlockDescription}
          onClick={() => setStep('block')}
          data-testid={getDataTestId('block.addCustom').toString()}
          className={clsx(isEndorsementView && 'w-full [&>div]:items-center')}
        />
      </div>
    </StepView>
  );
};
