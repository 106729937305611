/**
 * A centralised place meant for z-index management for the entire web-app
 */

export const CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX = 'z-10';
export const COMMENTS_BADGE_Z_INDEX = 'z-[15]';
export const EDIT_TOOLBAR_Z_INDEX = 'z-[15]';
export const EDITING_BLOCK_Z_INDEX = 'z-20';
export const BLOCK_ACTIONS_Z_INDEX = 'z-20';
export const ITEM_TAGS_Z_INDEX = 'z-20';
export const BLOCK_ACTIONS_HOVERED_Z_INDEX = 'hover:z-[21] group-hover:z-[21]';
export const CONTEXT_MENU_Z_INDEX = 'z-40';
export const CONTRACT_VIEW_Z_INDEX = 'z-50';
export const CONTRACT_BUILDER_FOOTER_Z_INDEX = 'z-[120]';
export const LOADING_OVERLAY_Z_INDEX = 'z-[121]';
export const DRAWER_Z_INDEX = 'z-[997]';
export const PDF_DRAWER_Z_INDEX = 'z-[998]';
export const CONTRACT_BUILDER_HEADER_Z_INDEX = 'z-[999]';
export const POPOVER_Z_INDEX = 'z-[1000]';
export const USER_AVATAR_Z_INDEX = 'z-[1150]';
export const PUBLISHING_OPTIONS_DROPDOWN_Z_INDEX = 'z-[1150]';
export const INSERT_MENU_Z_INDEX = 'z-[1200]';
export const MODAL_Z_INDEX = 'z-[1200]';
