import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Suspense } from 'react';
import { DRAWER_Z_INDEX } from '@constants/z-indices';
import clsx from 'clsx';
import type { MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

interface DrawerLayoutProps {
  className?: string;
  drawerContent: ReactNode;
  isOpen: boolean;
}

const animationProps: MotionProps = {
  animate: {
    x: 0,
  },
  exit: {
    x: '100%',
  },
  initial: {
    x: '100%',
  },
  transition: {
    duration: 0.3,
    ease: 'easeInOut',
  },
};

const DRAWER_WIDTH_CLASS = 'w-1/3 max-w-drawer';

export const DrawerLayout: FC<PropsWithChildren<DrawerLayoutProps>> = ({
  children,
  className,
  drawerContent,
  isOpen,
}) => {
  return (
    <div className={clsx('relative flex h-full w-full overflow-hidden', className)} data-testid="side-drawer">
      <div className="relative h-full grow">{children}</div>
      <Suspense>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              {...animationProps}
              className={clsx(
                'absolute right-0 top-0 flex h-full overflow-y-scroll bg-white',
                DRAWER_WIDTH_CLASS,
                DRAWER_Z_INDEX,
              )}
            >
              {drawerContent}
            </motion.div>
          )}
        </AnimatePresence>
      </Suspense>
    </div>
  );
};
