import { createContext, useContext } from 'react';
import type { Editor } from '@tiptap/react';

interface EditorsMapContextValue {
  deregisterEditor: (name: string) => void;
  getEditor: (name: string) => Editor | null;
  registerEditor: (name: string, editor: Editor) => void;
}
export const EditorsMapContext = createContext<EditorsMapContextValue | null>(null);

export const useEditorsMap = () => {
  const context = useContext(EditorsMapContext);

  if (!context) {
    throw new Error(`"EditorsMapProvider" must be mounted in the React DOM tree.`);
  }

  return context;
};
