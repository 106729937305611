import { cva } from 'class-variance-authority';

import sharedClasses from '../utils/shared-classes';

const { size_sm, size_lg, size_md } = sharedClasses;

export const inputClasses = cva(
  ['appearance-none', 'shadow-sm', 'block', 'w-full', 'text-sm', 'border', 'text-info-700'],
  {
    variants: {
      size: {
        custom: ['pr-3', 'py-2', 'rounded-md', size_md.height],
        sm: ['px-1', 'py-1', 'rounded', size_sm.height],
        md: ['px-3', 'py-2', 'rounded-md', size_md.height],
        lg: ['px-4', 'py-3', 'rounded-md', size_lg.height],
      },
      errors: {
        true: [
          'border-error-500',
          'focus:ring-error-500',
          'focus:border-error-500',
          'border-error-500',
          'text-error-500',
          'border',
        ],
        false: ['border-info-300', 'focus:border-primary-500'],
      },
      isDisabled: { true: sharedClasses.disabled },
      isReadOnly: { true: 'pointer-events-none' },
      isIncomplete: {
        true: 'field-incomplete',
      },
      isPublic: {
        true: ['invalid:border', 'invalid:border-error-500', 'focus:invalid:border-error-500'],
      },
    },
    defaultVariants: {
      size: 'md',
      errors: false,
      isDisabled: false,
      isReadOnly: false,
      isPublic: false,
    },
  },
);
