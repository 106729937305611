import { DATAPOINT } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';

export const brandingDatapoints: SelectMenuItem[] = [
  {
    label: 'Submission Metadata',
    id: 'metadata',
    order: 0,
    type: 'group',

    children: [
      { label: 'Submission ID', id: 'id', order: 0, type: DATAPOINT },
      { label: 'Submission Name', id: 'name', order: 1, type: DATAPOINT },
      { label: 'Submission Team', id: 'team', order: 2, type: DATAPOINT },
      { label: 'Submission Class of Business', id: 'classOfBusiness', order: 3, type: DATAPOINT },
      { label: 'Date Created At', id: 'dateCreatedAt', order: 4, type: DATAPOINT },
      { label: 'Time Created At', id: 'timeCreatedAt', order: 5, type: DATAPOINT },
      { label: 'Date Updated At', id: 'dateUpdatedAt', order: 6, type: DATAPOINT },
      { label: 'Time Updated At', id: 'timeUpdatedAt', order: 7, type: DATAPOINT },
      { label: 'User Created Name', id: 'userCreatedName', order: 8, type: DATAPOINT },
    ],
  },
  {
    label: 'Submission Datapoints',
    id: 'datapoints',
    order: 1,
    type: 'group',
    children: [
      { label: 'Unique Market Reference', id: 'umr', order: 0, type: DATAPOINT },
      {
        label: 'Placing Broker Unique Identification',
        id: 'placingBrokerUniqueIdentification',
        order: 1,
        type: DATAPOINT,
      },
    ],
  },
  {
    label: 'Page Counter',
    id: 'pageCounter',
    order: 2,
    type: 'group',
    children: [
      { label: 'Current Page', id: 'currentPage', order: 0, type: DATAPOINT },
      { label: 'Page Count', id: 'pageCount', order: 1, type: DATAPOINT },
    ],
  },
];
