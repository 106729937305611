import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { uploadMrcExtractionDocument } from '@src/mutations';
import { getMrcExtractionDocumentPresignedUrl } from '@src/queries';
import Nav from '@src/routes';

import { MrcDocumentUploadEmpty } from '../views/MrcDocumentUploadEmpty';
import { MrcDocumentUploadLoading } from '../views/MrcDocumentUploadLoading';

interface UploadState {
  error?: string;
  isLoading: boolean;
}

export const MrcDocumentUploadController = () => {
  const { submissionId } = useParams();

  const [uploadState, setUploadState] = useState<UploadState>({ error: undefined, isLoading: false });
  const navigate = useNavigate();

  const handleFileUpload = async (files: File[]) => {
    if (files.length < 1) {
      return;
    }

    setUploadState((current) => ({ ...current, isLoading: true }));
    const file = files[0];
    const response = await getMrcExtractionDocumentPresignedUrl(submissionId as string, file.name);

    if (response.status !== 201) {
      return setUploadState({ error: 'Failed to upload', isLoading: false });
    }

    await uploadMrcExtractionDocument(response.data.data.url, {
      ...response.data.data.fields,
      file,
    });

    const newSubmissionPath = generatePath(Nav.SubmissionForm, {
      id: submissionId as string,
    });

    return navigate(newSubmissionPath);
  };

  if (uploadState.isLoading) {
    return <MrcDocumentUploadLoading />;
  }

  return (
    <div className="relative h-full px-8">
      <div className="relative flex h-3/5 flex-col justify-center rounded-lg bg-info-50 p-6 text-center">
        <MrcDocumentUploadEmpty error={uploadState.error} onFileUpload={handleFileUpload} />
      </div>
    </div>
  );
};
