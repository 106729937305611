import type { EntityData } from '@ContractBuilder/types';
import type { DocumentContext } from '@ContractBuilder/utils';
import { canChange } from '@helpers/canChange';
import type { ResourceBlock } from '@root/@types/base';
import type { BaseUser } from '@root/@types/types';

import { crossCheckBlockUserCtx } from './cross-check-block-user-ctx';

export type BlockRulesIsDeletableArgs = {
  block: ResourceBlock;
  context: DocumentContext;
  isRevisionHistory: boolean;
  status: EntityData['status'];
  user: BaseUser | null;
};

export const blockRulesIsDeletable = ({
  block,
  context,
  isRevisionHistory,
  status,
  user,
}: BlockRulesIsDeletableArgs) => {
  const documentConditionsAllowChange = canChange(status) && !isRevisionHistory;
  const { canBeDeletedWithCurrentPermissions } = crossCheckBlockUserCtx(block, context, user);

  if (!documentConditionsAllowChange || !canBeDeletedWithCurrentPermissions) {
    return false;
  }

  return canBeDeletedWithCurrentPermissions;
};
