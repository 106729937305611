import React, { useMemo } from 'react';
import InputCurrency, { type InputCurrencyProps } from '@components/InputCurrency';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import type { UISchema } from '@root/@types/types';
import { get } from 'lodash-es';

interface InputCurrencySchemaProps extends InputCurrencyProps {
  item: UISchema;
  ownKey: string;
}

const getCdrDetails = (item: UISchema, cdrId?: string | string[], cdrName?: string | string[]) => {
  if (cdrId && cdrName) {
    return { cdrName, cdrId };
  }

  const amountCdrId = get(item, 'properties.amount.cdrId', cdrId) as string;
  const amountCdrName = get(item, 'properties.amount.cdrName', cdrName) as string;

  const currencyCdrId = get(item, 'properties.currency.cdrId', cdrId) as string;
  const currencyCdrName = get(item, 'properties.currency.cdrName', cdrName) as string;

  return {
    cdrId: [amountCdrId, currencyCdrId],
    cdrName: [amountCdrName, currencyCdrName],
  };
};

const InputCurrencySchema: React.FC<InputCurrencySchemaProps> = ({ item: itemSchema, ...props }) => {
  const { ownKey, isRequired, errors } = props;

  const selector = useMemo(
    () =>
      datapointFieldSelector({ ownKey, isRequired, childrenIds: [`${ownKey}.amount`, `${ownKey}.currency`], errors }),
    [ownKey, isRequired, errors],
  );
  const { value = {}, isIncomplete } = useDatapointsStore(selector);

  const currenciesList = get(itemSchema, 'properties.currency.enum', undefined) as string[] | undefined;

  const cdrDetails = getCdrDetails(itemSchema, props.cdrId, props.cdrName);

  return (
    <InputCurrency
      {...cdrDetails}
      isIncomplete={isIncomplete}
      value={value}
      currencies={currenciesList}
      minimum={get(itemSchema, 'properties.amount.minimum', 0) as number}
      maximum={get(itemSchema, 'properties.amount.maximum', Number.MAX_SAFE_INTEGER) as number}
      {...props}
    />
  );
};

export default InputCurrencySchema;
