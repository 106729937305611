// @ts-expect-error: We need to wait until [this](https://github.com/ueberdosis/tiptap/issues/3488) is resolved.
import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

export interface ContentRepeaterGroupOptions {
  HTMLAttributes: Record<string, any>;
}

export const ContentRepeaterGroup = Node.create<ContentRepeaterGroupOptions>({
  name: 'ContentRepeaterGroup',
  content: 'block+',
  allowGapCursor: true,
  selectable: true,
  isolating: true,
  group: 'block',

  addAttributes() {
    return {
      parentId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-parentid'),
        renderHTML: (attributes: Record<string, any>) => {
          return {
            'data-parentid': attributes.parentId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div`,
        getAttrs: (node: HTMLElement) => node.getAttribute('data-type') === 'content-repeater-group' && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return ['div', mergeAttributes({ 'data-type': 'content-repeater-group' }, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(() => (
      <NodeViewWrapper>
        <NodeViewContent className="rounded-md" />
      </NodeViewWrapper>
    ));
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        filterTransaction: (transaction, state) => {
          let result = true; // true for keep, false for stop transaction

          const {
            doc: { content },
          } = transaction;

          content.forEach((node) => {
            if (node.type.name === 'ContentRepeaterGroup') {
              const correspondingSectionRepeaters: Node[] = [];

              state.doc.content.forEach((stateNode) => {
                if (
                  stateNode.type.name === 'ContentRepeaterGroup' &&
                  stateNode.attrs.parentId === node.attrs.parentId
                ) {
                  correspondingSectionRepeaters.push(stateNode);
                }
              });

              correspondingSectionRepeaters.forEach((sectionRepeaterNode) => {
                if (sectionRepeaterNode.childCount !== node.childCount) {
                  result = false;
                }
              });
            }
          });

          return result;
        },
      }),
    ];
  },
});
