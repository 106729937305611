import type { FC, ReactNode } from 'react';
import React from 'react';
import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

interface DivWithTag extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ReactNode;
}

interface HeaderProps extends DivWithTag {
  headingText?: string;
  descriptionText?: ReactNode;
}

interface CardProps extends HeaderProps {
  content?: string;
  header?: ReactNode;
}

type ContentComponent = FC<UIComponentBase>;
type DescriptionComponent = FC<UIComponentBase>;
type HeaderComponent = FC<HeaderProps>;
type HeadingComponent = FC<DivWithTag>;

type CardComponent = FC<CardProps> & {
  Content: ContentComponent;
  Description: DescriptionComponent;
  Header: HeaderComponent;
  Heading: HeadingComponent;
};

const Heading: HeadingComponent = ({ className, children, tag, ...props }) => (
  <div className="flex items-center" {...props}>
    <h3 className={clsx('mr-4 text-xl font-semibold leading-relaxed', className)}>{children}</h3>
    {tag}
  </div>
);

const Description: DescriptionComponent = ({ className, children }) => (
  <div className={clsx('mt-2 text-sm leading-snug', className)}>{children}</div>
);

const Header: HeaderComponent = ({ className, headingText, descriptionText, children, tag, onClick }) => (
  <div className={clsx('', !clsx(className).match(/(p[xy]?)-(\d+)/) && 'p-4', className)} onClick={onClick}>
    {headingText && <Heading tag={tag}>{headingText}</Heading>}

    {descriptionText && <Description>{descriptionText}</Description>}

    {children}
  </div>
);

const Content: ContentComponent = ({ className, children }) => (
  <div className={clsx('px-4 pt-4', className)}>{children}</div>
);

const Card: CardComponent = ({ children, className, content, descriptionText, header, headingText, tag, ...props }) => (
  <div className={clsx('', className)} {...props}>
    {header}

    {!header && (headingText || descriptionText) && (
      <Header headingText={headingText} descriptionText={descriptionText} tag={tag} />
    )}

    {content && <Content>{content}</Content>}

    {children}
  </div>
);

Card.Content = Content;
Card.Description = Description;
Card.Header = Header;
Card.Heading = Heading;

export default Card;
